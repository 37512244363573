// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
export class FieldNames {
    
    public static readonly ActionContext: string = "ActionContext";
    public static readonly ActionName: string = "ActionName";
    public static readonly Actions: string = "Actions";
    public static readonly AirplaneID: string = "AirplaneID";
    public static readonly AllowFavoriteSearchResult = "AllowFavoriteSearchResult" as const;    
    public static readonly ApplicationName: string = "ApplicationName";
    public static readonly ApplicationVersion: string = "ApplicationVersion";
    public static readonly AssistantMessage: string = "AssistantMessage";
    public static readonly AssistantTokens: string = "AssistantTokens";
    public static readonly Author: string = "Author";
    public static readonly AzureGroupCsv: string = "AzureGroupCsv";
    public static readonly B2CStatus = "B2CStatus" as const;
    public static readonly BatchIdentifier: string = "BatchIdentifier" as const;    
    public static readonly BlobID: string = "BlobID";
    public static readonly CapacityK_TPM: string = "CapacityK_TPM";
    public static readonly CategoryName: string = "CategoryName";
    public static readonly ChatLogID: string = "ChatLogID";
    public static readonly ClientEnvironment: string = "ClientEnvironment";
    public static readonly CommitmentType = "CommitmentType" as const;
    public static readonly ConnectAsAdmin: string = "ConnectAsAdmin";
    public static readonly ConnectedAt: string = "ConnectedAt";
    public static readonly ConnectedAtUtc: string = "ConnectedAtUtc";
    public static readonly Content: string = "Content";
    public static readonly Count: string = "Count";
    public static readonly CreatedAt: string = "CreatedAt";
    public static readonly CreatedBy: string = "CreatedBy";
    public static readonly CreationDate: string = "CreationDate";
    public static readonly CurrentStep: string = "CurrentStep";
    public static readonly DataURL: string = "DataURL";
    public static readonly Date: string = "Date" as const;
    public static readonly DBVersion: string = "DBVersion";
    public static readonly DeliveryMethod: string = "DeliveryMethod" as const;
    public static readonly DeploymentID: string = "DeploymentID";
    public static readonly DeploymentName: string = "DeploymentName";
    public static readonly DeploymentStatus: string = "DeploymentStatus";
    public static readonly DeploymentVersion: string = "DeploymentVersion";
    public static readonly DisclaimerAgreementId: string = "DisclaimerAgreementId";
    public static readonly Document: string = "Document";
    public static readonly DocumentDate: string = "DocumentDate";
    public static readonly DocumentName: string = "DocumentName";
    public static readonly DownloadActions: string = "DownloadActions";
    public static readonly DurationInMs: string = "DurationInMs";
    public static readonly DurationMs: string = "DurationMs";
    public static readonly EmailAddress: string = "EmailAddress";
    public static readonly EmailWhitelistID = "EmailWhitelistID" as const;
    public static readonly EmbeddingStatus: string = "EmbeddingStatus";
    public static readonly EntityID: string = "EntityID";
    public static readonly EntityType: string = "EntityType";
    public static readonly EnvironmentName: string = "EnvironmentName";
    public static readonly ErrorMessage: string = "ErrorMessage";
    public static readonly ExampleTokens: string = "ExampleTokens";
    public static readonly ExceptionPagesCsv: string = "ExceptionPagesCsv";
    public static readonly ExternalGroupID: string = "ExternalGroupID";
    public static readonly FavoriteLastUpdatedAt = 'FavoriteLastUpdatedAt' as const;
    public static readonly FavoriteLastUpdatedBy = 'FavoriteLastUpdatedBy' as const;
    public static readonly FavoriteNotes = 'FavoriteNotes' as const;
    public static readonly FileName: string = "FileName";
    public static readonly FileOutputFormat: string = "FileOutputFormat";
    public static readonly FileSize = "FileSize" as const;
    public static readonly FirstName: string = "FirstName";
    public static readonly FrequencyPenalty: string = "FrequencyPenalty";
    public static readonly HasOverride = "HasOverride" as const;
    public static readonly ID = "ID" as const;
    public static readonly InputData = "InputData" as const;
    public static readonly InputFormat: string = "InputFormat";
    public static readonly InputTokens: string = "InputTokens";
    public static readonly IsChunked: string = "IsChunked";
    public static readonly LastName: string = "LastName";
    public static readonly LastSyncedAtUtc: string = "LastSyncedAtUtc";
    public static readonly LastSyncedBy: string = "LastSyncedBy";
    public static readonly LastUpdated = "LastUpdated" as const;
    public static readonly LastUpdatedAt = "LastUpdatedAt" as const;
    public static readonly LastUpdatedAtUtc = "LastUpdatedAtUtc" as const;
    public static readonly LastUpdatedBy = "LastUpdatedBy" as const;
    public static readonly LawFirmName: string = "LawFirmName";
    public static readonly LogMessage: string = "LogMessage";
    public static readonly MarkScheduleOfExceptions: string = "Mark Schedule of Exceptions";
    public static readonly MatterName: string = "MatterName";
    public static readonly MaxOutputTokens: string = "MaxOutputTokens";
    public static readonly MezzLoanChunkID: string = "MezzLoanChunkID";
    public static readonly MezzLoanID: string = "MezzLoanID";
    public static readonly ModelInputTokens: string = "ModelInputTokens";
    public static readonly ModelName: string = "ModelName";
    public static readonly ModelOutputTokens: string = "ModelOutputTokens";
    public static readonly ModelTotalTokens: string = "ModelTotalTokens";
    public static readonly ModelType: string = "ModelType";
    public static readonly ModelVersion: string = "ModelVersion";
    public static readonly MoonNotificationID = "MoonNotificationID" as const;
    public static readonly MultipleFileProcessing: string = "MultipleFileProcessing";
    public static readonly Name = "Name" as const;
    public static readonly NameIdentifier: string = "NameIdentifier";
    public static readonly NodeEnvironmentID: string = "NodeEnvironmentID";
    public static readonly Notes = "Notes" as const;
    public static readonly NotificationDescription = "NotificationDescription" as const;
    public static readonly NotificationType = "NotificationType" as const;
    public static readonly ObjectIdentifier: string = "ObjectIdentifier";
    public static readonly OptionName: string = "OptionName";
    public static readonly OptionValue: string = "OptionValue";
    public static readonly Ordinal: string = "Ordinal";
    public static readonly OutputData = "OutputData" as const;
    public static readonly OverrideData = "OverrideData" as const;    
    public static readonly PageNumber = "pageNumber" as const;
    public static readonly ParagraphID: string = "ParagraphID";
    public static readonly Part: string = "Part";
    public static readonly PreferredUserName: string = "PreferredUserName";
    public static readonly PresencePenalty: string = "PresencePenalty";
    public static readonly ProcessingStatus: string = "ProcessingStatus";
    public static readonly ProductName: string = "ProductName";
    public static readonly PropertyState: string = 'PropertyState';
    public static readonly PropertyType: string = 'PropertyType';
    public static readonly ReadableProcessingStatus: string = "ReadableProcessingStatus";
    public static readonly Region: string = "Region";
    public static readonly relevance = "relevance" as const;
    public static readonly RequestCost: string = "RequestCost";
    public static readonly RequestTokens: string = "RequestTokens";
    public static readonly ResponseCost: string = "ResponseCost";
    public static readonly ResponseTokens: string = "ResponseTokens";
    public static readonly ResultButtons: string = "ResultButtons";
    public static readonly Results: string = "Results";
    public static readonly RevisedContent: string = "RevisedContent";
    public static readonly RevisionStatus: string = "RevisionStatus";
    public static readonly RoleCode: string = "RoleCode";
    public static readonly savedAsFavorite = 'savedAsFavorite' as const;
    public static readonly SearchResultJson: string = "SearchResultJson";
    public static readonly SearchTableNumber: string = "SearchTableNumber";
    public static readonly ServiceName: string = "ServiceName";
    public static readonly SimilarChunks: string = "SimilarChunks";
    public static readonly SkillExample: string = "SkillExample";
    public static readonly SkillID = "SkillID" as const;
    public static readonly SkillMessageHashMD5: string = "SkillMessageHashMD5";
    public static readonly SkillName: string = "SkillName";
    public static readonly SkillNumber: string = "SkillNumber" as const;
    public static readonly SkillStatus: string = "SkillStatus";
    public static readonly SkillSubType: string = "SkillSubType";
    public static readonly SkillTestDataID = "SkillTestDataID" as const;
    public static readonly SkillType: string = "SkillType";
    public static readonly SkillVersion: string = "SkillVersion";
    public static readonly SortKey: string = "SortKey";
    public static readonly SqlMessage: string = "SqlMessage";
    public static readonly Status: string = "Status";
    public static readonly StopSequence: string = "StopSequence";
    public static readonly SubscriberUserName: string = "SubscriberUserName" as const;
    public static readonly Subscription: string = "Subscription";
    public static readonly Success: string = "Success";
    public static readonly SummaryType: string = "SummaryType";
    public static readonly SystemMessage = "SystemMessage" as const;
    public static readonly SystemTokens: string = "SystemTokens";
    public static readonly Temperature: string = "Temperature";
    public static readonly TemplateFileName: string = "TemplateFileName";
    public static readonly TemplateID: string = "TemplateID";
    public static readonly TemplateName: string = "TemplateName";
    public static readonly TemplateStorageLocation: string = "TemplateStorageLocation";
    public static readonly TemplateType: string = "TemplateType";
    public static readonly TermsPagesCsv: string = "TermsPagesCsv";
    public static readonly TestEnvironment: string = "TestEnvironment";
    public static readonly TitleSummaryBlobID: string = "TitleSummaryBlobID";
    public static readonly TitleSummaryID: string = "TitleSummaryID";
    public static readonly Tokens: string = "Tokens";
    public static readonly TokenSize: string = "TokenSize";
    public static readonly TopP: string = "TopP";
    public static readonly TotalCost: string = "TotalCost";
    public static readonly TotalTokens: string = "TotalTokens";
    public static readonly TotalVectors: string = "TotalVectors";
    public static readonly TraceJson: string = "TraceJson";
    public static readonly UserGroupID: string = "UserGroupID";
    public static readonly UserGroupName: string = "UserGroupName";
    public static readonly UserInfoID: string = "UserInfoID";
    public static readonly UserMessage: string = "UserMessage";
    public static readonly UserName: string = "UserName";
    public static readonly UserOptionID: string = "UserOptionID";
    public static readonly UserOptionMapID: string = "UserOptionMapID";
    public static readonly UserSessionID: string = "UserSessionID";
    public static readonly UserStatus: string = "UserStatus";
    public static readonly UserTokens: string = "UserTokens";
    public static readonly UserType: string = "UserType";
    public static readonly ValidUntil: string = "ValidUntil";
    public static readonly ValidUntilUtc: string = "ValidUntilUtc";
    public static readonly Vector: string = "Vector";
    public static readonly VectorAsCsv: string = "VectorAsCsv";
    public static readonly Version: string = "Version";
    public static readonly WebAppURL: string = "WebAppURL";
    public static readonly WhitelistValue = "WhitelistValue" as const;
    public static readonly XMLLastUpdatedAtUtc: string = "XMLLastUpdatedAtUtc";
    public static readonly XMLLastUpdatedBy: string = "XMLLastUpdatedBy";
    
}
