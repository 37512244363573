<moon-form-page
    *ngIf="!MSLoading"
    [MSParentForm]="MSContactSupportForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    [MSDisplaySaveButton]="false"
>
    <div
        [formGroup]="MSContactSupportForm"
    >
        <moon-form-control
            [MSLabel]="'Message'"
            [MSFormControl]="MSContactSupportForm.controls.message"
            [MSLabel]="'Please describe your issue here'"
            [MSLabelSize]="12"
            [MSInputSize]="12"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="message.value"
            >
                <textarea
                    #message
                    pInputTextarea
                    [formControl]="MSContactSupportForm.controls.message"
                > </textarea>
            </div>
        </moon-form-control>

        <moon-form-control
            [MSFormControl]="MSContactSupportForm.controls.contactInfo"
            [MSLabel]="'Contact Phone or Email'"
            [MSLabelSize]="12"
            [MSInputSize]="12"
        >
            <input
                id="ContactInfo"
                type="text"
                pInputText
                formControlName="contactInfo"
            >
        </moon-form-control>

        <moon-form-control
            [MSFormControl]="MSContactSupportForm.controls.contactTime"
            [MSLabel]="'Can we contact you? If yes, when is a good time?'"
            [MSLabelSize]="12"
            [MSInputSize]="12"
        >
            <input
                id="ContactInfo"
                type="text"
                pInputText
                formControlName="contactTime"
            >
        </moon-form-control>

        

    </div>

    <div class="flex justify-content-end gap-2">
        <div
            class="progress-bar"
            *ngIf="MSIsFormSubmitting"
        >
            Please wait...
        </div>
        <div class="flex justify-content-end gap-2">
            <button
                type="button"
                pButton
                pRipple
                label="Submit"
                class="w-init p-button-raised mr-2"
                [disabled]="MSIsFormSubmitting"
                (click)="MSOnClickSubmit()"
            > </button>
            <button
                pButton
                pRipple
                type="button"
                label="Cancel"
                [disabled]="MSIsFormSubmitting"
                class="w-init p-button-raised p-button-secondary"
                (click)="MSOnClickCancel()"
            > </button>
        </div>
    </div>
</moon-form-page>