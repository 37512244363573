import { Injectable }                                 from '@angular/core';
// MS Imports
import { ApiBlobResult, ApiContentResult, ApiResult } from '@moon-core/models/api-result';
import { MoonApiClient }                              from '@moon-core/services/moon-api.client';
import { DocumentOcr }                                from '@moon-public/api/response/document-ocr.response';
import { TitleDocumentGet }                           from '@moon-public/api/response/title-document.response';
import { RoutePath }                                  from '@moon-shared/constants/route-path';
import { ChatResult }                                 from '@moon-public/api/response/chat-result.response';
import { ChatSetting }                                from '@moon-maintainer/api/request/chat-setting.request';

@Injectable()
export class TitleDocumentController {

  private getBaseUrl(titleSummaryID: number): string {
    return this._moonApi.combineUrl(this._moonApi.getBaseUrl(RoutePath.TitleSummary),
      titleSummaryID, RoutePath.TitleDocument);
  }

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}
   */
  public async GetByID(titleSummaryID: number, titleDocumentID: number
  ): Promise<ApiContentResult<TitleDocumentGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID);
    return this._moonApi.getAsync<TitleDocumentGet>(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/ChatSetting/Summarize
   */
  public async GetChatSettingSummary(titleSummaryID: number): Promise<ApiContentResult<ChatSetting>> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), RoutePath.ChatSetting, RoutePath.Summarize);
    return this._moonApi.getAsync<ChatSetting>(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/ChatSetting/ExceptionNumber
   */
  public async GetChatSettingExceptionNumber(titleSummaryID: number): Promise<ApiContentResult<ChatSetting>> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), RoutePath.ChatSetting, RoutePath.ExceptionNumber);
    return this._moonApi.getAsync<ChatSetting>(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/Blob
   */
  public async GetBlob(titleSummaryID: number, titleDocumentID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.Blob);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   DELETE: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}
   */
  public async Delete(titleSummaryID: number, titleDocumentID: number): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID);
    return this._moonApi.deleteAsync(url);
  }

  /**
   *   DELETE: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/Blob
   */
  public async DeleteBlob(titleSummaryID: number, titleDocumentID: number): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.Blob);
    return this._moonApi.deleteAsync(url);
  }
  
  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/Blob
   */
  public async PutBlob(titleSummaryID: number, titleDocumentID: number, file: File
  ): Promise<ApiResult> {

    const formData = new FormData();
    formData.set('inputFile', file);
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.Blob);
    return this._moonApi.putAsync(url, formData);
  }

  // public async Process(titleDocumentID: number
  //   ): Promise<ApiResult> {

  //   const url = this._moonApi.combineUrl(this.getBaseUrl(), titleDocumentID, RoutePath.Process);
  //   return this._moonApi.postAsync(url, null);
  // }  

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/Ocr
   */
  public async GetOcr(titleSummaryID: number, titleDocumentID: number
  ): Promise<ApiContentResult<DocumentOcr>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.Ocr);
    return this._moonApi.getAsync<DocumentOcr>(url);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/Ocr
   */
  public async PostOcr(titleSummaryID: number, titleDocumentID: number): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.Ocr);
    return this._moonApi.postAsync(url, null);
  }

  /**
    *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/PromptTitleDocumentOcrText
    */
  public async GetTitleDocumentOcrText(
    titleSummaryID: number, titleDocumentID: number
  ): Promise<ApiContentResult<ChatResult>> {
    const url = this._moonApi.combineUrl(
      this.getBaseUrl(titleSummaryID),
      titleDocumentID,
      RoutePath.PromptTitleDocumentOcrText
    );
    return this._moonApi.getAsync<ChatResult>(url);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/PromptExceptionNumber
   */
  public async PromptExceptionNumber(titleSummaryID: number, titleDocumentID: number, chatSetting: ChatSetting | null
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.PromptExceptionNumber);
    return this._moonApi.postAsync(url, chatSetting);
  }
  
  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/PromptSummary
   */
  public async PromptSummary(titleSummaryID: number, titleDocumentID: number, chatSetting: ChatSetting | null
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.PromptSummary);
    return this._moonApi.postAsync(url, chatSetting);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/ChatLog/{chatLogID}/Override
   */
  public async ChatLogDataOverride(titleSummaryID: number, titleDocumentID: number, chatLogID: number)
    : Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.ChatLog, chatLogID, RoutePath.Override);
    return this._moonApi.postAsync(url, {});
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/OverrideExceptionNumber
   */
  public async OverrideExceptionNumber(titleSummaryID: number, titleDocumentID: number, exceptionType: string
  ): Promise<ApiResult> {
    const form = new FormData();
    form.set('exceptionNumberJson', exceptionType);
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.OverrideExceptionNumber);
    return this._moonApi.putAsync(url, form);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/OverrideExceptionType
   */
  public async OverrideExceptionType(titleSummaryID: number, titleDocumentID: number, exceptionType: string
  ): Promise<ApiResult> {
    const form = new FormData();
    form.set('exceptionType', exceptionType);
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.OverrideExceptionType);
    return this._moonApi.putAsync(url, form);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/OverrideRecommendedAction
   */
  public async OverrideRecommendedAction(titleSummaryID: number, titleDocumentID: number, recommendedAction: string
  ): Promise<ApiResult> {
    const form = new FormData();
    form.set('recommendedAction', recommendedAction);
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.OverrideRecommendedAction);
    return this._moonApi.putAsync(url, form);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/OverrideSummaryText
   */
  public async OverrideSummaryText(titleSummaryID: number, titleDocumentID: number, summaryText: string
  ): Promise<ApiResult> {
    const form = new FormData();
    form.set('summaryText', summaryText);
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.OverrideSummaryText);
    return this._moonApi.putAsync(url, form);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/{titleDocumentID}/ClearLogMessage
   */
  public async ClearLogMessage(titleSummaryID: number, titleDocumentID: number): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleDocumentID, RoutePath.ClearLogMessage);
    return this._moonApi.putAsync(url, null);
  }

}