// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
import { Component, EventEmitter, Input, OnInit,
    Output,
    ViewChild,
    ViewEncapsulation }                 from "@angular/core";
import { NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { FileUploadHandlerEvent }       from "primeng/fileupload";
import { Table, TableModule }           from "primeng/table";
// Moon imports
import { ApiBlobResult, ApiResult }     from "@moon-core/models/api-result";
import { ComponentUtilityService }      from "@moon-core/services/component-utility.service";

import { MoonMessageService }           from "@moon-shared/services/moon-message.service";
import { MessageSeverity }              from "@moon-shared/constants/message-severity";
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";

import { SkillTestDataGet }             from "@moon-maintainer/api/response/skill-test-data-get.response";
import { SkillTestDataController }      from "@moon-maintainer/api/skill-test-data.controller";
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";

@Component({
    selector: "moon-skill-test-data-table",
    templateUrl: "./skill-test-data-table.component.html",
    styleUrls: ['./skill-test-data-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        TableModule, ButtonModule, InputTextModule,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent
    ],
    providers: [
        MaintainerRouter, SkillTestDataController
    ]
})
export class SkillTestDataTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSSkillNumber: string = String.empty;
    @Input() public MSSkillTestDataList: SkillTestDataGet[];
    @Input() public MSLoading = true;
    @Output() MSRefreshEvent = new EventEmitter();
    @ViewChild("MSTableHeader") MSTableHeader: MoonTableHeaderComponent;

    public MSUploading: boolean = false;

    public MSSelectedSkillTestDataList: SkillTestDataGet[] = [];

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.InputData.toCamelCase(), header: FieldNames.InputData.getDisplayName(), class: 'w-45per'},
		{field: FieldNames.OutputData.toCamelCase(), header: FieldNames.OutputData.getDisplayName()},
        // Column PlaceHolder - DO NOT REMOVE
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;

    constructor(
        private _maintainerRouter: MaintainerRouter,
        private _componentUtilityService: ComponentUtilityService,
        private _skillTestDataController: SkillTestDataController,
        private _moonMessageService: MoonMessageService,
    ) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: SkillTestDataGet): void {
        this._maintainerRouter.ToSkillTestDataEdit(this.MSSkillNumber, item.skillTestDataID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.ToSkillTestDataAdd(this.MSSkillNumber);
    }

    public OnClickFind(): void {
        this._maintainerRouter.ToSkillTestData(this.MSSkillNumber);
    }

    public async OnClickExport() {
        this._moonMessageService.showToastMessage(MessageSeverity.Info, "Processing. Please wait.");

        const apiResult: ApiBlobResult = await this._skillTestDataController.ExportAsync(this.MSSkillNumber);
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.showToastMessage(MessageSeverity.Success, "Skill Test Data exported successfully.");
        }
    }

    public async OnUploadFile(event: FileUploadHandlerEvent) {
        this.MSUploading = true;
        if (event.files.length > 0 && event.files[0]){
            this._moonMessageService.showToastMessage(MessageSeverity.Info, "Processing. Please wait.");

            try {
                const apiResult: ApiResult = await this._skillTestDataController.ImportAsync(this.MSSkillNumber, event.files[0]);            
                if (this._componentUtilityService.WasSuccessful(apiResult)) {
                    this._moonMessageService.toastSuccess('Skill Test Data imported successfully.');
                    this.MSRefreshEvent.emit();
                }
            }
            finally {
                this.MSUploading = false;
                this.MSTableHeader.MSClearFile();
            }
        }
    }
    
    public OnSearchInput(SkillTestDataTable: Table, searchInput: string): void {
        SkillTestDataTable.filterGlobal(searchInput, "contains");
    }
}
