// Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM
import { NgIf }                         from '@angular/common';
import { Component, OnInit }            from '@angular/core';
// Moon Imports   
import { AppBreadcrumbService }         from '@app/app-layout/services/app.breadcrumb.service';
import { PublicRouter }                 from '@app/moon-public/public.router';
import { ApiContentResult }             from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { MezzLoanController }           from '@moon-public/api/mezz-loan.controller';
import { FindMezzLoanParameter }        from "@moon-public/api/request/find-mezz-loan.parameter";
import { MezzLoanFind }                 from "@moon-public/api/response/mezz-loan-find.response";
import { MezzLoanTableComponent }       from '@moon-public/mezz-loan/mezz-loan-table/mezz-loan-table.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';

@Component({
  selector: 'mezz-loan',
  templateUrl: './mezz-loan.component.html',
  styleUrls: ['./mezz-loan.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, MezzLoanTableComponent],
  providers: [MezzLoanController, PublicRouter]
})
export class MezzLoanComponent implements OnInit {

  public MSMezzLoanList: MezzLoanFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.MezzLoan.getDisplayName();
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _mezzLoanController: MezzLoanController
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadMezzLoanList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  private async loadMezzLoanList() {
    this.MSLoading = true;
    
    const findParameter = new FindMezzLoanParameter();
    const apiResult: ApiContentResult<MezzLoanFind[]> = await this._mezzLoanController.Find(findParameter);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSMezzLoanList = apiResult.content;
    }
    this.MSLoading = false;  
  }

  public MSReloadPage() {
    this.loadMezzLoanList();
  }
}
