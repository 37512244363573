import { NgIf }                                         from "@angular/common";
import { Component, HostListener, OnDestroy, OnInit }   from "@angular/core";
import { RouterOutlet }                                 from "@angular/router";
// Third-party imports
import { Message, PrimeNGConfig }                       from "primeng/api";
import { ConfirmDialogModule }                          from "primeng/confirmdialog";
// Moon Imports
import { MoonAppState }                                 from "@app/moon-app-state";
import { MoonApplicationInsight }                       from "@moon-core/services/moon-application-insight.service";
import { MsalWrapperService }                           from "@moon-core/services/msal-wrapper-service";
import { UserSessionService }                           from "@moon-core/services/user-session.service";
import { MoonDialogComponent }                          from "@moon-shared/components/moon-dialog/moon-dialog.component";
import { MoonErrorMessageComponent }                    from "@moon-shared/components/moon-error-message/moon-error-message.component";
import                                                       "@moon-shared/helper-extension/extension-importer";
import { MoonMessageService }                           from "@moon-shared/services/moon-message.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [NgIf, RouterOutlet, MoonErrorMessageComponent, ConfirmDialogModule, MoonDialogComponent],
})
export class AppComponent implements OnInit, OnDestroy {

    private readonly _id: string;
    topbarTheme: string = "dark";

    menuTheme: string = "light";

    layoutMode: string = "light";

    menuMode: string = "static";

    inlineMenuPosition: string = "bottom";

    inputStyle: string = "filled";

    ripple: boolean = true;

    isRTL: boolean = false;

    public ShowInlineMenu: boolean = true;

    public NsIsIFrame: boolean = false;

    public MSMessages: Message[];


    constructor(
        private _moonAppState: MoonAppState,
        private _msalWrapperService: MsalWrapperService,
        private _primengConfig: PrimeNGConfig,
        private _userSessionService: UserSessionService,
        private _moonApplicationInsight: MoonApplicationInsight,
        private _moonMessageService: MoonMessageService
    ) {
        this._id = String.currentTimeStamp();
        this._moonAppState.setAppComponentId(this._id);
    }

    ngOnInit() {
        this.subscribeToMoonMessages();

        this.NsIsIFrame = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
        this._primengConfig.ripple = true;

        this._msalWrapperService.Initialize();
        console.log(this._moonAppState);
    }

    private subscribeToMoonMessages() {
        return this._moonMessageService.GetMessages()
        .subscribe((messages) => {
            this.MSMessages = messages;
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler() {
        this._moonApplicationInsight.logEvent("Browser Close");
        this._userSessionService.HandleWindowBeforeUnload();
    }

    ngOnDestroy(): void {
        this._msalWrapperService.CleanUp();
    }
}
