import { Directive, Input, TemplateRef } from '@angular/core';

type MoonTemplates = "filter" | "button-right" | "button-left";

@Directive({
    selector: '[moonTemplate]',
    standalone: true
})
export class MoonTemplateDirective {
    @Input() moonTemplate: MoonTemplates;
    constructor(private _filterTemplateRef: TemplateRef<any>) {
    }

    public MSGetFilterTemplate(): TemplateRef<any> | null {
        if ("filter".equalsIgnoreCase(this.moonTemplate)) {
            return this._filterTemplateRef
        }
        return null;
    }
    public MSGetRightButtonTemplate(): TemplateRef<any> | null {
        if ("button-right".equalsIgnoreCase(this.moonTemplate)) {
            return this._filterTemplateRef
        }
        return null;
    }
    public MSGetLeftButtonTemplate(): TemplateRef<any> | null {
        if ("button-left".equalsIgnoreCase(this.moonTemplate)) {
            return this._filterTemplateRef
        }
        return null;
    }
}
