// Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM
import { Component, Input, OnInit }         from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
         ReactiveFormsModule, Validators }  from '@angular/forms';
// Third Party Imports
import { SharedModule }                     from 'primeng/api';
import { InputTextModule }                  from 'primeng/inputtext';
// Moon Imports
import { IApiResult }                       from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { Deployment }                       from '@moon-maintainer/api/request/deployment.request';
import { DeploymentGet }                    from "@moon-maintainer/api/response/deployment-get.response";
import { DeploymentController }             from '@moon-maintainer/api/deployment.controller';
import { MaintainerRouter }                 from "@moon-maintainer/maintainer.router";
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';


@Component({
    selector: 'deployment-form',
    templateUrl: './deployment-form.component.html',
    styleUrls: ['./deployment-form.component.css'],
    standalone: true,
    imports: [ 
        FormsModule, ReactiveFormsModule, SharedModule,
        InputTextModule,
        MoonFormPageComponent, MoonFormControlComponent
    ],
    providers: [DeploymentController]
})
export class DeploymentFormComponent implements OnInit {
    @Input() public MSDeploymentGet: DeploymentGet;
    @Input() public MSDeploymentID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<Deployment>> = new FormGroup<FormGroupOf<Deployment>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		deploymentName: new FormControl(null, [Validators.required]),
		deploymentStatus: new FormControl(null, [Validators.required]),
		serviceName: new FormControl(null, [Validators.required]),
		region: new FormControl(null, [Validators.required]),
		modelName: new FormControl(null, [Validators.required]),
		modelVersion: new FormControl(null, [Validators.required]),
		notes: new FormControl(null, {nonNullable: true }),
		capacityK_TPM: new FormControl(0, [Validators.required]),
		deploymentVersion: new FormControl(1, [Validators.required]),
		deploymentType: new FormControl(null, [Validators.required]),
        modelTotalTokens:new FormControl(0, {nonNullable: true }),
        modelOutputTokens:new FormControl(0, {nonNullable: true }),
        modelInputTokens:new FormControl(0, {nonNullable: true })
    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _deploymentController: DeploymentController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSDeploymentGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        this.MSParentForm.markAllAsTouched();

        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls(): void {
        this.MSParentForm.get('deploymentVersion')?.disable();
    }

    private doMapGetDataToFormControl(deployment: DeploymentGet) {
        if (!deployment) {
            return;
        }        
        this.MSDeploymentGet = deployment;
        this.MSParentForm.patchValue(deployment);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSDeploymentID
            ? await this._deploymentController.Update(this.MSDeploymentID, this.MSParentForm.getRawValue())
            : await this._deploymentController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        // Set Options PlaceHolder
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToDeployment();
    }
}
