// Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM
import { Component, Input, OnInit }         from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
    ReactiveFormsModule, 
    Validators}                             from '@angular/forms';
import { Clipboard }                        from '@angular/cdk/clipboard';
import { DecimalPipe, NgFor, NgIf }         from '@angular/common';
// Third Party Imports
import { SelectItem, SharedModule }         from 'primeng/api';
import { RippleModule }                     from 'primeng/ripple';
import { ButtonModule }                     from 'primeng/button';
import { DropdownModule }                   from 'primeng/dropdown';
import { InputNumberModule }                from 'primeng/inputnumber';
import { InputTextModule }                  from 'primeng/inputtext';
import { InputTextareaModule }              from 'primeng/inputtextarea';
import { RadioButtonClickEvent, RadioButtonModule }                from 'primeng/radiobutton';
import { SliderModule }                     from 'primeng/slider';
import { TooltipModule }                    from 'primeng/tooltip';
import { FieldsetModule }                   from 'primeng/fieldset';
import { PanelModule }                      from 'primeng/panel';
// Moon Imports
import { ApiContentResult, IApiResult }     from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { Skill }                            from '@moon-maintainer/api/request/skill.request';
import { SkillGet }                         from "@moon-maintainer/api/response/skill-get.response";
import { SkillController }                  from '@moon-maintainer/api/skill.controller';
import { MaintainerRouter }                 from '@moon-maintainer/maintainer.router';
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';
import { ModifiedFieldsService }            from '@moon-shared/services/modified-fields.service';
import { FieldValues }                      from '@moon-shared/constants/field-values';
import { MoonDragDropDirective }            from '@moon-shared/directives/file-upload-drag-drop.directive';
import { TemplateGet }                      from '@moon-maintainer/api/response/template-get.response';
import { TemplateController }               from '@moon-maintainer/api/template.controller';
import { SkillTestComponent }               from '@moon-maintainer/skill/skill-test/skill-test.component';
import { ConstantString }                   from '@moon-shared/constants/constant-string';
import { DeploymentController }             from '@moon-maintainer/api/deployment.controller';
import { DeploymentGet }                    from '@moon-maintainer/api/response/deployment-get.response';

@Component({
    selector: 'moon-skill-form',
    templateUrl: './skill-form.component.html',
    styleUrls: ['./skill-form.component.css'],
    standalone: true,
    imports: [
        NgFor, NgIf, FormsModule, ReactiveFormsModule, SharedModule,
        InputTextModule, InputTextareaModule, RadioButtonModule, InputNumberModule,
        ButtonModule, DropdownModule, RippleModule, SliderModule, PanelModule,
        FieldsetModule, TooltipModule, DecimalPipe,
        MoonDragDropDirective, MoonFormPageComponent, MoonFormControlComponent, SkillTestComponent
    ],
    providers: [
        SkillController,
        TemplateController,
        DeploymentController,
        ModifiedFieldsService
    ]
})
export class SkillFormComponent implements OnInit {
    @Input() public MSSkillGet: SkillGet;
    @Input() public MSSkillID: number | null;
    public MSTemplateSelectItem: SelectItem[] = [{ label: 'Select Template', value: null }];
    public MSDeploymentSelectItems: SelectItem[] = [{ label: 'Select Deployment', value: null }];

    // put model name

    public MSParentForm: FormGroup<FormGroupOf<Skill>> = new FormGroup<FormGroupOf<Skill>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		skillType: new FormControl('', {nonNullable: true }),
		skillSubType: new FormControl('', {nonNullable: true }),
		skillName: new FormControl('', {nonNullable: true }),
		skillStatus: new FormControl('', {nonNullable: true }),
		deploymentID: new FormControl(null, {nonNullable: true }),
		maxOutputTokens: new FormControl(0, {nonNullable: true }),
		systemMessage: new FormControl('', {nonNullable: true }),
		systemTokens: new FormControl(0, {nonNullable: true }),
		temperature: new FormControl(0,  { validators: [Validators.min(0), Validators.max(1)], nonNullable: true }),
		topP: new FormControl(0, { validators: [Validators.min(0), Validators.max(1)], nonNullable: true }),
        frequencyPenalty: new FormControl(0, { validators: [Validators.min(0), Validators.max(2)], nonNullable: true }),
        presencePenalty: new FormControl(0, { validators: [Validators.min(0), Validators.max(2)], nonNullable: true }),
        stopSequence: new FormControl('', { nonNullable: true }),
        skillVersion: new FormControl(0, { nonNullable: true }),
        templateID: new FormControl(null, { nonNullable: false }),
        modifiedFieldsJson: new FormControl(''),
        inputFormat: new FormControl('chat', {nonNullable: true }),
        fileOutputFormat: new FormControl('text', {nonNullable: true }),
        multipleFileProcessing: new FormControl('', {nonNullable: true }),
        modelErrorAction: new FormControl('', {nonNullable: true }),
        defaultResponseValue: new FormControl(''),
        notes: new FormControl('', {nonNullable: true }),
        // inputFile: new FormControl(),
    });

    
    public MSInputTypeRadioOptions: SelectItem[] = [
        { label: FieldValues.Text, value: "chat" },
        { label: FieldValues.Pdf, value: "file" }
    ]
    // public MSChatOutputRadioOptions: SelectItem[] = [
    //     { label: FieldValues.Text, value: FieldValues.Text.toLocaleLowerCase() },
    //     { label: FieldValues.Json, value: FieldValues.Json }
    // ]
    public MSFileOutputRadioOptions: SelectItem[] = [
        { label: FieldValues.Text, value: "text" },
        { label: FieldValues.Word, value: "file" }
    ]
    public MSMultipleFileProcessing: SelectItem[] = [
        { label: ConstantString.Summarize, value: ConstantString.Summarize.toLocaleLowerCase(), disabled: false },
        { label: FieldValues.SummarizeAndAppend.getDisplayName(), value: FieldValues.SummarizeAndAppend.toLocaleLowerCase().trim(), disabled: false }
    ]
    public MSModelErrorAction: SelectItem[] = [
        { label: ConstantString.Error, value: ConstantString.Error.toLocaleLowerCase(), disabled: false },
        { label: ConstantString.UseDefaultResponseValue.getDisplayName(), value: ConstantString.UseDefaultResponseValue.toLocaleLowerCase().trim(), disabled: false }
    ]


    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _skillController: SkillController,
        // private _moonMessageService: MoonMessageService,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter,
        private _modifiedFieldsService: ModifiedFieldsService,
        private _templateController: TemplateController,
        private _deploymentController: DeploymentController,
        private _clipboard: Clipboard
    ) {
    }

    async ngOnInit() {
        this.doInitializeFormControls();
        await this.getSelectItems();
        this.doMapGetDataToFormControl(this.MSSkillGet);
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        if (this.MSIsFormSubmitting) {
            return;
        }
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    public OnInputClick(event: RadioButtonClickEvent) {
        this.disableSummarizeAndAppend(event.value);
    }

    private disableSummarizeAndAppend(value: string) {
        const item = this.MSMultipleFileProcessing.find(item => item.label === FieldValues.SummarizeAndAppend.getDisplayName());
        if (item) {
            item.disabled = value === "chat" ? true : false;
        }
    }

    public MSSetFormControlValue(propertyKey: string, event: string | number | null): void {
        if (event == null) {
            event = 0;
        }
        this.MSParentForm.get(propertyKey)?.setValue(+event);
    }

    public OnShowExamplesClick(): void {
        if (!this.MSSkillID) return;

        this._maintainerRouter.ToSkillExample(this.MSSkillID, true);
    }

    public OnClickCopyMessage(e: MouseEvent): void {
        const systemMessage = this.MSParentForm.controls.systemMessage?.value;
        if (systemMessage)
            this._clipboard.copy(systemMessage);
        e.stopPropagation();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls() {
    }

    private doMapGetDataToFormControl(skill: SkillGet) {
        if (!skill) {
            this.disableSummarizeAndAppend(this.MSParentForm.controls.inputFormat.value)
            return;
        }
        this.MSSkillGet = skill;
        this.disableSummarizeAndAppend(this.MSSkillGet.inputFormat)
        this.MSParentForm.patchValue(skill);
        // this.setDefaultValue(skill);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;

        this.setModifiedFieldsFormValue();
        //return;
        const apiResult: IApiResult = this.MSSkillID
            ? await this._skillController.Update(this.MSSkillID, this.MSParentForm.getRawValue())
            : await this._skillController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private setModifiedFieldsFormValue() {
        if (this.MSSkillID == null)
            return;

        const formValue = this.MSParentForm.getRawValue() as SkillGet;
        // add deploymentName for skill history and remove deploymentID
        formValue.deploymentName = this.MSDeploymentSelectItems.find(a => a.value === formValue.deploymentID)?.label ?? String.empty;
        Object.safelyDeleteProperty(formValue, 'deploymentID');

        const modifiedFields = this._modifiedFieldsService.GetModifiedValuesFromObjects(formValue, this.MSSkillGet);
        this.MSParentForm.controls.modifiedFieldsJson.setValue(JSON.stringify(modifiedFields));
    }

    private async getSelectItems() {
        // Set Options PlaceHolder
        await Promise.all([this.loadTemplateList(), this.loadDeploymentList()])
    }

    private async loadDeploymentList() {
        const apiResult: ApiContentResult<DeploymentGet[]> = await this._deploymentController.GetList();
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
            const deploymentGets = apiResult.content;
            this.MSDeploymentSelectItems = deploymentGets.map(deployment => {
                return { label: deployment.deploymentName ?? String.empty, value: deployment.deploymentID, title: `${deployment.modelName} v${deployment.modelVersion} (${deployment.region}) (Input: ${deployment.modelInputTokens} Output: ${deployment.modelOutputTokens})` };
            });
        }
    }

    private async loadTemplateList() {
        const skillType: string = this.MSSkillGet?.skillType ?? String.empty;
        let templateType: string = '';
        switch (skillType) {
            case 'Revise':
                templateType = 'Revise';
                break;
            case 'Summarize':
                templateType = 'Summarize';
                break;
            default:
                templateType = String.empty;
                break;
        }

        if(!templateType || templateType == String.empty) return;

        const apiResult: ApiContentResult<TemplateGet[]> = await this._templateController.GetListByTemplateType(templateType);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
            const templateResult = apiResult.content;
            if(!templateResult.length) return;

            templateResult.map(template => {
                this.MSTemplateSelectItem.push({ label: template.templateName ?? String.empty, value: template.templateID });
            })
        }
    }

    public get MSTotalTokens(): number {
        return this.MSSkillGet ? (this.MSSkillGet?.systemTokens +  this.MSSkillGet?.skillExampleTokens) : 0;
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToSkill();
    }
}
