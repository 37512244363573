import { CommonModule }               from '@angular/common';
import { Component, OnInit }          from '@angular/core';
import { ActivatedRoute }             from '@angular/router';
// Third party imports
import { MenuItem, SharedModule }     from 'primeng/api';
import { ButtonModule }               from 'primeng/button';
import { InputNumberModule }          from 'primeng/inputnumber';
import { ProgressSpinnerModule }      from 'primeng/progressspinner';
import { RippleModule }               from 'primeng/ripple';
import { TabMenuModule }              from 'primeng/tabmenu';
// Moon Imports
import { AppBreadcrumbService }       from '@app-layout/services/app.breadcrumb.service';
import { TitleDocumentController }    from '@moon-public/api/title-document.controller';
import { TitleSummaryController }     from '@moon-public/api/title-summary.controller';
import { PublicRouter }               from '@moon-public/public.router';
import { DocumentResultComponent }    from '@moon-maintainer/title-commitment-advanced/document-result/document-result.component';
import { PdfDisplayComponent }        from '@moon-maintainer/title-commitment-advanced/pdf-display/pdf-display.component';
import { DefaultValues }              from '@moon-shared/constants/default-values';
import { RouteParameter }             from '@moon-shared/constants/route-parameter';
import { RoutePath }                  from '@moon-shared/constants/route-path';
import { MaintainerRouter }           from '@moon-maintainer/maintainer.router';

@Component({
  selector: 'document-pdf-review',
  templateUrl: './document-pdf-review.component.html',
  styleUrls: ['./document-pdf-review.component.scss'],
  standalone: true,
  imports: [CommonModule,
    SharedModule, InputNumberModule, ProgressSpinnerModule, TabMenuModule, ButtonModule, RippleModule,
    DocumentResultComponent, PdfDisplayComponent],
  providers: [TitleSummaryController, TitleDocumentController, PublicRouter]
})
export class DocumentPdfReviewComponent implements OnInit{
  public MSTitleSummaryID: number;
  public MSTitleDocumentID: number;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _publicRouter: PublicRouter,
    private _maintinterRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.MSTitleSummaryID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleSummaryID) ?? DefaultValues.NoID);
    this.MSTitleDocumentID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleDocumentID) ?? DefaultValues.NoID);
    this.setBreadcrumb();
  }
  public MSOnClickRouteBack(): void {
    this._publicRouter.RouteToTitleCommitmentsDetail(this.MSTitleSummaryID)
  }
  private setBreadcrumb() {
    const items: MenuItem[] = [
      {
        label: RoutePath.TitleCommitments.getDisplayName(),
        command: () => {
          this._maintinterRouter.RouteToTitleCommitmentsAdvanced();
        },
      },
      {
        label: RoutePath.Detail,
        command: () => {
          this._publicRouter.RouteToTitleCommitmentsDetail(this.MSTitleSummaryID)
        }
      },
      {
        label: RoutePath.PdfReview,
        routerLink: null,
      },
    ];
    this._appBreadcrumbService.setItems(items);
  }
}
