// Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM
import { Component, Input, OnInit,
    ViewEncapsulation }                 from "@angular/core";
import { NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { Table, TableModule }           from "primeng/table";
// Moon imports
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { DeploymentFind }               from "@moon-maintainer/api/response/deployment-find.response";
import { DeploymentGet }                from "@moon-maintainer/api/response/deployment-get.response";

@Component({
    selector: "moon-deployment-table",
    templateUrl: "./deployment-table.component.html",
    styleUrls: ['./deployment-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
              TableModule, ButtonModule, InputTextModule,
              MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [MaintainerRouter]
})
export class DeploymentTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSDeploymentList: DeploymentFind[];
    @Input() public MSLoading = true;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.DeploymentName.toCamelCase(), header: FieldNames.DeploymentName.getDisplayName(), class: 'w-14per'},
		{field: FieldNames.DeploymentStatus.toCamelCase(), header: FieldNames.DeploymentStatus.getDisplayName(), class: 'w-8per'},
		{field: FieldNames.ServiceName.toCamelCase(), header: FieldNames.ServiceName.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.Region.toCamelCase(), header: FieldNames.Region.getDisplayName(), class: 'w-8per'},
		{field: FieldNames.ModelName.toCamelCase(), header: FieldNames.ModelName.getDisplayName(), class: 'w-8per'},
		{field: FieldNames.ModelVersion.toCamelCase(), header: FieldNames.ModelVersion.getDisplayName(), class: 'w-8per'},
		{field: FieldNames.Notes.toCamelCase(), header: FieldNames.Notes.getDisplayName(), class: 'w-12per'},
		{field: FieldNames.CapacityK_TPM.toCamelCase(), header: FieldNames.CapacityK_TPM.getDisplayName(), class: 'w-7per'},
		{field: FieldNames.DeploymentVersion.toCamelCase(), header: FieldNames.DeploymentVersion.getDisplayName(), class: 'w-7per'},
		{field: FieldNames.ModelInputTokens.toCamelCase(), header: FieldNames.ModelInputTokens.getDisplayName(), class: 'w-7per'},
		{field: FieldNames.ModelOutputTokens.toCamelCase(), header: FieldNames.ModelOutputTokens.getDisplayName(), class: 'w-7per'},
		{field: FieldNames.ModelTotalTokens.toCamelCase(), header: FieldNames.ModelTotalTokens.getDisplayName(), class: 'w-7per'},
		

        // Column PlaceHolder - DO NOT REMOVE
        //{field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-20per'}
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;

    constructor(private _maintainerRouter: MaintainerRouter) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: DeploymentGet): void {
        this._maintainerRouter.RouteToDeploymentEdit(item.deploymentID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.RouteToDeploymentCreate();
    }

    public OnClickFind(): void {
        this._maintainerRouter.RouteToDeployment();
    }

    public OnSearchInput(DeploymentTable: Table, searchInput: string): void {
        DeploymentTable.filterGlobal(searchInput, "contains");
    }
}
