import { CommonModule }                                               from '@angular/common';
import { Component, EventEmitter, Input, Output }                     from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }    from '@angular/forms';
// Third party imports
import { ConfirmationService, MenuItem }                              from 'primeng/api';
import { BadgeModule }                                                from 'primeng/badge';
import { ButtonModule }                                               from 'primeng/button';
import { DialogModule }                                               from 'primeng/dialog';
import { FileSelectEvent, FileUpload, FileUploadModule }              from 'primeng/fileupload';
import { InputTextModule }                                            from 'primeng/inputtext';
import { InputTextareaModule }                                        from 'primeng/inputtextarea';
import { RippleModule }                                               from 'primeng/ripple';
import { DialogService, DynamicDialogConfig, DynamicDialogRef }       from 'primeng/dynamicdialog';
// Moon Imports
import { ApiContentResult, ApiResult }                                from '@moon-core/models/api-result';
import { ComponentUtilityService }                                    from '@moon-core/services/component-utility.service';
import { TitleExceptionGet }                                          from '@moon-public/api/response/title-exception.response';
import { TitleExceptionController }                                   from '@moon-public/api/title-exception.controller';
import { ChatLogComponent }                                           from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { SkillAdvancedEditModalComponent }                            from '@moon-maintainer/title-commitment-advanced/modals/skill-advanced-edit-modal/skill-advanced-edit-modal.component';
import { MoonFormControlComponent }                                   from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { DropdownButtonComponent }                                    from '@moon-shared/components/dropdown-button/dropdown-button.component';
import { MessageSeverity }                                            from '@moon-shared/constants/message-severity';
import { MoonMessageService }                                         from '@moon-shared/services/moon-message.service';
import { FieldValues }                                                from '@moon-shared/constants/field-values';
import { ChatSetting }                                                from '@moon-maintainer/api/request/chat-setting.request';

@Component({
  selector: 'moon-title-exception',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    ButtonModule, RippleModule, BadgeModule, InputTextModule, InputTextareaModule, DialogModule, FileUploadModule,
    DropdownButtonComponent, ChatLogComponent, MoonFormControlComponent,
  ],
  templateUrl: './title-exception.component.html',
  styleUrls: ['./title-exception.component.scss'],
  providers: [TitleExceptionController]
})
export class TitleExceptionComponent {
  @Input() public MSTitleException: TitleExceptionGet;
  @Output() public MSOnTitleExceptionChange = new EventEmitter();
  
  public MSUploadingDocument: boolean = false;
  public MSSaving: boolean = false;
  public MSEditAction: boolean = false;
  public MSEditType: boolean = false;
  public MSActionFormGroup: FormGroup<{ recommendedAction: FormControl<string> }>;
  public MSTypeFormGroup: FormGroup<{ exceptionType: FormControl<string> }>;
  public MSShowChatLog: boolean = false;
  public MSChatLogID: number;
  public MSDynamicDialogRef?: DynamicDialogRef;
  
  public MSPromptMenuItems: MenuItem[] = [
    {
        label: "View Exception Summarize Log",
        command: () => this.MSOnClickChatLog()
    },
    { separator: true },
    {
        label: "Run Exception Summarize",
        command: () => this.MSOnClickPromptException()
    },
    { separator: true },
    {
      label: 'Run Exception Summarize with Override',
      command: () => { this.MSOnClickExceptionSummarize(); }
    }
  ];

  constructor(
    private _dialogService: DialogService,
    private _moonMessageService: MoonMessageService,
    private _titleExceptionController: TitleExceptionController,
    private _componentUtilityService: ComponentUtilityService,
    private _confirmationService: ConfirmationService
  ) { }

  public async MSOnClickUploadDocument(event: FileSelectEvent, fileUpload: FileUpload) {
    const file = event.files[0];
    try {
      if (this._componentUtilityService.IsFileValid(file, {fileExtensions: [FieldValues.FileExtension_PDF] })) {
        this.MSUploadingDocument = true;
        const apiResult = await this._titleExceptionController.PostTitleDocumentBlob(this.MSTitleException.titleSummaryID, this.MSTitleException.titleExceptionID, file);

        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess('Title document upload successful.');
          this.MSOnTitleExceptionChange.emit();
        }
      }
    }
    finally {
      fileUpload.clear();
      this.MSUploadingDocument = false;
    }
  }
  public async MSOnClickChatLog(){
    if (this.MSTitleException.chatLogID !== null) {
      this.MSChatLogID = this.MSTitleException.chatLogID;
      this.MSShowChatLog = true;
    }
    else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Exception Chat Log Not Found.")
    }
  }
  
  public async MSClearLogMessage() {
    this._confirmationService.confirm({
      message: "This log message will be deleted. Are you sure you want to continue?",
      acceptLabel: "Yes",
      accept: () => this.doClearLogMessages(),
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
  }

  public async MSOnClickPromptException() {

    if (this.MSTitleException.hasError) {
      this.doPromptException(null);
    }
    else {
      this._confirmationService.confirm({
        message: "This prompt is already completed. Are you sure you want to continue?",
        acceptLabel: "Yes",
        accept: () => this.doPromptException(null),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }

  private async doPromptException(chatSetting: ChatSetting | null) {
    this.MSSaving = true;
    const apiResult: ApiResult = await this._titleExceptionController.PromptExceptionTypeAndAction(this.MSTitleException.titleSummaryID,this.MSTitleException.titleExceptionID, chatSetting);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Prompt exception successful.");
      this.MSOnTitleExceptionChange.emit();
    }
    this.MSSaving = false;
  }

  public async MSOnClickExceptionSummarize(): Promise<void> {

    const caption = `Skill: Exception Summary`;

    const apiResult: ApiContentResult<ChatSetting> = await this._titleExceptionController.GetChatSetting(this.MSTitleException?.titleSummaryID, this.MSTitleException.titleExceptionID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {

      const dynamicDialogConfig: DynamicDialogConfig<ChatSetting> = {
        styleClass: 'w-50per moon-modal-wrapper',
        header: caption,
        data: apiResult.content
      };

      this.MSDynamicDialogRef = this._dialogService.open(SkillAdvancedEditModalComponent, dynamicDialogConfig);
      this.MSDynamicDialogRef.onClose.subscribe(
        (chatSetting: ChatSetting) => {
          if (chatSetting) {
            this.doPromptException(chatSetting);
          }
        });
    }
  }

  public async MSOnOverrideChatLogData(): Promise<void>{
    try {
      this.MSSaving = true;
      const apiResult: ApiResult = await this._titleExceptionController.ChatOverride(this.MSTitleException.titleSummaryID,this.MSTitleException.titleExceptionID);
      this._componentUtilityService.WasSuccessful(apiResult);
    } finally {
      this.MSSaving = false;
    }
  }
  
  public MSToggleActionEdit() {
    this.MSEditAction = !this.MSEditAction;
    if (this.MSActionFormGroup == null) {
      this.initializeActionFormGroup();
    }
  }

  public MSToggleTypeEdit() {
    this.MSEditType = !this.MSEditType;
    if (this.MSTypeFormGroup == null) {
      this.initializeTypeFormGroup();
    }
  }

  public MSSaveRecommendedAction() {
    this.MSActionFormGroup.markAllAsTouched();
    this.MSActionFormGroup.updateValueAndValidity();
    if (this.MSActionFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid recommended action.")
      return;
    }
    this.doOverrideRecommendedAction();
  }

  public async MSSaveExceptionType() {
    this.MSTypeFormGroup.markAllAsTouched();
    this.MSTypeFormGroup.updateValueAndValidity();

    if (this.MSTypeFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid exception type.")
      return;
    }
    this.doOverrideExceptionType();
  }

  private initializeActionFormGroup() {
    this.MSActionFormGroup = new FormGroup({
      recommendedAction: new FormControl<string>(this.MSTitleException.recommendedAction,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(500)]
        })
    });
  }

  private async doClearLogMessages() {
    this.MSSaving = true;
    try {
      const apiResult: ApiResult = await this._titleExceptionController.ClearLogMessage(this.MSTitleException.titleSummaryID, this.MSTitleException.titleExceptionID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Log message cleared successfully.");
        this.MSOnTitleExceptionChange.emit();
      }
    }
    finally {
      this.MSSaving = false;
    }
  }
  
  private initializeTypeFormGroup() {
    this.MSTypeFormGroup = new FormGroup({
      exceptionType: new FormControl<string>(this.MSTitleException.exceptionType,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(100)]
        })
    });
  }

  private async doOverrideExceptionType() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleExceptionController.OverrideExceptionType(this.MSTitleException.titleSummaryID,this.MSTitleException.titleExceptionID, this.MSTypeFormGroup.getRawValue().exceptionType );
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Exception type updated successfully.");
        this.MSOnTitleExceptionChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }
  private async doOverrideRecommendedAction() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleExceptionController.OverrideRecommendedAction(this.MSTitleException.titleSummaryID, this.MSTitleException.titleExceptionID, this.MSActionFormGroup.getRawValue().recommendedAction );
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Recommended action updated successfully.");
        this.MSOnTitleExceptionChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }
}
