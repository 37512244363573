<!-- eslint-disable @angular-eslint/template/no-autofocus -->
<form
    [formGroup]="MSTitleCommitmentForm"
    class="px-2 md:px-8 moon-title-commitment-wizard-form"
>
    <moon-form-control
        class="p-fluid p-5 fadeinright animation-duration-100 "
        [MSFormControl]="MSTitleCommitmentForm.controls.matterName"
        [MSLabel]="'Please Enter Matter Name:'"
        [MSErrorLabel]="'Matter name'"
        [MSLabelSize]="12"
        [MSInputSize]="12"
        [MSErrorMessageSize]="12"
    >
        <div class="flex gap-2 justify-content-center">
            <input
                pInputText
                pAutoFocus 
                [autofocus]="true" 
                type="text"
                name="matterName"
                id="matterName"
                formControlName="matterName"
                placeholder="Enter Matter Name"
            >
        </div>
    </moon-form-control>
</form>