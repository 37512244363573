// Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM
import {
    DatePipe, NgClass, NgFor, NgIf, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                                       from "@angular/common";
import { 
    Component, EventEmitter, Input, OnInit,
    Output, ViewChild, ViewEncapsulation 
}                                                       from "@angular/core";
import { FormsModule }                                  from "@angular/forms";
// Third Party imports
import { ButtonModule }                                 from "primeng/button";
import { FileUploadHandlerEvent, FileUploadModule }     from "primeng/fileupload";
import { InputTextModule }                              from "primeng/inputtext";
import { Table, TableModule }                           from "primeng/table";
import { TooltipModule }                                from "primeng/tooltip";
import { SelectItem }                                   from 'primeng/api';
import { MultiSelectModule }                            from "primeng/multiselect";
// Moon imports
import { ApiBlobResult, ApiResult }                     from "@moon-core/models/api-result";
import { ComponentUtilityService }                      from "@moon-core/services/component-utility.service";
import { SkillGet }                                     from "@moon-maintainer/api/response/skill-get.response";
import { SkillController }                              from "@moon-maintainer/api/skill.controller";
import { MaintainerRouter }                             from '@moon-maintainer/maintainer.router';
import { MoonCustomDropdownComponent }                  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonTableHeaderComponent }                     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }                       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                                   from '@moon-shared/constants/field-names';
import { FieldValues }                                  from "@moon-shared/constants/field-values";
import { MessageSeverity }                              from "@moon-shared/constants/message-severity";
import { MoonTemplateDirective }                        from "@moon-shared/directives/moon-template.directive";
import { MoonDatePipe }                                 from "@moon-shared/pipes/moon-date.pipe";
import { MoonMessageService }                           from "@moon-shared/services/moon-message.service";
import { TableColumn }                                  from '@moon-shared/types/table-column.type';
@Component({
    selector: "moon-skill-table",
    templateUrl: "./skill-table.component.html",
    styleUrls: ['./skill-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle, MultiSelectModule, FormsModule,
        TableModule, ButtonModule, InputTextModule, DatePipe, TooltipModule, FileUploadModule,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonTemplateDirective, MoonDatePipe
    ],
    providers: [MaintainerRouter, SkillController]
})
export class SkillTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSSkillList: SkillGet[];
    @Input() public MSLoading = true;
    @Output() MSRefreshEvent = new EventEmitter();
    @ViewChild("MSTableHeader") MSTableHeader: MoonTableHeaderComponent;
    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.SkillNumber.toCamelCase(), header: FieldNames.SkillNumber.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.SkillType.toCamelCase(), header: FieldNames.SkillType.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.SkillSubType.toCamelCase(), header: FieldNames.SkillSubType.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.SkillName.toCamelCase(), header: FieldNames.SkillName.getDisplayName(), class: 'w-20per'},
		{field: FieldNames.SkillVersion.toCamelCase(), header: FieldNames.SkillVersion.getDisplayName(), class: 'w-5per'},
		{field: FieldNames.SkillStatus.toCamelCase(), header: FieldNames.SkillStatus.getDisplayName(), class: 'w-5per'},
		{field: FieldNames.DeploymentName.toCamelCase(), header: FieldNames.DeploymentName.getDisplayName(), class: 'w-15per'},
		{field: FieldNames.SkillExample.toCamelCase(), header: FieldNames.SkillExample.getDisplayName(), class: 'w-5per'},
		{field: FieldNames.TotalTokens.toCamelCase(), header: FieldNames.TotalTokens.getDisplayName(), class: 'w-5per'},
		{field: FieldNames.LastUpdated.toCamelCase(), header: FieldNames.LastUpdated.getDisplayName(), class: 'w-10per'},

        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class:'w-10per'}
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplayUpload: boolean = true;
    public MSDisplaySecondary: boolean= true;
    public MSAcceptedFileExtension: string = FieldValues.FileExtension_JSON;
    public MSUploading: boolean = false;
    public MSSkillTypeSelectItems: SelectItem[] = [];
    public MSSkillSubTypeSelectItems: SelectItem[] = [];
    public MSDeploymentSelectItems: SelectItem[] = [];
    public MSSkillStatusSelectItems: SelectItem[] = [];

    constructor(
        private _maintainerRouter: MaintainerRouter,
        private _skillController: SkillController,
        private _componentUtilityService: ComponentUtilityService,
        private _moonMessageService: MoonMessageService,
    ) {}

    ngOnInit(): void {
        this.MSEnableSearch = false;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
        this.setSelectItemOptions();
    }

    public OnClickItem(item: SkillGet): void {
        this._maintainerRouter.ToSkillEdit(item.skillID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.ToSkillAdd();
    }

    public async OnClickExport() {
        const apiResult: ApiBlobResult = await this._skillController.ExportAsync();
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.showToastMessage(MessageSeverity.Success, "Skills exported successfully.");
        }

    }

    public async OnUploadFile(event: FileUploadHandlerEvent) {
        this.MSUploading = true;
        if (event.files.length > 0 && event.files[0]){
            try {
                const apiResult: ApiResult = await this._skillController.ImportAsync(event.files[0]);            
                if (this._componentUtilityService.WasSuccessful(apiResult)) {
                    this._moonMessageService.toastSuccess('Skills imported successfully.');
                    this.MSRefreshEvent.emit();
                }
            }
            finally {
                this.MSUploading = false;
                this.MSTableHeader.MSClearFile();
            }
        }
    }
    
    public OnTestClick(): void {
        this._maintainerRouter.ToSkillTest(null);
    }

    public OnShowExamplesClick(item: SkillGet): void {
        this._maintainerRouter.ToSkillExample(item.skillID);
    }
    
    public OnShowTestDataClick(item: SkillGet): void {
        this._maintainerRouter.ToSkillTestData(item.skillNumber);
    }
    
    public OnShowChatLogClick(item: SkillGet): void {
        this._maintainerRouter.ToSkillChatLog(item.skillNumber);
    }
    
    public OnSearchInput(table: Table, searchInput: string): void {
        table.filterGlobal(searchInput, "contains");
    }

    private setSelectItemOptions(): void {
        if (this.MSSkillList) {

            const skillTypeSet = [...new Set(this.MSSkillList.map(item => item.skillType))];
            this.MSSkillTypeSelectItems = this.setItems(skillTypeSet);

            const skillSubtypeSet = [...new Set(this.MSSkillList.map(item => item.skillSubType))];
            this.MSSkillSubTypeSelectItems = this.setItems(skillSubtypeSet);

            const skillStatusSet = new Set(this.MSSkillList.map(item => item.skillStatus));
            this.MSSkillStatusSelectItems = this.setItems(Array.from(skillStatusSet));

            const deploymentNameSet = new Set(this.MSSkillList.map(item => item.deploymentName));
            this.MSDeploymentSelectItems = this.setItems(Array.from(deploymentNameSet));
        }
    }

    private setItems(setOfItems: (string | null)[]): SelectItem[] {
        const selectItems: SelectItem[] = [];
        setOfItems.map((element) => {
            selectItems.push({
                label: String(element),
                value: String(element)
            });
        });
        return selectItems;
    }
}
