import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  FormControl, FormGroup,
  FormsModule, ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
// Third Party imports
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuItem, SelectItem } from 'primeng/api';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
// MS Imports
import { ApiContentResult } from '@moon-core/models/api-result';
import { ComponentUtilityService } from '@moon-core/services/component-utility.service';

import { MoonFormControlComponent } from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { TokenBreakdownComponent } from '@moon-shared/components/token-breakdown/token-breakdown.component';

import { ChatResult } from '@moon-public/api/response/chat-result.response';
import { ChatSkillController } from '@moon-public/api/chat-skill.controller';
import { ChatParameter } from "@moon-public/models/chat-parameter";

import { MaintainerRouter } from '@moon-maintainer/maintainer.router';
import { SkillGet } from '@moon-maintainer/api/response/skill-get.response';
import { AppBreadcrumbService } from '@app/app-layout/services/app.breadcrumb.service';
import { RoutePath } from '@moon-shared/constants/route-path';
import { SkillController } from '@moon-maintainer/api/skill.controller';
import { MoonLoadingComponent } from '@moon-shared/components/moon-loading/moon-loading.component';
import { FieldValues } from '@moon-shared/constants/field-values';

interface SkillTestForm {
  message: FormControl<string>;
  messageToken: FormControl<number>;
  skillID: FormControl<number | undefined>;
  inputFile: FormControl<File | null>;
}

@Component({
  selector: 'moon-skill-test',
  templateUrl: './skill-test.component.html',
  styleUrls: ['./skill-test.component.scss'],
  standalone: true,
  imports: [
    FormsModule, ReactiveFormsModule,
    TokenBreakdownComponent, MoonFormControlComponent, InputTextareaModule, ButtonModule, RippleModule, DropdownModule, FileUploadModule,
    NgIf, BadgeModule, TooltipModule, ProgressSpinnerModule, MoonLoadingComponent
  ],
  providers: [
    ChatSkillController,
    MaintainerRouter,
    SkillController,
  ]
})

export class SkillTestComponent implements OnInit {
  @Input() public MSSkillGet: SkillGet;
  @Input() public MSDisableForSkillEdit: boolean = false;
  @Input() public MSSubmitting: boolean = false;
  public MSIsFileUploaded: boolean = false;
  public MSSkillTestForm = new FormGroup<SkillTestForm>({
    message: new FormControl(String.empty, { nonNullable: true }),
    messageToken: new FormControl(0, { nonNullable: true }),
    skillID: new FormControl(undefined, { nonNullable: true, validators: Validators.required }),
    inputFile: new FormControl(null)
  });

  public MSSkillTesting: boolean = false;
  public MSChatResult: ChatResult | null;
  public MSTestMode: boolean = false;
  public MSLoading: boolean = false;
  public MSSkillListSelectItem: SelectItem[] = [];
  private skillName: string | null | undefined;

  constructor(
    private _chatSkillController: ChatSkillController,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _maintainerRouter: MaintainerRouter,
    private _componentUtilityService: ComponentUtilityService,
    private _clipboard: Clipboard,
    private _skillController: SkillController,
    private _router: Router

  ) {
    this.setSkillNameFromRouterStateData();
  }

  async ngOnInit() {
    this.setBreadcrumb();
    await this.loadSkillList();
    this.setDefaultSkill()
    //this.calculateTokenOnSKillTestFormMessageChange();
  }

  private setBreadcrumb() {
    if (this.MSDisableForSkillEdit) return;
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.Skill, routerLink: this._maintainerRouter.SkillRouteLink() },
      { label: RoutePath.Test, routerLink: null }
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }

  private async getSkillByID(skillID: number) {
    if (skillID) {
      const apiResult: ApiContentResult<SkillGet> = await this._skillController.GetByID(skillID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSSkillGet = apiResult.content;
      }
    }
  }

  public MSHandleFileInput([file]: File[]): void {
    if (this._componentUtilityService.IsFileValid(file, { fileExtensions: [FieldValues.FileExtension_PDF, FieldValues.FileExtension_TXT] })) {
      this.MSIsFileUploaded = true;
      this.MSSkillTestForm.controls.inputFile.setValue(file);
    }
  }
  public MSClearSelectedFile(fileUpload: FileUpload): void {
    fileUpload.clear();
    this.MSSkillTestForm.controls.inputFile.reset();
    this.MSIsFileUploaded = false;
  }
  public async MSOnSkillChange(event: DropdownChangeEvent): Promise<void> {
    const skillID = event.value;
    if (skillID) {
      await this.getSkillByID(skillID);
    }
  }

  public MSOnClickGoBack(): void {
    this._maintainerRouter.ToSkill();
  }

  public get MSTokenBreakdown() {
    const tokenBreakdown = { promptTokens: 0, inputTokens: 0, maxOutputTokens: 0, used: 0, limit: 0 };
    // tokenBreakdown.promptTokens =
    //   this.MSSkillForm.controls.systemTokens.value +
    //   this.MSSkillExampleFormArray.controls.reduce(
    //     (a, b) => a + (b.controls.userTokens.value + b.controls.assistantTokens.value), 0
    //   );
    tokenBreakdown.inputTokens = this.MSSkillTestForm.controls.messageToken.value;
    // tokenBreakdown.maxOutputTokens = this.MSSkillForm.controls.maxOutputTokens.value;
    tokenBreakdown.limit = 32000;
    tokenBreakdown.used = tokenBreakdown.promptTokens + tokenBreakdown.inputTokens + tokenBreakdown.maxOutputTokens;
    return tokenBreakdown;
  }

  public async MSApplySkill(): Promise<void> {
    if (this.MSSkillGet == null) { return; }
    if (this.MSSkillTestForm.invalid) { return; }

    this.MSSkillTesting = true;
    try {


      const chatParameter: ChatParameter = new ChatParameter();
      chatParameter.skillName = this.MSSkillGet.skillName;
      chatParameter.skillType = this.MSSkillGet.skillType;
      chatParameter.isTest = this.MSTestMode;

      if (this.MSIsFileUploaded) {
        await this.runFileChat(chatParameter);
      }
      else {
        await this.runChat(chatParameter);
      }
    }
    finally {
      
      this.MSSkillTesting = false;
    }
  }
  
  private async runChat(chatParameter: ChatParameter) {
    const message: string = this.MSSkillTestForm.value.message?.trim() ?? String.empty;
    if (String.empty.equalsIgnoreCase(message)) {
      return;
    }

    chatParameter.inputText = message;

    const apiResult: ApiContentResult<ChatResult> = await this._chatSkillController.RunChat(chatParameter);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSChatResult = apiResult.content;
      this.MSSkillTestForm.controls.messageToken.setValue(this.MSChatResult.inputTokens);

    }
  }

  private async runFileChat(chatParameter: ChatParameter) {
    
    const file = this.MSSkillTestForm.value.inputFile;
    
    if (!this._componentUtilityService.IsFileValid(file, { fileExtensions: [FieldValues.FileExtension_TXT, FieldValues.FileExtension_PDF] })) {
      return;
    }
    
    chatParameter.inputFile = file;

    const apiResult: ApiContentResult<ChatResult> = await this._chatSkillController.RunFileChat(chatParameter);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSChatResult = apiResult.content;
      this.MSSkillTestForm.controls.messageToken.setValue(this.MSChatResult.inputTokens);
    }
  }

  public MSClearSkillTestForm(fileUpload: FileUpload): void {
    this.MSSkillTestForm.reset();
    this.MSChatResult = null;
    this.MSClearSelectedFile(fileUpload);
  }

  public MSCopyOutputToClipboard(): void {
    if (this.MSChatResult?.content)
      this._clipboard.copy(this.MSChatResult.content);
  }

  // private calculateTokenOnSKillTestFormMessageChange() {
  //   this.MSSkillTestForm.controls.message.valueChanges
  //     .pipe(
  //       distinctUntilChanged(),
  //       debounceTime(3000),
  //       switchMap(newValue => this._chatSkillApiService.PostCountToken(newValue))
  //     )
  //     .subscribe(tokenSize => {
  //       this.MSSkillTestForm.controls.messageToken.setValue(tokenSize);
  //     });
  // }

  private async loadSkillList(): Promise<void> {
    this.MSLoading = true;

    const apiResult: ApiContentResult<SkillGet[]> = await this._skillController.GetList();
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      apiResult.content.forEach((item) => {
        this.MSSkillListSelectItem.push({
          label: item.skillName,
          value: item.skillID,
          title: `${item.skillSubType ? item.skillSubType + '\n' : ''} ${item.deploymentName} ${item.modelName} v${item.modelVersion} (${item.region}) (Input: ${item.modelInputTokens} Output: ${item.modelOutputTokens})`
        });
      });
    }
    this.MSLoading = false;
  }

  private async setDefaultSkill(): Promise<void> {
    if (this.MSDisableForSkillEdit) 
      this.MSSkillTestForm.controls.skillID.setValue(this.MSSkillGet.skillID);
    
    if (!this.skillName) return;
    const selectedItem = this.MSSkillListSelectItem.find(item => item.label?.equalsIgnoreCase(this.skillName));
    if (!selectedItem) return;

    this.MSSkillTestForm.controls.skillID.setValue(selectedItem.value);
    this.getSkillByID(selectedItem.value);
  }

  private setSkillNameFromRouterStateData(): void {
    const navigation = this._router.getCurrentNavigation();
    if (navigation) {
      const data = navigation.extras.state as { skillName: string | null | undefined; };
      this.skillName = data.skillName;
    }
  }

  @HostListener('window:keyup', ['$event'])
  public MSOnKeyPress($event: KeyboardEvent): void {
    if ($event.ctrlKey && $event.altKey && $event.key == "t")
      this.MSTestMode = !this.MSTestMode;
  }
}
