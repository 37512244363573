<!-- Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                (MSOnClickEventRemove)="OnClickRemove()">
    <ng-container *ngIf="!MSLoading">
        <deployment-form #MSForm
                       [MSDeploymentID]="MSDeploymentID"
                       [MSDeploymentGet]="MSDeploymentGet">
        </deployment-form>
    </ng-container>
</moon-edit-page>
