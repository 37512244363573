<!-- Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div class="card moon-card" *ngIf="!MSLoading">
    <moon-email-whitelist-table [MSPageTitle]="MSPageTitle"
                [MSEmailWhitelistList]="MSEmailWhitelistList"
                [MSLoading]="MSLoading"
                (MSOnEmitReload)="MSOnEmitReload()"
                ></moon-email-whitelist-table>
</div>
