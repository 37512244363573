import { CommonModule }                                             from '@angular/common';
import { Component, OnInit }                                        from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }  from '@angular/forms';
// Third party imports
import { ButtonModule }                                             from 'primeng/button';
import { DropdownModule }                                           from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef }                    from 'primeng/dynamicdialog';
import { InputTextModule }                                          from 'primeng/inputtext';
import { ProgressBarModule }                                        from 'primeng/progressbar';
import { RippleModule }                                             from 'primeng/ripple';
// Moon imports
import { ComponentUtilityService }                                  from '@moon-core/services/component-utility.service';
import { TitleCommitmentFind }                                      from '@moon-public/api/response/title-commitment-find.response';
import { TitleSummaryController }                                   from '@moon-public/api/title-summary.controller';
import { TitleCommitmentStatus }                                    from '@moon-maintainer/title-commitment-advanced/models/processing-status';
import { MoonFormControlComponent }                                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }                                    from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { MessageSeverity }                                          from '@moon-shared/constants/message-severity';
import { MoonMessageService }                                       from '@moon-shared/services/moon-message.service';

export type CloseTitleCommitmentEditDynamicDialogData = 'cancel' | 'success';

@Component({
  selector: 'moon-title-review-edit-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    InputTextModule, DropdownModule, ProgressBarModule, ButtonModule, RippleModule,
    MoonFormControlComponent, MoonFormPageComponent],
  templateUrl: './title-review-edit-modal.component.html',
  styleUrls: ['./title-review-edit-modal.component.scss']
})
export class TitleReviewEditModalComponent implements OnInit {
  public MSTitleCommitment!: TitleCommitmentFind;
  public MSStatusOptions = [
    {label: TitleCommitmentStatus.Complete.toTitleCase(), value: TitleCommitmentStatus.Complete}, 
    {label: TitleCommitmentStatus.CLOSED.toTitleCase(), value: TitleCommitmentStatus.CLOSED}
  ];
  public MSTitleReviewEditForm!: FormGroup<{
    status: FormControl<string>;
    matterName: FormControl<string>;
  }>;
  public MSIsFormSubmitting: boolean = false;
  constructor(
    private _dynamicDialogConfig: DynamicDialogConfig<TitleCommitmentFind>,
    private _dynamicDialogRef: DynamicDialogRef,
    private _moonMessageService: MoonMessageService,
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
  ) { }
  ngOnInit() {
    const data: TitleCommitmentFind | undefined = this._dynamicDialogConfig.data;

    if (data == null) {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "No documents found.");
      this._dynamicDialogRef.close();
      return;
    }

    this.MSTitleCommitment = data;
    this.MSTitleReviewEditForm = new FormGroup({
      status: new FormControl(this.MSTitleCommitment.status, { nonNullable: true, validators: Validators.required }),
      matterName: new FormControl(this.MSTitleCommitment.matterName, { nonNullable: true, validators: Validators.required }),
    });

    if (TitleCommitmentStatus.Intake.equalsIgnoreCase(this.MSTitleCommitment.status) || TitleCommitmentStatus.Processing.equalsIgnoreCase(this.MSTitleCommitment.status)) {
      this.MSTitleReviewEditForm.controls.status.disable();
    }
  }


  public MSOnClickCancel() {
    const data: CloseTitleCommitmentEditDynamicDialogData = 'cancel';
    this._dynamicDialogRef.close(data);
  }
  public async MSOnClickSubmit() {

    if (this._componentUtilityService.IsFormValid(this.MSTitleReviewEditForm)) {
      this.MSIsFormSubmitting = true;

      try {
        const formValue = this.MSTitleReviewEditForm.getRawValue();
        this.MSTitleCommitment = { ...this.MSTitleCommitment, status: formValue.status, matterName: formValue.matterName };

        const apiResult = await this._titleSummaryController.Update(this.MSTitleCommitment.titleSummaryID, this.MSTitleCommitment);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess("Title commitment updated.");
          const data: CloseTitleCommitmentEditDynamicDialogData = 'success';
          this._dynamicDialogRef.close(data);
        }
      }
      finally {
        this.MSIsFormSubmitting = false;
      }
    }
  }

}