// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
import { MenuItem, PrimeIcons }      from 'primeng/api';
//Third Party Imports
import { RoutePath }                 from '@moon-shared/constants/route-path';
import { UserOptionService }         from '@moon-core/services/user-option.service';
import { DefaultValues }             from '@moon-shared/constants/default-values';
import { UserSessionService }        from '@moon-core/services/user-session.service';
import { ConsumerRouter }            from '@moon-consumer/consumer.router';
import { MaintainerRouter }          from '@moon-maintainer/maintainer.router';

export const ConsumerMenuItems = (_userOptionService: UserOptionService,
  _userSessionService: UserSessionService,
  _consumerRouter: ConsumerRouter,
  _maintainerRouter: MaintainerRouter
): MenuItem => {
  const consumerItems: MenuItem[] = [];

  if (DefaultValues.Y.equalsIgnoreCase(_userOptionService.MSUserOption.ShowTitleCommitment)) {
    if (_userSessionService.IsMaintainer()) {
      consumerItems.push({
        label: RoutePath.TitleCommitments.getDisplayName(), icon: PrimeIcons.FILE, command: () => {
          _maintainerRouter.RouteToTitleCommitmentsAdvanced();
        },
      })
    }
    else {
      consumerItems.push({
        label: RoutePath.TitleCommitments.getDisplayName(), icon: PrimeIcons.FILE, command: () => {
          _consumerRouter.RouteToTitleCommitmentsSimple();
        },
      })
    }
  }

  if (DefaultValues.Y.equalsIgnoreCase(_userOptionService.MSUserOption.ShowAirplaneTerms)) {
    consumerItems.push(
      { label: RoutePath.Airplane.getDisplayName(), icon: PrimeIcons.SEND, routerLink: [RoutePath.Airplane] },
    )
  }

  if (DefaultValues.Y.equalsIgnoreCase(_userOptionService.MSUserOption.ShowSurvey)) {
    consumerItems.push(
      { label: RoutePath.Survey.getDisplayName(), icon: PrimeIcons.BOOK, routerLink: [RoutePath.Survey] },
    )
  }

  return {
    label: "Consumer",
    items: consumerItems
  }
};
