import { Component, OnInit }                      from '@angular/core';
import { CommonModule }                           from '@angular/common';
import { ActivatedRoute }                         from '@angular/router';
//Moon Imports
import { AppBreadcrumbService }                   from '@app-layout/services/app.breadcrumb.service';
import { MoonEditPageComponent }                  from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { MoonNotificationFormComponent }          from '@moon-maintainer/moon-notification/moon-notification-form/moon-notification-form.component';
import { RouteParameter }                         from '@moon-shared/constants/route-parameter';
import { RoutePath }                              from '@moon-shared/constants/route-path';
import { ApiContentResult }                       from '@moon-core/models/api-result';
import { MoonNotificationController }             from '@moon-maintainer/api/moon-notification.controller';
import { MoonNotificationGet }                    from '@moon-maintainer/api/response/moon-notification-get.response';
import { ComponentUtilityService }                from '@moon-core/services/component-utility.service';

@Component({
  selector: 'moon-moon-notification-edit-page',
  standalone: true,
  imports: [CommonModule, MoonEditPageComponent, MoonNotificationFormComponent],
  templateUrl: './moon-notification-edit-page.component.html',
  styleUrl: './moon-notification-edit-page.component.scss',
  providers: [MoonNotificationController, ]
})
export class MoonNotificationEditPageComponent implements OnInit {
  public MSLoading: boolean;
  public MSPageTitle: string = RoutePath.Notification.getDisplayName();
  public MSDisplayRemoveButton: boolean = false;
  public MSMoonNotificationID: number | null;
  public MSMoonNotificationGet: MoonNotificationGet;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _moonNotificationController: MoonNotificationController,
    private _componentUtilityService: ComponentUtilityService
  ) {}
  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSMoonNotificationID) {
      await this.getNotificationByID(this.MSMoonNotificationID);
    }
    this.MSLoading = false;
  }

  private readRouteParameters() {
    const notificationID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.MoonNotificationID);
    this.MSMoonNotificationID = notificationID
      ? parseInt(notificationID)
      : null;
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetEditPageBreadcrumb(
      this.MSPageTitle,
      RoutePath.Notification,
      this.MSMoonNotificationID
    );
  }

  private async getNotificationByID(notificationID: number): Promise<void> {
    try {
      this.MSLoading = true;
      if (notificationID) {
        const apiResult: ApiContentResult<MoonNotificationGet> =
          await this._moonNotificationController.GetByID(notificationID);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this.MSMoonNotificationGet = apiResult.content;
        }
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
