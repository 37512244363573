<moon-loading *ngIf="MSLoading"></moon-loading>
<div
    *ngIf="!MSLoading"
    class="card moon-card"
>
    <form [formGroup]="MSSkillTestForm">
        <section id="skill-test">
            <!-- <moon-token-breakdown [MSTokenBreakdown]="MSTokenBreakdown"></moon-token-breakdown> -->
            <div
                class="flex"
                *ngIf="!MSDisableForSkillEdit"
            >
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-arrow-left"
                    (click)="MSOnClickGoBack()"
                    class="p-button-rounded p-button-text mr-2"
                    title="Go Back"
                > </button>
                <h1 class="text-lg m-0 mt-3">Skill Test</h1>
            </div>
            <div
                class="col-12 md:col-6 p-fluid p-0"
                *ngIf="!MSDisableForSkillEdit"
            >
                <moon-form-control
                    [MSDisplayLabel]="false"
                    [MSInputSize]="9"
                    [MSErrorMessageSize]="3"
                    [MSLabel]="'Skill'"
                    [MSFormControl]="MSSkillTestForm.controls.skillID"
                >
                    <form [formGroup]="MSSkillTestForm">
                        <p-dropdown
                            id="skill-dropdown"
                            [options]="MSSkillListSelectItem"
                            formControlName="skillID"
                            (onChange)="MSOnSkillChange($event)"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Select a skill"
                        >
                            <ng-template
                                let-option
                                pTemplate="item"
                            >
                                {{ option.label }} <br />
                                <small class="moon-white-space">
                                    {{ option.title }}
                                </small>
                            </ng-template>
                        </p-dropdown>
                    </form>
                </moon-form-control>
            </div>
            <div class="col-12 p-0 inline-block p-fluid">
                <div
                    *ngIf="MSIsFileUploaded; else userMessage"
                    class="my-1"
                >
                    User message is disabled when using file mode.
                </div>

                <ng-template #userMessage>
                    <moon-form-control
                        [MSInputSize]="12"
                        [MSDisplayLabel]="false"
                        [MSLabel]="'User Message'"
                        [MSFormControl]="MSSkillTestForm.controls.message"
                    >
                        <div
                            class="grow-wrap"
                            [attr.textarea-value]="userMessage.value"
                        >
                            <textarea
                                #userMessage
                                rows="3"
                                pInputTextarea
                                placeholder="Start writing"
                                [formControl]="MSSkillTestForm.controls.message"
                            ></textarea>
                        </div>
                    </moon-form-control>
                </ng-template>

                <!-- File Input -->
                <moon-form-control
                    class="col-12 md:col-6 p-fluid"
                    [MSFormControl]="MSSkillTestForm.controls.inputFile"
                    [MSDisplayLabel]="false"
                >
                    <div class="flex">
                        <p-fileUpload
                            #fileUpload
                            [mode]="'basic'"
                            accept=".txt, .pdf"
                            chooseLabel="or choose file instead"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="MSHandleFileInput($event.files)"
                        > </p-fileUpload>
                        <button
                            *ngIf="MSSkillTestForm.value.inputFile"
                            type="button"
                            pButton
                            pRipple
                            title="Clear file"
                            icon="pi pi-times"
                            (click)="MSClearSelectedFile(fileUpload)"
                        ></button>
                    </div>
                </moon-form-control>
            </div>
            <div class="flex justify-content-between mb-2">
                <div>
                    <button
                        type="button"
                        pButton
                        pRipple
                        class="p-button-raised h-min"
                        icon="pi pi-telegram"
                        title="Send"
                        label="Send"
                        (click)="MSApplySkill()"
                        [disabled]="MSSubmitting"
                    >
                    </button>
                    <button
                        type="button"
                        pButton
                        pRipple
                        title="Clear all"
                        label="Clear"
                        class="p-button-raised h-min"
                        icon="pi pi-times"
                        (click)="MSClearSkillTestForm(fileUpload)"
                        [disabled]="MSSubmitting"
                    >
                    </button>
                </div>
                <div>
                    <p-badge
                        *ngIf="MSTestMode"
                        value="Test mode"
                    ></p-badge>
                    <button
                        type="button"
                        pButton
                        pRipple
                        class="p-button-raised ml-auto"
                        icon="pi pi-copy"
                        title="Copy Output to Clipboard"
                        (click)="MSCopyOutputToClipboard()"
                    >
                    </button>
                </div>
            </div>
            <div
                *ngIf="MSSkillTesting; else skillOutput"
                class="flex justify-content-center align-items-center h-30rem"
            >
                <p-progressSpinner
                    aria-label="Loading"
                    styleClass="w-4rem h-4rem"
                    strokeWidth="4"
                    animationDuration=".5s"
                ></p-progressSpinner>
            </div>
            <ng-template #skillOutput>
                <div
                    class="flex justify-content-between"
                    *ngIf="MSChatResult"
                >
                    <div class="text-sm text-secondary">Input: {{MSChatResult.inputTokens}} tokens, Output:
                        {{MSChatResult.replyTokens}} tokens</div>
                    <div class="text-sm text-secondary">Deployment: {{MSChatResult.chatSetting.deploymentName}}
                        ({{MSChatResult.chatSetting.region}})</div>
                    <div class="text-sm text-secondary">Time: {{MSChatResult.durationMs / 1000}}s</div>
                </div>
                <div class="ms-skill-test-output white-space-pre-line">
                    {{MSChatResult?.content ?? 'Assistant Response'}}
                </div>
            </ng-template>

        </section>
    </form>
</div>