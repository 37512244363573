<ng-template #loading>
    <moon-loading></moon-loading>
</ng-template>

<ng-container *ngIf="!MSLoading;else loading">
    <ng-template #noexceptions>
        <p class="moon-document-upload-paragraph-message">No exceptions found.</p>
    </ng-template>
    <ng-container *ngIf="MSTitleCommitmentFull?.titleExceptionGetList?.length; else noexceptions">
        <p class="moon-document-upload-paragraph-message">{{MSParagraphMessage}}</p>
        <ng-container *ngFor="let titleException of MSTitleCommitmentFull.titleExceptionGetList">
            <div class="py-1 border-bottom-1 surface-border">
                <div
                    *ngIf="titleException.titleExceptionID"
                    class="flex flex-column gap-1"
                >
                    <div>
                        <h1 class="text-lg mb-0 inline-block">
                            {{titleException.exceptionNumber}}
                        </h1>
                        <span class="ml-2">{{titleException.exceptionDescription}}</span>
                    </div>
                    <div class="flex align-items-center gap-1">
                        <p-fileUpload
                            *ngIf="titleException.titleExceptionID > 0"
                            #MSFileUpload
                            mode="basic"
                            accept=".pdf"
                            [auto]="true"
                            (uploadHandler)="MSOnClickUploadDocumentForExceptions(titleException, $event, MSFileUpload)"
                            [customUpload]="true"
                            chooseIcon="pi pi-upload"
                            chooseLabel="Upload document"
                            title="Upload document manually"
                            [disabled]="MSLoading"
                            class="p-button-raised mr-2 moon-button"
                        ></p-fileUpload>
                    </div>
                </div>
                <div *ngIf="titleException?.titleDocumentGetList">
                    <div class="py-2">
                        <ul
                            *ngFor="let titleDocument of titleException.titleDocumentGetList; let i=index"
                            class="list-none p-0 m-0 moon-document-grid"
                        >
                            <li
                                class="py-2 pl-4 border-bottom-1 "
                                [ngClass]="titleDocument.hasError ? 'border-top-1 border-red-500' : 'surface-border'"
                                moonDragDrop
                                [allowDrop]="true"
                                allowMultiple="false"
                                [fileType]="['application/pdf']"
                                (outputFileDropped)="MSOnFileDrop(titleDocument, $event)"
                            >
                                <div class="flex flex-1 gap-3 my-2 align-items-center">
                                    <div class="flex flex-column w-9">
                                        <div class="flex gap-2">
                                            <h1 class="text-base mb-1">
                                                {{i+1}}.&nbsp;{{titleDocument.textList}}
                                            </h1>
                                        </div>
                                        <div
                                            *ngIf="titleDocument.pageList"
                                            class="text-sm"
                                        >
                                            Page: {{titleDocument.pageList }}
                                        </div>
                                        <div
                                            *ngIf="titleDocument.fileUrl"
                                            class="text-sm"
                                        >
                                            Url:
                                            <a
                                                class="cursor-pointer hover:text-blue-500"
                                                [href]="titleDocument.fileUrl"
                                                target="_blank"
                                            >
                                                {{titleDocument.fileUrl | slice:0:80}}
                                                <span *ngIf="titleDocument.fileUrl.length > 80">...</span>
                                            </a>
                                        </div>
                                        <div
                                            *ngIf="titleDocument.fileSize"
                                            class="text-sm"
                                        >
                                            <span>Filename: {{titleDocument.fileName}}</span>
                                            <span class="ml-1">(size: {{titleDocument.fileSize/ 1024 | number:'1.0-2'}} kb)</span>
                                        </div>
                                        <div
                                            *ngIf="titleDocument.fileSize"
                                            class="text-sm"
                                        >

                                        </div>
                                    </div>
                                    <div class="w-6 flex justify-content-end">
                                        <button
                                            [disabled]="MSLoading"
                                            pButton
                                            pRipple
                                            icon="pi pi-times"
                                            class="p-button-raised h-min"
                                            label="Delete Title Document"
                                            title="Delete Title Document"
                                            (click)="MSOnClickDeleteDocument(titleDocument)"
                                        > </button>
                                        <button
                                            *ngIf="titleDocument.fileSize;else uploadblob"
                                            [disabled]="MSLoading"
                                            pButton
                                            pRipple
                                            class="p-button-raised h-min"
                                            label="Clear Uploaded File"
                                            title="Clear Uploaded File"
                                            (click)="MSOnClickDeleteDocumentBlob(titleDocument)"
                                        > </button>
                                        <ng-template #uploadblob>
                                            <p-fileUpload
                                                #MSFileUpload
                                                mode="basic"
                                                accept=".pdf"
                                                [auto]="true"
                                                (uploadHandler)="MSOnClickUploadDocument(titleDocument, $event, MSFileUpload)"
                                                [customUpload]="true"
                                                chooseIcon="pi pi-upload"
                                                chooseLabel="Upload or drop file here"
                                                title="Upload document manually"
                                                [disabled]="MSLoading"
                                                class="p-button-raised mr-2 mb-2 moon-button"
                                            ></p-fileUpload>
                                        </ng-template>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>



<p-confirmDialog
    key="title-document-upload-confirmation"
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
    styleClass="w-30rem"
    acceptButtonStyleClass="p-button-text"
    rejectButtonStyleClass="p-button-text"
></p-confirmDialog>