import { Route, UrlSegment }                from '@angular/router';
// Third party imports
import { AppMainComponent }                 from '@app-main/app.main.component';
import { AppErrorComponent }                from '@app-pages/app.error.component';
import { MoonPostLogoutComponent }          from '@app-pages/moon-post-logout/moon-post-logout.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
// MS Imports
import { CollaboratorRoutes }               from '@moon-collaborator/collaborator.routes';
import { ConsumerRoutes }                   from '@moon-consumer/consumer.routes';
import { DisclaimerAgreementComponent }     from '@moon-core/components/disclaimer-agreement/disclaimer-agreement.component';
import { MoonExternalComponent }            from '@moon-core/components/moon-external/moon-external.component';
import { AzureLoginComponent }              from '@moon-core/components/azure-login/azure-login.component';
import { DatabaseConnectComponent }         from '@moon-core/components/database-connect/database-connect.component';
import { DiagnosticComponent }              from '@moon-core/components/diagnostic/diagnostic.component';
import { HistoryRoutes }                    from '@moon-maintainer/history.routes';
import { MaintainerRoutes }                 from '@moon-maintainer/maintainer.routes';
import { PreviewRoutes }                    from '@moon-public/preview.routes';
import { PublicRoutes }                     from '@moon-public/public.routes';
import { ConstantString }                   from '@moon-shared/constants/constant-string';
import { RoutePath }                        from '@moon-shared/constants/route-path';
import { RouteParameter }                   from '@moon-shared/constants/route-parameter';
/**
 * MSAL Angular can protect routes in your application using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
export const appRoutes: Route[] = [
    { 
        path: RoutePath.Diagnostic, component: DiagnosticComponent, 
        canDeactivate: [() => true] // to bypass MSAL Guard
    },
    {
        path: RoutePath.External,
        children: [
            { path: RoutePath.Empty, component: MoonExternalComponent },
            { path: String.createRoute(RouteParameter.ExternalRoutePage.toRouteParam()), component:  MoonExternalComponent },
        ]
    },
    {
        path: RoutePath.Empty,
        component: AppMainComponent,
        canActivate: [MsalGuard],
        children: [
            ...ConsumerRoutes,
            ...PublicRoutes,
            ...MaintainerRoutes,
            ...CollaboratorRoutes,
            ...PreviewRoutes,
            ...HistoryRoutes,
            { path: RoutePath.DisclaimerAgreement, component: DisclaimerAgreementComponent, canActivate: [MsalGuard] },
            { path: RoutePath.Empty, redirectTo: ConstantString.ClauseSearch, pathMatch: 'full' }
        ]
    },
    // This is to handle double navigation from msal.
    // MSAL redirects to a route starting with code={randomString}
    // Ref: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6772#issuecomment-1856494337
    {
        component: MsalRedirectComponent,
        matcher: (url: UrlSegment[]) => {
            const msalRoutes = ['code=', 'state=', 'error=']
            return url.length === 1 && msalRoutes.some(msalRoute => url[0]?.path.startsWith(msalRoute)) ? { consumed: url } : null;
        },
    },
    { path: RoutePath.Error, component: AppErrorComponent },
    { path: RoutePath.PostLogout, component: MoonPostLogoutComponent },
    { path: RoutePath.ConnectDatabase, component: DatabaseConnectComponent, canActivate: [MsalGuard] },
    { path: RoutePath.Login, component: AzureLoginComponent },
];
