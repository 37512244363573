import { CommonModule, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, Input, OnInit }             from '@angular/core';
import { ReactiveFormsModule }                  from '@angular/forms';
import { Clipboard }                            from '@angular/cdk/clipboard';
// Third party imports
import { SharedModule }                         from 'primeng/api';
import { FormsModule }                          from '@angular/forms';
import { Table, TableModule }                   from 'primeng/table';
import { TabViewModule }                        from 'primeng/tabview';
import { ButtonModule }                         from 'primeng/button';
import { TooltipModule }                        from 'primeng/tooltip';
import { DialogModule }                         from 'primeng/dialog';
import { InputSwitchModule }                    from 'primeng/inputswitch';
// Moon Imports
import { ApiContentResult, ApiResult, IApiResult } from '@moon-core/models/api-result';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';
import { DocumentOcr }                          from '@moon-public/api/response/document-ocr.response';
import { TitleDocumentMap }                     from '@moon-public/api/response/title-document-map.response';
import { TitleExceptionMap }                    from '@moon-public/api/response/title-exception-map.response';
import { TitleSummaryController }            from '@moon-public/api/title-summary.controller';
import { ChatLogComponent }                     from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { DocumentMappingComponent }             from '@moon-maintainer/title-commitment-advanced/summary-result/document-mapping/document-mapping.component';
import { SummaryOcrComponent }                  from '@moon-maintainer/title-commitment-advanced/summary-result/summary-ocr/summary-ocr.component';
import { MoonTableHeaderComponent }             from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { FieldValues }                          from '@moon-shared/constants/field-values';
import { FieldNames }                           from '@moon-shared/constants/field-names';
import { TitleCommitmentOcrGet }                   from '@moon-public/api/response/title-commitment-log/title-commitment-ocr-get.response';
import { DefaultValues }                        from '@moon-shared/constants/default-values';
import { MessageSeverity }                      from '@moon-shared/constants/message-severity';
import { MoonMessageService }                   from '@moon-shared/services/moon-message.service';
export type OcrResult = { pageNumber: number, content: string, overrideContent?: string };
@Component({
  selector: 'moon-summary-result',
  standalone: true,
  templateUrl: './summary-result.component.html',
  styleUrls: ['./summary-result.component.scss'],
  imports: [
    CommonModule, ReactiveFormsModule, NgIf, NgFor, SlicePipe,
    FormsModule, TabViewModule, TableModule, SharedModule, DialogModule, InputSwitchModule,
    MoonTableHeaderComponent, ChatLogComponent, ButtonModule, TooltipModule,
    DocumentMappingComponent, SummaryOcrComponent
  ],
})
export class SummaryResultComponent implements OnInit {
  @Input() public MSTitleSummaryID: number;
  @Input() public MSChatLogID: number | null;
  
  public MSTitleExceptionMapArray: TitleExceptionMap[] = [];
  public MSTitleDocumentMapArray!: TitleDocumentMap[];
  public MSTitleDocumentMapFilteredArray!: TitleDocumentMap[];
  public MSOcrContentArray: OcrResult[] = [];
  public MSOcrSkillData: TitleCommitmentOcrGet;
  public MSOcrInProgress: boolean;
  public MSLoadingDocumentMapping: boolean = false;
  public MSShowNotFoundOnly: boolean = false;
  public MSShowChatLog: boolean = false;
  public MSEditResult: boolean = true;
  constructor(
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
    private _clipboard: Clipboard,
    private _moonMessageService: MoonMessageService
  ) { }

  ngOnInit() {
    this.MSLoadDocumentAndExceptionMaps();
    this.MSLoadOcr(this.MSTitleSummaryID);
    this.getOcrSkill();
  }
  
  public MSLoadDocumentAndExceptionMaps() {
    this.loadDocumentMaps(this.MSTitleSummaryID);
    this.loadExceptionMaps(this.MSTitleSummaryID);
  }
  
  public MSToggleShowNotFoundOnly(): void {
    if (this.MSShowNotFoundOnly){
      this.MSTitleDocumentMapFilteredArray = 
        this.MSTitleDocumentMapArray.filter(s => 
          "[\"NOT FOUND\"]".equalsIgnoreCase(s.exceptionNumberJson));
    }
    else {
      this.MSTitleDocumentMapFilteredArray = this.MSTitleDocumentMapArray;
    }
  }

  private async loadExceptionMaps(titleSummaryID: number) {
    this.MSLoadingDocumentMapping = true;
    try
    {
      const apiResult: ApiContentResult<TitleExceptionMap[]> = await this._titleSummaryController.GetExceptionMapList(titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleExceptionMapArray = apiResult.content;
      }
    }
    finally{
      this.MSLoadingDocumentMapping = false;
    }
  }

  private async loadDocumentMaps(titleSummaryID: number) {
    const apiResult: ApiContentResult<TitleDocumentMap[]> = await this._titleSummaryController.GetDocumentMapList(titleSummaryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSTitleDocumentMapArray = apiResult.content;
      this.MSToggleShowNotFoundOnly();
    }
  }

  public async MSLoadOcr(titleSummaryID: number) {
    this.MSOcrInProgress = true;
    try {
      const apiResult: ApiContentResult<DocumentOcr> = await this._titleSummaryController.GetOcr(titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSOcrContentArray = this.extractOcrContent(apiResult.content);
      }
    }
    finally {
      this.MSOcrInProgress = false;
    }
  }

  public calculateDocumentTotal(exceptionNumber: string) {
    const total = this.MSTitleExceptionMapArray.filter(d => d.exceptionNumber === exceptionNumber && FieldValues.Y.equalsIgnoreCase(d.hasDocument))?.length ?? 0;
    return total;
  }

  public OnSearchInput(table: Table, searchInput: string): void {
    table.filterGlobal(searchInput, "contains");
  }

  public async OnClickOCRSkill(): Promise<void> {
    try {
      this.MSOcrInProgress = true;
      const apiResult: IApiResult = await this._titleSummaryController.GetTitleSummaryOcrText(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.getOcrSkill();
        //this.MSOcrSkillData = apiResult.content;
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }

  public OnClickCopy(e: MouseEvent): void {
    const systemMessage = this.MSOcrSkillData.reviseOcrText;
    if (systemMessage)
      this._clipboard.copy(systemMessage);
    e.stopPropagation();
  }
  
  public async MSOnOverrideChatLogData()
    : Promise<void> {
    
    try {
      this.MSOcrInProgress = true;
      const apiResult: ApiResult = await this._titleSummaryController.ChatOverride(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.loadExceptionMaps(this.MSTitleSummaryID);
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }

  public OnClickSkillChatLog(): void {
    if (!this.MSOcrSkillData.reviseOcrChatLogID){
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Chat Log not available.");
      this.MSShowChatLog = false;
      return;
    }
    this.MSShowChatLog = true
  }

  private extractOcrContent(documentOcr: DocumentOcr): OcrResult[] {
    const ocrLines: OcrResult[] = documentOcr.pages.flatMap(a => {
      return { 
        pageNumber: a.pageNumber, 
        content: a.lines.join(DefaultValues.Separator),
        overrideContent: a.overrideLines
      };
    });
    return ocrLines.orderBy(FieldNames.PageNumber);
  }

  private async getOcrSkill(): Promise<void> {
    try {
      this.MSOcrInProgress = true;
      const apiResult: ApiContentResult<TitleCommitmentOcrGet> = await this._titleSummaryController.GetOcrSkillData(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSOcrSkillData = apiResult.content;
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }
}
