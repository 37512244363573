<div
    class="btn-more-dropdown-icon moon-cursor-pointer"
    (click)="OnClickIcon(menu, $event)"
>
    <ng-content></ng-content>
</div>
<p-tieredMenu
    #menu
    [styleClass]="MSMenuStyleClass"
    [model]="model"
    [popup]="true"
    appendTo="body"
></p-tieredMenu>
