<!-- Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM -->
<moon-table-page [MSPageTitle]="MSPageTitle"
                    [MSItemList]="MSEmailWhitelistList"
                    [MSDisplayCreateButton]="MSDisplayCreate"
                    [MSDisplaySecondaryButton]="MSDisplaySecondary"
                    [MSSecondaryButtonIcon]="'pi pi-refresh'"
                    [MSSecondaryButtonToolTip]="'Reload'"
                    (MSEventOnClickCreate)="OnClickCreate()"
                    (MSEventOnClickSecondary)="MSReloadList()">
    <p-table #MSTable
                [columns]="MSTableColumns"
                [rowHover]="true"
                [value]="MSEmailWhitelistList"
                selectionMode="single"
                [loading]="MSLoading"
                styleClass="p-datatable-gridlines p-datatable-striped">
        <ng-template pTemplate="caption">
            <moon-table-header [MSPageTitle]="MSPageTitle"
                                [MSEnableSearch]="MSEnableSearch"
                                [MSDisplayCreateButton]="MSDisplayCreate"
                                [MSDisplaySecondaryButton]="MSDisplaySecondary"
                                [MSSecondaryButtonIcon]="'pi pi-refresh'"
                                [MSSecondaryButtonToolTip]="'Refresh'"
                                (MSEventOnClickCreate)="OnClickCreate()"
                                (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                                (MSEventOnClickSecondary)="MSReloadList()">
            </moon-table-header>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'actions',
                    clickable: col.field !== 'actions'
                }"
                    (click)="col.field === 'actions' || OnClickItem(rowData)">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'actions'">
                            <moon-custom-dropdown [model]="rowData.actions">
                                <i class="pi pi-ellipsis-v"></i>
                            </moon-custom-dropdown>
                        </div>
                        <div *ngSwitchCase="'lastUpdated'">
                            {{ rowData.lastUpdatedBy }} <br /> at 
                            {{ rowData.lastUpdatedAt | moondate }}
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>

