// Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM
// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
// Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM
import { Injectable }   from "@angular/core";
import { Router }       from "@angular/router";
// import { ConstantString } from "@moon-shared/constants/constant-string";
import { RoutePath }    from '@moon-shared/constants/route-path';

@Injectable()
export class ConsumerRouter {
    constructor(
        private _router: Router,
    ) { }

    public NavigateToSearch() {
        this._router.navigate([RoutePath.ClauseSearch]);
    }
    
    // TitleCommitment Start
    public TitleCommitmentsRouteLink() {
        return RoutePath.TitleCommitmentsSimple;
    }

    public RouteToTitleCommitmentsSimple() {
        this._router.navigate([RoutePath.TitleCommitmentsSimple,]);
    }
    
    public RouteToTitleUserPage(titleSummaryID: number) {
        this._router.navigate([RoutePath.TitleCustomerPage, titleSummaryID]);
    }
    
    public RouteToTitleCommitmentsAdd(step: string = RoutePath.CommitmentType ): void {
        this._router.navigate([RoutePath.TitleCommitments, RoutePath.Add, step]);
    }
    // TitleCommitment End

    // Airplane Start
    public RouteToAirplane(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Airplane, ...command]);
    }

    public RouteToAirplaneCreate(): void {
        this.RouteToAirplane(RoutePath.Add);
    }

    public RouteToAirplaneEdit(airplaneID: number) {
        this.RouteToAirplane(airplaneID, RoutePath.Edit);
    }
    // Airplane End

    // Survey Start
    public RouteToSurvey(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Survey, ...command]);
    }

    public RouteToSurveyAdd(): void {
        this._router.navigate([RoutePath.Survey, RoutePath.Add]);
    }
    // Survey End
    
    // Placeholder Router
}
