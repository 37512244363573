<div
    class="mb-1 border-bottom-1 surface-border py-2"
    [ngClass]="MSTitleException.hasError ? 'border-top-1 border-red-500' : 'surface-border'"
>
    <h1 class="flex flex-1 gap-3 mb-2 text-lg align-items-center">
        <div>{{MSTitleException.part}}</div>
        <div>{{MSTitleException.exceptionNumber}}</div>
        <div *ngIf="MSTitleException.processingStatus">
            <p-badge
                severity="warning"
                title="Processing status"
                value=" {{MSTitleException.processingStatus}}"
            ></p-badge>
        </div>
        <div *ngIf="MSTitleException.hasError">
            <p-badge
                severity="danger"
                value="Error"
            ></p-badge>
        </div>

        <div class="ml-auto flex">
            <p-fileUpload
                *ngIf="MSTitleException.titleExceptionID > 0"
                #MSFileUpload
                mode="basic"
                accept=".pdf"
                [auto]="true"
                (onSelect)="MSOnClickUploadDocument($event, MSFileUpload)"
                chooseIcon="pi pi-upload"
                [chooseLabel]="MSUploadingDocument ? 'Uploading' : 'Upload document'"
                title="Upload document"
                [disabled]="MSUploadingDocument || MSSaving"
                class="p-button-raised moon-button"
            ></p-fileUpload>

            <ng-container *ngIf="MSTitleException.hasDocument === 'N'">
                <moon-dropdown-button
                    [MSLabel]="'Exception Prompt'"
                    [MSDisabled]="MSSaving"
                    [MSButtons]="MSPromptMenuItems"
                ></moon-dropdown-button>
            </ng-container>
        </div>
    </h1>
    <div
        class="mb-2"
        *ngIf="MSTitleException.exceptionDescription"
    >
        {{MSTitleException.exceptionDescription}}
    </div>
    <div
        class="mb-2 white-space-pre-line"
        *ngIf="MSTitleException.logMessage"
    >
        <div>
            <span class="font-bold">Log Messages:</span>
            <button
                pButton
                pRipple
                class="h-min w-min p-0 m-0 ml-2 bg-unset hover:underline text-primary text-base"
                label="Clear Log Messages"
                (click)="MSClearLogMessage()"
            > </button>
        </div>
        <div class="white-space-pre-line">{{MSTitleException.logMessage}}</div>
    </div>
    <ng-container *ngIf="MSTitleException.hasDocument === 'N'">
        <div class="mb-2">
            <span class="font-bold">Exception Type</span>:
            <div *ngIf="MSEditType; else showType">
                <form
                    [formGroup]="MSTypeFormGroup"
                    (ngSubmit)="MSSaveExceptionType()"
                >
                    <moon-form-control
                        [MSFormControl]="MSTypeFormGroup.controls.exceptionType"
                        [MSLabel]="'Exception Type'"
                        [MSDisplayLabel]="false"
                        [MSInputSize]="12"
                        [MSErrorMessageSize]="12"
                    >
                        <input
                            id="exceptionType"
                            #action
                            pInputText
                            [formControl]="MSTypeFormGroup.controls.exceptionType"
                        />
                    </moon-form-control>
                    <button
                        type="submit"
                        pButton
                        pRipple
                        label="Save"
                        class="p-button-raised mr-2 mb-2"
                        *ngIf="MSTypeFormGroup?.dirty"
                        [disabled]="MSSaving"
                    > </button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Cancel"
                        [disabled]="MSSaving"
                        class="p-button-raised p-button-secondary mr-2 mb-2"
                        (click)="MSToggleTypeEdit()"
                    > </button>
                </form>
            </div>
            <ng-template #showType>
                <span
                    title="Click to edit"
                    class="hover:surface-hover"
                    (click)="MSToggleTypeEdit()"
                >{{MSTitleException.exceptionType}}
                </span>
                <i
                    class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                    (click)="MSToggleTypeEdit()"
                ></i>
                <!-- <span
                    *ngIf="MSTitleException.overrideExceptionType"
                    class="text-gray-400 text-sm"
                >(Edited)
                </span> -->
            </ng-template>
        </div>
        <div class="mb-2">
            <span class="font-bold">Recommended Action</span>:
            <div *ngIf="MSEditAction; else showAction">
                <form
                    [formGroup]="MSActionFormGroup"
                    (ngSubmit)="MSSaveRecommendedAction()"
                >
                    <moon-form-control
                        [MSFormControl]="MSActionFormGroup.controls.recommendedAction"
                        [MSLabel]="'Recommended Action'"
                        [MSDisplayLabel]="false"
                        [MSInputSize]="12"
                        [MSErrorMessageSize]="12"
                    >
                        <div
                            class="grow-wrap"
                            [attr.textarea-value]="action.value"
                        >
                            <textarea
                                id="recommendedAction"
                                #action
                                pInputTextarea
                                [formControl]="MSActionFormGroup.controls.recommendedAction"
                            ></textarea>
                        </div>
                    </moon-form-control>
                    <button
                        type="submit"
                        pButton
                        pRipple
                        label="Save"
                        class="p-button-raised mr-2 mb-2"
                        *ngIf="MSActionFormGroup?.dirty"
                        [disabled]="MSSaving"
                    > </button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Cancel"
                        [disabled]="MSSaving"
                        class="p-button-raised p-button-secondary mr-2 mb-2"
                        (click)="MSToggleActionEdit()"
                    > </button>
                </form>
            </div>
            <ng-template #showAction>
                <span
                    title="Click to edit"
                    class="hover:surface-hover"
                    (click)="MSToggleActionEdit()"
                >{{MSTitleException.recommendedAction}}
                </span>
                <i
                    class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                    (click)="MSToggleActionEdit()"
                ></i>
                <!-- <span
                    *ngIf="MSTitleException.overrideRecommendedAction"
                    class="text-gray-400 text-sm"
                >(Edited)
                </span> -->
            </ng-template>
        </div>
    </ng-container>
</div>

<!-- Chat Log Viewer -->
<p-dialog
    header="Chat Log"
    [(visible)]="MSShowChatLog"
    styleClass="w-90vw"
    position="top"
    [draggable]="false"
>
    <moon-chat-log
        *ngIf="MSShowChatLog"
        [MSChatLogID]="MSChatLogID"
        (MSOnOverrideChatLogData)="MSOnOverrideChatLogData()"
    ></moon-chat-log>
</p-dialog>