<div class="card moon-pdf-ocr-container">
    <p-tabView [scrollable]="true">
        <p-tabPanel header="Document Mapping">
            <ng-template #documentMapLoading>Loading...</ng-template>
            <ng-container *ngIf="!MSLoadingDocumentMapping; else documentMapLoading">
                <ng-template #noDocumentMaps>No document mapping found.</ng-template>
                <ng-container *ngIf="MSTitleDocumentMapArray?.length; else noDocumentMaps">

                    <div class="moon-show-hide-pdf-thumbnails">
                        <p-inputSwitch
                            inputId="toggleShowNotFound"
                            [(ngModel)]="MSShowNotFoundOnly"
                            (onChange)="MSToggleShowNotFoundOnly()"
                        ></p-inputSwitch>
                        <label for="toggleShowNotFound">Show Not Found Only</label>
                    </div>

                    <ul class="m-0 p-0 list-none">
                        <li
                            *ngFor="let titleDocumentMap of MSTitleDocumentMapFilteredArray; let i=index"
                            class="py-2 pl-4 border-bottom-1 surface-border"
                        >
                            <moon-document-mapping
                                [MSTitleDocumentMap]="titleDocumentMap"
                                [MSTitleSummaryID]="MSTitleSummaryID"
                                [MSTitleDocumentNumber]="i"
                                (MSOnTitleDocumentChange)="MSLoadDocumentAndExceptionMaps()"
                            ></moon-document-mapping>
                        </li>
                    </ul>
                </ng-container>
            </ng-container>
        </p-tabPanel>
        <p-tabPanel header="Exception Mapping">
            <p-table
                #MSTable
                [value]="MSTitleExceptionMapArray"
                sortMode="single"
                rowGroupMode="rowspan"
                groupRowsBy="titleExceptionID"
                styleClass="p-datatable-gridlines"
                [scrollable]="true"
                [globalFilterFields]="['exceptionNumber','part','textList']"
            >
                <ng-template pTemplate="caption">
                    <moon-table-header
                        #MSTableHeader
                        [MSEnableSearch]="true"
                        [MSDisplayCreateButton]="false"
                        [MSDisplaySecondaryButton]="false"
                        [MSDisplayUploadButton]="false"
                        (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                    ></moon-table-header>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Exception Number</th>
                        <th>Document</th>
                        <th>Exception Number Json</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-document
                    let-rowIndex="rowIndex"
                    let-rowgroup="rowgroup"
                    let-rowspan="rowspan"
                >
                    <tr>
                        <td
                            *ngIf="rowgroup"
                            [attr.rowspan]="rowspan"
                        >
                            <div class="font-bold">{{document.exceptionNumber}} </div>
                            <div
                                class="mt-1"
                                *ngIf="calculateDocumentTotal(document.exceptionNumber) as totalDocument"
                            >
                                {{totalDocument}} document(s)
                            </div>
                        </td>
                        <td>
                            {{document.textList}}
                        </td>
                        <td>
                            {{document.exceptionNumberJson}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="OCR Data">
            <ng-container *ngFor="let ocr of MSOcrContentArray">
                <moon-summary-ocr
                    [MSTitleSummaryID]="MSTitleSummaryID"
                    [MSOcr]="ocr"
                    [MSIsProcessiing]="MSOcrInProgress"
                    (MSOnOcrChange)="MSLoadOcr(MSTitleSummaryID)"
                ></moon-summary-ocr>
                <hr>
            </ng-container>
        </p-tabPanel>
        <p-tabPanel header="OCR Skill">
            <div class="grid">
                <div class="col-12">
                    <button
                        class="float-right"
                        pButton
                        pRipple
                        icon="pi pi-copy"
                        pTooltip="Copy OCR Skill Text to Clipboard"
                        tooltipPosition="top"
                        [disabled]="MSOcrInProgress"
                        (click)="OnClickCopy($event)"
                    > </button>
                    <button
                        class="float-right"
                        pButton
                        pRipple
                        icon="pi pi-history"
                        pTooltip="Show OCR Skill Chat Log"
                        tooltipPosition="top"
                        [disabled]="MSOcrInProgress"
                        (click)="OnClickSkillChatLog()"
                    > </button>
                    <button
                        class="float-right"
                        pButton
                        [loading]="MSOcrInProgress"
                        loadingIcon="pi pi-spin pi-spinner"
                        pTooltip="Run OCR Skill"
                        tooltipPosition="top"
                        icon="pi pi-play"
                        (click)="OnClickOCRSkill()"
                    >
                    </button>
                </div>
                <div class="col-12">
                    <p
                        *ngIf="MSOcrSkillData"
                        class="white-space-pre-wrap"
                    >
                        {{ MSOcrSkillData.reviseOcrText }}
                    </p>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Chat Result">
            <ng-template #noChatLogID>Chat log is not available.</ng-template>
            <moon-chat-log
                *ngIf="MSChatLogID;else noChatLogID"
                [MSChatLogID]="MSChatLogID"
                [MSEditResult]="MSEditResult"
                (MSOnOverrideChatLogData)="MSOnOverrideChatLogData()"
            ></moon-chat-log>
        </p-tabPanel>
    </p-tabView>
</div>

<p-dialog
    header="Chat Log"
    [(visible)]="MSShowChatLog"
    styleClass="w-90vw h-full"
    [modal]="true"
    [draggable]="false"
>
    <moon-chat-log
        *ngIf="MSShowChatLog"
        [MSChatLogID]="MSOcrSkillData.reviseOcrChatLogID"
        [MSEditResult]="MSEditResult"
    ></moon-chat-log>
</p-dialog>