import { Route }                        from "@angular/router";
import { MsalGuard }                    from "@azure/msal-angular";
// MS Imports
import { PreviewRouteGuard }            from "@moon-core/guards/preview-route.guard";
import { UserSessionGuard }             from "@moon-core/guards/user-session.guard";
import { MezzLoanChunkComponent }       from "@moon-public/mezz-loan-chunk/mezz-loan-chunk.component";
import { MezzLoanEditPageComponent }    from '@moon-public/mezz-loan/mezz-loan-edit-page/mezz-loan-edit-page.component';
import { MezzLoanRevisionComponent }    from '@moon-public/mezz-loan/mezz-loan-revision/mezz-loan-revision.component';
import { MezzLoanComponent }            from '@moon-public/mezz-loan/mezz-loan.component';
import { SummarizeComponent }           from "@moon-public/summarize/summarize.component";
import { ReviseComponent }              from "@moon-public/revise/revise.component";
import { RouteParameter }               from "@moon-shared/constants/route-parameter";
import { RoutePath }                    from "@moon-shared/constants/route-path";

export const PreviewRoutes: Route[] = [        
    { path: RoutePath.Summarize, component: SummarizeComponent, canActivate: [MsalGuard, UserSessionGuard] },
    {
        path: RoutePath.MezzLoan,
        canActivate: [MsalGuard, UserSessionGuard, PreviewRouteGuard],
        children: [
            { path: RoutePath.Empty, component: MezzLoanComponent },
            { path: RoutePath.Add, component: MezzLoanEditPageComponent },
            { path: String.createRoute(RouteParameter.MezzLoanID.toRouteParam(), RoutePath.Revision), component: MezzLoanRevisionComponent },
            { path: String.createRoute(RouteParameter.MezzLoanID.toRouteParam(), RoutePath.Edit), component: MezzLoanEditPageComponent },
            
            {
                path: String.createRoute(RouteParameter.MezzLoanID.toRouteParam(), RoutePath.Chunk),
                component: MezzLoanChunkComponent,
                canActivate: [MsalGuard, UserSessionGuard]
            },
        ]
    },
    { path: RoutePath.Revise, component: ReviseComponent, canActivate: [MsalGuard, UserSessionGuard] },
];
