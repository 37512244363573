// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import {
    DecimalPipe,
    NgClass, NgFor, NgIf, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                               from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter, Input, OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
}                                               from "@angular/core";
import { FormsModule }                          from "@angular/forms";
// Third Party imports
import { PrimeIcons, SelectItem }               from "primeng/api";
import { ButtonModule }                         from "primeng/button";
import { DialogModule }                         from "primeng/dialog";
import { DropdownModule }                       from "primeng/dropdown";
import { InputSwitchModule }                    from "primeng/inputswitch";
import { InputTextModule }                      from "primeng/inputtext";
import { MultiSelectModule }                    from "primeng/multiselect";
import { RippleModule }                         from "primeng/ripple";
import { Table, TableModule }                   from "primeng/table";
import { TagModule }                            from "primeng/tag";
import { TieredMenuModule }                     from "primeng/tieredmenu";
// Moon imports
import { ConsumerRouter }                       from "@moon-consumer/consumer.router";
import { TitleDocumentsUploadComponent }        from "@moon-consumer/title-commitment/title-documents-upload/title-documents-upload.component";
import { ApiBlobResult }                        from "@moon-core/models/api-result";
import { ComponentUtilityService }              from "@moon-core/services/component-utility.service";
import { UserSessionService }                   from "@moon-core/services/user-session.service";
import { MaintainerRouter }                     from "@moon-maintainer/maintainer.router";
import { TitleContactCustomerComponent }        from "@moon-maintainer/title-commitment-advanced/title-contact-customer/title-contact-customer.component";
import { TitleCommitment }                         from "@moon-public/api/request/title-commitment.request";
import { TitleCommitmentFind }                     from "@moon-public/api/response/title-commitment-find.response";
import { TitleCommitmentGet }                      from "@moon-public/api/response/title-commitment-get.response";
import { TitleSummaryController }               from "@moon-public/api/title-summary.controller";
import { PublicRouter }                         from "@moon-public/public.router";
import { TitleStep, TitleCommitmentStatus } from "@moon-maintainer/title-commitment-advanced/models/processing-status";
import { MoonCustomDropdownComponent }          from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonLoadingComponent }                 from "@moon-shared/components/moon-loading/moon-loading.component";
import { MoonTableHeaderComponent }             from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }               from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { ConstantString }                       from "@moon-shared/constants/constant-string";
import { FieldNames }                           from '@moon-shared/constants/field-names';
import { MoonTemplateDirective }                from "@moon-shared/directives/moon-template.directive";
import { MoonDatePipe }                         from "@moon-shared/pipes/moon-date.pipe";
import { MoonMessageService }                   from "@moon-shared/services/moon-message.service";
import { TableColumn }                          from '@moon-shared/types/table-column.type';

@Component({
    selector: "moon-title-commitment-advanced-table",
    templateUrl: "./title-commitment-advanced-table.component.html",
    styleUrls: ['./title-commitment-advanced-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle, MoonDatePipe, DecimalPipe,
        TableModule, ButtonModule, RippleModule, InputTextModule, DropdownModule, InputSwitchModule, FormsModule, TieredMenuModule, TagModule, DialogModule, MultiSelectModule,
        TitleDocumentsUploadComponent, TitleContactCustomerComponent,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonLoadingComponent, MoonTemplateDirective],
        providers: [PublicRouter]
})
export class TitleCommitmentAdvancedTableComponent implements OnInit, AfterViewInit {
    @Input() public MSPageTitle: string;
    @Input() public MSTitleCommitmentList: TitleCommitmentFind[];
    @Input() public MSLoading = false;
    @Output() public MSOnEmitReload = new EventEmitter();
    @ViewChild("MSTable") MSTable: Table;
    public MSSelectedStatus: string = TitleCommitmentStatus.Complete;
    public MSSelectedProcessingStatus: string = ConstantString.All;
    public MSShowContactConsumer: boolean = false;
    
    public MSStatusFilterOptions: SelectItem[] = [
        { label: TitleCommitmentStatus.AdminReview, value: TitleCommitmentStatus.AdminReview },
        { label: TitleCommitmentStatus.Intake, value: TitleCommitmentStatus.Intake },
        { label: TitleCommitmentStatus.Processing.getDisplayName(), value: TitleCommitmentStatus.Processing },
        { label: TitleCommitmentStatus.Error.getDisplayName(), value: TitleCommitmentStatus.Error },
        { label: TitleCommitmentStatus.Complete, value: TitleCommitmentStatus.Complete }
    ];
    public MSProcessingStatusFilter: SelectItem[] = [
        { label: TitleStep.BackgroundQueue.getDisplayName(), value: TitleStep.BackgroundQueue },
        { label: TitleStep.DownloadPdfs.getDisplayName(), value: TitleStep.DownloadPdfs },
        { label: TitleStep.TitleOcr.getDisplayName(), value: TitleStep.TitleOcr },
        { label: TitleStep.DocumentOcr.getDisplayName(), value: TitleStep.DocumentOcr },
        { label: TitleStep.PromptTitleSummary.getDisplayName(), value: TitleStep.PromptTitleSummary },
        { label: TitleStep.PromptTitleDocument.getDisplayName(), value: TitleStep.PromptTitleDocument },
        { label: TitleStep.PromptTitleException.getDisplayName(), value: TitleStep.PromptTitleException },
        { label: TitleStep.ManualReview.getDisplayName(), value: TitleStep.ManualReview },
        { label: TitleStep.End.getDisplayName(), value: TitleStep.End },
        { label: ConstantString.All, value: ConstantString.All },
    ];
    public MSDownloading: boolean = false;
    public MSTableColumns: TableColumn[] = [
        { field: FieldNames.TitleSummaryID.toCamelCase(), header: String.empty, class: 'w-20per', },
        { field: FieldNames.MatterName.toCamelCase(), header: FieldNames.MatterName.getDisplayName(), class: 'w-20per', },
        { field: FieldNames.SummaryType.toCamelCase(), header: FieldNames.CommitmentType.getDisplayName(), class: 'w-10per' },
        {
            field: FieldNames.Status.toCamelCase(),
            header: FieldNames.Status.getDisplayName(),
            class: 'w-5per',
            showColumnFilter: true,
            filterOptions: this.MSStatusFilterOptions
        },
        { 
            field: FieldNames.CurrentStep.toCamelCase(), 
            header: FieldNames.CurrentStep.getDisplayName(), 
            class: 'w-5per', 
            showColumnFilter: true,
            filterOptions: this.MSProcessingStatusFilter
        },
        { field: FieldNames.FileName.toCamelCase(), header: FieldNames.FileName.getDisplayName(), class: 'w-15per' },
        { field: FieldNames.FileSize.toCamelCase(), header: FieldNames.FileSize.getDisplayName(), class: 'w-5per', sortable: true },
        { field: FieldNames.TotalCost.toCamelCase(), header: FieldNames.TotalCost.getDisplayName(), class: 'w-5per' },
        { field: FieldNames.LogMessage.toCamelCase(), header: FieldNames.LogMessage.getDisplayName(), class: 'w-20per white-space-pre-wrap' },
        {
            field: FieldNames.ExceptionPagesCsv.toCamelCase(),
            header: FieldNames.ExceptionPagesCsv.replace("Csv", String.empty).getDisplayName(),
            class: 'w-10per'
        },
        { field: FieldNames.LastUpdated.toCamelCase(), header: FieldNames.LastUpdated.getDisplayName(), class: 'w-8per' },
        //{ field: FieldNames.LastUpdatedAtUtc.toCamelCase(), header: FieldNames.LastUpdatedAtUtc.getDisplayName(), class: 'w-15per' },
        {
            field: FieldNames.CreatedAt.toCamelCase(),
            header: FieldNames.CreatedAt.getDisplayName(),
            class: 'w-5per',
            sortable: true
        },

        // Column PlaceHolder - DO NOT REMOVE
        { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-30per' }
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplaySecondary: boolean;

    public MSShowDocumentUpload: boolean = false;
    public MSSelectedTitleCommitment: TitleCommitmentGet | null;

    public MSIsChunkedSelectItemList: SelectItem[] = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
    ]
    constructor(
        private _maintainerRouter: MaintainerRouter,
        private _userSessionService: UserSessionService,
        private _consumerRouter: ConsumerRouter,
        private _cd: ChangeDetectorRef,
        private _publicRouter: PublicRouter,
        private _titleSummaryController: TitleSummaryController,
        private _componentUtilityService: ComponentUtilityService,
        private _moonMessageService: MoonMessageService
    ) { }
    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
        this.MSDisplaySecondary = true;
    }
    ngAfterViewInit() {
        this.MSDisplaySecondary = this.MSTitleCommitmentList.length > 0;
        this.MSTable.filteredValue = this.getDefaultFilteredTitleCommitment();
        this._cd.detectChanges();
    }
    
    public MSGetStatusIcon(titleCommitment: TitleCommitment): string {
        if (TitleCommitmentStatus.Processing.equalsIgnoreCase(titleCommitment.status))
            return PrimeIcons.HOURGLASS;
        else if (TitleCommitmentStatus.Intake.equalsIgnoreCase(titleCommitment.status))
            return PrimeIcons.TIMES;
        else if (TitleCommitmentStatus.Error.equalsIgnoreCase(titleCommitment.status))
            return PrimeIcons.TIMES;
        else if (TitleCommitmentStatus.Complete.equalsIgnoreCase(titleCommitment.status))
            return PrimeIcons.CHECK;
        return String.empty;
    }
    public async MSOnClickDownloadUploadedPdf(event: MouseEvent, titleCommitment: TitleCommitmentFind) {
        // to avoid calling onItemClick()
        event.stopImmediatePropagation();
        this._moonMessageService.toastInfo("Downloading uploaded pdf...");

        const apiResult: ApiBlobResult = await this._titleSummaryController.GetPdfBlob(titleCommitment.titleSummaryID);
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.toastSuccess("Pdf downloaded successful.");
        }
    }

    public MSApplyFilter() {
        const filteredTitleCommitment = this.filterByStatus(this.MSTitleCommitmentList);
        this.MSTable.filteredValue = this.filterByProcessingStatus(filteredTitleCommitment);
    }
    private filterByStatus(tilteSummaryList: TitleCommitmentFind[]): TitleCommitmentFind[] {
        const statusFilterValue = this.MSSelectedStatus;
        return tilteSummaryList.filter(a => statusFilterValue.equalsIgnoreCase(a.status));
    }    
    private filterByProcessingStatus(tilteSummaryList: TitleCommitmentFind[]): TitleCommitmentFind[] {
        const processingStatusFilterValue = this.MSSelectedProcessingStatus;
        if (ConstantString.All.equalsIgnoreCase(processingStatusFilterValue)) {
            return tilteSummaryList;
        }
        return tilteSummaryList.filter(a => processingStatusFilterValue.equalsIgnoreCase(a.currentStep));
    }

    public OnClickItem(titleCommitment: TitleCommitmentFind): void {
        this.routeToTitleCommitmentsDetail(titleCommitment);
    }


    public OnClickCreate(): void {
        this._consumerRouter.RouteToTitleCommitmentsAdd();
    }

    public OnClickFind(): void {
        this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
    }
    public MSReloadList() {
        this.MSOnEmitReload.emit();
    }
    public OnSearchInput(MSTable: Table, searchInput: string): void {
        MSTable.filterGlobal(searchInput, "contains");
    }

    public MSToggleAdvancedView(): void {
        this._consumerRouter.RouteToTitleCommitmentsSimple();
    }

    public MSIsTitleCommitmentProcessing(titleCommitment: TitleCommitmentGet): boolean {
        return TitleCommitmentStatus.Processing.equalsIgnoreCase(titleCommitment.status);
    }
    public MSIsStatusIntake(titleCommitment: TitleCommitmentGet): boolean {
        return TitleCommitmentStatus.Intake.equalsIgnoreCase(titleCommitment.status);
    }
    public async MSOnClickUploadTitleDocument(titleCommitment: TitleCommitmentFind): Promise<void> {
        this.MSSelectedTitleCommitment = titleCommitment;
        this.MSShowDocumentUpload = true;
    }

    public MSOnAllDocumentDownloaded(): void {
        this.MSShowDocumentUpload = false;
        this.MSReloadList();
    }

    public async MSOnClickContactSupport(titleCommitment: TitleCommitmentFind): Promise<void> {
        this.MSSelectedTitleCommitment = titleCommitment;
        this.MSShowContactConsumer = true;
    }
    
    public MSIsUserMaintainer(): boolean {
        return this._userSessionService.IsMaintainer();
    }

    private getDefaultFilteredTitleCommitment(): TitleCommitmentFind[] {
        return this.MSTitleCommitmentList;
    }
    private routeToTitleCommitmentsDetail(titleCommitment: TitleCommitmentFind) {
        this._publicRouter.RouteToTitleCommitmentsDetail(titleCommitment.titleSummaryID);
    }
}
