// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
import { CommonModule, NgIf }                            from '@angular/common';
import { Component, OnInit }                             from '@angular/core';
import { ActivatedRoute, Router }                        from '@angular/router';
//Third Party Imports
import { MenuItem }                                      from 'primeng/api';
import { TableModule, Table }                            from 'primeng/table';
import { ButtonModule }                                  from 'primeng/button';
// Moon Imports
import { AppBreadcrumbService }                          from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }                   from '@moon-core/models/api-result';
import { ComponentUtilityService }                       from '@moon-core/services/component-utility.service';
import { MoonNotificationGet }                           from '@moon-maintainer/api/response/moon-notification-get.response';
import { MoonNotificationController }                    from '@moon-maintainer/api/moon-notification.controller';
import { MoonNotificationSubscriptionController }        from '@moon-maintainer/api/moon-notification-subscription.controller';
import { MoonNotificationSubscription }                  from '@moon-maintainer/api/request/moon-notification-subscription.request';
import { MoonUserNotificationSubscriptionGet }           from '@moon-maintainer/api/response/moon-user-notification-subscription-get.response';
import { MaintainerRouter }                              from '@moon-maintainer/maintainer.router';
import { MoonNotificationTableComponent }                from '@moon-maintainer/moon-notification/moon-notification-table/moon-notification-table.component';
import { MoonLoadingComponent }                          from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }                         from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }                                from '@moon-shared/constants/constant-string';
import { FieldNames }                                    from '@moon-shared/constants/field-names';
import { TableColumn }                                   from '@moon-shared/types/table-column.type';
import { MoonMessageService }                            from '@moon-shared/services/moon-message.service';
import { MessageSeverity }                               from '@moon-shared/constants/message-severity';
import { MoonTablePageComponent }                        from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { RoutePath }                                     from '@moon-shared/constants/route-path';
import { MoonTableHeaderComponent }                      from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';

@Component({
  selector: 'moon-notification-add-page',
  templateUrl: './moon-notification-subscription-page.component.html',
  styleUrls: ['./moon-notification-subscription-page.component.css'],
  standalone: true,
  imports: [
    NgIf,
    CommonModule,
    ButtonModule,
    TableModule,
    MoonLoadingComponent,
    MoonEditPageComponent,
    MoonNotificationTableComponent,
    MoonTablePageComponent,
    MoonTableHeaderComponent,
  ],
  providers: [
    MoonNotificationController,
    MoonNotificationSubscriptionController,
    MaintainerRouter,
  ],
})
export class MoonNotificationSubscriptionPageComponent implements OnInit {
  public MSLoading: boolean = true;
  public MSDisplayRemoveButton: boolean = false;
  public MSDisplayFind: boolean = false;
  public MSDisplayCreate: boolean = false;
  public MSEnableSearch: boolean = true;

  public MSMoonNotificationID: number | null;
  public MSMoonNotificationName: string | null;
  public MSMoonNotificationGet: MoonNotificationGet;
  public MSPageTitle: string = RoutePath.Notification.getDisplayName();
  public MSSubscriberList: MoonUserNotificationSubscriptionGet[];
  public MSNumberOfFilteredValues: number;
  public MSTableColumns: TableColumn[] = [
    {
      field: FieldNames.UserName.toCamelCase(),
      header: FieldNames.UserName.getDisplayName(),
      class: 'w-25per',
    },
    {
      field: FieldNames.Subscription.toCamelCase(),
      header: FieldNames.Subscription.getDisplayName(),
      class: 'w-25per',
    },
  ];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _moonNotificationSubscriptionController: MoonNotificationSubscriptionController,
    private _moonMessageService: MoonMessageService
  ) {
    const navigation = this._router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        moonNotificationName: string | null;
      };
      this.MSMoonNotificationName = state?.moonNotificationName;
    }
    this.MSPageTitle += this.MSMoonNotificationName
      ? `: ${this.MSMoonNotificationName.getDisplayName()}`
      : String.empty;
  }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSMoonNotificationID) {
      await this.getSubscribersByMoonNotificationID(this.MSMoonNotificationID);
    }
  }

  // Start: Button Click Event Handlers

  public OnSearchInput(subscriptionTable: Table, searchInput: string): void {
    subscriptionTable.filterGlobal(searchInput, 'contains');
  }

  public MSOnFilter(eventItems: MoonUserNotificationSubscriptionGet[]): void {
    this.MSNumberOfFilteredValues = eventItems.length;
  }

  public async MSSubscribeUser(user: MoonUserNotificationSubscriptionGet): Promise<void> {
    if (!this.MSMoonNotificationID) {
      this._moonMessageService.showToastMessage(
        MessageSeverity.Info,
        "Can't perform this operation."
      );
      return;
    }

    const notificationBody: MoonNotificationSubscription = {
      deliveryMethod: ConstantString.Email,
      moonNotificationID: this.MSMoonNotificationID,
      subscriberUserName: user.preferredUserName,
      notificationObjectID: null,
    };
    const apiResult: ApiResult =
      await this._moonNotificationSubscriptionController.SubscribeUser(
        notificationBody
      );
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess('Subscribed Successfully.');
      this.getSubscribersByMoonNotificationID(this.MSMoonNotificationID);
    }
  }

  public async MSUnsubscribeUser(user: MoonUserNotificationSubscriptionGet): Promise<void> {
    if (!this.MSMoonNotificationID) {
      this._moonMessageService.showToastMessage(
        MessageSeverity.Info,
        "Can't perform this operation."
      );
      return;
    }

    const apiResult: ApiResult =
      await this._moonNotificationSubscriptionController.Delete(
        user.moonNotificationSubscriptionID
      );
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess('Removed Successfully.');
      this.getSubscribersByMoonNotificationID(this.MSMoonNotificationID!);
    }
  }
  // End: Button Click Event Handlers

  private setBreadcrumb(): void {
    const items: MenuItem[] = [
      {
        label: RoutePath.Notification.getDisplayName(),
        routerLink: RoutePath.Notification,
      },
      {
        label: RoutePath.Subscription,
        routerLink: null,
      },
    ];
    this._appBreadcrumbService.setItems(items);
  }

  private readRouteParameters(): void {
    const moonNotificationID = this._activatedRoute.snapshot.paramMap.get(
      FieldNames.MoonNotificationID
    );
    this.MSMoonNotificationID = moonNotificationID ? +moonNotificationID : null;
  }

  private async getSubscribersByMoonNotificationID(
    moonNotificationID: number
  ): Promise<void> {
    try {
      this.MSLoading = true;
      if (moonNotificationID) {
        const apiResult: ApiContentResult<
          MoonUserNotificationSubscriptionGet[]
        > =
          await this._moonNotificationSubscriptionController.GetSubscriberList(
            moonNotificationID
          );
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this.MSSubscriberList = apiResult.content;
        }
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
