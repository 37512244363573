import { NgIf }                                         from "@angular/common";
import { Component, EventEmitter, Input, Output }       from "@angular/core";
// Third party imports
import { TooltipModule }                                from "primeng/tooltip";
import { RippleModule }                                 from "primeng/ripple";
import { ButtonModule }                                 from "primeng/button";
import { FileUploadHandlerEvent, FileUploadModule }     from "primeng/fileupload";
// Moon Imports
import { EmptyStateComponent }                          from "@moon-shared/components/empty-state/empty-state.component";
import { DefaultValues } from "@moon-shared/constants/default-values";

@Component({
    selector: "moon-table-page",
    templateUrl: "./moon-table-page.component.html",
    styleUrls: ["./moon-table-page.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        EmptyStateComponent,
        ButtonModule,
        RippleModule,
        TooltipModule,
        FileUploadModule
    ],
})
export class MoonTablePageComponent {
    public readonly MSMaxFileSize: number = DefaultValues.MaxFileSize;
    @Input() public MSItemList: any[];
    @Input() public MSDisplayRouteBackButton: boolean = false;
    @Input() public MSDisplayCreateButton: boolean = true;
    @Input() public MSDisplayFindButton: boolean = false;
    @Input() public MSPageTitle: string;
    
    @Input() public MSDisplaySecondaryButton: boolean = false;
    @Input() public MSSecondaryButtonIcon: string;
    @Input() public MSSecondaryButtonToolTip: string;
    @Input() public MSNumberOfFilteredTableRows: number;

    @Output() public MSEventOnClickRouteBack = new EventEmitter();
    @Output() public MSEventOnClickCreate = new EventEmitter();
    @Output() public MSEventOnClickFind = new EventEmitter();
    @Output() public MSEventOnClickSecondary = new EventEmitter();
    
    @Input() MSDisplayUploadButton: boolean = false;
    @Input() MSAcceptedFileExtension: string = String.empty;
    @Output() public MSEventOnUploadFile: EventEmitter<FileUploadHandlerEvent> = new EventEmitter();

    constructor() {}

    public get MSHasItems(): boolean {
        return this.MSItemList && this.MSItemList.length > 0;
    }

    public get MSItemCount(): string {
        let count;
        
        if (this.MSNumberOfFilteredTableRows || this.MSNumberOfFilteredTableRows == 0) {
            count = this.MSNumberOfFilteredTableRows;
        }
        else {
            count= this.MSItemList ? this.MSItemList.length : 0;
        }
        
        return `Number of Items: ${count}`;
    }

    public OnClickRouteBack() {
        this.MSEventOnClickRouteBack.emit();
    }

    public OnClickCreate() {
        this.MSEventOnClickCreate.emit();
    }

    public OnClickFind() {
        this.MSEventOnClickFind.emit();
    }

    public OnClickSecondary() {
        this.MSEventOnClickSecondary.emit();
    }
    
    public OnUploadFile(event: FileUploadHandlerEvent) {
        this.MSEventOnUploadFile.emit(event);
    }
}
