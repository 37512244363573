import {
  CommonModule, NgClass, NgFor,
  NgIf, NgSwitch, NgSwitchCase
}                                                       from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild }  from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule }  from '@angular/forms';
// Third party imports
import { ButtonModule }                                 from 'primeng/button';
import { FileUpload, FileUploadModule }                 from 'primeng/fileupload';
import { RippleModule }                                 from 'primeng/ripple';
import { TableModule }                                  from 'primeng/table';
import { ProgressBarModule }                            from 'primeng/progressbar';
import { ApiBlobResult }                                from '@moon-core/models/api-result';
import { ConsumerRouter }                               from '@moon-consumer/consumer.router';
// MS Imports
import { AppBreadcrumbService }                         from '@app-layout/services/app.breadcrumb.service';

import { ConstantString }                               from '@moon-shared/constants/constant-string';
import { MoonFormControlComponent }                     from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MessageSeverity }                              from '@moon-shared/constants/message-severity';
import { MoonDragDropDirective }                        from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonEnterKeyupDirective }                      from '@moon-shared/directives/moon-enter-keyup.directive';
import { MoonFileUpload }                               from '@moon-shared/models/moon-file-upload.model';
import { MoonMessageService }                           from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                                  from '@moon-shared/types/form-group-of.type';

import { SurveyService }                                from '@moon-consumer/survey/service/survey.service';
import { Survey }                                       from '@moon-consumer/api/request/survey.request';
import { SurveyController }                             from '@moon-consumer/api/survey.controller';
import { ComponentUtilityService }                      from '@moon-core/services/component-utility.service';

@Component({
  selector: 'moon-survey-file',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    ButtonModule,
    RippleModule,
    FileUploadModule,
    TableModule,
    MoonFormControlComponent,
    MoonDragDropDirective,
    MoonEnterKeyupDirective,
    ProgressBarModule,
  ],
  providers: [
    SurveyService, 
    SurveyController
  ],
  templateUrl: './survey-upload-file.component.html',
  styleUrl: './survey-upload-file.component.scss',
})
export class SurveyUploadFileComponent implements OnInit, AfterViewInit {
  public MSSurveyForm!: FormGroup<FormGroupOf<Survey>>;
  public MSUploading: boolean = false;
  public MSPageTitle: string = ConstantString.Survey;
  @ViewChild('fileUploader') public MSFileUploader: FileUpload;

  constructor(
    private _componentUtilityService: ComponentUtilityService,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _moonMessageService: MoonMessageService,
    private _surveyController: SurveyController,
    private _surveyService: SurveyService,
    private _consumerRouter: ConsumerRouter,
  ) {}

  ngOnInit() {
    this._surveyService;
    this._surveyController;

    this.setBreadcrumb();
    this.setTitleCommitmentFromWizardService();
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }

  ngAfterViewInit() {
    this.setFileUploadFromForm();
  }

  private setTitleCommitmentFromWizardService() {
    this.MSSurveyForm = this._surveyService.MSSurveyForm;
  }

  private setFileUploadFromForm() {
    const inputFile = this.MSSurveyForm.value.inputFile;
    if (inputFile != null) {
      this.MSFileUploader.files.push(inputFile);
      this.MSFileUploader.upload();
    }
  }

  public MSHandleFileInput(files: File[]) {
    if (!files?.length) return;

    const uploadedFile = files[0];

    if (uploadedFile) {
      this.updateFileControl(uploadedFile);
      // SurveyController.Create
    }
  }

  public async MSOnFileDrop(file: MoonFileUpload) {
    if (file && file.file.size > 0) {
      this.updateFileControl(file.file);
      return;
    }

    this._moonMessageService.toastInfo(
      'The file you trying to upload is not extracted from zip or may be empty. Please check and try again!'
    );
  }
  private updateFileControl(file: File) {
    this.MSSurveyForm.controls.inputFile.setValue(file);
    this.MSSurveyForm.markAsDirty();
    this.MSSurveyForm.controls.inputFile.updateValueAndValidity();
    this.MSSurveyForm.updateValueAndValidity();
  }

  public MSClearSelectedFile() {
    this.MSSurveyForm.controls.inputFile.setValue(null);
    this.MSSurveyForm.updateValueAndValidity();
  }

  public async MSOnClickUpload() {
    if (this.MSSurveyForm.controls.inputFile?.valid) {
      const inputFile = this.MSSurveyForm.value.inputFile;
      if (inputFile != null) {

        try {
          this.MSUploading = true;
          const apiResult: ApiBlobResult = await this._surveyController.Upload(inputFile);
          if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.toastSuccess("Survey summarized successfully.");
            this.MSOnClickGoBack();
          }
        } finally {
          this.MSUploading = false;
        }
      }
    } else {
      this._moonMessageService.showToastMessage(
        MessageSeverity.Warn,
        'File is required.'
      );
    }
  }

  public MSOnClickGoBack() {
    this._consumerRouter.RouteToSurvey();
  }
}
