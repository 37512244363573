// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
import { Injectable }               from "@angular/core";
// MS Imports
import { ApiResult }                from '@moon-core/models/api-result';
import { MoonApiClient }            from '@moon-core/services/moon-api.client';
import { ConstantString }           from "@moon-shared/constants/constant-string";
import { CustomerMessage }          from "@moon-maintainer/api/request/customer-message.request";
import { RoutePath }                from "@moon-shared/constants/route-path";

@Injectable()
export class TitleAdminController {

  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.TitleAdmin);
  }

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   POST: Maintainer/v1/TitleAdmin/{titleSummaryID}/ContactCustomer
   */
  public async ContactCustomer(titleSummaryID: number, customerMessage: CustomerMessage
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ContactCustomer);
    return this._moonApi.postWithResultAsync(url, customerMessage);
  }

}
