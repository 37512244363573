<form
    [formGroup]="MSTitleCommitmentForm"
    class="px-2 md:px-8 moon-title-commitment-wizard-form"
>
    <!-- File Input -->
    <moon-form-control
        class="col-12 moon-title-commitment-file-input"
        [MSFormControl]="MSTitleCommitmentForm.controls.inputFile"
        [MSErrorLabel]="'File'"
        [MSLabelSize]="12"
        [MSInputSize]="12"
        [MSErrorMessageSize]="12"
        [MSDisplayLabel]="false"
    >

        <p-fileUpload
            #fileUploader
            class="w-full"
            [accept]="'application/pdf'"
            [auto]="true"
            [customUpload]="true"
            [mode]="'advanced'"
            [showUploadButton]="false"
            [showCancelButton]="false"
            (onUpload)="MSHandleFileInput($event.files)"
            (onSelect)="MSHandleFileInput($event.files)"
            (onRemove)="MSClearSelectedFile()"
        >
            <ng-template pTemplate="content">
                <div *ngIf="fileUploader.files.length > 0">
                </div>
                <div class="w-full md:h-20rem xl:h-25rem py-3">
                    <div class="h-full flex flex-column justify-content-center align-items-center">
                        <div
                            class="flex flex-column justify-content-center align-items-center moon-cursor-copy"
                            onkeypress=""
                            (click)="fileUploader.advancedFileInput.nativeElement.click()"
                        >
                            <i class="pi pi-cloud-upload text-900 text-5xl mb-3 "></i>
                            <span class="text-xl mb-3"> Upload or Drag File Here</span>
                        </div>
                        <div class="text-center">
                            <h2 class="font-bold text-base">
                                By uploading a title commitment to this website, you agree to the
                                <a
                                    target="_blank"
                                    href="https://moonstonesoftware.ai/terms-and-conditions"
                                    class="underline text-color"
                                >Terms and Conditions</a>.
                            </h2>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="file">
                <p-table [value]="[MSTitleCommitmentForm.value.inputFile]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th
                                pSortableColumn="name"
                                class="white-space-nowrap"
                            >
                                Name <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th
                                pSortableColumn="size"
                                class="white-space-nowrap"
                            >
                                File Size <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="body"
                        let-file
                    >
                        <tr>
                            <td>
                                <div class="flex align-items-center">
                                    <i [class]="'text-xl text-primary mr-2 ' + file.icon"></i>
                                    <span>{{file.name}}</span>
                                </div>
                            </td>
                            <td>
                                <span>{{file.size/1024 | number:'1.0-2'}} kb</span>
                            </td>
                            <td class="text-center">
                                <button
                                    pButton
                                    pRipple
                                    class="p-button-danger p-button-text p-button-rounded mr-2"
                                    icon="pi pi-times"
                                    (click)="MSClearSelectedFile()"
                                > </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
        </p-fileUpload>
    </moon-form-control>
</form>