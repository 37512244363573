import { Component } from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { Router, RouterLink } from '@angular/router';
import { ServerError } from '@azure/msal-browser';
import { MsalWrapperService } from '@moon-core/services/msal-wrapper-service';

@Component({
    selector: 'app-error',
    templateUrl: './app.error.component.html',
    standalone: true,
    imports: [
        RouterLink,
        ButtonModule,
        RippleModule,
    ],
})
export class AppErrorComponent {
    public MSErrorHeading: string = "Error";
    public MSErrorDetail: string = "Requested resource is not available.";
    public ISMsalError: boolean = false;

    constructor(private _router: Router, private _msalWrapperService: MsalWrapperService) {
        this.loadMsalError();
    }
    private loadMsalError() {
        const currentRouterState = this._router.getCurrentNavigation()?.extras.state;
        if (currentRouterState) {
            const msalError: ServerError = currentRouterState["msalError"];
            if (msalError) {
                this.ISMsalError = true;
                this.MSErrorHeading = msalError.errorCode?.underscoreToSpace().toTitleCase() ?? 'Login Error';
                this.MSErrorDetail = msalError.errorMessage;
            }
        }
    }

    public MSGoBack() {
        if (this.ISMsalError) {
            this._msalWrapperService.login();
        }
        else {
            this._router.navigate(["/"]);
        }
    }


}
