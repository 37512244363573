// Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM
import { NgIf }                                 from '@angular/common';
import { Component, OnInit }                    from '@angular/core';
import { ActivatedRoute }                       from '@angular/router';
// Third Party Imports
import { 
    ConfirmationService, 
    MenuItem, PrimeIcons }                      from 'primeng/api';
import { ConfirmDialogModule }                  from 'primeng/confirmdialog';
// Moon Imports
import { AppBreadcrumbService }                 from '@app-layout/services/app.breadcrumb.service';
import { DocumentCategoryController }           from '@moon-collaborator/api/document-category.controller';
import { DocumentCategoryGet }                  from "@moon-collaborator/api/response/document-category-get.response";
import { CollaboratorRouter }                   from '@moon-collaborator/collaborator.router';
import { DocumentCategoryTableComponent }       from '@moon-collaborator/document/document-category/document-category-table/document-category-table.component';
import { ApiContentResult, ApiResult }          from '@moon-core/models/api-result';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';
import { MoonLoadingComponent }                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                       from '@moon-shared/constants/constant-string';
import { RouteParameter }                       from '@moon-shared/constants/route-parameter';
import { RoutePath }                            from '@moon-shared/constants/route-path';

@Component({
    selector: 'document-category',
    templateUrl: './document-category.component.html',
    styleUrls: ['./document-category.component.css'],
    standalone: true,
    imports: [
        NgIf,
        MoonLoadingComponent,
        DocumentCategoryTableComponent,
        ConfirmDialogModule
    ],
    providers: [
        ConfirmationService,
        DocumentCategoryController,
        CollaboratorRouter,
    ]
})
export class DocumentCategoryComponent implements OnInit {

    public MSDocumentCategoryList: DocumentCategoryGet[];
    public MSLoading: boolean = true;

    public MSPageTitle: string = ConstantString.DocumentCategories.getDisplayName();
    public MSEnvironmentName: string = String.empty;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _appBreadcrumbService: AppBreadcrumbService,
        private _componentUtilityService: ComponentUtilityService,
        private _documentCategoryController: DocumentCategoryController,
        private _collaboratorRouter: CollaboratorRouter,
        private _confirmationService: ConfirmationService
    ) { }

    ngOnInit() {
        this.readRouteParameters();
        this.setBreadcrumb();
        this.loadDocumentCategoryList();
    }

    private readRouteParameters() {
        this.MSEnvironmentName = this._activatedRoute.snapshot.paramMap.get(RouteParameter.EnvironmentName) ?? String.empty;
    }

    private setBreadcrumb() {
        const pageBreadcrumb: MenuItem[] = [
            { label: this.MSEnvironmentName, routerLink: [`${this._collaboratorRouter.EnvironmentRouteLink()}`] }, 
            { label: RoutePath.Documents, routerLink: this._collaboratorRouter.DocumentRouteLink(this.MSEnvironmentName) },
            { label: RoutePath.DocumentCategory.getDisplayName(), routerLink: null }
        ];

        this._appBreadcrumbService.setItems(pageBreadcrumb);
    }

    private async loadDocumentCategoryList() {
        this.MSLoading = true;

        const apiResult: ApiContentResult<DocumentCategoryGet[]> = await this._documentCategoryController.GetList(this.MSEnvironmentName);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
            this.onGetListSuccess(apiResult.content)
        }
        this.MSLoading = false;
    }

    private onGetListSuccess(documentCategoryList: DocumentCategoryGet[]) {
        if (documentCategoryList === null) {
            return;
        }

        this.MSDocumentCategoryList = documentCategoryList.map((documentCategory: DocumentCategoryGet) => {
            documentCategory.actions = this.getActions(this.MSEnvironmentName, documentCategory);
            return documentCategory;
        });
    }


    private getActions(environmentName: string, documentCategory: DocumentCategoryGet) {
        return [
            {
                icon: PrimeIcons.PENCIL,
                label: 'Edit Item',
                command: () => {
                    this._collaboratorRouter.ToDocumentCategoryEdit(environmentName, documentCategory.documentCategoryID);
                }
            },
            {
                icon: PrimeIcons.TRASH,
                label: 'Delete Item',
                command: () => {
                    this._confirmationService.confirm({
                        message: `Are you sure you want to remove ${documentCategory.categoryName}?`,
                        accept: () => {
                            this.doRemove(environmentName, documentCategory.documentCategoryID);
                        },
                    });
                
                }
            }
        ];
    }

    private async doRemove(environmentName: string, documentCategoryID: number) {
        const apiResult: ApiResult = await this._documentCategoryController.Delete(environmentName, documentCategoryID);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
            this._collaboratorRouter.ToDocumentCategory(environmentName);
            this.loadDocumentCategoryList();
        }
    }
}
