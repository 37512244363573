<!-- Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSTitleCommitmentList"
    [MSNumberOfFilteredTableRows]="MSTable?.filteredValue?.length || 0"
    [MSDisplayCreateButton]="MSDisplayCreate"
    [MSDisplayFindButton]="MSDisplayFind"
    [MSDisplaySecondaryButton]="MSDisplaySecondary"
    [MSSecondaryButtonIcon]="'pi pi-refresh'"
    [MSSecondaryButtonToolTip]="'Reload'"
    (MSEventOnClickCreate)="OnClickCreate()"
    (MSEventOnClickFind)="OnClickFind()"
    (MSEventOnClickSecondary)="MSReloadList()"
>
    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [scrollable]="true"
        [rowHover]="true"
        [value]="MSTitleCommitmentList"
        selectionMode="multiple"
        styleClass="p-datatable-gridlines p-datatable-striped"
    >
        <ng-template
            pTemplate="emptymessage"
            let-columns
        >
            <tr>
                <td
                    [style.flex-basis]="'auto'"
                    [attr.colspan]="columns?.length"
                    class="moon-text-center"
                >
                    No data found.
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="caption">
            <moon-table-header
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="false"
                [MSDisplayFindButton]="MSDisplayFind"
                [MSDisplaySecondaryButton]="MSDisplaySecondary"
                MSSecondaryButtonIcon="pi pi-refresh"
                MSSecondaryButtonToolTip="Refresh"
                (MSEventOnClickFind)="OnClickFind()"
                (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                (MSEventOnClickSecondary)="MSReloadList()"
            >
                <ng-template moonTemplate="button-left">
                    <button
                        pButton
                        pRipple
                        class="p-button-raised ml-2"
                        icon="pi pi-plus"
                        label="Add New Title Commitment"
                        (click)="OnClickCreate()"
                    > </button>
                </ng-template>
                <ng-template moonTemplate="filter">
                    <div class="flex align-items-center gap-2 mr-2">
                        <ng-container *ngIf="MSIsUserMaintainer()">
                            <div>
                                <label for="switch1">Advanced</label>&nbsp;
                                <p-inputSwitch
                                    inputId="switch1"
                                    [ngModel]="true"
                                    (onChange)="MSToggleAdvancedView()"
                                ></p-inputSwitch>
                            </div>
                            <div class="moon-vertical-divider"></div>
                        </ng-container>
                    </div>
                </ng-template>
            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [pSortableColumn]="col.sortable ? col.field: ''"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }"
                >
                    <div *ngIf="col.showColumnFilter; else normalColumnHeader">
                        <div class="flex gap-1 align-items-center">
                            {{ col.header }}
                            <p-columnFilter
                                [field]="col.field"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                                (click)="$event.stopPropagation()"
                                (keypress)="$event.stopPropagation()"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">{{ col.header }}</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="col.filterOptions"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </div>

                    <ng-template #normalColumnHeader>
                        <div class="flex gap-1 align-items-center">
                            {{ col.header }}
                            <p-sortIcon
                                *ngIf="col.sortable"
                                [field]="col.field"
                            ></p-sortIcon>
                            <p-columnFilter
                                class="hidden"
                                (click)="$event.stopPropagation()"
                                (keypress)="$event.stopPropagation()"
                            ></p-columnFilter>
                        </div>
                    </ng-template>
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <ng-container *ngFor="let col of columns">
                    <td
                        [title]="col.field === 'actions' ? '' :'Click to check detail'"
                        [class]="col.class"
                        [ngClass]="{'actions-column': col.field === 'actions', clickable: col.field !== 'actions'}"
                        (click)="col.field === 'actions' || OnClickItem(rowData)"
                        (keypress)="col.field === 'actions' || OnClickItem(rowData)"
                    >
                        <ng-container [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'matterName'">
                                <div class="flex justify-content-between align-items-start gap-1">
                                    <div class="w-9">{{rowData[col.field] }}</div>
                                    <div class="w-3 text-right">
                                        <button
                                            pButton
                                            pRipple
                                            class="p-button-raised p-button-rounded"
                                            pTooltip="Uploaded Title Summary PDF"
                                            icon="pi pi-file-pdf"
                                            (click)="MSOnClickDownloadUploadedPdf($event, rowData)"
                                        ></button>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'currentStep'">
                                <p-tag
                                    styleClass="bg-unset text-primary-600 text-base flex-row-reverse gap-2"
                                    [rounded]="true"
                                    [value]="rowData[col.field]"
                                />
                            </ng-container>
                            <ng-container *ngSwitchCase="'status'">
                                <p-tag
                                    styleClass="bg-unset text-primary-600 text-base flex-row-reverse gap-2"
                                    [rounded]="true"
                                    [value]="rowData[col.field]"
                                    [icon]="MSGetStatusIcon(rowData)"
                                />
                            </ng-container>
                            <ng-container *ngSwitchCase="'fileSize'">
                                <span>{{ rowData[col.field] / 1024 | number:'1.0-2'}} kb</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'lastUpdated'">
                                <span> {{ rowData.lastUpdatedBy }} <br />
                                    at {{ rowData.lastUpdatedAt | moondate}}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'createdAt'">
                                {{ rowData.createdBy }} {{rowData[col.field] | moondate}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'actions'">
                                <ng-container *ngIf="!MSIsTitleCommitmentProcessing(rowData)">
                                    <button
                                        *ngIf="MSIsStatusIntake(rowData)"
                                        pButton
                                        pRipple
                                        type="button"
                                        (click)="MSOnClickUploadTitleDocument(rowData)"
                                        icon="pi pi-upload"
                                        pTooltip="Errors have occurred while downloading documents."
                                        tooltipPosition="left"
                                        label="Upload Documents"
                                    > </button>
                                    <button
                                        pButton
                                        pRipple
                                        type="button"
                                        (click)="MSOnClickContactSupport(rowData)"
                                        icon="pi pi-phone"
                                        tooltipPosition="left"
                                        label="Contact Customer"
                                    > </button>
                                </ng-container>
                                <ng-container *ngFor="let action of rowData.actions">
                                    <button
                                        #btn
                                        type="button"
                                        pButton
                                        pRipple
                                        [label]="action.label"
                                        [icon]="action.icon"
                                        [disabled]="MSDownloading"
                                        [pTooltip]="action.tooltip"
                                        (click)="action.command()"
                                    > </button>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchDefault>{{ rowData[col.field] }}</ng-container>
                        </ng-container>
                    </td>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>

<moon-loading
    *ngIf="MSLoading"
    [MSLoadingMessage]="'Processing'"
></moon-loading>

<!-- Title Document Upload -->
<p-dialog
    [(visible)]="MSShowDocumentUpload"
    styleClass="w-90vw h-full moon-dialog-no-header"
    position="top"
    [draggable]="false"
    [modal]="true"
    [showHeader]="true"
    header="{{MSSelectedTitleCommitment?.matterName}} - List of Title Exception Documents"
>
    <moon-title-documents-upload
        *ngIf="MSShowDocumentUpload && MSSelectedTitleCommitment"
        [MSTitleSummaryID]="MSSelectedTitleCommitment.titleSummaryID"
        (MSAllDocumentDownloadedEvent)="MSOnAllDocumentDownloaded()"
    ></moon-title-documents-upload>
</p-dialog>

<!-- moon-maintainer\title-commitment-advanced\title-contact-customer\TitleContactSupportCustomer -->
<p-dialog
    [(visible)]="MSShowContactConsumer"
    styleClass="w-90vw h-full moon-dialog-no-header"
    position="top"
    [draggable]="false"
    [modal]="true"
    [showHeader]="true"
    header="Contact Customer"
>
    <moon-title-contact-customer
        *ngIf="MSShowContactConsumer && MSSelectedTitleCommitment"
        [(MSVisible)]="MSShowContactConsumer"
        [MSTitleSummaryID]="MSSelectedTitleCommitment.titleSummaryID"
    ></moon-title-contact-customer>
</p-dialog>