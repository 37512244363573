// Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM
import { Component, Input, OnInit }     from '@angular/core';
import {
    FormControl, FormGroup, FormsModule,
    ReactiveFormsModule,
    Validators
}                                       from '@angular/forms';
// Third Party Imports
import { SelectItem, SharedModule }     from 'primeng/api';
import { ButtonModule }                 from 'primeng/button';
import { DropdownModule }               from 'primeng/dropdown';
import { InputTextModule }              from 'primeng/inputtext';
import { RippleModule }                 from 'primeng/ripple';
// Moon Imports
import { DocumentCategoryController }   from '@moon-collaborator/api/document-category.controller';
import { DocumentCategory }             from '@moon-collaborator/api/request/document-category.request';
import { DocumentCategoryGet }          from "@moon-collaborator/api/response/document-category-get.response";
import { CollaboratorRouter }           from '@moon-collaborator/collaborator.router';
import { IApiResult }                   from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { MoonFormControlComponent }     from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                  from '@moon-shared/types/form-group-of.type';

@Component({
    selector: 'document-category-form',
    templateUrl: './document-category-form.component.html',
    styleUrls: ['./document-category-form.component.css'],
    standalone: true,
    imports: [ 
        FormsModule, ReactiveFormsModule, SharedModule,
        MoonFormPageComponent, MoonFormControlComponent,
        InputTextModule,
        ButtonModule, DropdownModule, 
        RippleModule,    
    ],
    providers: [DocumentCategoryController]
})
export class DocumentCategoryFormComponent implements OnInit {
    @Input() public MSEnvironmentName: string;
    @Input() public MSDocumentCategoryGet: DocumentCategoryGet;
    @Input() public MSDocumentCategoryID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<DocumentCategory>> = new FormGroup<FormGroupOf<DocumentCategory>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		categoryName: new FormControl('', {nonNullable: true, validators: Validators.required }),
		searchTableNumber: new FormControl(1, {nonNullable: true, validators: Validators.required }),
		sortKey: new FormControl(0, {nonNullable: true, validators: Validators.required }),
    });
    public MSSearchTableNumberSelectItem: SelectItem[] = [];
    public MSIsFormSubmitting: boolean;

    // Declare Options PlaceHolder

    constructor(
        private _documentCategoryController: DocumentCategoryController,
        private _componentUtilityService: ComponentUtilityService,
        private _collaboratorRouter: CollaboratorRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSDocumentCategoryGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        this.MSParentForm.markAllAsTouched();
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls() {
    }

    private doMapGetDataToFormControl(documentCategory: DocumentCategoryGet) {
        if (!documentCategory) {
            return;
        }        
        this.MSDocumentCategoryGet = documentCategory;
        this.MSParentForm.patchValue(documentCategory);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSDocumentCategoryID
            ? await this._documentCategoryController.Update(this.MSEnvironmentName, this.MSDocumentCategoryID, this.MSParentForm.getRawValue())
            : await this._documentCategoryController.Create(this.MSEnvironmentName, this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        this.MSSearchTableNumberSelectItem = [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
        ];
        // Set Options PlaceHolder
      }    

    private routeToBasePage(): void {
        this._collaboratorRouter.ToDocumentCategory(this.MSEnvironmentName);
    }
}
