<!-- Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSSkillList"
    [MSDisplayCreateButton]="MSDisplayCreate"
    [MSDisplayUploadButton]="MSDisplayUpload"
    [MSAcceptedFileExtension]="MSAcceptedFileExtension"
    (MSEventOnClickCreate)="OnClickCreate()"
    (MSEventOnUploadFile)="OnUploadFile($event)"
>

    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [rowHover]="true"
        [value]="MSSkillList"
        selectionMode="single"
        [loading]="MSLoading"
        styleClass="p-datatable-gridlines p-datatable-striped"
        [scrollable]="true"
        [loading]="MSUploading"
    >
        <ng-template pTemplate="caption">
            <moon-table-header
                #MSTableHeader
                [MSEnableSearch]="true"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayCreateButton]="MSDisplayCreate"
                [MSDisplaySecondaryButton]="MSDisplaySecondary"
                MSSecondaryButtonToolTip="Export skills as Json file"
                [MSSecondaryButtonIcon]="'pi pi-file-export'"
                [MSDisplayUploadButton]="MSDisplayUpload"
                [MSAcceptedFileExtension]="MSAcceptedFileExtension"
                (MSEventOnClickCreate)="OnClickCreate()"
                (MSEventOnUploadFile)="OnUploadFile($event)"
                (MSEventOnClickSecondary)="OnClickExport()"
                (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
            >
                <ng-template moonTemplate="button-right">
                    <button
                        *ngIf="MSSkillList.length > 0"
                        pButton
                        (click)="OnTestClick()"
                        type="button"
                        icon="pi pi-wrench"
                        class="p-button-rounded p-button-raised mb-2"
                        pTooltip="Test Skill"
                        tooltipPosition="top"
                    > </button>
                </ng-template>
            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }"
                    [pSortableColumn]="col.field"
                >
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'deploymentName'">
                            {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
                            <p-columnFilter
                                field="deploymentName"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">Deployment</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="MSDeploymentSelectItems"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>

                        <div *ngSwitchCase="'skillType'">
                            {{ col.header }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                            <p-columnFilter
                                field="skillType"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">Skill Type</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="MSSkillTypeSelectItems"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>

                        <div *ngSwitchCase="'skillSubType'">
                            Skill Sub-Type
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                            <p-columnFilter
                                field="skillSubType"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">Skill Sub-Type</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="MSSkillSubTypeSelectItems"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>

                        <div *ngSwitchCase="'skillStatus'">
                            {{ col.header }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                            <p-columnFilter
                                field="skillStatus"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">Skill Type</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="MSSkillStatusSelectItems"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                        <div *ngSwitchCase="'totalTokens'">
                            {{ col.header }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                            <label for="totalTokens">
                                <span
                                    [pTooltip]="'The column number is a summation of System Tokens, User Tokens and Assistant Tokens'"
                                    tooltipPosition="bottom"
                                >
                                    <i class="pi pi-info-circle"></i></span>
                            </label>
                        </div>

                        <span *ngSwitchDefault>
                            {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                        </span>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <td 
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                                    'actions-column': col.field === 'actions',
                                    clickable: col.field !== 'actions'
                                }"
                    (click)="col.field === 'actions' || OnClickItem(rowData)"
                >
                    <div [ngSwitch]="col.field">
                        <ng-container 
                            *ngSwitchCase="'actions'" 
                            class="flex"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                (click)="OnShowExamplesClick(rowData)"
                                label="Examples"
                                pTooltip="Show skill examples"
                                tooltipPosition="top"
                            > </button>
                            <button
                                pButton
                                pRipple
                                type="button"
                                (click)="OnShowTestDataClick(rowData)"
                                label="Test Data"
                                pTooltip="Show skill test data"
                                tooltipPosition="top"
                            > </button>
                            <button
                                pButton
                                pRipple
                                type="button"
                                (click)="OnShowChatLogClick(rowData)"
                                label="Chat Log"
                                pTooltip="Show skill chat log"
                                tooltipPosition="top"
                            > </button>
                        </ng-container>
                        <div *ngSwitchCase="'lastUpdated'">
                            <span> {{ rowData.lastUpdatedBy }} <br />
                                at {{ rowData.lastUpdatedAt | moondate}}
                            </span>
                        </div>
                        <div *ngSwitchCase="'deploymentName'">
                            {{ rowData[col.field] }} <br />
                            <small>
                                {{rowData.modelName }} v{{ rowData.modelVersion }} ({{ rowData.region }}) (Input: {{ rowData.modelInputTokens}} Output: {{ rowData.modelOutputTokens}}) <br />
                                <span>
                                    <b>Model Tokens: <br /></b>
                                    <b>Input:</b> {{ rowData.modelInputTokens }} <br />
                                    <b>Output:</b> {{rowData.modelOutputTokens}} <br />
                                    <b>Total:</b> {{rowData.modelTotalTokens}} 
                                </span>
                            </small>
                        </div>
                        <div *ngSwitchCase="'totalTokens'">
                            {{ rowData.systemTokens + rowData.skillExampleTokens}}
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>