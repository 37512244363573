// Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM
import {
    NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                           from "@angular/common";
import {
    Component, EventEmitter, Input, OnInit,
    Output,
    ViewEncapsulation
}                                           from "@angular/core";
// Third Party imports
import { ButtonModule }                     from "primeng/button";
import { InputTextModule }                  from "primeng/inputtext";
import { Table, TableModule }               from "primeng/table";
// Moon imports
import { AirplaneController }               from "@moon-consumer/api/airplane.controller";
import { AirplaneFind }                     from "@moon-consumer/api/response/airplane-find.response";
import { AirplaneGet }                      from "@moon-consumer/api/response/airplane-get.response";
import { ConsumerRouter }                   from "@moon-consumer/consumer.router";
import { ApiBlobResult }                    from "@moon-core/models/api-result";
import { ComponentUtilityService }          from "@moon-core/services/component-utility.service";
import { MoonCustomDropdownComponent }      from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonTableHeaderComponent }         from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }           from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                       from '@moon-shared/constants/field-names';
import { TableColumn }                      from '@moon-shared/types/table-column.type';

@Component({
    selector: "airplane-table",
    templateUrl: "./airplane-table.component.html",
    styleUrls: ['./airplane-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
              TableModule, ButtonModule, InputTextModule,
              MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [AirplaneController, ConsumerRouter]
})
export class AirplaneTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSAirplaneList: AirplaneFind[];
    @Input() public MSLoading = true;
    @Output() public MSReloadList: EventEmitter<null> = new EventEmitter();

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.FileName.toCamelCase(), header: FieldNames.FileName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.TermsPagesCsv.toCamelCase(), header: FieldNames.TermsPagesCsv.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ReadableProcessingStatus.toCamelCase(), header: FieldNames.ProcessingStatus.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.LogMessage.toCamelCase(), header: FieldNames.LogMessage.getDisplayName(), class: 'w-25per white-space-pre-wrap'},

        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-20per'}
    ];

    public MSEnableSearch: boolean;
    public MSDisplayCreate: boolean;

    constructor(
        private _consumerRouter: ConsumerRouter, 
        private _airplaneController: AirplaneController, 
        private _componentUtilityService: ComponentUtilityService,
    ) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
    }

    public OnClickItem(item: AirplaneGet): void {
        this._consumerRouter.RouteToAirplaneEdit(item.airplaneID);
    }

    public MSOnClickCreate(): void {
        this._consumerRouter.RouteToAirplaneCreate();
    }

    public MSOnClickRefresh(): void {
        this.MSReloadList.emit();
    }

    public OnSearchInput(AirplaneTable: Table, searchInput: string): void {
        AirplaneTable.filterGlobal(searchInput, "contains");
    }

    public async MSOnDownloadWordSummaryClick(airplane: AirplaneGet) {
      this.MSLoading = true;
      try {
        const apiResult: ApiBlobResult = await this._airplaneController.GetDocxBlob(airplane.airplaneID);
        this._componentUtilityService.WasDownloadSuccessful(apiResult);
      } finally {
        this.MSLoading = false;
      }
    }
}
