// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
import { Component, EventEmitter, Input, OnInit,
    Output,
    ViewEncapsulation }                 from "@angular/core";
import { DatePipe, NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { Table, TableModule }           from "primeng/table";
// Moon imports
import { TemplateGet }                  from "@moon-maintainer/api/response/template-get.response";
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonDatePipe }                 from "@moon-shared/pipes/moon-date.pipe";

@Component({
    selector: "template-table",
    templateUrl: "./template-table.component.html",
    styleUrls: ['./template-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle, DatePipe,
        TableModule, ButtonModule, InputTextModule,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonDatePipe
    ],
    providers: [MaintainerRouter]
})
export class TemplateTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSTemplateList: TemplateGet[];
    @Input() public MSLoading = true;
    @Output() MSRefreshEvent = new EventEmitter();

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.TemplateName.toCamelCase(), header: FieldNames.TemplateName.getDisplayName(), class: 'w-20per'},
		{field: FieldNames.TemplateFileName.toCamelCase(), header: FieldNames.TemplateFileName.getDisplayName(), class: 'w-20per'},
		{field: FieldNames.TemplateType.toCamelCase(), header: FieldNames.TemplateType.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.LogMessage.toCamelCase(), header: FieldNames.LogMessage.getDisplayName(), class: 'w-15per'},
		{field: FieldNames.LastUpdated.toCamelCase(), header: FieldNames.LastUpdated.getDisplayName(), class: 'w-20per'},
        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-10per'}
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;

    constructor(private _maintainerRouter: MaintainerRouter) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: TemplateGet): void {
        this._maintainerRouter.RouteToTemplateEdit(item.templateID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.RouteToTemplateCreate();
    }

    public OnClickFind(): void {
        this._maintainerRouter.RouteToTemplate();
    }

    public OnSearchInput(TemplateTable: Table, searchInput: string): void {
        TemplateTable.filterGlobal(searchInput, "contains");
    }
}
