import { HashLocationStrategy, LocationStrategy }                               from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi }         from '@angular/common/http';
import { 
    APP_INITIALIZER, ErrorHandler, 
    enableProdMode, importProvidersFrom, inject 
}                                                                               from '@angular/core';
import { FormsModule }                                                          from '@angular/forms';
import { BrowserModule, bootstrapApplication }                                  from '@angular/platform-browser';
import { provideAnimations }                                                    from '@angular/platform-browser/animations';
import {
    NavigationError, Router, provideRouter,
    withComponentInputBinding, withNavigationErrorHandler
}                                                                               from '@angular/router';
// Third party imports
import { AppBreadcrumbService }                                                 from '@app-layout/services/app.breadcrumb.service';
import { MenuService }                                                          from '@app-layout/services/app.menu.service';
import { AppMainComponent }                                                     from '@app-main/app.main.component';
import {
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService, MsalGuard,
    MsalInterceptor, MsalService
}                                                                               from '@azure/msal-angular';
import { ConfirmationService, MessageService }                                  from 'primeng/api';
// MS Imports
import { AppComponent }                                                         from '@app/app.component';
import { AppRouter }                                                            from '@app/app.router';
import { appRoutes }                                                            from '@app/app.routes';
import { MoonAppState }                                                         from '@app/moon-app-state';
import { appInitializerFn }                                                     from '@moon-core/factory/app-initializer-factory';
import { 
    msalGuardConfigFactory, msalInstanceFactory, 
    msalInterceptorConfigFactory 
}                                                                               from '@moon-core/factory/msal-auth-factory';
import { MoonUserSessionHeaderInterceptor }                                     from '@moon-core/interceptor/moon-user-session-header.interceptor';
import { MoonErrorHandler }                                                     from '@moon-core/moon-error-handler/moon-error-handler';
import { MoonApiInterceptor }                                                   from '@moon-core/interceptor/moon-api.interceptor';
import { DbConnectApiService }                                                  from '@moon-core/api/db-connect-api.service';
import { EnvironmentApiService }                                                from '@moon-core/api/environment-api.service';
import { ComponentUtilityService }                                              from '@moon-core/services/component-utility.service';
import { MoonAppConfigService }                                                 from '@moon-core/services/moon-app-config.service';
import { MoonApplicationInsight }                                               from '@moon-core/services/moon-application-insight.service';
import { UserOptionService }                                                    from '@moon-core/services/user-option.service';
import { UserSessionService }                                                   from '@moon-core/services/user-session.service';
import { MoonApiClient }                                                        from '@moon-core/services/moon-api.client';
import { MoonFetchAPIService }                                                  from '@moon-core/services/moon-fetch-api.service';
import { FileSaverService }                                                     from '@moon-core/services/moon-file-saver.service';
import { MsalWrapperService }                                                   from '@moon-core/services/msal-wrapper-service';
import { MoonMenuItemService }                                                  from '@app/moon-menu-item.service';
import { MoonMessageService }                                                   from '@moon-shared/services/moon-message.service';
import { FileService }                                                          from '@moon-shared/services/file.service';
import { environment }                                                          from 'src/environments/environment';
import { UserOptionController }                                                 from '@moon-maintainer/api/user-option.controller';
import { UserOptionMapController }                                              from '@moon-public/api/user-option-map.controller';
import { ConsumerRouter }                                                       from '@moon-consumer/consumer.router';
import { MaintainerRouter }                                                     from '@moon-maintainer/maintainer.router';

if (environment.production) {
    enableProdMode();
}

const moonAppState = new MoonAppState();

bootstrapApplication(AppComponent, {
    providers: [
        {
            provide: MoonAppState,
            useValue: moonAppState
        },
        MoonErrorHandler,
        MoonAppConfigService,
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: ErrorHandler,
            useClass: MoonErrorHandler,
        },
        provideRouter(appRoutes,
            withComponentInputBinding(),
            withNavigationErrorHandler((err: NavigationError) => inject(MoonErrorHandler).HandleRouteError(err)) // disabled initial navigation because it tries to resolve MsalGuard, before appInitializer
        ),
        MoonApplicationInsight,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [MoonAppConfigService, MoonApplicationInsight, Router],
        },

        // MSAL Injection Start
        {
            provide: MSAL_INSTANCE,
            useFactory: msalInstanceFactory,
            deps: [MoonAppConfigService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: msalGuardConfigFactory,
            deps: [MoonAppConfigService]
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: msalInterceptorConfigFactory,
            deps: [MoonAppConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MoonUserSessionHeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MoonApiInterceptor,
            multi: true,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        MsalService,
        MsalBroadcastService,
        MsalGuard,
        // MSAL Injection End

        // Angular Modules
        importProvidersFrom(BrowserModule, FormsModule,),
        provideAnimations(),

        // Primeng services required for bootstrap: Start
        ConfirmationService,
        MessageService,
        MenuService,
        // Primeng services required for bootstrap: End

        // Moon services required for Bootstrap: Start
        MoonApiClient,
        FileSaverService,
        DbConnectApiService,
        MoonMessageService,
        UserSessionService,
        UserOptionService,
        MoonFetchAPIService,
        AppBreadcrumbService,
        MoonMenuItemService,
        EnvironmentApiService,
        MsalWrapperService,
        ComponentUtilityService,
        UserOptionController,
        UserOptionMapController,

        AppRouter,
        ConsumerRouter,
        MaintainerRouter,
        // Moon services required for Bootstrap: End
        
        FileService,
        AppComponent,
        AppMainComponent,
    ]
})
    .catch(err => console.error(err));

