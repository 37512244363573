// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
import { Injectable }   from "@angular/core";
import { Router }       from "@angular/router";
// Moon Imports
import { RoutePath }    from "@moon-shared/constants/route-path";

@Injectable()
export class MaintainerRouter {
    constructor(private _router: Router) { }

    //Title Commitment
    public RouteToTitleCommitmentsAdvanced(...command: (string | number)[]) {
        this._router.navigate([RoutePath.TitleCommitmentsAdvanced, ...command]);
    }

    public RouteToTitleCommitmentsAdvancedDetail(titleSummaryID: number) {
        this.RouteToTitleCommitmentsAdvanced(titleSummaryID, RoutePath.Detail);
    }

    public ToChatLog() {
        this._router.navigate([RoutePath.ChatLog]);
    }

    public ToSkillChatLog(skillNumber: string) {
        this._router.navigate([RoutePath.ChatLog, skillNumber]);
    }
    
    public ToSearchLog() {
        this._router.navigate([RoutePath.SearchLog]);
    }
    
    // UserInfo Start
    public UserInfoRouteLink(): (string | number)[] {
        return [RoutePath.UserInfo];
    }

    public ToUserInfo(...command: (string | number)[]) {
        this._router.navigate([RoutePath.UserInfo, ...command]);
    }

    public ToUserInfoAdd(): void {
        this.ToUserInfo(RoutePath.Add);
    }

    public ToUserInfoEdit(userInfoID: number) {
        this.ToUserInfo(userInfoID, RoutePath.Edit);
    }
    // UserInfo End
    
    // NodeEnvironmentUserGroupMap Start
    public NodeEnvironmentUserGroupMapRouteLink(nodeEnvironmentID: number) {
        return [RoutePath.NodeEnvironment, nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap];
    }

    private RouteToNodeEnvironmentUserGroupMap(...command: (string | number)[]) {
        this._router.navigate([RoutePath.NodeEnvironment, ...command]);
    }

    public ToNodeEnvironmentUserGroupMap(nodeEnvironmentID: number) {
        this.RouteToNodeEnvironmentUserGroupMap(nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap);
    }

    public ToNodeEnvironmentUserGroupMapAdd(nodeEnvironmentID: number): void {
        this.RouteToNodeEnvironmentUserGroupMap(nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap, RoutePath.Add);
    }

    public ToNodeEnvironmentUserGroupMapEdit(nodeEnvironmentID: number,nodeEnvironmentUserGroupMapID: number) {
        this.RouteToNodeEnvironmentUserGroupMap(nodeEnvironmentID, RoutePath.NodeEnvironmentUserGroupMap, nodeEnvironmentUserGroupMapID, RoutePath.Edit);
    }
    // NodeEnvironmentUserGroupMap End
    
    // NodeEnvironment Start
    public NodeEnvironmentRouteLink() {
        return [RoutePath.NodeEnvironment];
    }

    public ToNodeEnvironment(...command: (string | number)[]) {
        this._router.navigate([RoutePath.NodeEnvironment, ...command]);
    }
    
    public ToNodeEnvironmentAdd(): void {
        this.ToNodeEnvironment(RoutePath.Add);
    }

    public ToNodeEnvironmentEdit(nodeEnvironmentID: number) {
        this.ToNodeEnvironment(nodeEnvironmentID, RoutePath.Edit);
    }
    // NodeEnvironment End

    // Skill Start
    public SkillRouteLink() {
        return [RoutePath.Skill];
    }

    public ToSkill(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Skill, ...command]);
    }
    
    public ToSkillAdd(): void {
        this.ToSkill(RoutePath.Add);
    }

    public ToSkillEdit(skillID: number) {
        this.ToSkill(skillID, RoutePath.Edit);
    }

    public ToSkillTest(skillName: string | null | undefined) {
        this._router.navigate([RoutePath.Skill, RoutePath.Test], { state: { skillName: skillName} });
    }
    // Skill End

    // SkillExample Start
    public SkillExampleRouteLink(skillID: number) {
        return [RoutePath.Skill, skillID, RoutePath.SkillExample];
    } 

    public ToSkillExample(skillID: number, navigatedFromSkillPage: boolean = false, ...command: (string | number)[]) {
        if (navigatedFromSkillPage) {
            this._router.navigate([RoutePath.Skill, skillID, RoutePath.SkillExample], {
                state: { navigatedFromEdit: true }
            })
            return;
        }
        this.ToSkill(skillID, RoutePath.SkillExample, ...command);
    }
    
    public ToSkillExampleAdd(skillID: number): void {
        this.ToSkillExample(skillID, false, RoutePath.Add);
    }

    public ToSkillExampleEdit(skillID: number, skillExampleID: number) {
        this.ToSkillExample(skillID, false, skillExampleID, RoutePath.Edit);
    }
    // SkillExample End

    // SkillTestData Start
    public SkillTestData(skillNumber: string) {
        return [RoutePath.Skill, skillNumber, RoutePath.SkillTestData];
    } 

    public ToSkillTestData(skillNumber: string, ...command: (string | number)[]) {
        this._router.navigate([RoutePath.Skill, skillNumber, RoutePath.SkillTestData, ...command]);
        return ;
    }
    
    public ToSkillTestDataAdd(skillNumber: string): void {
        this.ToSkillTestData(skillNumber, RoutePath.Add);
    }
    
    public ToSkillTestDataEdit(skillNumber: string, skillTestDataID: number) {
        this.ToSkillTestData(skillNumber, skillTestDataID, RoutePath.Edit);
    }
    // SkillTestData End

    // UserGroup Start

    public ToUserGroup(...command: (string | number)[]) {
        this._router.navigate([RoutePath.UserGroup, ...command]);
    }
   
    public ToUserGroupAdd(): void {
        this.ToUserGroup(RoutePath.Add);
    }

    public ToUserGroupEdit(userInfoID: number) {
        this.ToUserGroup(userInfoID, RoutePath.Edit);
    }

    // UserGroup End

    
    // Template Start
    public RouteToTemplate() {
        return [RoutePath.Template];
    }

    public ToTemplate(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Template, ...command]);
    }
    
    public RouteToTemplateCreate(): void {
        this.ToTemplate(RoutePath.Add);
    }

    public RouteToTemplateEdit(templateID: number) {
        this.ToTemplate(templateID, RoutePath.Edit);
    }
    // Template End
    
    // Deployment Start
    public RouteToDeployment() {
        return [RoutePath.Deployment];
    }

    public ToDeployment(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Deployment, ...command]);
    }
    
    public RouteToDeploymentCreate(): void {
        this.ToDeployment(RoutePath.Add);
    }

    public RouteToDeploymentEdit(DeploymentID: number) {
        this.ToDeployment(DeploymentID, RoutePath.Edit);
    }
    // Deployment End
    
    // EmailWhitelist Start
    public RouteToEmailWhitelist() {
        return [RoutePath.EmailWhitelist];
    }

    public ToEmailWhitelist(...command: (string | number)[]) {
        this._router.navigate([RoutePath.EmailWhitelist, ...command]);
    }
    
    public RouteToEmailWhitelistCreate(): void {
        this.ToEmailWhitelist(RoutePath.Add);
    }

    public RouteToEmailWhitelistEdit(EmailWhitelistID: number) {
        this.ToEmailWhitelist(EmailWhitelistID, RoutePath.Edit);
    }
    // EmailWhitelist End

    // MoonNotification Start
    public RouteToMoonNotification() {
        return [RoutePath.Notification];
    }

    public ToMoonNotification(...command: (string | number)[]) {
        this._router.navigate([RoutePath.Notification, ...command]);
    }
    
    public RouteToMoonNotificationCreate(): void {
        this.ToMoonNotification(RoutePath.Add);
    }

    public RouteToMoonNotificationEdit(moonNotificationID: number) {
        this.ToMoonNotification(moonNotificationID, RoutePath.Edit);
    }

    public RouteToMoonNotificationSubscription(moonNotificationName: string | null, moonNotificationID: number) {
        this._router.navigate([RoutePath.Notification, moonNotificationID, RoutePath.Subscription], {
            state: { moonNotificationName: moonNotificationName }
        })
    }

    public RouteToMoonNotificationDelivery(notificationType: string | null, moonNotificationID: number) {
        this._router.navigate([RoutePath.Notification, moonNotificationID, RoutePath.Delivery], {
            state: { notificationType: notificationType }
        })
    }
    // MoonNotification End

}
