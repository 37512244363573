<form
    [formGroup]="MSUserOptionMapForm"
    class="py-4"
>
    <h1 class="text-lg mb-2">User Options</h1>
    <!-- Form PlaceHolder - DO NOT REMOVE -->
    <moon-form-control
        [MSLabel]="'Clause Search Envrionment'"
        [MSFormControl]="MSUserOptionMapForm.controls.ClauseSearchEnvironment"
        [MSHint]="'Must be a valid JSON string'"
    >
        <input
            name="ClauseSearchEnvironment"
            type="text"
            formControlName="ClauseSearchEnvironment"
            pInputText
        />
    </moon-form-control>
    <moon-form-control
        [MSLabel]="'Clause Search Document Type'"
        [MSFormControl]="MSUserOptionMapForm.controls.ClauseSearchDocumentType"
        [MSHint]="'Must be a valid JSON string'"
    >
        <input
            name="ClauseSearchDocumentType"
            type="text"
            formControlName="ClauseSearchDocumentType"
            pInputText
        />
    </moon-form-control>

    <moon-form-control
        [MSLabel]="'Show Airplane Terms'"
        [MSFormControl]="MSUserOptionMapForm.controls.ShowAirplaneTerms"
    >
        <p-dropdown
            name="ShowAirplaneTerms"
            formControlName="ShowAirplaneTerms"
            [options]="['Y', 'N']"
        ></p-dropdown>
    </moon-form-control>

    <moon-form-control
        [MSLabel]="'Show Title Commitment'"
        [MSFormControl]="MSUserOptionMapForm.controls.ShowTitleCommitment"
    >
        <p-dropdown
            name="ShowTitleCommitment"
            formControlName="ShowTitleCommitment"
            [options]="['Y', 'N']"
        ></p-dropdown>
    </moon-form-control>

    <moon-form-control
        [MSLabel]="'Show Survey'"
        [MSFormControl]="MSUserOptionMapForm.controls.ShowSurvey"
    >
        <p-dropdown
            name="ShowSurvey"
            formControlName="ShowSurvey"
            [options]="['Y', 'N']"
        ></p-dropdown>
    </moon-form-control>

</form>