import {
  AsyncPipe, DatePipe, DecimalPipe, KeyValuePipe,
  NgClass, NgFor, NgIf, SlicePipe
}                                                       from '@angular/common';
import { Component, OnInit }                            from '@angular/core';
import { FormsModule }                                  from '@angular/forms';
import { ActivatedRoute }                               from '@angular/router';
// Third party imports
import { ConfirmationService, MenuItem, SharedModule }  from 'primeng/api';
import { BadgeModule }                                  from 'primeng/badge';
import { ButtonModule }                                 from 'primeng/button';
import { ConfirmDialogModule }                          from 'primeng/confirmdialog';
import { DialogModule }                                 from 'primeng/dialog';
import { DialogService }                                from 'primeng/dynamicdialog';
import { InputNumberModule }                            from 'primeng/inputnumber';
import { InputTextModule }                              from 'primeng/inputtext';
import { ProgressSpinnerModule }                        from 'primeng/progressspinner';
import { RippleModule }                                 from 'primeng/ripple';
import { SplitButtonModule }                            from 'primeng/splitbutton';
import { TabMenuModule }                                from 'primeng/tabmenu';
import { TieredMenuModule }                             from 'primeng/tieredmenu';
import { ToolbarModule }                                from 'primeng/toolbar';
// MS Imports
import { AppBreadcrumbService }                         from '@app-layout/services/app.breadcrumb.service';
import { ApiContentResult }                             from '@moon-core/models/api-result';
import { ComponentUtilityService }                      from '@moon-core/services/component-utility.service';
import { MaintainerRouter }                             from '@moon-maintainer/maintainer.router';
import { ChatLogComponent }                             from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { TitleCommitmentFull }                          from '@moon-public/api/response/title-commitment-full.response';
import { TitleDocumentGet }                             from '@moon-public/api/response/title-document.response';
import { TitleSummaryController }                       from '@moon-public/api/title-summary.controller';
import { PublicRouter }                                 from '@moon-public/public.router';
import { TitleDocumentCustomerComponent }               from '@moon-consumer/title-commitment/title-customer-page/title-document-customer/title-document-customer.component';
import { TitleExceptionCustomerComponent }              from '@moon-consumer/title-commitment/title-customer-page/title-exception-customer/title-exception-customer.component';
import { DropdownButtonComponent }                      from "@moon-shared/components/dropdown-button/dropdown-button.component";
import { MoonLoadingComponent }                         from '@moon-shared/components/moon-loading/moon-loading.component';
import { DefaultValues }                                from '@moon-shared/constants/default-values';
import { RouteParameter }                               from '@moon-shared/constants/route-parameter';
import { RoutePath }                                    from '@moon-shared/constants/route-path';
@Component({
  selector: 'moon-title-customer-page',
  templateUrl: './title-customer-page.component.html',
  styleUrls: ['./title-customer-page.component.scss'],
  standalone: true,
  imports: [
    NgClass, NgFor, NgIf, AsyncPipe, ButtonModule, DatePipe, DecimalPipe, FormsModule, KeyValuePipe, SlicePipe,
    BadgeModule, ConfirmDialogModule, DialogModule, InputNumberModule, InputTextModule, ProgressSpinnerModule,
    RippleModule, SharedModule, SplitButtonModule, TabMenuModule, TieredMenuModule, ToolbarModule,
    DropdownButtonComponent, ChatLogComponent, MoonLoadingComponent, TitleDocumentCustomerComponent, TitleExceptionCustomerComponent,
  ],
  providers: [TitleSummaryController, PublicRouter, MaintainerRouter, ConfirmationService, DialogService]
})
export class TitleCustomerPageComponent implements OnInit {
  public MSLoading: boolean = true;
  public MSShowOcrConfirmation: boolean = false;
  public MSShowChatLog: boolean = false;
  public MSShowTitleReviewLog: boolean = false;
  public MSChatLogID: number | null;
  public MSTitleSummaryID: number;
  public MSSelectedTitleDocument: TitleDocumentGet;
  public MSTitleCommitment: TitleCommitmentFull;
  public MSTestMode: boolean = false;
  public MSApplying: boolean = false;
  public MSDownloadButtons: MenuItem[] = [];
  public MSShowMarkAsComplete: boolean = false;
  public MSIsRerunInProgress: boolean = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _publicRouter: PublicRouter,
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
    private _maintainerRouter: MaintainerRouter
  ) {
  }

  async ngOnInit() {
    this.MSTitleSummaryID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleSummaryID) ?? DefaultValues.NoID);
    this.setBreadcrumb();
    this.MSLoadTitleCommitment();
  }

  public MSLoadTitleCommitment() {
    this.loadTitleCommitmentByID(this.MSTitleSummaryID);
  }
  
  private async loadTitleCommitmentByID(titleSummaryID: number) {
    this.MSLoading = true;
    try {
      const apiResult: ApiContentResult<TitleCommitmentFull> = await this._titleSummaryController.GetFullByID(titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleCommitment = apiResult.content;
        this.setHasErrorAndTryParseDocumentSummary();
        this.MSShowMarkAsComplete = String.isTrueValue(this.MSTitleCommitment.allowMarkAsComplete);
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  private setHasErrorAndTryParseDocumentSummary() {

    Object.safelyDefineProperty(this.MSTitleCommitment, "hasError", !String.isNullOrWhiteSpace(this.MSTitleCommitment.logMessage));

    this.MSTitleCommitment.titleExceptionGetList.forEach(exception => {

      Object.safelyDefineProperty(exception, "hasError", !String.isNullOrWhiteSpace(exception.logMessage));

      exception.titleDocumentGetList?.forEach(doc => {
        this.parseDocumentSummaryIfValid(doc);
        Object.safelyDefineProperty(doc, "hasError", !String.isNullOrWhiteSpace(doc.logMessage));
      });
    });
  }

  private parseDocumentSummaryIfValid(document: TitleDocumentGet) {

    const summaryText = document.summaryText;

    if (String.isNullOrWhiteSpace(summaryText))
      return;

    try {
      const parsedValue = JSON.parse(summaryText);
      Object.safelyDeleteProperty(parsedValue, "Type");
      Object.safelyDeleteProperty(parsedValue, "Action");
      Object.safelyDefineProperty(document, "summaryObject", parsedValue);
    }
    catch (_: unknown) { }
  }

  private setBreadcrumb(titleName?: string) {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.TitleCommitments.getDisplayName(), routerLink: [`${this._publicRouter.TitleCommitmentsRouteLink()}`] },
      { label: titleName ?? RoutePath.Detail, routerLink: null },
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }
  public async MSOnRefreshClick() {
    await this.loadTitleCommitmentByID(this.MSTitleSummaryID);
  }

  public MSOnClickGoBack() {
    this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
  }
    
  // END Header Menu
  
}
