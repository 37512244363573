import { AsyncPipe, NgIf }                from '@angular/common';
import { Component }                      from '@angular/core';
//Third party imports
import { ButtonModule }                   from 'primeng/button';
import { RippleModule }                   from 'primeng/ripple';
//Moon Imports
import { ConsumerRouter }                 from '@moon-consumer/consumer.router';
import { UserSessionService }             from '@moon-core/services/user-session.service';
import { MaintainerRouter }               from '@moon-maintainer/maintainer.router';

@Component({
  selector: 'moon-title-commitment-final',
  standalone: true,
  imports: [NgIf, AsyncPipe, ButtonModule, RippleModule],
  templateUrl: './title-commitment-final.component.html',
  styleUrl: './title-commitment-final.component.scss',
})
export class TitleCommitmentFinalComponent {

  constructor(
    private _consumerRouter: ConsumerRouter,
    private _maintainerRouter: MaintainerRouter,
    private _userSessionService: UserSessionService
  ) { }

  public MSGoToTitleCommitment(): void {
    if (this._userSessionService.IsMaintainer()) {
      this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
    }
    else
      this._consumerRouter.RouteToTitleCommitmentsSimple();
  }
}
