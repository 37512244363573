// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
import { Injectable }               from "@angular/core";
// MS Imports
import { ApiBlobResult, ApiContentResult,
         ApiResult }                from '@moon-core/models/api-result';
import { MoonApiClient }            from '@moon-core/services/moon-api.client';
import { ConstantString }           from "@moon-shared/constants/constant-string";
import { FindTemplateParameter }    from "@moon-maintainer/api/request/find-template.parameter";
import { TemplateFind }             from "@moon-maintainer/api/response/template-find.response";
import { TemplateGet }              from "@moon-maintainer/api/response/template-get.response";

@Injectable()
export class TemplateController {

  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.Template);
  }

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   POST: Maintainer/v1/Template/find
   */
  public async Find(findParameter: FindTemplateParameter
  ): Promise<ApiContentResult<TemplateFind[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }

  /**
   *   GET: Maintainer/v1/Template
   */
  public async GetList(
  ): Promise<ApiContentResult<TemplateGet[]>> {

    const url = this.getBaseUrl();
    return this._moonApi.getAsync<TemplateGet[]>(url);
  }

  /**
   *   GET: Maintainer/v1/Template/TemplateType/{templateType}
   */
  public async GetListByTemplateType(templateType: string
  ): Promise<ApiContentResult<TemplateGet[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'TemplateType', templateType)
    return this._moonApi.getAsync<TemplateGet[]>(url);
  }

  /**
   *   POST: Maintainer/v1/Template
   */
  public async Create(templateFormData: FormData
  ): Promise<ApiContentResult<number>> {

    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, templateFormData);
  }

  /**
   *   GET: Maintainer/v1/Template/{templateID}
   */
  public async GetByID(templateID: number
  ): Promise<ApiContentResult<TemplateGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), templateID);
    return this._moonApi.getAsync<TemplateGet>(url);
  }

  /**
   *   PUT: Maintainer/v1/Template/{templateID}
   */
  public async Update(templateID: number, templateFormData: FormData
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), templateID);
    return this._moonApi.putAsync(url, templateFormData);
  }

  /**
   *   DELETE: Maintainer/v1/Template/{templateID}
   */
  public async Delete(templateID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), templateID);
    return this._moonApi.deleteAsync(url);
  }

  /**
 * GET:  Maintainer/v1/Template/{templateID}
 */
  public async GetBlobAsync(templateID: number, blobID: number): Promise<ApiBlobResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), templateID, ConstantString.Blob, blobID);
    return this._moonApi.getBlobAsync(url);
  }
}
