import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Directive({
  selector: '[moonEnterKeyup]',
  standalone: true
})
export class MoonEnterKeyupDirective {
  @Output() MSOnEnterKeyUp = new EventEmitter<void>();
  private _isNavigationActive = false;
  private _enterPressed = false;

  constructor(private _router: Router) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._isNavigationActive = false;
      }
    });
  }

  @HostListener('document:keydown', ['$event']) handleKeyDownEvent() {
      this._enterPressed = true;
  }

  @HostListener('document:keyup', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (!this._isNavigationActive && this._enterPressed && event.key === 'Enter') {
      this._enterPressed = false;
      this.MSOnEnterKeyUp.emit();
    }
  }
}
