// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
import { Component, Input, OnInit }         from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
         ReactiveFormsModule, 
         Validators}                         from '@angular/forms';
// Third Party Imports
import { SelectItem, SharedModule }         from 'primeng/api';
import { InputTextareaModule }              from 'primeng/inputtextarea';
import { InputTextModule }                  from 'primeng/inputtext';
import { FieldsetModule }                   from 'primeng/fieldset';
import { DecimalPipe, NgIf }                from '@angular/common';
import { ButtonModule }                     from 'primeng/button';
import { DropdownModule }                   from 'primeng/dropdown';
import { TooltipModule }                    from 'primeng/tooltip';
// Moon Imports
import { ApiBlobResult, IApiResult }        from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';
import { MoonDragDropDirective }            from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonFileUpload }                   from '@moon-shared/models/moon-file-upload.model';
import { MoonMessageService }               from '@moon-shared/services/moon-message.service';

import { Template }                         from '@moon-maintainer/api/request/template.request';
import { TemplateGet }                      from "@moon-maintainer/api/response/template-get.response";
import { TemplateController }               from '@moon-maintainer/api/template.controller';
import { MaintainerRouter }                 from "@moon-maintainer/maintainer.router";

@Component({
    selector: 'template-form',
    templateUrl: './template-form.component.html',
    styleUrls: ['./template-form.component.css'],
    standalone: true,
    imports: [
        NgIf,
        FormsModule, ReactiveFormsModule, SharedModule,
        MoonFormPageComponent, MoonFormControlComponent,
        InputTextModule, InputTextareaModule,
        FieldsetModule, MoonDragDropDirective,
        DecimalPipe, ButtonModule, DropdownModule,
        TooltipModule
    ],
    providers: [TemplateController]
})
export class TemplateFormComponent implements OnInit {
    @Input() public MSTemplateGet: TemplateGet;
    @Input() public MSTemplateID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<Template>> = new FormGroup<FormGroupOf<Template>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
        templateType: new FormControl(null, { nonNullable: true, validators: Validators.required }),
        templateName: new FormControl(null, { nonNullable: true, validators: Validators.required }),
        inputFile: new FormControl(),
        // templateTypeSelectItem: new FormControl(null, { validators: Validators.required }),
    });

    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    public MSTemplateTypeSelectItemList: SelectItem[] = [
        { label: 'Select Template Type', value: null },
        { label: 'Summarize', value: "Summarize" },
        { label: 'Revise', value: "Revise" },
    ]

    // Declare Options PlaceHolder

    constructor(
        private _templateController: TemplateController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter,
        private _moonMessageService: MoonMessageService,
    ) {
    }

    ngOnInit(): void {
        this.doMapGetDataToFormControl(this.MSTemplateGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        this.MSParentForm.markAllAsTouched();

        if (this.MSIsFormSubmitting) {
            return;
        }
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers
    private doMapGetDataToFormControl(template: TemplateGet) {
        if (!template) {
            this.MSParentForm.controls.inputFile.addValidators([Validators.required]);
            this.MSParentForm.controls.inputFile.updateValueAndValidity();
            return;
        }
        this.MSTemplateGet = template;
        this.MSParentForm.patchValue(template);

        // this.setDefaultValue(template);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;

        const template: Template = this.MSParentForm.getRawValue();

        const formData = new FormData();
        formData.set('templateName', template.templateName ?? String.empty);
        formData.set('templateType', template.templateType ?? String.empty);

        if (template.inputFile)
            formData.set('inputFile', template.inputFile);

        const apiResult: IApiResult = this.MSTemplateID
            ? await this._templateController.Update(this.MSTemplateID, formData)
            : await this._templateController.Create(formData);
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        // Set Options PlaceHolder
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToTemplate();
    }

    // private setDefaultValue(template: TemplateGet) {
    //     const templateType: SelectItem | null = this.MSTemplateTypeSelectItemList.find(a => a.value == template.templateType) ?? null;
    //     this.MSParentForm.controls.templateTypeSelectItem.setValue(templateType);
    // }

    // public OnChangeTemplateType(selectItem: SelectItem) {
    //     if(!selectItem) return;
    //     this.MSParentForm.controls.templateTypeSelectItem.setValue(selectItem);
    //     this.MSParentForm.controls.templateType.setValue(selectItem.value);
    // }

    public async MSOnClickDownloadFile() {
        this._moonMessageService.toastInfo("Processing. Please wait.");
        const apiResult: ApiBlobResult = await this._templateController.GetBlobAsync(this.MSTemplateGet.templateID, this.MSTemplateGet.blobID ?? 0);
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.toastSuccess("Download completed.");
        }
    }

    public MSOnFileDrop(file: MoonFileUpload) {
        if (file && file.file.size > 0) {
            this.updateFileControl(file.file);
        }
    }
    private updateFileControl(file: File) {
        this.MSParentForm.controls.inputFile.setValue(file);
        this.MSParentForm.markAsDirty();
        this.MSParentForm.controls.inputFile.updateValueAndValidity();
        this.MSParentForm.updateValueAndValidity();
    }

    public MSHandleFileInput(files: FileList | null) {
        if (!files?.length) return;

        const uploadedFile = files.item(0);

        if (uploadedFile)
            this.updateFileControl(uploadedFile);

    }

    public MSClearSelectedFile() {
        this.MSParentForm.controls.inputFile.setValue(null);
        this.MSParentForm.updateValueAndValidity();
    }
}
