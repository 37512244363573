// Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { Deployment }                 from "@moon-maintainer/api/request/deployment.request";
import { FindDeploymentParameter }    from "@moon-maintainer/api/request/find-deployment.parameter";
import { DeploymentFind }             from "@moon-maintainer/api/response/deployment-find.response";
import { DeploymentGet }              from "@moon-maintainer/api/response/deployment-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";

@Injectable()
export class DeploymentController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.Deployment);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/Deployment/find
 */
  public async Find(_: FindDeploymentParameter
    ): Promise<ApiContentResult<DeploymentFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl());
    return this._moonApi.getAsync(url);
  }
  
/**
 *   GET: Maintainer/v1/Deployment
 */
  public async GetList(
    ): Promise<ApiContentResult<DeploymentGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<DeploymentGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/Deployment
 */
  public async Create(deployment: Deployment
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, deployment);
  }
  
/**
 *   GET: Maintainer/v1/Deployment/{documentID}
 */
  public async GetByID(deploymentID: number
    ): Promise<ApiContentResult<DeploymentGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), deploymentID);
    return this._moonApi.getAsync<DeploymentGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/Deployment/{documentID}
 */
  public async Update(deploymentID: number, deployment: Deployment
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), deploymentID);
    return this._moonApi.putAsync(url, deployment);
  }
  
/**
 *   DELETE: Maintainer/v1/Deployment/{documentID}
 */
  public async Delete(deploymentID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), deploymentID);
    return this._moonApi.deleteAsync(url);
  }
}
