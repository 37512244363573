// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import { Injectable }                 from "@angular/core";

// MS Imports
import { ApiContentResult, ApiResult }  from "@moon-core/models/api-result";
import { MoonApiClient }                from "@moon-core/services/moon-api.client";
import { ConstantString }               from "@moon-shared/constants/constant-string";
import { MoonJobGet }                   from "@moon-public/api/response/moon-job-get.response";
import { MoonJobDetailGet }             from "@moon-public/api/response/moon-job-detail-get.response";
import { TitleCommitmentUpload }        from "@moon-public/api/request/title-commitment.upload";
import { TitleBackendOption }           from "@moon-public/api/request/title-backend-option.request";

@Injectable()
export class TitleIntakeController {

  private getBaseUrl(): string {
    return this._moonApi.getBaseUrl(ConstantString.TitleIntake);
  }

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   GET: Public/v1/TitleIntake/{moonJobID}
   */
  public async GetMoonJobByID(moonJobID: number): Promise<ApiContentResult<MoonJobGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJobID);
    return this._moonApi.getAsync<MoonJobGet>(url);
  }

  /**
   *   GET: Public/v1/TitleIntake/{moonJobID}/Details
   */
  public async GetMoonJobDetailsByID(moonJobID: number): Promise<ApiContentResult<MoonJobDetailGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJobID);
    return this._moonApi.getAsync<MoonJobDetailGet>(url);
  }
  
  /**
   *   Step 0
   * 
   *   POST: Public/v1/TitleIntake
   */
  public async Start(description: string): Promise<ApiContentResult<number>> {
    const url = this.getBaseUrl();
    const formData = new FormData();
    formData.set('description', description);
    return this._moonApi.postWithResultAsync(url, formData);
  }

  /**
   *   Step 1
   * 
   *   POST: Public/v1/TitleIntake/{moonJobID}/CreateTitleSummary
   */
  public async CreateTitleSummary(moonJonID: number, titleSummary: TitleCommitmentUpload): Promise<ApiContentResult<number>> {
    const formData = new FormData();
    formData.set('exceptionPagesCsv', titleSummary.exceptionPagesCsv);
    formData.set('matterName', titleSummary.matterName);
    formData.set('summaryType', titleSummary.summaryType);
    formData.set('propertyState', titleSummary.propertyState);
    if (titleSummary.inputFile) {
      formData.set('inputFile', titleSummary.inputFile);
    }
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJonID, ConstantString.CreateTitleSummary);
    return this._moonApi.postWithResultAsync(url, formData);
  }

  /**
   *   Step 2
   * 
   *   POST: Public/v1/TitleIntake/{moonJobID}/CreateTitleDocuments
   */
  public async CreateTitleDocuments(moonJonID: number): Promise<ApiContentResult<number[]>> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJonID, ConstantString.CreateTitleDocuments);
    return this._moonApi.postWithResultAsync(url, {});
  }
  /**
   *   Step 2
   * 
   *   POST: Public/v1/TitleIntake/{moonJobID}/DownloadFirstDocument
   */
  public async DownloadFirstDocument(moonJonID: number): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJonID, ConstantString.DownloadFirstDocument);
    return this._moonApi.postAsync(url, {});
  }

  /**
   *   Step 3
   * 
   *   POST: Public/v1/TitleIntake/{moonJobID}/CreateExceptions
   */
  public async CreateExceptions(moonJonID: number): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJonID, ConstantString.CreateExceptions);
    return this._moonApi.postAsync(url, {});
  }

  /**
   *   POST: Public/v1/TitleIntake/{moonJobID}/ValidateStep/{stepName}
   */
  public async ValidateTitleSummary(moonJonID: number, stepName: string): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJonID, ConstantString.ValidateStep, stepName);
    return this._moonApi.postAsync(url, {});
  }

  // /**
  //  *   Step 4
  //  * 
  //  *   POST: Public/v1/TitleIntake/{moonJobID}/DownloadTitleDocuments
  //  */
  // public async DownloadTitleDocuments(moonJonID: number): Promise<ApiResult> {
  //   const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJonID, ConstantString.DownloadTitleDocuments);
  //   return this._moonApi.postAsync(url, {});
  // }

  /**
   *   Step 5
   * 
   *   POST: Public/v1/TitleIntake/{moonJobID}/Queue
   */
  public async Queue(moonJonID: number, option: TitleBackendOption): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonJonID, ConstantString.Queue);
    return this._moonApi.postAsync(url, option);
  }
}
