import { DecimalPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, SlicePipe }   from '@angular/common';
import { Component, OnInit }                           from '@angular/core';
import { FormsModule, ReactiveFormsModule }            from '@angular/forms';
// Third party imports
import { PdfViewerModule }                             from 'ng2-pdf-viewer';
import { ConfirmationService, MenuItem, PrimeIcons }   from 'primeng/api';
import { ButtonModule }                                from 'primeng/button';
import { DialogService, DynamicDialogConfig }                               from 'primeng/dynamicdialog';
import { InputSwitchModule }                           from 'primeng/inputswitch';
import { InputTextModule }                             from 'primeng/inputtext';
import { RadioButtonModule }                           from 'primeng/radiobutton';
import { RippleModule }                                from 'primeng/ripple';
import { StepsModule }                                 from 'primeng/steps';
// MS Imports
import { AppBreadcrumbService }                        from '@app-layout/services/app.breadcrumb.service';
import { ApiBlobResult, ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }                     from '@moon-core/services/component-utility.service';
import { FindTitleCommitmentParameter }                from '@moon-public/api/request/find-title-commitment.parameter';
import { TitleCommitmentFind }                         from '@moon-public/api/response/title-commitment-find.response';
import { TitleSummaryInternalNotes }                   from '@moon-maintainer/api/request/title-summary-internal-notes.request';
import { TitleSummaryController }                      from '@moon-public/api/title-summary.controller';
import { MoonFormControlComponent }                    from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }                        from '@moon-shared/components/moon-loading/moon-loading.component';
import { RoutePath }                                   from '@moon-shared/constants/route-path';
import { MoonDragDropDirective }                       from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonMessageService }                          from '@moon-shared/services/moon-message.service';
import {TitleCommitmentAdvancedTableComponent}         from '@moon-maintainer/title-commitment-advanced/title-commitment-advanced-table/title-commitment-advanced-table.component';
import { TitleCommitmentStatus }                       from '@moon-maintainer/title-commitment-advanced/models/processing-status';
import { TitleSummaryInternalNotesModalComponent }     from '@moon-maintainer/title-commitment-advanced/modals/title-summary-internal-notes-modal/title-summary-internal-notes-modal.component';


@Component({
  selector: 'moon-title-commitment-advanced',
  standalone: true,
  imports: [
    DecimalPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, SlicePipe, FormsModule, ReactiveFormsModule,
    PdfViewerModule,
    ButtonModule, InputSwitchModule, InputTextModule, RadioButtonModule, RippleModule, StepsModule,
    MoonDragDropDirective, MoonLoadingComponent, TitleCommitmentAdvancedTableComponent, MoonFormControlComponent,
  ],
  providers: [TitleSummaryController, DialogService],
  templateUrl: './title-commitment-advanced.component.html',
  styleUrl: './title-commitment-advanced.component.scss',
})
export class TitleCommitmentAdvancedComponent implements OnInit{
  public MSLoading: boolean;
  public MSTitleCommitmentList: TitleCommitmentFind[];

  // Wizard Component Start


  // Wizard Component Ends
  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _titleSummaryController: TitleSummaryController,
    private _moonMessageService: MoonMessageService,
    private _confirmationService: ConfirmationService,
    private _dialogService: DialogService,
  ) { }
  async ngOnInit() {
    this.setBreadcrumb();
    this.loadTitleCommitmentList();
  }


  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(RoutePath.TitleCommitments.getDisplayName());
  }

  private async loadTitleCommitmentList() {
    try {
      this.MSLoading = true;
      const findParameter = new FindTitleCommitmentParameter();
      const apiResult: ApiContentResult<TitleCommitmentFind[]> = await this._titleSummaryController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleCommitmentList = apiResult.content;
        this.MSTitleCommitmentList.map(titleCommitment => {
          titleCommitment.actions = this.getDownloadButtonActions(titleCommitment);
          titleCommitment.lastUpdatedAt = titleCommitment.lastUpdatedAtUtc.toLocalDate();
          titleCommitment.createdAt = titleCommitment.createdAtUtc.toLocalDate();
          titleCommitment.matterDisplayName = `${titleCommitment.matterName} (${titleCommitment.summaryType})`;
          return titleCommitment;
        });
      }
    } finally {
      this.MSLoading = false;
    }
  }


  private getDownloadButtonActions(titleCommitment: TitleCommitmentFind): MenuItem[] {
    const actionBtns: MenuItem[] = [];

    if(TitleCommitmentStatus.Processing.equalsIgnoreCase(titleCommitment.status))
      return [];

    if (String.isTrueValue(titleCommitment.allowDownloadWord)) {
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: RoutePath.Exceptions, command: () => this.OnDownloadWordSummaryClick(titleCommitment, RoutePath.Exceptions) });
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: RoutePath.Summaries, command: () => this.OnDownloadWordSummaryClick(titleCommitment, RoutePath.Summaries) });
    }
    
    if (String.isTrueValue(titleCommitment.allowExportPdf)) {
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: 'Exception PDFs', command: () => this.OnExportPdfClick(titleCommitment) });
    }
    if (String.isTrueValue(titleCommitment.allowExportOcr)) {
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: 'Exception OCR Text', command: () => this.OnExportOcrClick(titleCommitment) });
    }
    actionBtns.push({ icon: PrimeIcons.TRASH, label: String.empty, tooltip: 'Delete this item', command: () => this.onDeleteTitleDocument(titleCommitment) });
    actionBtns.push({ icon: PrimeIcons.BOOK, label: String.empty, tooltip: 'Internal Notes', command: () => this.onAUInternalNotes(titleCommitment) });
    return actionBtns;
  }

  
  private async onAUInternalNotes(titleCommitment: TitleCommitmentFind): Promise<void> {
    const caption = `Title Summary: ${titleCommitment.matterName}`;

    const dynamicDialogConfig: DynamicDialogConfig<TitleSummaryInternalNotes> = {
      styleClass: 'w-50per moon-modal-wrapper',
      header: caption,
      data: {
        titleSummaryID: titleCommitment.titleSummaryID,
        internalNotes: titleCommitment.internalNotes
      }
    };

    const dynamicDialogRef = this._dialogService.open(TitleSummaryInternalNotesModalComponent, dynamicDialogConfig);
    dynamicDialogRef.onClose.subscribe(
      async (internalNotes: TitleSummaryInternalNotes) => {
        this.doUpdateInternalNotes(internalNotes);
      });
  }

  private async doUpdateInternalNotes(internalNotes: TitleSummaryInternalNotes) {
    try {
      this.MSLoading = true;

      const apiResult: ApiResult = await this._titleSummaryController.UpdateInternalNotes(internalNotes);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess(`Internal Notes updated successful.`);
        this.loadTitleCommitmentList();
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  private async onDeleteTitleDocument(titleCommitment: TitleCommitmentFind): Promise<void> {
    this._confirmationService.confirm({
      message: `This action cannot be undone. Are you sure to delete the item '${titleCommitment.matterName}'?`,
      accept: async () => {
        await this.doDeleteTitleDocument(titleCommitment);
      },
    });
  }
  private async doDeleteTitleDocument(titleCommitment: TitleCommitmentFind) {
    try {
      this.MSLoading = true;

      const apiResult: ApiResult = await this._titleSummaryController.Delete(titleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess(`Title commitment: "${titleCommitment.matterName}" deleted successful.`);
        this.loadTitleCommitmentList();
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  public async OnDownloadWordSummaryClick(titleCommitment: TitleCommitmentFind, blobType: string): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.GetDocxBlob(titleCommitment.titleSummaryID, blobType);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download Word summary successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnDownloadTitleCommitmentClick(titleCommitment: TitleCommitmentFind) {
    try {
      this.MSLoading = true;

      const apiResult: ApiBlobResult = await this._titleSummaryController.GetPdfBlob(titleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Title commitment downloaded successful.");
      }
    }
    finally {
      this.MSLoading = false;
    }
  }
  public async OnExportPdfClick(titleCommitment: TitleCommitmentFind): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportPdf(titleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export pdf successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnExportOcrClick(titleCommitment: TitleCommitmentFind): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportOcr(titleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export ocr successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }
  public async MSOnEmitReload() {
    this.loadTitleCommitmentList();
  }

}
