import { NgFor }                            from '@angular/common';
import { Component, OnInit }                from '@angular/core';
import { FormGroup, ReactiveFormsModule }   from '@angular/forms';
// Third party imports
import { SelectItem }                       from 'primeng/api';
import { ButtonModule }                     from 'primeng/button';
import { RadioButtonModule }                from 'primeng/radiobutton';
import { RippleModule }                     from 'primeng/ripple';
// MS Imports
import { ConsumerRouter }                   from '@moon-consumer/consumer.router';
import { TitleCommitmentWizardService }     from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { TitleCommitmentUpload }            from '@moon-public/api/request/title-commitment.upload';
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { DefaultValues }                    from '@moon-shared/constants/default-values';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';

@Component({
  selector: 'moon-title-commitment-hyperlinks',
  standalone: true,
  imports: [
    NgFor, ReactiveFormsModule,
    ButtonModule, RadioButtonModule, RippleModule,
    MoonFormControlComponent
  ],
  providers:[ConsumerRouter],
  templateUrl: './title-commitment-hyperlinks.component.html',
  styleUrl: './title-commitment-hyperlinks.component.scss'
})
export class TitleCommitmentHyperlinksComponent implements OnInit {

  public MSTitleReviewSummaryTypes: SelectItem[] = [{label: 'Yes',value: DefaultValues.Y}, {label: 'No',value: DefaultValues.N},];
  public MSTitleCommitmentForm!: FormGroup<FormGroupOf<TitleCommitmentUpload>>;
  constructor(
    private _titleCommitmentWizardService: TitleCommitmentWizardService
  ) { }
  ngOnInit() {
    this.setTitleCommitmentFromWizardService();
  }
  private setTitleCommitmentFromWizardService() {
    this.MSTitleCommitmentForm = this._titleCommitmentWizardService.MSTitleCommitmentForm;
  }

  public MSOnClickNext(): void {
    this._titleCommitmentWizardService.GoToNextStep();
  }
}
