import { NgIf }                 from '@angular/common';
import { Component }            from '@angular/core';
//Third party imports
import { ButtonModule }         from 'primeng/button';
import { RippleModule }         from 'primeng/ripple';
//Moon Imports
import { ConsumerRouter }       from '@moon-consumer/consumer.router';

@Component({
  selector: 'moon-airplane-process',
  standalone: true,
  imports: [NgIf, ButtonModule, RippleModule],
  templateUrl: './airplane-process.component.html',
  styleUrl: './airplane-process.component.scss',
  providers: [ConsumerRouter]
})
export class AirplaneProcessComponent {
  constructor(
    private _consumerRouter: ConsumerRouter
  ) { }

  public MSGoToAirplane(): void {
    this._consumerRouter.RouteToAirplane();
  }
}
