// Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM
import {
    DatePipe, NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                         from "@angular/common";
import {
    Component, Input, OnInit,
    ViewChild, ViewEncapsulation
}                                         from "@angular/core";
import { ActivatedRoute }                 from "@angular/router";
// Third Party imports
import { ButtonModule }                   from "primeng/button";
import { InputTextModule }                from "primeng/inputtext";
import { Table, TableModule }             from "primeng/table";
// Moon imports
import { NodeEnvironmentUserGroupMapGet } from "@moon-maintainer/api/response/node-environment-user-group-map-get.response";
import { MaintainerRouter }               from '@moon-maintainer/maintainer.router';
import { MoonCustomDropdownComponent }    from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonTableHeaderComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }         from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                     from '@moon-shared/constants/field-names';
import { RouteParameter }                 from "@moon-shared/constants/route-parameter";
import { MoonDatePipe }                   from "@moon-shared/pipes/moon-date.pipe";
import { TableColumn }                    from '@moon-shared/types/table-column.type';

@Component({
    selector: "node-environment-user-group-map-table",
    templateUrl: "./node-environment-user-group-map-table.component.html",
    styleUrls: ['./node-environment-user-group-map-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        TableModule, ButtonModule, InputTextModule, DatePipe,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonDatePipe
    ],
    providers: [MaintainerRouter]
})
export class NodeEnvironmentUserGroupMapTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSNodeEnvironmentUserGroupMapList: NodeEnvironmentUserGroupMapGet[];
    @Input() public MSLoading = true;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.UserGroupName.toCamelCase(), header: FieldNames.UserGroupName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.CreationDate.toCamelCase(), header: FieldNames.CreationDate.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.SortKey.toCamelCase(), header: FieldNames.SortKey.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.Status.toCamelCase(), header: FieldNames.Status.getDisplayName(), class: 'w-25per'},

        // Column PlaceHolder - DO NOT REMOVE
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    private _nodeEnvironmentID: number;

    @ViewChild("MSTable") MSTable: Table;

    constructor(private _maintainerRouter: MaintainerRouter,
        private _activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
        this.readRouteParameters();
    }

    public OnClickItem(item: NodeEnvironmentUserGroupMapGet): void {
        this._maintainerRouter.ToNodeEnvironmentUserGroupMapEdit(item.nodeEnvironmentID,item.nodeEnvironmentUserGroupMapID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.ToNodeEnvironmentUserGroupMapAdd(this._nodeEnvironmentID);
    }
    
    public MSOnClickRouteBack() {
        this._maintainerRouter.ToNodeEnvironment();
    }

    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }
    
    private readRouteParameters() {
        const nodeEnvironmentID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.NodeEnvironmentID);
        this._nodeEnvironmentID = +nodeEnvironmentID!
    }
}
