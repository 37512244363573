<!-- Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM -->
<moon-form-page
    [MSParentForm]="MSParentForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    (MSOnClickEventSubmit)="OnClickSubmit()"
    (MSOnClickEventCancel)="OnClickCancel()"
>
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'Notification Type'"
            [MSFormControl]="MSParentForm.controls.notificationType"
        >
            <input
                type="text"
                formControlName="notificationType"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Notification Description'"
            [MSFormControl]="MSParentForm.controls.notificationDescription"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="notes.value"
            >
                <textarea
                    #notes
                    id="notes"
                    pInputTextarea
                    [formControl]="MSParentForm.controls.notificationDescription"
                ></textarea>
            </div>
        </moon-form-control>

    </div>
</moon-form-page>