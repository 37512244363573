<div
    class="flex flex-column sm:flex-row sm:justify-content-between align-items-start sm:align-items-end table-header moon-table-header">
    <div class="grid mb-2 md:mb-0">
        <button
            *ngIf="MSDisplayRouteBackButton"
            pButton
            pRipple
            type="button"
            icon="pi pi-arrow-left"
            (click)="OnClickRouteBack()"
            class="p-button-rounded p-button-text m-0"
            pTooltip="Go Back"
            tooltipPosition="bottom"
        ></button>
        <ng-container *ngTemplateOutlet="MSLeftButtonTemplate"></ng-container>
        <h1 class="text-xl m-2 align-self-center">{{ MSPageTitle }}</h1>
    </div>
    <div class="moon-button flex gap-2">
        <ng-container *ngTemplateOutlet="MSFilterTemplate"></ng-container>

        <span
            *ngIf="MSEnableSearch"
            class="p-input-icon-left moon-search"
        >
            <i class="pi pi-search"></i>
            <input
                #textInput
                pInputText
                type="text"
                (input)="OnSearchInput(textInput.value)"
                placeholder="Search"
            />
        </span>
        <button
            pButton
            *ngIf="MSDisplayCreateButton"
            (click)="OnClickCreate()"
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-raised m-0"
            pTooltip="Create New {{ MSPageTitle }}"
            tooltipPosition="left"
        ></button>

        <button
            pButton
            *ngIf="MSDisplayFindButton"
            (click)="OnClickFind()"
            type="button"
            icon="pi pi-search"
            class="p-button-rounded p-button-raised m-0"
            pTooltip="Find {{ MSPageTitle }}"
            tooltipPosition="left"
        ></button>

        <button
            pButton
            *ngIf="MSDisplaySecondaryButton"
            (click)="OnClickSecondary()"
            type="button"
            icon="{{ MSSecondaryButtonIcon }}"
            class="p-button-rounded p-button-raised m-0"
            pTooltip="{{ MSSecondaryButtonToolTip }}"
            tooltipPosition="left"
        ></button>

        <p-fileUpload
            *ngIf="MSDisplayUploadButton"
            class="m-0 inline-block"
            styleClass="border-circle p-button-raised"
            #MSFileUpload
            mode="basic"
            [accept]="MSAcceptedFileExtension"
            [maxFileSize]="MSMaxFileSize"
            [auto]="true"
            (uploadHandler)="OnUploadFile($event)"
            [customUpload]="true"
            chooseIcon="pi pi-upload"
            pTooltip="Import {{MSPageTitle}}"
            tooltipPosition="left"
        ></p-fileUpload>
        <ng-container *ngTemplateOutlet="MSRightButtonTemplate"></ng-container>
    </div>
</div>