// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
// Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM
// Moon Auto Generated (1.6.0) at 7/4/2024 5:44:58 AM
// Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM
// Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM
// Moon Auto Generated (1.5.0) at 2/21/2024 10:18:48 AM
export class RoutePath {

    public static readonly Add = 'Add' as const;
    public static readonly MatterName = 'MatterName' as const;
    public static readonly Endorsement = 'Endorsement' as const;
    public static readonly Airplane: string = "Airplane";
    public static readonly All = 'All' as const;
    public static readonly BatchDownload = 'BatchDownload' as const;
    public static readonly BatchOcr = 'BatchOcr' as const;
    public static readonly BatchPrompt = 'BatchPrompt' as const;
    public static readonly BlacklineUtility = 'BlacklineUtility' as const;
    public static readonly Blob = 'Blob' as const;
    public static readonly ChatLog = 'ChatLog' as const;
    public static readonly ChatOverride = 'ChatOverride' as const;
    public static readonly ChatSetting = 'ChatSetting' as const;
    public static readonly Chunk = 'Chunk' as const;
    public static readonly ClauseSearch = 'ClauseSearch' as const;
    public static readonly ClearLogMessage = 'ClearLogMessage' as const;
    public static readonly Complete = 'Complete' as const;
    public static readonly ConnectDatabase = 'ConnectDatabase' as const;
    public static readonly ConnectUser = 'ConnectUser' as const;
    public static readonly Contact = 'Contact' as const;
    public static readonly ContactCustomer = 'ContactCustomer' as const;
    public static readonly ContactSupport = 'ContactSupport' as const;
    public static readonly ConvertedImage = 'ConvertedImage' as const;
    public static readonly Delivery = 'Delivery' as const;
    public static readonly Deployment: string = "Deployment";
    public static readonly Detail = 'Detail' as const;
    public static readonly Diagnostic = 'Diagnostic' as const;
    public static readonly DisclaimerAgreement = 'DisclaimerAgreement' as const;
    public static readonly DocumentCategory = 'DocumentCategory' as const;
    public static readonly DocumentClassify = 'DocumentClassify' as const;
    public static readonly Documents = 'Documents' as const;
    public static readonly DocumentSummarize = 'DocumentSummarize' as const;
    public static readonly DocxBlob = 'DocxBlob' as const;
    public static readonly Download = 'Download' as const;
    public static readonly Edit = 'Edit' as const;
    public static readonly EmailWhitelist = "EmailWhitelist" as const;
    public static readonly Empty = '' as const;
    public static readonly Environment = 'Environment' as const;
    public static readonly Error = 'Error' as const;
    public static readonly ExceptionNumber = 'ExceptionNumber' as const;
    public static readonly ExceptionPagesPdfBlob = 'ExceptionPagesPdfBlob' as const;
    public static readonly ExceptionRecreate = 'ExceptionRecreate' as const;
    public static readonly Exceptions = 'Exceptions' as const;
    public static readonly ExportOcr = 'ExportOcr' as const;
    public static readonly ExportPdf = 'ExportPdf' as const;
    public static readonly External = 'External' as const;
    public static readonly Final = 'Final' as const;
    public static readonly Find = 'Find' as const;
    public static readonly Full = 'Full' as const;
    public static readonly FullTextSearch = 'FullTextSearch' as const;
    public static readonly History = "History" as const;
    public static readonly Hyperlinks = 'Hyperlinks' as const;
    public static readonly Login = 'Login' as const;
    public static readonly MarkScheduleOfExceptions = 'MarkScheduleOfExceptions' as const;
    public static readonly MarkTermPage = 'MarkTermPage' as const;
    public static readonly MezzLoan = "MezzLoan" as const;
    public static readonly MoonNotification = "MoonNotification" as const;
    public static readonly NodeEnvironment = 'NodeEnvironment' as const;
    public static readonly NodeEnvironmentUserGroupMap = 'NodeEnvironmentUserGroupMap' as const;
    public static readonly Notification = 'Notification' as const;
    public static readonly Number = 'Number' as const;
    public static readonly Ocr = 'Ocr' as const;
    public static readonly OcrRemaining = 'OcrRemaining' as const;
    public static readonly OcrSkillData = 'OcrSkillData' as const;
    public static readonly OverrideExceptionNumber = 'OverrideExceptionNumber' as const;
    public static readonly Override = 'Override' as const;
    public static readonly OverrideExceptionType = 'OverrideExceptionType' as const;
    public static readonly OverrideOcr = "OverrideOcr" as const;
    public static readonly OverrideRecommendedAction = 'OverrideRecommendedAction' as const;
    public static readonly OverrideSummaryText = 'OverrideSummaryText' as const;
    public static readonly Page = 'Page' as const;
    public static readonly PdfBlob = 'PdfBlob' as const;
    public static readonly PdfReview = 'PdfReview' as const;    
    public static readonly Pdfs = 'Pdfs' as const;    
    public static readonly PostLogout = 'PostLogout' as const;
    public static readonly Process = 'Process' as const;
    public static readonly PromptExceptionNumber = 'PromptExceptionNumber' as const;
    public static readonly PromptExceptionTypeAndAction = 'PromptExceptionTypeAndAction' as const;
    public static readonly PromptSummary = 'PromptSummary' as const;
    public static readonly PromptTitleDocument = 'PromptTitleDocument' as const;
    public static readonly PromptTitleDocumentOcrText = 'PromptTitleDocumentOcrText' as const;
    public static readonly PromptTitleException = 'PromptTitleException' as const;
    public static readonly PromptTitleSummary = 'PromptTitleSummary' as const;
    public static readonly PromptTitleSummaryReviseOcrText = 'PromptTitleSummaryReviseOcrText' as const;
    public static readonly PromptTypeAndAction = 'PromptTypeAndAction' as const;
    public static readonly Remaining = 'Remaining' as const;
    public static readonly Revise = 'Revise' as const;
    public static readonly Revision = 'Revision' as const;
    public static readonly SaveTestData = 'SaveTestData' as const;
    public static readonly SaveTestResult = 'SaveTestResult' as const;
    public static readonly SearchLog = 'SearchLog' as const;
    public static readonly CommitmentType = 'CommitmentType' as const;
    public static readonly Skill = 'Skill' as const;
    public static readonly SkillExample = 'SkillExample' as const;
    public static readonly SkillTestData = "SkillTestData" as const;
    public static readonly Subscription = 'Subscription' as const;
    public static readonly Summaries = 'Summaries' as const;
    public static readonly Summarize = 'Summarize' as const;
    public static readonly Survey = 'Survey' as const;
    public static readonly Template = "Template" as const;
    public static readonly Test = 'Test' as const;
    public static readonly TitleCommitments = 'TitleCommitments' as const;
    public static readonly TitleCommitmentsAdvanced = 'TitleCommitments/Advanced' as const;
    public static readonly TitleCommitmentsSimple = 'TitleCommitments/Simple' as const;
    public static readonly TitleCustomerPage = 'TitleCustomerPage' as const;
    public static readonly TitleDocument = 'TitleDocument' as const;
    public static readonly TitleDocumentMapList = 'TitleDocumentMapList' as const;
    public static readonly TitleException = 'TitleException' as const;
    public static readonly TitleExceptionMapList = 'TitleExceptionMapList' as const;
    public static readonly TitleSummary = 'TitleSummary' as const;
    public static readonly TitleSummaryLog = 'TitleSummaryLog' as const;
    public static readonly UpdateAll = 'UpdateAll' as const;
    public static readonly UpdateExceptionChatLog = 'UpdateExceptionChatLog' as const;
    public static readonly Upload = 'Upload' as const;
    public static readonly Uploaded = 'Uploaded' as const;
    public static readonly UploadFile = 'UploadFile' as const;
    public static readonly UploadTitleDocument = 'UploadTitleDocument' as const;
    public static readonly UserGroup = "UserGroup" as const;
    public static readonly UserGroupMap = "UserGroupMap" as const;
    public static readonly UserInfo = 'UserInfo' as const;
    public static readonly UserSessionLog = "UserSessionLog" as const;    
    public static readonly WordSummary = "WordSummary" as const;
}
