// Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM
import { NgIf }                     from '@angular/common';
import { Component, OnInit }        from '@angular/core';
// Moon Imports
import { AppBreadcrumbService }     from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { DeploymentController }           from '@moon-maintainer/api/deployment.controller';
import { FindDeploymentParameter }        from "@moon-maintainer/api/request/find-deployment.parameter";
import { DeploymentFind }                 from "@moon-maintainer/api/response/deployment-find.response";
import { DeploymentTableComponent }       from '@moon-maintainer/deployment/deployment-table/deployment-table.component';
import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }           from '@moon-shared/constants/constant-string';

@Component({
  selector: 'moon-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, DeploymentTableComponent],
  providers: [DeploymentController]
})
export class DeploymentComponent implements OnInit {

  public MSDeploymentList: DeploymentFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.Deployment;
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _deploymentController: DeploymentController
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadDeploymentList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  private async loadDeploymentList() {    
    try {
      this.MSLoading = true;
      const findParameter = new FindDeploymentParameter();
      const apiResult: ApiContentResult<DeploymentFind[]> = await this._deploymentController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSDeploymentList = apiResult.content;
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
