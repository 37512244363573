<div class="grid">
    <div class="col-8">

        <button
            pButton
            pRipple
            type="button"
            icon="pi pi-arrow-left"
            (click)="MSOnClickRouteBack()"
            class="p-button-rounded p-button-text mr-2 mb-2 absolute z-1"
            title="Go Back"
        ></button>

        <moon-pdf-display
            [MSTitleSummaryID]="MSTitleSummaryID"
            [MSTitleDocumentID]="MSTitleDocumentID"
        >
        </moon-pdf-display>
    </div>
    <div class="col-4">
        <moon-document-result
            [MSTitleDocumentID]="MSTitleDocumentID"
            [MSTitleSummaryID]="MSTitleSummaryID"
        >
        </moon-document-result>
    </div>
</div>