import { NgIf, NgTemplateOutlet }                                     from "@angular/common";
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, AfterContentInit }   from "@angular/core";
// Third party imports
import { ButtonModule }                             from "primeng/button";
import { FileUpload, FileUploadHandlerEvent, FileUploadModule } from "primeng/fileupload";
import { InputTextModule }                          from "primeng/inputtext";
import { TooltipModule }                            from "primeng/tooltip";
// Moon Imports
import { MoonTemplateDirective } from "@moon-shared/directives/moon-template.directive";
import { DefaultValues } from "@moon-shared/constants/default-values";
@Component({
    selector: "moon-table-header",
    templateUrl: "./moon-table-header.component.html",
    styleUrls: ["./moon-table-header.component.scss"],
    standalone: true,
    imports: [
        NgIf, NgTemplateOutlet,
        InputTextModule,
        ButtonModule,
        TooltipModule,
        FileUploadModule
    ],
})
export class MoonTableHeaderComponent implements AfterContentInit {
    public readonly MSMaxFileSize: number = DefaultValues.MaxFileSize;
    @Input() MSPageTitle: string;
    @Input() MSEnableSearch: boolean = false;
    @Input() MSDisplayRouteBackButton: boolean = false;
    @Input() MSDisplayCreateButton: boolean = true;
    @Input() MSDisplayFindButton: boolean = false;
    @Input() MSDisplayUploadButton: boolean = false;
    @Input() MSAcceptedFileExtension: string = String.empty;

    @Input() public MSDisplaySecondaryButton: boolean = false;
    @Input() public MSSecondaryButtonIcon: string;
    @Input() public MSSecondaryButtonToolTip: string;
    
    @Output() public MSEventOnClickRouteBack = new EventEmitter();
    @Output() public MSEventOnClickCreate = new EventEmitter();
    @Output() public MSEventOnClickFind = new EventEmitter();
    @Output() public MSEventOnSearchInput = new EventEmitter();
    @Output() public MSEventOnClickSecondary = new EventEmitter();
    @Output() public MSEventOnUploadFile: EventEmitter<FileUploadHandlerEvent> = new EventEmitter();
    @ViewChild('MSFileUpload') MSFileUpload: FileUpload;
    @ContentChildren(MoonTemplateDirective, { descendants: true }) MSTemplateDirectives!: QueryList<MoonTemplateDirective>;
    MSFilterTemplate: TemplateRef<any> | null;
    MSRightButtonTemplate: TemplateRef<any> | null;
    MSLeftButtonTemplate: TemplateRef<any> | null;
    ngAfterContentInit() {
        this.MSTemplateDirectives?.forEach((directive: MoonTemplateDirective) => {
          if (!this.MSRightButtonTemplate) {
            this.MSRightButtonTemplate = directive.MSGetRightButtonTemplate();
          }
          if (!this.MSFilterTemplate) {
            this.MSFilterTemplate = directive.MSGetFilterTemplate();
          }
          if (!this.MSLeftButtonTemplate) {
            this.MSLeftButtonTemplate = directive.MSGetLeftButtonTemplate();
          }
        });
    }
    public OnClickRouteBack() {
        this.MSEventOnClickRouteBack.emit();
    }

    public OnClickCreate() {
        this.MSEventOnClickCreate.emit();
    }

    public OnClickFind() {
        this.MSEventOnClickFind.emit();
    }

    public OnSearchInput(searchInput: string) {
        this.MSEventOnSearchInput.emit(searchInput);
    }

    public OnClickSecondary() {
        this.MSEventOnClickSecondary.emit();
    }
    public OnUploadFile(event: FileUploadHandlerEvent) {
        this.MSEventOnUploadFile.emit(event);
    }

    public MSClearFile(): void {
        this.MSFileUpload.clear();
    }
}
