import { DatePipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "moondate",
    standalone: true,
})
export class MoonDatePipe extends DatePipe implements PipeTransform {
    private moonstoneDefaultLocalFormat = 'M/d/yyyy, h:mm a';
    private moonstoneDefaultUtcFormat = 'EEE, d MMM y, HH:mm:ss';
    private utcFormatOption= 'utc' as const;
    constructor(@Inject(LOCALE_ID) locale: string) {
        super(locale);
    }

    override transform(value: any, format: string = this.moonstoneDefaultLocalFormat): any {
        if (this.moonstoneDefaultLocalFormat.equalsIgnoreCase(format)) {
            return super.transform(value, this.moonstoneDefaultLocalFormat)
        }
        else if (this.utcFormatOption.equalsIgnoreCase(format)) {
            return super.transform(value, this.moonstoneDefaultUtcFormat) + ' GMT';
        }
        else {
            super.transform(value, format);
        }
    }
}
