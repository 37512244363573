import { Injectable }                                           from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators }  from '@angular/forms';
// MS Imports
import { FormGroupOf }                                          from '@moon-shared/types/form-group-of.type';
import { FieldValues }                                          from '@moon-shared/constants/field-values';
import { Survey }                                               from '@moon-consumer/api/request/survey.request';

@Injectable()
export class SurveyService {
    
    public MSSurveyForm: FormGroup<FormGroupOf<Survey>> = new FormGroup<FormGroupOf<Survey>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
        inputFile: new FormControl(null, Validators.required),
    }, this.fileValidator);


    private fileValidator() {
        return (form: AbstractControl<Survey>): { [key: string]: any; } | null => {
            const fileControl: AbstractControl<File> | null = form.get('inputFile') as AbstractControl<File>;

            // File empty check
            if (fileControl?.value == null || fileControl.value.size === 0) {
                form.setErrors({ hasEmptyContent: true });
            }
            else {
                // File type check
                if (![FieldValues.FileType_PDF].includes(fileControl.value.type)) {
                    fileControl.setErrors({
                        invalidFileType: true,
                    });
                }
            }
            return null;
        }
    }

}