import { Injectable }                           from '@angular/core';
//Moon Imports
import { MoonApiClient }                        from '@moon-core/services/moon-api.client';
import { ConstantString }                       from '@moon-shared/constants/constant-string';
import { ApiContentResult, ApiResult }          from '@moon-core/models/api-result';
import { MoonUserNotificationSubscriptionGet }  from '@moon-maintainer/api/response/moon-user-notification-subscription-get.response';
import {MoonNotificationSubscription}           from '@moon-maintainer/api/request/moon-notification-subscription.request';

@Injectable()
export class MoonNotificationSubscriptionController {
  constructor(private _moonApi: MoonApiClient) {}

  /**
   *   GET: Maintainer/v1/NotificationSubscription/MoonNotification
   */
  public async GetSubscriberList(
    moonNotificationID: number
  ): Promise<ApiContentResult<MoonUserNotificationSubscriptionGet[]>> {
    const url = this._moonApi.combineUrl(
      this.getBaseUrl(),
      ConstantString.MoonNotification,
      moonNotificationID
    );
    return this._moonApi.getAsync<MoonUserNotificationSubscriptionGet[]>(url);
  }

  /**
   *   POST: Maintainer/v1/NotificationSubscription
   */
  public async SubscribeUser(
    moonNotification: MoonNotificationSubscription
  ): Promise<ApiContentResult<number>> {
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, moonNotification);
  }

  /**
   *   DELETE: Maintainer/v1/NotificationSubscription/{notificationSubscriptionID}
   */
  public async Delete(notificationSubscriptionID: number): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(
      this.getBaseUrl(),
      notificationSubscriptionID
    );
    return this._moonApi.deleteAsync(url);
  }

  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(
      ConstantString.NotificationSubscription
    );
  }
}
