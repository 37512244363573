// Moon Auto Generated (1.0.0) at 11/22/2023 11:48:18 AM
import {
  AsyncPipe, NgClass, NgFor, NgIf,
  NgStyle, NgSwitch, NgSwitchCase,
  NgSwitchDefault
}                                                       from '@angular/common';
import { Component, OnInit, ViewChild }                 from '@angular/core';
import {
  FormBuilder, FormsModule,
  ReactiveFormsModule
}                                                       from '@angular/forms';
import { ActivatedRoute }                               from '@angular/router';
// Third Party imports      
import { MenuItem, SharedModule }                       from "primeng/api";
import { ButtonModule }                                 from 'primeng/button';
import { DropdownModule }                               from "primeng/dropdown";
import { InputNumberModule }                            from "primeng/inputnumber";
import { InputTextModule }                              from "primeng/inputtext";
import { ProgressBarModule }                            from 'primeng/progressbar';
import { RippleModule }                                 from "primeng/ripple";
import { TableModule }                                  from "primeng/table";
import { ToolbarModule }                                from 'primeng/toolbar';
import { TooltipModule }                                from "primeng/tooltip";
// Moon Imports
import { AppBreadcrumbService }                         from '@app-layout/services/app.breadcrumb.service';
import { DocumentController }                           from "@moon-collaborator/api/document.controller";
import { DocumentChunkGet }                             from "@moon-collaborator/api/response/document-chunk-get.response";
import { CollaboratorRouter }                           from "@moon-collaborator/collaborator.router";
import { DocumentChunkTableComponent }                  from '@moon-collaborator/document/document-chunk/document-chunk-table/document-chunk-table.component';
import { ApiBlobResult, ApiContentResult, ApiResult }   from '@moon-core/models/api-result';
import { ComponentUtilityService }                      from "@moon-core/services/component-utility.service";
import { FormStickyNavbarComponent }                    from '@moon-shared/components/form-sticky-navbar/form-sticky-navbar.component';
import { MoonCustomDropdownComponent }                  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonFormControlComponent }                     from "@moon-shared/components/moon-form-control/moon-form-control.component";
import { MoonLoadingComponent }                         from "@moon-shared/components/moon-loading/moon-loading.component";
import { MoonTableHeaderComponent }                     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }                       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { RouteParameter }                               from '@moon-shared/constants/route-parameter';
import { RoutePath }                                    from '@moon-shared/constants/route-path';
import { MoonMessageService }                           from "@moon-shared/services/moon-message.service";

type EmbeddingStatus = 'MCR' | 'DNC' | 'CRT' | 'ALL';

@Component({
  selector: 'document-chunk',
  templateUrl: './document-chunk.component.html',
  styleUrls: ['./document-chunk.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgFor, NgClass, NgSwitch,
    NgSwitchCase, NgSwitchDefault, NgStyle, 
    FormsModule, ReactiveFormsModule,
    MoonFormControlComponent,
    DropdownModule, SharedModule,
    InputTextModule, RippleModule,
    TooltipModule,InputNumberModule, 
    TableModule, ToolbarModule, ButtonModule,
    ProgressBarModule, AsyncPipe,
    MoonTablePageComponent,
    MoonTableHeaderComponent,
    MoonCustomDropdownComponent,
    MoonLoadingComponent,
    FormStickyNavbarComponent, 
    DocumentChunkTableComponent,
  ],
  providers: [
    DocumentController,
    CollaboratorRouter
  ]
})
export class DocumentChunkComponent implements OnInit {

  public MSDocumentChunkList: DocumentChunkGet[];
  public MSFilteredChunkList: DocumentChunkGet[] = [];
  public MSLoading: boolean = true;  
  public MSPageTitle: string = "Document Chunk";
  public MSEnvironmentName: string;
  public MSDocumentID: number;
  @ViewChild("MSTableComponent") MSTableComponent: DocumentChunkTableComponent;
  public MSFilterChunkForm = this._formBuilder.group({
    tokenMin: 1,
    tokenMax: 1000,
    embeddingFilter: 'ALL',
    tokenFilter: '',
  });
  
  public MSEmbeddingStatuses: { label: EmbeddingStatus; value: string }[] = [
    { label: 'DNC', value: 'DNC' },
    { label: 'MCR', value: 'MCR' },
    { label: 'CRT', value: 'CRT' },
    { label: 'ALL', value: 'ALL' },
  ];
  
  public MSOriginalTokenRange = { min: 0, max: 100 };
  public MSFilterApplied: boolean = false;
  public MSIsProcessRunning: boolean = false;
  public MSCurrentProcessMessage: string;

  constructor(
    
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _collaboratorRouter: CollaboratorRouter,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _documentController: DocumentController,
    private _moonMessageService: MoonMessageService,
    
  ) { }

  ngOnInit() {
    this.readRouteParameters();
    this.loadDocumentChunkList();
    this.setBreadcrumb();
  }
  
  public get MSIsProcessInProgress(): boolean {
    return this.MSIsProcessRunning;
  }

  public get MSProcessMessage(): string {
    return this.MSCurrentProcessMessage ?? String.empty;
  }
 
  private readRouteParameters() {
    this.MSEnvironmentName = this._activatedRoute.snapshot.paramMap.get(RouteParameter.EnvironmentName) ?? String.empty;
    this.MSDocumentID = + (this._activatedRoute.snapshot.paramMap.get(RouteParameter.DocumentID) ?? -999);
  }

  private setBreadcrumb() {
    const editPageBreadcrumb: MenuItem[] = [
      { label: this.MSEnvironmentName, routerLink: [`${this._collaboratorRouter.EnvironmentRouteLink()}`] }, 
      { label: RoutePath.Documents, routerLink: this._collaboratorRouter.DocumentRouteLink(this.MSEnvironmentName) },
      { label: RoutePath.Chunk, routerLink: null }  
    ];
    this._appBreadcrumbService.setItems(editPageBreadcrumb);
  }

  private setDefaultFilterFieldValues() {
    this.MSFilterChunkForm.controls.tokenMin.setValue(1);
    this.MSFilterChunkForm.controls.tokenMax.setValue(1000);
    this.MSFilterChunkForm.controls.embeddingFilter.setValue('ALL');
    this.MSFilterChunkForm.controls.tokenFilter.setValue(String.empty);
  }
  
  private async loadDocumentChunkList() {
    this.MSLoading = true;
    
    const apiResult: ApiContentResult<DocumentChunkGet[]> = await this._documentController.GetChunkListAsync(this.MSEnvironmentName, this.MSDocumentID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSDocumentChunkList = apiResult.content;
      this.MSApplyFilter();
    }

    this.MSLoading = false;  
  }

  public MSClearFilter() {
    this.MSFilterApplied = false;
    this.MSFilterChunkForm.reset();
    this.setDefaultFilterFieldValues();
    this.MSFilteredChunkList = this.MSDocumentChunkList;
  }

  public MSApplyFilter() {
    this.MSFilterApplied = true;
    const tokenMin: number =
      this.MSFilterChunkForm.controls.tokenMin.value ?? 0;
    const tokenMax: number =
      this.MSFilterChunkForm.controls.tokenMax.value ?? 1000;
    const selectedEmbeddingFilter =
      this.MSFilterChunkForm.value.embeddingFilter;

    // Apply filtering logic in the map operator
    this.MSFilteredChunkList = this.MSDocumentChunkList.filter((chunk) => {
      // Embedding status filter
      if (
        selectedEmbeddingFilter != 'ALL' &&
        chunk.embeddingStatus != selectedEmbeddingFilter
      ) {
        return false;
      }

      // Token size filter
      if (
        chunk.tokenSize < (tokenMin ?? 0) ||
        chunk.tokenSize > (tokenMax ?? 1000)
      )
        return false;

      // if both passes, then return true
      return true;
    });
  }

  public MSOnRefreshClick() {
    this.setDefaultFilterFieldValues();
    this.loadDocumentChunkList();
  }

  public MSOnClickGoBack() {
    this._collaboratorRouter.ToDocument(this.MSEnvironmentName);
  }

  public async MSOnClickDownloadDocument() {
    const apiResult: ApiBlobResult = await this._documentController.GetBlobAsync(this.MSEnvironmentName, this.MSDocumentID);
    if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download completed.");
    }
  }

  public async MSOnClickMarkForCreate() {
    const documentIDs: number[] = this.MSTableComponent?.MSSelectedChunks.map(
      (chunk) => chunk.documentChunkID
    );
    if (documentIDs) {
      this.MSIsProcessRunning = true;
      this.MSCurrentProcessMessage = "Processing. Please wait..."
      const apiResult: ApiResult =
        await this._documentController.UpdateChunkStatusAsync(
          this.MSEnvironmentName,
          documentIDs,
          'MCR'
        );
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSIsProcessRunning = false;
        this.loadDocumentChunkList();
      }
    }
  }
  
  
  public async MSOnClickDoNotCreate() {
    const documentIDs: number[] = this.MSTableComponent.MSSelectedChunks.map(
      (chunk) => chunk.documentChunkID
    );
    if (documentIDs) {
      this.MSIsProcessRunning = true;
      this.MSCurrentProcessMessage = "Processing. Please wait..."

      const apiResult: ApiResult =
        await this._documentController.UpdateChunkStatusAsync(
          this.MSEnvironmentName,
          documentIDs,
          'DNC'
        );
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSIsProcessRunning = false;
        this.loadDocumentChunkList();
      }
    }
  }

  private get selectedDocumentItems(): DocumentChunkGet[] {
    return this.MSTableComponent?.MSSelectedChunks;
  }

  public get MSDoDisableActionButton(): boolean {
    return (
      !(this.selectedDocumentItems && this.selectedDocumentItems.length > 0) ||
      this.MSIsProcessInProgress
    );
  }
}
