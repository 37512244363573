import { HttpErrorResponse, HttpStatusCode }    from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone }     from '@angular/core';
import { NavigationError, Router }              from '@angular/router';
// Moonstone Imports
import { MoonFormNotModifiedError }             from '@moon-core/moon-errors/moon-form-not-modified.error';
import { MoonHttpErrorResponse }                from '@moon-core/moon-errors/moon-http-error-response';
import { MoonSessionErrorResponse }             from '@moon-core/moon-errors/moon-session-error-response';
import { MoonApplicationInsight }               from '@moon-core/services/moon-application-insight.service';
import { UserSessionService }                   from '@moon-core/services/user-session.service';
import { DefaultValues }                        from '@moon-shared/constants/default-values';
import { MessageSeverity }                      from '@moon-shared/constants/message-severity';
import { RoutePath }                            from '@moon-shared/constants/route-path';
import { MoonMessageService }                   from '@moon-shared/services/moon-message.service';
import { environment }                          from 'src/environments/environment';

@Injectable()
export class MoonErrorHandler implements ErrorHandler {

    constructor(
        private _ngZone: NgZone,
        private _moonApplicationInsight: MoonApplicationInsight,
        private _moonMessageService: MoonMessageService,
        private _userSessionService: UserSessionService,
        private _router: Router
    ) { }
    
    private get _isConnectedToDatabase(): boolean {
        return this._userSessionService ? this._userSessionService.IsConnectedToDatabase() : false;
    }

    private get _enableErrorLogging(): boolean {
        return environment.production;
    }

    public HandleRouteError(error: NavigationError) : void {
        this.logToConsole('Navigation Error from global error handler', error);
        this._router.navigate([RoutePath.Error], { skipLocationChange: true });
    }

    handleError(error: Error | HttpErrorResponse) {
        this.logToConsole('Error from global error handler', error);

        if (this._enableErrorLogging) {
            this._moonApplicationInsight.logException(error);
        }

        if (error instanceof MoonSessionErrorResponse) {
            this.handleSessionError(error);
        } else if (error instanceof MoonHttpErrorResponse) {
            this.handleMoonHttpError(error);
        } else if (error instanceof MoonFormNotModifiedError) {
            this.handleFormNotModifiedError(error);
        } else {
            this.handleClientError(error);
        }
    }

    private handleFormNotModifiedError(error: MoonFormNotModifiedError) {
        this.logToConsole(error);
        this._moonMessageService.showToastMessage(MessageSeverity.Info, error.message);
    }

    private handleClientError(error: Error) {

        let errorMessage = 'Something went wrong. Please contact MOONSTONE.';

        const separator = DefaultValues.Separator;        
        const sessionMessage: string = this._userSessionService.getDisplayString();
        const summary = `${separator}Error: ${error.message}${separator}${sessionMessage}` +
        `Page: ${this._router.url}`;

        if (!this._isConnectedToDatabase) {
            errorMessage = 'Detail: ' + error.stack;
            this.logToConsole(error);
        }
        const completeMessage = `${errorMessage}${separator}${summary}${separator}{Client Error}`
        this._moonMessageService.showMessages(MessageSeverity.Error, completeMessage);
    }

    private handleSessionError(sessionError: MoonSessionErrorResponse) {
        this.logToConsole(sessionError);

        alert("Invalid Moonstone session. Please login again.");

        this._moonApplicationInsight.logEvent("MoonErrorHander: Invalid Session Issue. Routing to Login Page.");
        this._ngZone.run(() => this._router.navigate([RoutePath.Login], { state: { bypassUnsavedChangeGuard: true } })).then();
    }

    private handleMoonHttpError(moonHttpError: MoonHttpErrorResponse): void {
        
        const separator = DefaultValues.Separator;
        const errorMessage: string = moonHttpError.message;
        const severity: string = this.getMessageSeverity(+moonHttpError.status);
        const message =
            `${moonHttpError.name}${separator}` +
            `${errorMessage}${separator}` +
            //`${this.validationMessages.join(separator)}${separator}` +
            `${this._userSessionService.getDisplayString()}${separator}` +
            `${moonHttpError.httpMethod}: ${moonHttpError.url} (${moonHttpError.status})${separator}` +
            `Page: ${this._router.url}`;

        this._moonMessageService.showMessages(severity, message);
    }

    private getMessageSeverity(status: number) {
        const warningErrorCodes: HttpStatusCode[] = [HttpStatusCode.Unauthorized, HttpStatusCode.UnprocessableEntity];
        return warningErrorCodes.includes(status) ? MessageSeverity.Warn : MessageSeverity.Error;
    }

    private logToConsole(message: any, ...content: any) {
        return console.log(message, content);
    }
}
