<div class="moon-inputfield grid align-items-center">
    <!-- LABEL -->
    <div
        *ngIf="MSDisplayLabel"
        class="col-12 md:col-{{ MSLabelSize || 2 }} moon-form-input-label"
    >
        <label [for]="MSLabel">
            {{ MSIsRequired || MSHasRequiredValidator ? "*" : "" }} {{ MSLabel }}
            <ng-container *ngIf="MSSecondLabel">
                <span class="text-color-secondary">{{ MSSecondLabel }}</span>
            </ng-container>
            <span
                *ngIf="MSHint"
                [pTooltip]="MSHint"
                tooltipPosition="bottom"
            >
                <i class="pi pi-info-circle"></i></span>

        </label>
    </div>
    <!-- INPUT FIELD -->
    <div class="col-12 md:col-{{ MSInputSize || 4 }}">
        <ng-content></ng-content>
    </div>
    <!-- MESSAGE -->
    <div
        class="col-12 md:col-{{ 12 - (MSInputSize || 4) - (MSLabelSize || 2) }}"
        [class]="MSErrorMessageStyleClass"
        *ngIf="MSDisplayErrMsg && MSFormControl?.invalid && (MSFormControl.dirty || MSFormControl.touched)"
    >
        <div
            class="p-message p-message-error"
            *ngIf="MSFormControl.errors"
        >
            <div *ngIf="MSFormControl.errors['required']">
                {{ MSErrorLabel || MSLabel}} is required.
            </div>
            <div *ngIf="MSFormControl.errors['list']">
                Select item from the list.
            </div>
            <div *ngIf="MSFormControl.errors['email']">
                {{ MSErrorLabel || MSLabel}} has invalid format.
            </div>
            <div *ngIf="MSFormControl.errors['pattern']">
                {{ MSErrorLabel || MSLabel}} has invalid characters.
            </div>
            <div *ngIf="MSFormControl.errors['JSONValidation']">
                {{ MSErrorLabel || MSLabel}} has invalid JSON input.
            </div>
            <div *ngIf="MSFormControl.errors['min']">
                {{ MSErrorLabel || MSLabel}} is less than {{ MSFormControl.errors['min']['min'] }}.
            </div>
            <div *ngIf="MSFormControl.errors['max']">
                {{ MSErrorLabel || MSLabel}} is greater than {{ MSFormControl.errors['max']['max'] }}.
            </div>
            <div *ngIf="MSFormControl.errors['minlength']">
                {{ MSErrorLabel || MSLabel}} must be greater than
                {{ MSFormControl.errors['minlength']['requiredLength'] }} characters
                long.
            </div>
            <div *ngIf="MSFormControl.errors['maxlength']">
                {{ MSErrorLabel || MSLabel}} must be less than
                {{ MSFormControl.errors['maxlength']['requiredLength'] }} characters
                long.
            </div>
            <div *ngIf="MSFormControl.errors['serverError']">
                {{ MSFormControl.errors['serverError'] }}
            </div>
            <div *ngIf="MSFormControl.errors['hasEmptyContent']">
                File content cannot be empty.
            </div>
            <div *ngIf="MSFormControl.errors['invalidFileType']">
                Invalid File Type.
            </div>
            <div *ngIf="MSFormControl.errors['invalidData']">
                {{MSFormControl.errors['invalidData'] ?? 'Invalid Data.'}}
            </div>
        </div>
    </div>
</div>