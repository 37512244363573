import { DecimalPipe, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, OnDestroy, OnInit }                 from '@angular/core';
import { FormGroup, FormsModule }                       from '@angular/forms';
//Third Party Imports
import { PdfViewerModule }                              from 'ng2-pdf-viewer';
import { PDFDocumentProxy }                             from 'pdfjs-dist/types/src/display/api';
import { ButtonModule }                                 from 'primeng/button';
import { DialogModule }                                 from 'primeng/dialog';
import { InputNumberModule }                            from 'primeng/inputnumber';
import { RippleModule }                                 from 'primeng/ripple';
// MS Imports
import { AirplaneWizardService }                        from '@moon-consumer/airplane/add-airplane-wizard/service/airplane-wizard.service';
import { AirplaneController }                           from '@moon-consumer/api/airplane.controller';
import { Airplane }                                     from '@moon-consumer/api/request/airplane.request';
import { ConsumerRouter }                               from '@moon-consumer/consumer.router';
import { PdfPagePreviewComponent }                      from '@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-mark-exception-page/pdf-page-preview/pdf-page-preview.component';
import { ApiBlobResult, ApiContentResult }              from '@moon-core/models/api-result';
import { ComponentUtilityService }                      from '@moon-core/services/component-utility.service';
import { TitleCommitmentPdf }                           from '@moon-maintainer/title-commitment-advanced/title-commitment-pdf';
import { MoonLoadingComponent }                         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MessageSeverity }                              from '@moon-shared/constants/message-severity';
import { RoutePath }                                    from '@moon-shared/constants/route-path';
import { MoonEnterKeyupDirective }                      from '@moon-shared/directives/moon-enter-keyup.directive';
import { MoonMessageService }                           from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                                  from '@moon-shared/types/form-group-of.type';

@Component({
  selector: 'moon-airplane-mark-page',
  standalone: true,
  imports: [
    DecimalPipe, FormsModule, NgClass, NgFor, NgIf, SlicePipe,
    ButtonModule, InputNumberModule, RippleModule, DialogModule,
    PdfViewerModule,
    MoonLoadingComponent, MoonEnterKeyupDirective, PdfPagePreviewComponent
  ],
  templateUrl: './airplane-mark-term-page.component.html',
  styleUrl: './airplane-mark-term-page.component.scss',
  providers: [ConsumerRouter, AirplaneController]
})
export class AirplaneMarkTermPageComponent implements OnInit, OnDestroy {
  public MSLoading: boolean = false;
  public MSLoadingMessage: string = 'Loading PDF';
  public MSAirplaneForm!: FormGroup<FormGroupOf<Airplane>>;
  public MSTitleCommitmentPdfBlob = new TitleCommitmentPdf();
  public MSShowPdfPreviewPage: boolean = false;
  public MSPreviewPageNumber!: number;

  constructor(
    private _airplaneWizardService: AirplaneWizardService,
    private _moonMessageService: MoonMessageService,
    private _consumerRouter: ConsumerRouter,
    private _airplaneController: AirplaneController,
    private _componentUtilityService: ComponentUtilityService,
  ) { }
  ngOnDestroy(): void {
    this.MSTitleCommitmentPdfBlob.pdfSrc = null;
  }
  ngOnInit() {
    this.setTitleCommitmentFromWizardService();
    const pdfFile: File = this.MSAirplaneForm.controls.inputFile?.getRawValue() as File;
    if (pdfFile == null) {
      this._moonMessageService.showMessages(MessageSeverity.Warn, "Cannot retrieve file. Please fill the form again.");
      this._consumerRouter.RouteToAirplaneCreate();
      return;
    }

    this.MSLoading = true;
    this.MSTitleCommitmentPdfBlob.ReadContentFromFile(pdfFile);
  }
  public async MSOnPdfLoadCompleted(event: PDFDocumentProxy): Promise<void> {
    try {
      await this.MSTitleCommitmentPdfBlob.OnPdfLoadComplete(event);
    }
    finally {
      this.MSLoading = false;
    }
  }
  public MSMarkUnmarkAsExceptionPage($event: number): void {
    this.MSTitleCommitmentPdfBlob.MarkUnmarkAsExceptionPage($event);
    this.MSShowPdfPreviewPage = false;
  }

  public MSOnClickGoBack() {
    this._consumerRouter.RouteToAirplaneCreate();
  }
  public MSOnClickFinish() {
    if (this.MSLoading || this.MSShowPdfPreviewPage) {
      return;
    }
    this.processExceptions();
  }
  public MSOnClickPreview(pageNumber: number): void {
    this.MSPreviewPageNumber = pageNumber;
    this.MSShowPdfPreviewPage = true;
  }
  public MSOnPNextPageEvent() {
    if (this.MSPreviewPageNumber < (this.MSTitleCommitmentPdfBlob.totalPdfPage - 1))
      this.MSPreviewPageNumber = this.MSPreviewPageNumber + 1
  }
  public MSOnPreviousPageEvent() {
    if (this.MSPreviewPageNumber > 0)
      this.MSPreviewPageNumber = this.MSPreviewPageNumber - 1
  }


  private processExceptions() {
    this.setExceptionPagesFromMarkedPages();
    this.MSAirplaneForm.markAllAsTouched();
    this.MSAirplaneForm.updateValueAndValidity();

    if (this.MSAirplaneForm.controls.termsPagesCsv.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Error, "You have not marked any term pages.");
      return;
    }

    if (!this._componentUtilityService.IsFormValid(this.MSAirplaneForm))
      return;

    const airplaneForm: Airplane = this.MSAirplaneForm.getRawValue() as Airplane;
    if (!airplaneForm.inputFile) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, 'Input file is required');
      return
    }
    this.processAirplaneDocument(airplaneForm);
  }

  private async processAirplaneDocument(airlineForm: Airplane) {

    this.MSLoadingMessage = 'Processing document...'
    this.MSLoading = true;
    try {
      const apiResult: ApiContentResult<number> = await this._airplaneController.Create(airlineForm);
      if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSAirplaneForm)) {
        
        this.routeToProcessPage();
        const processReulst: ApiBlobResult = await this._airplaneController.ProcessAirplaneTerms(apiResult.content);
        
        if (this._componentUtilityService.WasSuccessful(processReulst)) {
          this._componentUtilityService.WasDownloadSuccessful(processReulst);
          this._moonMessageService.toastSuccess("Document processed successfully.");
        }
      }
    }
    finally {
      this.MSLoading = false;
    }
  }
  
  private routeToProcessPage() {
    this._consumerRouter.RouteToAirplane(RoutePath.Add, RoutePath.Process);
  }

  private setExceptionPagesFromMarkedPages() {
    const exceptionPages: number[] = this.MSTitleCommitmentPdfBlob.GetExceptionPageArray();
    if (exceptionPages) {
      this.MSAirplaneForm.controls.termsPagesCsv.setValue(exceptionPages.join(','));
    }
  }
  private setTitleCommitmentFromWizardService() {
    this.MSAirplaneForm = this._airplaneWizardService.MSAirplaneForm;
  }
}
