<div
    class="mb-1 border-bottom-1 surface-border py-2"
    [ngClass]="MSTitleException.hasError ? 'border-top-1 border-red-500' : 'surface-border'"
>
    <h1 class="flex flex-1 gap-3 mb-2 text-lg align-items-center">
        <div>{{MSTitleException.part}}</div>
        <div>{{MSTitleException.exceptionNumber}}</div>
        <div *ngIf="MSTitleException.processingStatus">
            <p-badge
                severity="warning"
                title="Processing status"
                value=" {{MSTitleException.processingStatus}}"
            ></p-badge>
        </div>
        <div *ngIf="MSTitleException.hasError">
            <p-badge
                severity="danger"
                value="Error"
            ></p-badge>
        </div>

        <div class="ml-auto flex">
            <p-fileUpload
                *ngIf="MSTitleException.titleExceptionID > 0"
                #MSFileUpload
                mode="basic"
                accept=".pdf"
                [auto]="true"
                (onSelect)="MSOnClickUploadDocument($event, MSFileUpload)"
                chooseIcon="pi pi-upload"
                [chooseLabel]="MSUploadingDocument ? 'Uploading' : 'Upload document'"
                title="Upload document"
                [disabled]="MSUploadingDocument || MSSaving"
                class="p-button-raised moon-button"
            ></p-fileUpload>
        </div>
    </h1>
    <div
        class="mb-2"
        *ngIf="MSTitleException.exceptionDescription"
    >
        {{MSTitleException.exceptionDescription}}
    </div>

    <ng-container *ngIf="MSTitleException.hasDocument === 'N'">
        <div class="mb-2">
            <span class="font-bold">Exception Type</span>:
            <div>
                <span>{{MSTitleException.exceptionType}}
                </span>
                <!-- <span
                    *ngIf="MSTitleException.overrideExceptionType"
                    class="text-gray-400 text-sm"
                >(Edited)
                </span> -->
            </div>
        </div>
        <div class="mb-2">
            <span class="font-bold">Recommended Action</span>:
            <div>
                <span>{{MSTitleException.recommendedAction}}
                </span>
                <!-- <span
                    *ngIf="MSTitleException.overrideRecommendedAction"
                    class="text-gray-400 text-sm"
                >(Edited)
                </span> -->
            </div>
        </div>
    </ng-container>
</div>
