import { DecimalPipe, NgClass, NgFor, NgIf, SlicePipe }         from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output }       from '@angular/core';
// Third Party imports
import { ConfirmationService }                                  from 'primeng/api';
import { BadgeModule }                                          from 'primeng/badge';
import { ButtonModule }                                         from 'primeng/button';
import { ConfirmDialogModule }                                  from 'primeng/confirmdialog';
import { FileUpload, FileUploadHandlerEvent, FileUploadModule } from 'primeng/fileupload';
import { RippleModule }                                         from 'primeng/ripple';
// Moon imports
import { ComponentUtilityService }                              from '@moon-core/services/component-utility.service';
import { TitleDocumentGet }                                     from '@moon-public/api/response/title-document.response';
import { TitleExceptionGet }                                    from '@moon-public/api/response/title-exception.response';
import { TitleCommitmentFull }                                  from '@moon-public/api/response/title-commitment-full.response';
import { TitleDocumentController }                              from '@moon-public/api/title-document.controller';
import { TitleExceptionController }                             from '@moon-public/api/title-exception.controller';
import { TitleSummaryController }                            from '@moon-public/api/title-summary.controller';
import { MoonLoadingComponent }                                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { FieldValues }                                          from '@moon-shared/constants/field-values';
import { MoonDragDropDirective }                                from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonMessageService }                                   from '@moon-shared/services/moon-message.service';
import { MoonFileUpload }                                       from '@moon-shared/models/moon-file-upload.model';

@Component({
  selector: 'moon-title-documents-upload',
  standalone: true,
  imports: [
    NgIf, NgFor, NgClass, DecimalPipe, SlicePipe,
    BadgeModule, ButtonModule, RippleModule, FileUploadModule, ConfirmDialogModule,
    MoonLoadingComponent, MoonDragDropDirective
  ],
  templateUrl: './title-documents-upload.component.html',
  styleUrl: './title-documents-upload.component.scss',
  providers: [TitleSummaryController, TitleDocumentController, TitleExceptionController]
})
export class TitleDocumentsUploadComponent implements OnInit {
  @Input() public MSTitleSummaryID!: number;
  @Input() public MSParagraphMessage: string = 'We were unable to download these title documents. Please upload the document manually to continue title commitment process.';
  @Input() public MSShowOnlyDownloadFailedDocuments: boolean = true;
  public MSLoading: boolean = false;
  public MSTitleCommitmentFull!: TitleCommitmentFull;
  public MSUploadingDocument: boolean = false;
  @Output() public MSAllDocumentDownloadedEvent: EventEmitter<null> = new EventEmitter();

  constructor(
    private _titleSummaryController: TitleSummaryController,
    private _titleDocumentController: TitleDocumentController,
    private _titleExceptionController: TitleExceptionController,
    private _moonMessageService: MoonMessageService,
    private _componentUtilityService: ComponentUtilityService,
    private _confirmationService: ConfirmationService,
  ) { }

  ngOnInit() {
    this.MSLoadTitleCommitment();
  }

  public async MSLoadTitleCommitment() {
    this.MSLoading = true;
    try {
      const apiResult = await this._titleSummaryController.GetFullByID(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleCommitmentFull = apiResult.content;

        if (this.MSShowOnlyDownloadFailedDocuments) {
          this.MSTitleCommitmentFull.titleExceptionGetList.map(a => {
            a.titleDocumentGetList = a.titleDocumentGetList.filter(a => !a.hasBlob);
          });
          this.MSTitleCommitmentFull.titleExceptionGetList = this.MSTitleCommitmentFull.titleExceptionGetList.filter(a => !a.titleDocumentGetList.isEmpty())
        }
        if (this.MSTitleCommitmentFull.titleExceptionGetList.isEmpty()) {
          this.MSAllDocumentDownloadedEvent.emit();
        }
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async MSOnClickChoosePagesFromTitleCommitment() {
  }

  public async MSOnClickDeleteDocumentBlob(titleDocumentGet: TitleDocumentGet) {
    try {
        this.MSLoading = true;
        const apiResult = await this._titleDocumentController.DeleteBlob(this.MSTitleSummaryID, titleDocumentGet.titleDocumentID);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess('Title document content cleared successfully.');
          this.MSLoadTitleCommitment();
        }
    }
    finally {
      this.MSLoading = false;
    }
  }

  public async MSOnClickDeleteDocument(titleDocumentGet: TitleDocumentGet) {
    this._confirmationService.confirm({
      key: 'title-document-upload-confirmation',
      message: "Are you sure you would like to delete this item?",
      acceptLabel: "Yes",
      accept: () => this.doDeleteDocument(titleDocumentGet),
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
  }

  // private async doDeleteDocument(titleDocumentGet: TitleDocumentGet) {

  //   try {
  //     this.MSLoading = true;
  //     const apiResult = await this._titleDocumentController.Delete(this.MSTitleSummaryID, titleDocumentGet.titleDocumentID);
  //     if (this._componentUtilityService.WasSuccessful(apiResult)) {
  //       this._moonMessageService.toastSuccess('Title document delete successful.');
  //       await this.MSLoadTitleCommitment();
  //     }
  //   }
  //   finally {
  //     this.MSLoading = false;
  //   }
  // }

  private async doDeleteDocument(titleDocumentGet: TitleDocumentGet) {

    try {
      this.MSLoading = true;
      const apiResult = await this._titleDocumentController.Delete(this.MSTitleSummaryID, titleDocumentGet.titleDocumentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess('Title document blob delete successful.');
        await this.MSLoadTitleCommitment();
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  public async MSOnFileDrop(titleDocument: TitleDocumentGet, event: MoonFileUpload) {
    if (event.file) {
      await this.doUploadTitleDocumentBlob(event.file, titleDocument);
    }
  }
  
  public async MSOnClickUploadDocument(titleDocument: TitleDocumentGet, event: FileUploadHandlerEvent, fileUpload: FileUpload) {
    if (event.files.length > 0 && event.files[0]) {
      try {
        await this.doUploadTitleDocumentBlob(event.files[0], titleDocument);
      }
      finally {
        fileUpload.clear();
      }
    }
  }
  private async doUploadTitleDocumentBlob(file: File, titleDocument: TitleDocumentGet) {
    try {
      if (this._componentUtilityService.IsFileValid(file, { fileExtensions: [FieldValues.FileExtension_PDF] })) {
        this.MSLoading = true;
        const apiResult = await this._titleDocumentController.PutBlob(this.MSTitleSummaryID, titleDocument.titleDocumentID, file);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess('Title document upload successful.');
          await this.MSLoadTitleCommitment();
        }
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  public async MSOnClickUploadDocumentForExceptions(titleException: TitleExceptionGet, event: FileUploadHandlerEvent, fileUpload: FileUpload) {
    const file = event?.files[0];
    if (file) {
      try {
        if (this._componentUtilityService.IsFileValid(file, { fileExtensions: [FieldValues.FileExtension_PDF] })) {
          {
            this.MSLoading = true;
            const apiResult = await this._titleExceptionController.PostTitleDocumentBlob(this.MSTitleSummaryID, titleException.titleExceptionID, file);
            if (this._componentUtilityService.WasSuccessful(apiResult)) {
              this._moonMessageService.toastSuccess('Title document upload successful.');
              this.MSLoadTitleCommitment();
            }
          }
        }
      }
      finally {
        this.MSLoading = false;
        fileUpload.clear();
      }
    }
  }
  
}
