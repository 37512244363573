import { CommonModule }                             from '@angular/common';
import { Component, OnInit }                                from '@angular/core';
// Third party imports
import { DataViewModule }                           from 'primeng/dataview';
import { DynamicDialogConfig, DynamicDialogRef }    from 'primeng/dynamicdialog';
import { FieldsetModule }                           from 'primeng/fieldset';
import { TagModule }                                from 'primeng/tag';
// Moon Imports
import { SimilarChunk }                             from '@moon-public/clause-search/models/similar-chunk';
import { BlackLinesComponent }                      from '@moon-shared/components/black-lines/black-lines.component';
import { MessageSeverity }                          from '@moon-shared/constants/message-severity';
import { MoonMessageService }                       from '@moon-shared/services/moon-message.service';

export type SameChunkDynamicDialogData = {
    MSOriginalSearchText: string,
    MSOriginalChunk: SimilarChunk;
    MSSameChunks: SimilarChunk[];
};

@Component({
    standalone: true,
    selector: 'moon-matching-clause-modal',
    templateUrl: './matching-clause-modal.component.html',
    styleUrls: ['./matching-clause-modal.component.scss'],
    imports: [CommonModule, DataViewModule, TagModule, FieldsetModule, BlackLinesComponent],
    providers: []
})
export class MatchingClauseModalComponent implements OnInit {
    public MSOriginalSearchText: string;
    public MSOriginalChunk: SimilarChunk;
    public MSSameChunk: SimilarChunk[] = [];
    constructor(
        private _dynamicDialogConfig: DynamicDialogConfig<SameChunkDynamicDialogData>,
        private _dynamicDialogRef: DynamicDialogRef,
        private _moonMessageService: MoonMessageService
    ) { }
    ngOnInit() {
        const data: SameChunkDynamicDialogData | undefined = this._dynamicDialogConfig.data;

        if (data == null) {
            this._moonMessageService.showToastMessage(MessageSeverity.Info, "No documents found.");
            this._dynamicDialogRef.close();
            return;
        }
        this.MSOriginalChunk = data.MSOriginalChunk;
        this.MSOriginalSearchText = data.MSOriginalSearchText;
        this.MSSameChunk = data.MSSameChunks;
        this.MSSameChunk.splice(0, 0, this.MSOriginalChunk);
    }
}