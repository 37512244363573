<div class="card relative">
    <button
        [disabled]="MSIsPdfDownloadingAndLoading"
        pButton
        pRipple
        class="p-button-outlined cursor-pointer absolute right-0 p-1"
        icon="pi pi-cloud-download"
        title="Download Document"
        tooltipPosition="top"
        (click)="MSDownloadPdf()"
    ></button>

    <div class="moon-show-hide-pdf-thumbnails">
        <p-inputSwitch
            inputId="togglefilter"
            [(ngModel)]="MSShowPdfThumbnails"
        ></p-inputSwitch>
        <label for="togglefilter">{{MSShowPdfThumbnails ? 'Hide ' : 'Show '}} Thumbnails</label>
    </div>

    <h1 class="text-base font-bold text-center my-2 col-offset-3">
        <ng-container *ngIf="MSTitleDocumentID; else summaryHeading">Uploaded Pdf</ng-container>
        <ng-template #summaryHeading>Exception Pages</ng-template>
        <span
            *ngIf="MSExceptionPagesCSV"
            class="text-sm font-italic font-light text-center ml-2"
        >
            (marked pages from original pdf: {{MSExceptionPagesCSV}})
        </span>
    </h1>

    <div
        *ngIf="MSIsPdfDownloadingAndLoading; else loadingComplete"
        class="flex justify-content-center align-items-center moon-pdf-viewer-spinner"
    >
        <p-progressSpinner
            aria-label="Loading"
            styleClass="w-4rem h-4rem"
            strokeWidth="4"
            animationDuration=".5s"
        ></p-progressSpinner>
    </div>
    <ng-template #loadingComplete>
        <ng-template #noContent>
            <div class="flex justify-content-between align-items-center">
                No content found.
            </div>
        </ng-template>
        <div
            *ngIf="MSTitleCommitmentPdfBlob.pdfSrc; else noContent"
            class="moon-pdf-viewer-container"
        >
            <div
                id="moon-pdf-indicator"
                class="flex-column align-items-center py-2 px-4 gap-4 w-20rem relative overflow-auto"
                [ngClass]="MSShowPdfThumbnails ? 'flex fadeinleft animation-duration-100' : 'hidden'"
            >
                <ng-container *ngFor="let item of MSTitleCommitmentPdfBlob.pdfThumbnails; let i=index">
                    <div
                        class="moon-thumnail-image-container relative"
                        [ngClass]="{'active' : MSTitleCommitmentPdfBlob.activePdfPage == i+1, 'marked': MSTitleCommitmentPdfBlob.IsPageMarked(item.page) }"
                        [title]="MSTitleCommitmentPdfBlob.IsPageMarked(item.page) ? 'Marked as exception page': ''"
                        (click)="MSTitleCommitmentPdfBlob.activePdfPage = i+1"
                        id="moon-thumbnail-page-{{item.page}}"
                    >
                        <img
                            src="{{item.url}}"
                            alt="item.page"
                            class="moon-thumbnail-image"
                        />
                    </div>
                    <div class="pagenumber pb-1">{{item.page}}</div>
                </ng-container>
            </div>
            <pdf-viewer
                #pdfViewer
                class="moon-pdf-viewer"
                [src]="MSTitleCommitmentPdfBlob.pdfSrc"
                [page]="MSTitleCommitmentPdfBlob.activePdfPage"
                [show-all]="true"
                [original-size]="false"
                [zoom-scale]="'page-width'"
                [zoom]="MSTitleCommitmentPdfBlob.zoom"
                (after-load-complete)="MSTitleCommitmentPdfBlob.OnPdfLoadComplete($event, pdfViewer)"
                (pageChange)="MSTitleCommitmentPdfBlob.OnPdfPageChanged($event)"
            ></pdf-viewer>

            <div class="moon-pdf-page-number">
                {{MSTitleCommitmentPdfBlob.activePdfPage}}
            </div>
        </div>
    </ng-template>
</div>