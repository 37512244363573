// Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiBlobResult, ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { Skill }                from "@moon-maintainer/api/request/skill.request";
import { SkillExampleGet }      from "@moon-maintainer/api/response/skill-example-get.response";
import { SkillGet }             from "@moon-maintainer/api/response/skill-get.response";
import { SkillTestDataGet } from "@moon-maintainer/api/response/skill-test-data-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";
import { RoutePath } from "@moon-shared/constants/route-path";

@Injectable()
export class SkillController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.Skill);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   GET: Maintainer/v1/Skill
 */
  public async GetList(
    ): Promise<ApiContentResult<SkillGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<SkillGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/Skill
 */
  public async Create(skill: Skill
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, skill);
  }
  
/**
 *   GET: Maintainer/v1/Skill/{documentID}
 */
  public async GetByID(skillID: number
    ): Promise<ApiContentResult<SkillGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillID);
    return this._moonApi.getAsync<SkillGet>(url);
  }
  
/**
 *   GET: Maintainer/v1/Skill/Number/{skillNumber}
 */
public async GetByNumber(skillNumber: string
  ): Promise<ApiContentResult<SkillGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), RoutePath.Number, skillNumber);
    return this._moonApi.getAsync<SkillGet>(url);
  }

/**
 *   PUT: Maintainer/v1/Skill/{documentID}
 */
  public async Update(skillID: number, skill: Skill
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillID);
    return this._moonApi.putAsync(url, skill);
  }
  
/**
 *   GET: Maintainer/v1/Skill/{skillID}/SkillExample
 */
  public async GetSkillExampleList(skillID: number
    ): Promise<ApiContentResult<SkillExampleGet[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillID, ConstantString.SkillExample);
    return this._moonApi.getAsync<SkillExampleGet[]>(url);
  }

  /**
   * GET: Maintainer/v1/Skill/Export
   */
  public async ExportAsync(
    ): Promise<ApiBlobResult> {
    
      const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Export);
    return this._moonApi.getBlobAsync(url);
  }
  
  /**
   * POST: Maintainer/v1/Skill/Import
   */
  public async ImportAsync(inputFile: File
    ): Promise<ApiResult> {
    
    const formFile = new FormData();
    formFile.append('formFile', inputFile);

    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Import);
    return this._moonApi.postAsync(url, formFile);
  }

  /**
   *   DELETE: Maintainer/v1/Skill/{skillID}
   */
  public async Delete(skillID: number
    ): Promise<ApiResult> {    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillID);
    return this._moonApi.deleteAsync(url);
  }

/**
 *   GET: Maintainer/v1/Skill/{skillNumber}/SkillTestData
 */
  public async GetSkillTestDataList(skillNumber: string
    ): Promise<ApiContentResult<SkillTestDataGet[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), skillNumber, RoutePath.SkillTestData);
    return this._moonApi.getAsync<SkillTestDataGet[]>(url);
  }

}
