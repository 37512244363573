import { DecimalPipe, NgClass, NgFor, NgIf, SlicePipe }             from '@angular/common';
import { Component, EventEmitter, Input, Output }                   from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }  from '@angular/forms';
// Third Party imports
import { ProgressBarModule }          from 'primeng/progressbar';
import { CheckboxModule }             from 'primeng/checkbox';
import { BadgeModule }                from 'primeng/badge';
import { ButtonModule }               from 'primeng/button';
import { InputTextareaModule }        from 'primeng/inputtextarea';
import { RippleModule }               from 'primeng/ripple';
// Moon imports
import { ComponentUtilityService }    from '@moon-core/services/component-utility.service';
import { CustomerMessage }            from '@moon-maintainer/api/request/customer-message.request';
import { TitleAdminController }       from '@moon-maintainer/api/title-admin.controller';
import { MoonFormControlComponent }   from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }       from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonFormPageComponent }      from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { MoonMessageService }         from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                from '@moon-shared/types/form-group-of.type';

@Component({
  selector: 'moon-title-contact-customer',
  standalone: true,
  imports: [
    NgIf, NgFor, NgClass, DecimalPipe, SlicePipe,
    BadgeModule, ButtonModule, RippleModule, ReactiveFormsModule,
    InputTextareaModule, CheckboxModule,
    MoonLoadingComponent, MoonFormControlComponent, MoonFormPageComponent,
    ProgressBarModule,
  ],
  templateUrl: './title-contact-customer.component.html',
  styleUrl: './title-contact-customer.component.scss',
  providers: [TitleAdminController]
})
export class TitleContactCustomerComponent {
  @Input() public MSTitleSummaryID!: number;
  @Input() public MSVisible: boolean = false;
  @Output() public MSVisibleChange: EventEmitter<boolean> = new EventEmitter();
  public MSLoading: boolean = false;
  public MSIsFormSubmitting: boolean = false;

  public MSContactCustomerForm: FormGroup<FormGroupOf<CustomerMessage>> = new FormGroup<FormGroupOf<CustomerMessage>>({
    includeSummary: new FormControl(false, { nonNullable: true }),
    // FormBuilder PlaceHolder - DO NOT REMOVE
    message: new FormControl(null, Validators.required)
  });

  constructor(
    private _titleAdminController: TitleAdminController,
    private _moonMessageService: MoonMessageService,
    private _componentUtilityService: ComponentUtilityService,
  ) { }

  public async MSOnClickSubmit() {
    try {
      this.MSIsFormSubmitting = true;

      if (this._componentUtilityService.IsFormValid(this.MSContactCustomerForm)) {
        const apiResult = await this._titleAdminController.ContactCustomer(this.MSTitleSummaryID, this.MSContactCustomerForm.getRawValue());
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess('Message submitted to customer.');
          this.closeDialog();
        }
      }
    }
    finally {
      this.MSIsFormSubmitting = false;
    }
  }

  public MSOnClickCancel() {
    this.closeDialog();
  }

  private closeDialog() {
    this.MSVisibleChange.emit(false);
  }
}
