// Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM
import { NgIf }                         from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }            from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
// Moon Imports
import { AppBreadcrumbService }         from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { DeploymentGet }                from '@moon-maintainer/api/response/deployment-get.response';
import { DeploymentController }         from '@moon-maintainer/api/deployment.controller';
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { DeploymentFormComponent }      from '@moon-maintainer/deployment/deployment-form/deployment-form.component';
import { DeploymentTableComponent }     from '@moon-maintainer/deployment/deployment-table/deployment-table.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';
import { FieldNames }                   from '@moon-shared/constants/field-names';
import {RoutePath}                      from '@moon-shared/constants/route-path';

@Component({
    selector: 'deployment-add-page',
    templateUrl: './deployment-edit-page.component.html',
    styleUrls: ['./deployment-edit-page.component.css'],
    standalone: true,
    imports: [NgIf,
              MoonLoadingComponent, MoonEditPageComponent, DeploymentTableComponent, DeploymentFormComponent],
    providers: [DeploymentController, MaintainerRouter]
})
export class DeploymentEditPageComponent implements OnInit {

  public MSDeploymentID: number | null;
  public MSDeploymentGet: DeploymentGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.Deployment;
  public MSEnvironmentName = String.empty;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _deploymentController: DeploymentController,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSDeploymentID){
      await this.getDeploymentByID(this.MSDeploymentID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSDeploymentID){
      this.doRemove(this.MSDeploymentID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSDeploymentID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    this._appBreadcrumbService.SetEditPageBreadcrumb(this.MSPageTitle, RoutePath.Deployment, this.MSDeploymentID);
  }

  private readRouteParameters() {
    const deploymentID = this._activatedRoute.snapshot.paramMap.get(FieldNames.DeploymentID);
    this.MSDeploymentID = deploymentID ? +deploymentID : null;
  }

  private async getDeploymentByID(deploymentID: number) {
    if (deploymentID) {
      const apiResult: ApiContentResult<DeploymentGet> = await this._deploymentController.GetByID(deploymentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSDeploymentGet = apiResult.content;
      }
    }
  }

  private async doRemove(deploymentID: number) {
    const apiResult: ApiResult = await this._deploymentController.Delete(deploymentID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.RouteToDeployment();
    }
  }
}
