// Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM
import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { Table, TableModule }           from "primeng/table";
// Moon imports
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { EmailWhitelistFind }           from "@moon-maintainer/api/response/email-whitelist-find.response";
import { EmailWhitelistGet }            from "@moon-maintainer/api/response/email-whitelist-get.response";
import { MoonDatePipe }                 from '@moon-shared/pipes/moon-date.pipe';

@Component({
    selector: "moon-email-whitelist-table",
    templateUrl: "./email-whitelist-table.component.html",
    styleUrls: ['./email-whitelist-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
                TableModule, ButtonModule, InputTextModule, MoonDatePipe,
                MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [MaintainerRouter]
})

export class EmailWhitelistTableComponent implements OnInit, AfterViewInit {
    @Input() public MSPageTitle: string;
    @Input() public MSEmailWhitelistList: EmailWhitelistFind[];
    @Input() public MSLoading = true;
    @Output() public MSOnEmitReload = new EventEmitter();
    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.WhitelistValue.toCamelCase(), header: FieldNames.WhitelistValue.getDisplayName(), class: 'w-35per'},
		{field: FieldNames.Notes.toCamelCase(), header: FieldNames.Notes.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.LastUpdated.toCamelCase(), header: FieldNames.LastUpdated.getDisplayName(), class: 'w-25per'},
        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-5per'}
    ];

    public MSEnableSearch: boolean = false;
    public MSDisplayCreate: boolean = false;
    public MSDisplaySecondary: boolean = false;

    constructor(
        private _maintainerRouter: MaintainerRouter,
        private _changeDetection: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
    }

    ngAfterViewInit(){
        this.MSDisplaySecondary = this.MSEmailWhitelistList.length > 0;
        this._changeDetection.detectChanges();
    }

    public MSReloadList(): void {
        this.MSOnEmitReload.emit();
    }

    public OnClickItem(item: EmailWhitelistGet): void {
        this._maintainerRouter.RouteToEmailWhitelistEdit(item.emailWhitelistID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.RouteToEmailWhitelistCreate();
    }

    public OnClickFind(): void {
        this._maintainerRouter.RouteToEmailWhitelist();
    }

    public OnSearchInput(EmailWhitelistTable: Table, searchInput: string): void {
        EmailWhitelistTable.filterGlobal(searchInput, "contains");
    }
}
