<moon-loading *ngIf="MSLoading"></moon-loading>

<div *ngIf="!MSLoading">
    <p-tabView [(activeIndex)]="MSSelectedTabIndex">
        <p-tabPanel header="Title Summary Log">
            <p-table
                *ngIf="MSTitleCommitmentLog.titleSummaryLogGetList.length;else emptyMessage"
                #MSTableSummaryLog
                [columns]="MSTableColumns"
                [scrollable]="true"
                [rowHover]="true"
                [value]="MSTitleCommitmentLog.titleSummaryLogGetList"
                selectionMode="single"
                styleClass="p-datatable-gridlines p-datatable-striped moon-title-review-log"
                [globalFilterFields]="['chatLogID', 'skillName', 'lastUpdatedBy', 'deploymentName', 'temperature', 'fileName']"
            >
                <ng-template pTemplate="caption">
                    <moon-table-header
                        [MSEnableSearch]="true"
                        [MSDisplayCreateButton]="false"
                        (MSEventOnSearchInput)="OnSearchInput(MSTableSummaryLog, $event)"
                    >
                    </moon-table-header>
                </ng-template>

                <ng-template
                    pTemplate="header"
                    let-columns
                >
                    <tr>
                        <th
                            *ngFor="let col of columns"
                            [class]="col.class"
                            [pSortableColumn]="col.sortable ? col.field: ''"
                        >
                            {{ col.header }}
                            <p-sortIcon
                                *ngIf="col.sortable"
                                [field]="col.field"
                            ></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-log
                    let-columns="columns"
                >
                    <tr>
                        <td
                            *ngFor="let col of columns"
                            [class]="col.class"
                            [attr.rowspan]="col.field === 'chatLogID' ? 2 : 1"
                        >
                            <div [ngSwitch]="col.field">
                                <span *ngSwitchCase="'lastUpdated'">
                                    {{ log.lastUpdatedBy }} <br />
                                    at {{ log.lastUpdatedAt | moondate}}
                                </span>
                                <div
                                    *ngSwitchCase="'actions'"
                                    class="text-center"
                                >
                                    <button
                                        pButton
                                        label="Show Full Log"
                                        pTooltip="Show Chat Log Output"
                                        (click)="MSOnClickChatLogOutput(log.chatLogID)"
                                    > </button>
                                </div>
                                <span *ngSwitchDefault>{{ log[col.field] }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="11">
                            <div
                                class="mb-2"
                                *ngIf="log.entityType"
                            >
                                <span class="font-bold">Entity</span>: {{log.entityType}}({{log.entityID}})
                            </div>
                            <div
                                class="mb-2"
                                *ngIf="log.actionName"
                            >
                                <span class="font-bold">Action</span>: {{log.actionName}}({{log.entityID}})
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Cost</span>:
                                {{log.requestCost}} + {{log.responseCost}} = {{log.requestCost + log.responseCost}}
                            </div>
                            <div
                                class="mb-2"
                                *ngIf="log.durationInMs"
                            >
                                <span class="font-bold">Duration</span>: {{log.durationInMs / 1000}}s
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Temperature</span>: {{log.temperature}},
                                <span class="font-bold">Top P</span>: {{log.topP}},
                                <span class="font-bold">Frequency Penalty</span>: {{log.frequencyPenalty}},
                                <span class="font-bold">Presence Penalty</span>: {{log.presencePenalty}},
                                <span class="font-bold">Stop Sequence</span>: {{log.stopSequence}}
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Tokens</span>:
                                Max Output: {{log.maxOutputTokens}},
                                System: {{log.systemTokens}},
                                Example: {{log.exampleTokens}},
                                Input: {{log.inputTokens}},
                                Request: {{log.requestTokens}},
                                Response: {{log.responseTokens}}
                            </div>
                            <div
                                *ngIf="log.errorMessage"
                                class="white-space: pre-wrap"
                            >
                                <span class="font-bold">Error Message</span>
                                {{log.errorMessage}}
                            </div>
                            <div
                                *ngIf="log.traceJson"
                                class=" white-space: pre-wrap"
                            >
                                <span class="font-bold">Trace Json</span>
                                {{log.traceJson}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Title Document Log">
            <p-table
                *ngIf="MSTitleCommitmentLog.titleDocumentLogGetList.length;else emptyMessage"
                #MSTableDocumentLog
                [columns]="MSTableColumns"
                [scrollable]="true"
                [rowHover]="true"
                [value]="MSTitleCommitmentLog.titleDocumentLogGetList"
                selectionMode="single"
                styleClass="p-datatable-gridlines p-datatable-striped moon-title-review-log"
                [globalFilterFields]="['chatLogID', 'skillName', 'lastUpdatedBy', 'deploymentName', 'temperature', 'fileName']"
            >
                <ng-template pTemplate="caption">
                    <moon-table-header
                        [MSEnableSearch]="true"
                        [MSDisplayCreateButton]="false"
                        (MSEventOnSearchInput)="OnSearchInput(MSTableDocumentLog, $event)"
                    >
                    </moon-table-header>
                </ng-template>

                <ng-template
                    pTemplate="header"
                    let-columns
                >
                    <tr>
                        <th
                            *ngFor="let col of columns"
                            [class]="col.class"
                            [pSortableColumn]="col.sortable ? col.field: ''"
                        >
                            {{ col.header }}
                            <p-sortIcon
                                *ngIf="col.sortable"
                                [field]="col.field"
                            ></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-log
                    let-columns="columns"
                >
                    <tr>
                        <td
                            *ngFor="let col of columns"
                            [class]="col.class"
                            [attr.rowspan]="col.field === 'chatLogID' ? 2 : 1"
                        >
                            <div [ngSwitch]="col.field">
                                <span *ngSwitchCase="'lastUpdated'">
                                    {{ log.lastUpdatedBy }} <br />
                                    at {{ log.lastUpdatedAt | moondate}}
                                </span>
                                <div
                                    *ngSwitchCase="'actions'"
                                    class="text-center"
                                >
                                    <button
                                        pButton
                                        label="Show Full Log"
                                        pTooltip="Show Chat Log Output"
                                        (click)="MSOnClickChatLogOutput(log.chatLogID)"
                                    > </button>
                                </div>
                                <span *ngSwitchDefault>{{ log[col.field] }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="11">
                            <div
                                class="mb-2"
                                *ngIf="log.entityType"
                            >
                                <span class="font-bold">Entity</span>: {{log.entityType}}({{log.entityID}})
                            </div>
                            <div
                                class="mb-2"
                                *ngIf="log.actionName"
                            >
                                <span class="font-bold">Action</span>: {{log.actionName}}({{log.entityID}})
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Cost</span>:
                                {{log.requestCost}} + {{log.responseCost}} = {{log.requestCost + log.responseCost}}
                            </div>
                            <div
                                class="mb-2"
                                *ngIf="log.durationInMs"
                            >
                                <span class="font-bold">Duration</span>: {{log.durationInMs / 1000}}s
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Temperature</span>: {{log.temperature}},
                                <span class="font-bold">Top P</span>: {{log.topP}},
                                <span class="font-bold">Frequency Penalty</span>: {{log.frequencyPenalty}},
                                <span class="font-bold">Presence Penalty</span>: {{log.presencePenalty}},
                                <span class="font-bold">Stop Sequence</span>: {{log.stopSequence}}
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Tokens</span>:
                                Max Output: {{log.maxOutputTokens}},
                                System: {{log.systemTokens}},
                                Example: {{log.exampleTokens}},
                                Input: {{log.inputTokens}},
                                Request: {{log.requestTokens}},
                                Response: {{log.responseTokens}}
                            </div>
                            <div
                                *ngIf="log.errorMessage"
                                class="white-space: pre-wrap"
                            >
                                <span class="font-bold">Error Message</span>
                                {{log.errorMessage}}
                            </div>
                            <div
                                *ngIf="log.traceJson"
                                class=" white-space: pre-wrap"
                            >
                                <span class="font-bold">Trace Json</span>
                                {{log.traceJson}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </p-tabPanel>
        <p-tabPanel header="Title Exception Log">
            <p-table
                *ngIf="MSTitleCommitmentLog.titleExceptionLogGetList.length;else emptyMessage"
                #MSTableException
                [columns]="MSTableExceptionColumns"
                [scrollable]="true"
                [rowHover]="true"
                [value]="MSTitleCommitmentLog.titleExceptionLogGetList"
                selectionMode="single"
                styleClass="p-datatable-gridlines p-datatable-striped moon-title-review-log"
                [globalFilterFields]="['chatLogID', 'skillName', 'lastUpdatedBy', 'deploymentName', 'part']"
            >
                <ng-template pTemplate="caption">
                    <moon-table-header
                        [MSEnableSearch]="true"
                        [MSDisplayCreateButton]="false"
                        (MSEventOnSearchInput)="OnSearchInput(MSTableException, $event)"
                    >
                    </moon-table-header>
                </ng-template>

                <ng-template
                    pTemplate="header"
                    let-columns
                >
                    <tr>
                        <th
                            *ngFor="let col of columns"
                            [class]="col.class"
                            [pSortableColumn]="col.sortable ? col.field: ''"
                        >
                            {{ col.header }}
                            <p-sortIcon
                                *ngIf="col.sortable"
                                [field]="col.field"
                            ></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-log
                    let-columns="columns"
                >
                    <tr>
                        <td
                            *ngFor="let col of columns"
                            [class]="col.class"
                            [attr.rowspan]="col.field === 'chatLogID' ? 2 : 1"
                        >
                            <div [ngSwitch]="col.field">
                                <span *ngSwitchCase="'part'">
                                    {{ log[col.field] }} {{ log.exceptionNumber }}
                                </span>
                                <span *ngSwitchCase="'lastUpdated'">
                                    {{ log.lastUpdatedBy }} <br />
                                    at {{ log.lastUpdatedAt | moondate}}
                                </span>
                                <div
                                    *ngSwitchCase="'actions'"
                                    class="text-center"
                                >
                                    <button
                                        pButton
                                        label="Show Full Log"
                                        pTooltip="Show Chat Log Output"
                                        (click)="MSOnClickChatLogOutput(log.chatLogID)"
                                    > </button>
                                </div>
                                <span *ngSwitchDefault>{{ log[col.field] }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="11">
                            <div
                                class="mb-2"
                                *ngIf="log.entityType"
                            >
                                <span class="font-bold">Entity</span>: {{log.entityType}}({{log.entityID}})
                            </div>
                            <div
                                class="mb-2"
                                *ngIf="log.actionName"
                            >
                                <span class="font-bold">Action</span>: {{log.actionName}}({{log.entityID}})
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Cost</span>:
                                {{log.requestCost}} + {{log.responseCost}} = {{log.requestCost + log.responseCost}}
                            </div>
                            <div
                                class="mb-2"
                                *ngIf="log.durationInMs"
                            >
                                <span class="font-bold">Duration</span>: {{log.durationInMs / 1000}}s
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Temperature</span>: {{log.temperature}},
                                <span class="font-bold">Top P</span>: {{log.topP}},
                                <span class="font-bold">Frequency Penalty</span>: {{log.frequencyPenalty}},
                                <span class="font-bold">Presence Penalty</span>: {{log.presencePenalty}},
                                <span class="font-bold">Stop Sequence</span>: {{log.stopSequence}}
                            </div>
                            <div class="mb-2">
                                <span class="font-bold">Tokens</span>:
                                Max Output: {{log.maxOutputTokens}},
                                System: {{log.systemTokens}},
                                Example: {{log.exampleTokens}},
                                Input: {{log.inputTokens}},
                                Request: {{log.requestTokens}},
                                Response: {{log.responseTokens}}
                            </div>
                            <div
                                *ngIf="log.errorMessage"
                                class="white-space: pre-wrap"
                            >
                                <span class="font-bold">Error Message</span>
                                {{log.errorMessage}}
                            </div>
                            <div
                                *ngIf="log.traceJson"
                                class=" white-space: pre-wrap"
                            >
                                <span class="font-bold">Trace Json</span>
                                {{log.traceJson}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</div>

<ng-template
    #emptyMessage
    pTemplate="emptymessage"
>
    <p class="empty-message">
        No Logs found.
    </p>
</ng-template>

<p-dialog
    header="Chat Log"
    [(visible)]="MSShowChatLog"
    styleClass="w-90vw h-full"
    [modal]="true"
    [draggable]="false"
>
    <moon-chat-log
        *ngIf="MSShowChatLog"
        [MSChatLogID]="MSShowChatLogIDForOutput"
    ></moon-chat-log>
</p-dialog>