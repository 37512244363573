<div class="flex flex-column align-items-center gap-1">
    <div class="flex flex-column align-items-center">
        <a
            *ngIf="MSPdfThumbnailPage"
            class="moon-thumnail-image-container relative"
            [ngClass]="{'marked': MSIsMarkedPage}"
            id="moon-thumbnail-page-{{MSPdfThumbnailPage.page}}"
        >
            <img
                src="{{MSPdfThumbnailPage.url}}"
                [alt]="MSPdfThumbnailPage.page"
                class="moon-thumbnail-image"
            />
        </a>
        <div class="flex justify-content-between align-items-center absolute h-full w-full">
            <button
                tabindex="-1"
                pButton
                pRipple
                class="p-button-text text-white"
                icon="pi pi-arrow-left"
                (click)="MSOnClickPreviousPage()"
            ></button>
            <button
                tabindex="-1"
                pButton
                pRipple
                class="p-button-text text-white"
                icon="pi pi-arrow-right"
                (click)="MSOnClickNextPage()"
            ></button>
        </div>
    </div>

    <div class="flex justify-content-center">
        <button
            #markbutton
            tabindex="-1"
            pButton
            pRipple
            class="p-button-raised"
            [label]="MSIsMarkedPage ? 'Unmark': 'Mark'"
            (click)="MSMarkAsExceptionPage();markbutton.blur()"
        ></button>
    </div>
</div>