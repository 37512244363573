import { Injectable }               from "@angular/core";
// MS Imports
import { ApiBlobResult, ApiContentResult, ApiResult
  }                        from '@moon-core/models/api-result';
import { MoonApiClient }            from '@moon-core/services/moon-api.client';
import { ConstantString }           from "@moon-shared/constants/constant-string";
import { SurveyFind }               from "@moon-consumer/api/response/survey-find.response";
import { FindSurveyParameter }      from "@moon-consumer/api/request/find-survey.parameter";
import { RoutePath } from "@moon-shared/constants/route-path";

@Injectable()
export class SurveyController {
  
  private getBaseUrl(): string {
    return this._moonApi.getBaseUrl(ConstantString.Survey);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Public/v1/Airplane/find
 */
  public async Find(findParameter: FindSurveyParameter
  ): Promise<ApiContentResult<SurveyFind[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }

  /**
   *   POST: Public/v1/Survey
  */
  public async Upload(inputFile: File
  ): Promise<ApiBlobResult> {

    const formData = new FormData();
    if (inputFile) {
      formData.set('inputFile', inputFile);
    }
    const url = this.getBaseUrl();
    return this._moonApi.getBlobWithPostAsync(url, formData);
  }
  
  /**
   *   GET: Public/v1/Survey/{surveyID}/Blob/Uploaded
   */
  public async GetBlobUploaded(surveyID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), surveyID, RoutePath.Blob, RoutePath.Uploaded);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   GET: Public/v1/Survey/{surveyID}/Blob/ConvertedImage
   */
  public async GetBlobConvertedImage(surveyID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), surveyID, RoutePath.Blob, RoutePath.ConvertedImage);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   GET: Public/v1/Survey/{surveyID}/Blob/WordSummary
   */
  public async GetBlobWordSummary(surveyID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), surveyID, RoutePath.Blob, RoutePath.WordSummary);
    return this._moonApi.getBlobAsync(url);
  }

  
  /**
   *   DELETE: Public/v1/Survey/{surveyID}
   */
  public async Delete(surveyID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), surveyID);
    return this._moonApi.deleteAsync(url);
  }
}
