<div class="pages-body error-page flex flex-column">
    <div class="layout-topbar-dark">
        <div class="layout-topbar p-3 flex justify-content-between flex-row align-items-center">
            <div class="topbar-left ml-3 flex">
                <a
                    href="/"
                    class="logo"
                >
                    <img
                        class="moon-logo"
                        src="assets/layout/images/logo-dark.png"
                        alt=""
                    />
                </a>
            </div>
        </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>{{MSErrorHeading}}</h2>
            </div>
            <div class="card mt-3 px-6">
                <img
                    src="assets/layout/images/pages/error.png"
                    alt=""
                />
            </div>
            <div class="pages-detail pb-6 white-space-pre-wrap">{{MSErrorDetail}}</div>
            <button
                pButton
                pRipple
                type="button"
                label="GO BACK"
                (click)="MSGoBack()"
                class="p-button-text"
            ></button>
        </div>
    </div>
</div>