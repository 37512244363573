// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
import { NgIf }                                   from '@angular/common';
import { Component, OnInit, ViewChild }           from '@angular/core';
import { ActivatedRoute }                         from '@angular/router';
// Thrid Party Imports
import { MenuItem }                               from 'primeng/api';
import { ButtonModule }                           from 'primeng/button';
import { ProgressBarModule }                      from 'primeng/progressbar';
import { DialogService }                          from 'primeng/dynamicdialog';
// Moon Imports             
import { AppBreadcrumbService }                   from '@app-layout/services/app.breadcrumb.service';
import { MoonMessageService }                     from '@moon-shared/services/moon-message.service';
import { MessageSeverity }                        from '@moon-shared/constants/message-severity';
import { MoonDialogService }                      from '@moon-shared/services/moon-dialog.service';
import { MoonLoadingComponent }                   from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                         from '@moon-shared/constants/constant-string';
import { FieldNames }                             from '@moon-shared/constants/field-names';

import { ApiContentResult }                       from '@moon-core/models/api-result';
import { ComponentUtilityService }                from '@moon-core/services/component-utility.service';

import { MezzLoanChunkRevisionGet }               from '@moon-public/api/response/mezz-loan-chunk-revision-get.response';
import { PublicRouter }                           from '@moon-public/public.router';
import { MezzLoanController }                     from '@moon-public/api/mezz-loan.controller';
import { MezzLoanChunkFind }                      from "@moon-public/api/response/mezz-loan-chunk-find.response";
import { MezzLoanChunkTableComponent }            from '@moon-public/mezz-loan-chunk/mezz-loan-chunk-table/mezz-loan-chunk-table.component';
import { MezzLoanChunkBatchProcessService }       from '@moon-public/mezz-loan-chunk/services/mezz-loan-chunk-batch-process.service';
import { MezzLoanChunkBatch }                     from '@moon-public/api/request/mezz-loan-chunk-batch.request';

@Component({
  selector: 'mezz-loan-chunk',
  templateUrl: './mezz-loan-chunk.component.html',
  styleUrls: ['./mezz-loan-chunk.component.css'],
  standalone: true,
  imports: [
    NgIf,
    ButtonModule, ProgressBarModule,
    MoonLoadingComponent,
    MezzLoanChunkTableComponent
  ],
  providers: [
    DialogService, MoonDialogService,
    MezzLoanController,
    PublicRouter,
    MezzLoanChunkBatchProcessService
  ]
})
export class MezzLoanChunkComponent implements OnInit {

  public MSMezzLoanID: number | null;
  public MSMezzLoanChunkList: MezzLoanChunkFind[];
  public MSLoading: boolean = true;
  public MSPageTitle: string = ConstantString.MezzLoanChunk.getDisplayName();
  public MSEnvironmentName = String.empty;

  @ViewChild("MSTableComponent") MSTableComponent: MezzLoanChunkTableComponent;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _mezzLoanController: MezzLoanController,
    private _publicRouter: PublicRouter,
    private _moonMessageService: MoonMessageService,
    private _mezzLoanChunkBatchProcessService: MezzLoanChunkBatchProcessService,
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSMezzLoanID) {
      await this.loadMezzLoanChunkList(this.MSMezzLoanID);
    }
  }

  public get MSProcessMessage(): string {
    return this._mezzLoanChunkBatchProcessService.ProcessMessage;
  }

  public get MSIsProcessInProgress(): boolean {
    return this._mezzLoanChunkBatchProcessService.DisplayProgressBar;
  }

  private get _selectedItems(): MezzLoanChunkFind[] {
    return this.MSTableComponent?.GetSelectedItems();
  }

  public get MSSSelectedItemsLength(): number {
    if (this.MSTableComponent && this.MSTableComponent?.GetSelectedItems()) return this.MSTableComponent?.GetSelectedItems()?.length;
    else return 0;
  }

  public get MSSSelectedItemsTokenSize(): number {
    let totalTokenSize: number = 0;
    if (this.MSTableComponent && this.MSTableComponent.GetSelectedItems()) {
      this.MSTableComponent?.GetSelectedItems().forEach(a => {
        totalTokenSize += a.tokenSize ?? 0;
      });
    } 
    return totalTokenSize;
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);

    const pageBreadcrumb: MenuItem[] = [
      { label: ConstantString.MezzLoan.getDisplayName(), routerLink: [`${this._publicRouter.MezzLoanRouteLink()}`] },
      { label: this.MSPageTitle, routerLink: null },
    ];
    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }

  private readRouteParameters() {
    const mezzLoanID = this._activatedRoute.snapshot.paramMap.get(FieldNames.MezzLoanID);
    this.MSMezzLoanID = mezzLoanID ? +mezzLoanID : null;
  }

  private async loadMezzLoanChunkList(mezzLoanID: number) {
    this.MSLoading = true;
    const apiResult: ApiContentResult<MezzLoanChunkRevisionGet[]> = await this._mezzLoanController.GetListRevisionAsync(mezzLoanID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSMezzLoanChunkList = apiResult.content;
    }
    this.MSLoading = false;
  }

  public MSReloadPage() {
    if (this.MSMezzLoanID == null) return;
    this.loadMezzLoanChunkList(this.MSMezzLoanID);
  }

  public async OnRunPromptClick() {
    if (!this._selectedItems?.length) {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Please Select Item.");
      return;
    }

    await this._mezzLoanChunkBatchProcessService.PromptMezzLoanChunk(this.MSMezzLoanID ?? 0, this._selectedItems);
    this.MSReloadPage();
  }

  public async OnRunPromptBatchClick() {
    if (!this._selectedItems?.length) {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Please Select Item.");
      return;
    }

    const input = new MezzLoanChunkBatch();
    input.mezzLoanChunkIDList = this._selectedItems.map(s => s.mezzLoanChunkID);
    await this._mezzLoanController.ReviseChunkBatchAsync(input);
    this.MSReloadPage();
  }

}
