// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import { Injectable }                 from "@angular/core";
// MS Imports
import { ApiResult }                  from '@moon-core/models/api-result';
import { MoonApiClient }              from '@moon-core/services/moon-api.client';
import { SupportMessage }             from "@moon-public/api/request/support-message.request";
import { ConstantString }             from "@moon-shared/constants/constant-string";
import { RoutePath }                  from "@moon-shared/constants/route-path";

@Injectable()
export class SupportController {

  private getBaseUrl(): string {
    return this._moonApi.getStandardUrl(ConstantString.Support);
  }

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   POST: Public/v1/Support/Contact
   */
  public async Contact(supportMessage: SupportMessage)
    : Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), RoutePath.Contact);
    return this._moonApi.postAsync(url, supportMessage);
  }

}
