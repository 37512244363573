import { Inject, Injectable } from "@angular/core";
// Third party imports
import {
    MSAL_GUARD_CONFIG, MsalBroadcastService,
    MsalGuardConfiguration, MsalService
} from "@azure/msal-angular";
import {
    AccountInfo, AuthenticationResult, EventMessage, EventType, PopupRequest, SsoSilentRequest, IdTokenClaims, InteractionStatus,
    RedirectRequest,
    PromptValue,
    InteractionType,
    ServerError
} from "@azure/msal-browser";
import { Subject, filter, takeUntil } from "rxjs";
// MS Imports
import { MoonAppState } from "@app/moon-app-state";
import { AzureConnectionDetail } from "@moon-core/models/azure-connection-detail.model";
import { MoonApplicationInsight } from "@moon-core/services/moon-application-insight.service";
import { Diagnostic } from "@moon-core/models/diagnostic.model";
import { MoonAppConfigService } from "@moon-core/services/moon-app-config.service";
import { MoonB2CPoliciesConfig, MoonMsalConfig } from "@moon-core/api/response/moon-environment-config.response";
import { Router } from "@angular/router";
import { RoutePath } from "@moon-shared/constants/route-path";

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};

@Injectable()
export class MsalWrapperService {
    private readonly _id: string;
    private readonly _msalConfig: MoonMsalConfig;
    private loginDisplay = false;
    private _azureConnectionDetail: AzureConnectionDetail | null = null;
    private readonly _destroying$ = new Subject<void>();
    public MSDiagnostic: Diagnostic;
    public IsMoonstoneB2C: boolean = false;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private _config: MoonAppConfigService,
        private _moonAppState: MoonAppState,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private _moonApplicationInsight: MoonApplicationInsight,
        private _router: Router,
    ) {
        this._id = String.currentTimeStamp();
        this._msalConfig = this._config.GetMsalConfig();
        this.checkAndSetForMoonstoneB2C();
        this._moonAppState.setMsalWrapperId(this._id);
    }

    private checkAndSetForMoonstoneB2C() {
        this.IsMoonstoneB2C = this._msalConfig.b2cPolicies != null;
    }

    public Initialize(): void {

        this.authService.handleRedirectObservable().subscribe();

        /**
         * You can subscribe to MSAL events as shown below. For more info,
         * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
         */
        this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe((result: EventMessage) => {
                console.log(result);
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });

        if (this.IsMoonstoneB2C) {
            this.HandleB2CEvents(this._msalConfig.b2cPolicies);
        }
    }

    private HandleB2CEvents(b2cPolicies: MoonB2CPoliciesConfig): void {

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                    || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                    || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {

                const payload = result.payload as AuthenticationResult;
                const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

                if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
                    this.authService.instance.setActiveAccount(payload.account);
                }

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
                 * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
                 * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {

                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = this.authService.instance.getAllAccounts()
                        .find((account: AccountInfo) =>
                            account.idTokenClaims?.oid === idtoken.oid
                            && account.idTokenClaims?.sub === idtoken.sub
                            && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
                                || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
                        );

                    const signUpSignInFlowRequest: SsoSilentRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount!
                    };

                    // silently login again with the signUpSignIn policy
                    this.authService.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow (see above ln. 74-92).
                 */
                if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
                    const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
                        authority: this._msalConfig.b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [...this._msalConfig.scope],
                        prompt: PromptValue.LOGIN // force user to reauthenticate with their new password
                    };

                    this.login(signUpSignInFlowRequest);
                }

                return result;
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                    const resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
                        authority: b2cPolicies.authorities.resetPassword.authority,
                        scopes: [],
                    };
                    
                    this.login(resetPasswordFlowRequest);
                }
                else if ('ServerError'.equalsIgnoreCase(result?.error?.name) ) {
                    this.handleMsalServerError(result.error as ServerError);
                }
                else{
                    console.error(result.error);
                    this._moonApplicationInsight.logException(result.error as Error);
                }
            });
    }

    private handleMsalServerError(error: ServerError) {
        this._router.navigate([RoutePath.Error], {state: {msalError: error}})
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
        console.log(this.loginDisplay);
    }

    private checkAndSetActiveAccount(): void {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();
        if (activeAccount) {
            this.setAzureConnectionDetail(activeAccount);
        }
        else if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            const accounts: AccountInfo[] = this.authService.instance.getAllAccounts();
            // add your code for handling multiple accounts here
            this.authService.instance.setActiveAccount(accounts[0] ?? null);
            this.setAzureConnectionDetail(accounts[0] ?? null);
        }
        else {
            // handle case when no account found
        }
    }

    public get GetLoggedUserName(): string {
        return this._azureConnectionDetail?.azureUserName ?? "Not Authenticated";
    }

    public get GetLoggedUserFullName(): string {
        return this._azureConnectionDetail?.azureFullName ?? "Not Authenticated";
    }

    private setAzureConnectionDetail(activeAccount: AccountInfo | null): void {

        if (activeAccount) {
            this._azureConnectionDetail = {
                azureUserName: activeAccount.username,
                azureFullName: activeAccount.name ?? String.empty,
                accountIdentifier: activeAccount.localAccountId
            };
            this._moonApplicationInsight.SetAzureUserName(activeAccount.username);
        }
        else {
            this._azureConnectionDetail = null;
        }
    }

    public ConnectToAzure(): void {
        this._moonApplicationInsight.logEvent("Azure Session: INITIATED");
        this.loginRedirect();
    }

    public DisconnectFromAzure(): void {
        this._moonApplicationInsight.logEvent("Azure Session: END");
        this.logout();
    }

    public IsConnectedToAzure(): boolean {
        return this._azureConnectionDetail ? true : false;
    }

    public GetAccessToken(): string {
        return "No token";
    }

    private loginRedirect() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService.loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    private logout(popup?: boolean) {
        const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

        if (activeAccount) {
            if (popup) {
                this.authService.logoutPopup({
                    mainWindowRedirectUri: "/"
                });
            } else {
                this.authService.logoutRedirect();
            }
            this.setAzureConnectionDetail(null);
        }
    }

    // unsubscribe to events when component is destroyed
    public CleanUp(): void {

        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
