
import { ApiResult } from '@moon-core/models/api-result';

export class CallResult extends ApiResult {

  public setFailure(message: string): void {
    this.success = false;
    this.message = message;
  }

  public setSuccess(): void {
    this.success = true;
    this.message = String.empty;
  }

  public parseApiResult(apiResult: ApiResult): boolean {
    this.message = apiResult.message;
    this.success = apiResult.success;
    this.apiError = apiResult.apiError;
    return this.success;
  }
}

export class CallContentResult<T> extends CallResult {    
    content: T;
}
