// Moon Auto Generated (1.0.0) at 12/7/2023 9:19:24 AM
import { inject }                                       from "@angular/core";
import { Route, Router }                                from "@angular/router";
//Third Party Imports
import { MsalGuard }                                    from "@azure/msal-angular";
// MS Imports
import { AddAirplaneWizardComponent }                   from '@moon-consumer/airplane/add-airplane-wizard/add-airplane-wizard.component';
import { AirplaneMarkTermPageComponent }                from "@moon-consumer/airplane/add-airplane-wizard/airplane-mark-term-page/airplane-mark-term-page.component";
import { AirplaneProcessComponent }                     from "@moon-consumer/airplane/add-airplane-wizard/airplane-process/airplane-process.component";
import { AirplaneUploadFileComponent }                  from "@moon-consumer/airplane/add-airplane-wizard/airplane-upload-file/airplane-upload-file.component";
import { AirplaneComponent }                            from '@moon-consumer/airplane/airplane.component';
import { SurveyComponent }                              from '@moon-consumer/survey/survey.component';
import { SurveyUploadFileComponent }                    from '@moon-consumer/survey/survey-upload/survey-upload-file.component';
import { AddTitleCommitmentWizardComponent }            from "@moon-consumer/title-commitment/add-title-commitment-wizard/add-title-commitment-wizard.component";
import { TitleCommitmentAddEndorsementComponent }       from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-add-endorsement/title-commitment-add-endorsement.component";
import { TitleCommitmentAddMatterNameComponent }        from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-add-matter-name/title-commitment-add-matter-name.component";
import { TitleCommitmentFinalComponent }                from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-final/title-commitment-final.component";
import { TitleCommitmentHyperlinksComponent }           from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-hyperlinks/title-commitment-hyperlinks.component";
import { TitleCommitmentMarkExceptionPageComponent }    from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-mark-exception-page/title-commitment-mark-exception-page.component";
import { TitleCommitmentSelectPolicyComponent }         from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-select-policy/title-commitment-select-policy.component";
import { TitleCommitmentUploadFileComponent }           from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-upload-file/title-commitment-upload-file.component";
import { TitleDocumentManualUploadComponent }           from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-document-manual-upload/title-document-manual-upload.component";
import { TitleCommitmentComponent }                     from "@moon-consumer/title-commitment/title-commitment.component";
import { UserSessionGuard }                             from "@moon-core/guards/user-session.guard";
import { UserOptionService }                            from "@moon-core/services/user-option.service";
import { TitleCustomerPageComponent }                   from "@moon-consumer/title-commitment/title-customer-page/title-customer-page.component";
import { DefaultValues }                                from "@moon-shared/constants/default-values";
import { RouteParameter }                               from "@moon-shared/constants/route-parameter";
import { RoutePath }                                    from "@moon-shared/constants/route-path";

// This is a temporary route guard until airplane terms is finalized
const AirplaneRouteGuard = () => {
    const airplaneTermsOptionValue = inject(UserOptionService).MSUserOption.ShowAirplaneTerms;
    if(DefaultValues.Y.equalsIgnoreCase(airplaneTermsOptionValue)){
        return true
    } 
    inject(Router).navigate([RoutePath.Error], { skipLocationChange: true });
    return false;
};
const TitleCommitmentRouteGuard = () => { 
    const titleCommitmentOptionValue = inject(UserOptionService).MSUserOption.ShowTitleCommitment;
    if(DefaultValues.Y.equalsIgnoreCase(titleCommitmentOptionValue)){
        return true
    } 
    inject(Router).navigate([RoutePath.Error], { skipLocationChange: true });
    return false;
};
export const ConsumerRoutes: Route[] = [
    {
        path: String.createRoute(RoutePath.TitleCustomerPage, RouteParameter.TitleSummaryID.toRouteParam()),
        canActivate: [MsalGuard, UserSessionGuard, TitleCommitmentRouteGuard],
        component: TitleCustomerPageComponent
    },
    {
        path: `${RoutePath.TitleCommitmentsSimple}`,
        canActivate: [MsalGuard, UserSessionGuard, TitleCommitmentRouteGuard],
        component: TitleCommitmentComponent
    },
    {
        path: `${RoutePath.TitleCommitments}/${RoutePath.Add}`, component: AddTitleCommitmentWizardComponent,
        canActivate: [MsalGuard, UserSessionGuard, TitleCommitmentRouteGuard],
        children: [
            { path: String.empty, redirectTo: RoutePath.CommitmentType, pathMatch: 'full' },
            { path: RoutePath.Endorsement, component: TitleCommitmentAddEndorsementComponent },
            { path: RoutePath.CommitmentType, component: TitleCommitmentSelectPolicyComponent },
            { path: RoutePath.MatterName, component: TitleCommitmentAddMatterNameComponent },
            { path: RoutePath.UploadFile, component: TitleCommitmentUploadFileComponent },
            { path: RoutePath.Hyperlinks, component: TitleCommitmentHyperlinksComponent },
            { path: RoutePath.MarkScheduleOfExceptions, component: TitleCommitmentMarkExceptionPageComponent },
            { path: RoutePath.UploadTitleDocument, component: TitleDocumentManualUploadComponent },
            { path: RoutePath.Final, component: TitleCommitmentFinalComponent },
        ]
    },
    {
        path: RoutePath.Airplane,
        canActivate: [MsalGuard, UserSessionGuard, AirplaneRouteGuard],
        children: [
            { path: RoutePath.Empty, component: AirplaneComponent },
            {
                path: RoutePath.Add, component: AddAirplaneWizardComponent,
                children: [
                    { path: String.empty, redirectTo: RoutePath.UploadFile, pathMatch: 'full' },
                    { path: RoutePath.UploadFile, component: AirplaneUploadFileComponent },
                    { path: RoutePath.MarkTermPage, component: AirplaneMarkTermPageComponent },
                    { path: RoutePath.Process, component: AirplaneProcessComponent },
                ]
            },
        ]
    },
    {
        path: RoutePath.Survey,
        canActivate: [MsalGuard, UserSessionGuard],
        children: [
            { path: RoutePath.Empty, component: SurveyComponent },
            { path: RoutePath.Add, component: SurveyUploadFileComponent }
        ]
    }
    // Placeholder for route
];
