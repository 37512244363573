import { Injectable }   from "@angular/core";
import { Router }       from "@angular/router";
import { RoutePath } from "@app/moon-shared/constants/route-path";
import { ConsumerRouter } from "@moon-consumer/consumer.router";
import { MsalWrapperService } from "@moon-core/services/msal-wrapper-service";
import { UserOptionService } from "@moon-core/services/user-option.service";
import { UserSessionService } from "@moon-core/services/user-session.service";
import { MaintainerRouter } from "@moon-maintainer/maintainer.router";
import { DefaultValues } from "@moon-shared/constants/default-values";

@Injectable()
export class AppRouter {
    constructor(
        private _router: Router, 
        private _msalWrapperService: MsalWrapperService, 
        private _userSessionService: UserSessionService,
        private _userOptionService: UserOptionService,
        private _consumerRouter: ConsumerRouter,
        private _maintainerRouter: MaintainerRouter
    ) { }

    public RouteToLogin() {
        this._router.navigate([RoutePath.Login], {
            state: { bypassUnsavedChangeGuard: true },
        });
    }

    public RouteToConnectDatabase() {
        this._router.navigate([RoutePath.ConnectDatabase], {
            state: { bypassUnsavedChangeGuard: true },
        });
    }

    public RouteToPostLogout() {
        this._router.navigate([RoutePath.PostLogout], {
            state: { bypassUnsavedChangeGuard: true },
        });
    }

    public RouteToContactSupportPage() {
        this._router.navigate([RoutePath.ContactSupport]);
    }

    public RouteToConnectUserPage(environmentName: string) {
        this._router.navigate([RoutePath.ConnectUser, environmentName]);
    }

    public RouteToDisclaimerPage() {
        this._router.navigate([RoutePath.DisclaimerAgreement]);
    }

    public RouteToTitleCommitment() {
        this._router.navigate([RoutePath.TitleCommitmentsAdvanced]);
    }

    public RouteToDefault() {
        if (this._msalWrapperService.IsMoonstoneB2C) {
            if (DefaultValues.Y.equalsIgnoreCase(this._userOptionService.MSUserOption.ShowTitleCommitment)) {
                this._consumerRouter.RouteToTitleCommitmentsSimple();
            }
            else if (DefaultValues.Y.equalsIgnoreCase(this._userOptionService.MSUserOption.ShowAirplaneTerms)) {
                this._consumerRouter.RouteToAirplane();
            }
            else {
                this._router.navigate([RoutePath.Error], {skipLocationChange: true});
            }
        }
        else if (this._userSessionService.IsMaintainer() && DefaultValues.Y.equalsIgnoreCase(this._userOptionService.MSUserOption.ShowTitleCommitment)) {
            this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
        }
        else {
            this._router.navigate([RoutePath.Empty]);
        }
    }
}
