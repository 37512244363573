import { Injectable }                     from "@angular/core";
// MS Imports
import { ApiContentResult, ApiResult }    from '@moon-core/models/api-result';
import { MoonApiClient }                  from '@moon-core/services/moon-api.client';
import { SearchParameter }                from "@moon-public/clause-search/api/request/search.parameter";
import { SearchResult }                   from "@moon-public/clause-search/api/response/search-result.response";
import { UpdateFavoriteRequest }          from "@moon-public/clause-search/api/request/update-favorite.request";
import { ConstantString }                 from '@moon-shared/constants/constant-string';

@Injectable()
export class SearchApiService {
  private getBaseUrl(): string {
    return `${ConstantString.StandardVersion1}/${ConstantString.Search}`;
  }

  constructor(private _moonApi: MoonApiClient) { }
  /**
   * POST: /v1/Search
   */
  public async PostSearch(searchParameter: SearchParameter): Promise<ApiContentResult<SearchResult>> {
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, searchParameter);
  }

  /**
   *  PUT: /v1/Search/{environmentName}/Chunk/{documentChunkID}/UpdateFavorite
   */
  public async UpdateFavorite(environmentName: string, documentChunkID: number, favoriteChunkForm: UpdateFavoriteRequest): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(
      this.getBaseUrl(), environmentName, ConstantString.Chunk, documentChunkID, ConstantString.UpdateFavorite
    );
    return this._moonApi.putAsync(url, favoriteChunkForm);
  }

}