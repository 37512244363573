import { CommonModule }             from '@angular/common';
import { Component, OnInit }        from '@angular/core';
import { ActivatedRoute }           from '@angular/router';
import { filter }                   from 'rxjs';
import { AppRouter }                from '@app/app.router';
// Third party imports
import { MsalBroadcastService }     from '@azure/msal-angular';
import { EventMessage, EventType }  from '@azure/msal-browser';
// Moon Imports
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { MsalWrapperService }       from '@moon-core/services/msal-wrapper-service';
import { UserOptionService }        from '@moon-core/services/user-option.service';
import { UserSessionService }       from '@moon-core/services/user-session.service';

import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { DefaultValues }            from '@moon-shared/constants/default-values';
import { RouteParameter }           from '@moon-shared/constants/route-parameter';
import { RoutePath }                from '@moon-shared/constants/route-path';
import { MaintainerRouter }         from '@moon-maintainer/maintainer.router';

@Component({
  selector: 'moon-external',
  standalone: true,
  imports: [CommonModule, MoonLoadingComponent],
  templateUrl: './moon-external.component.html',
  styleUrls: ['./moon-external.component.scss']
})
export class MoonExternalComponent implements OnInit {
  private _requestRoutePage: string;
  constructor(
    private _appRouter: AppRouter,
    private _maintainerRouter: MaintainerRouter,
    private _activatedRoute: ActivatedRoute,
    private _msalBroadcastService: MsalBroadcastService,
    private _msalWrapperService: MsalWrapperService,
    private _userSessionService: UserSessionService,
    private _userOptionService: UserOptionService,
    private _componentUtilityService: ComponentUtilityService
  ) {
  }
  ngOnInit(): void {
    this.readRouteParameters();
    this.tryConnectUser();
  }

  private readRouteParameters() {
    this._requestRoutePage = this._activatedRoute.snapshot.paramMap.get(RouteParameter.ExternalRoutePage) ?? String.empty;
  }

  private async tryConnectUser(): Promise<void> {
    if (this._msalWrapperService.IsConnectedToAzure() === false) {
      this.doConnectToAzure();
      return;
    }
    if (this._userSessionService.IsConnectedToDatabase() === false) {
      this.dbConnectUser(DefaultValues.Y)
      return;
    }

    this.routeToRequestedPage()
  }


  private doConnectToAzure() {
    this._msalWrapperService.ConnectToAzure();
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((_: any) => {
        this.dbConnectUser(DefaultValues.Y);
      });
  }

  private async dbConnectUser(forceDisconnect: string) {
    const connectResult = await this._userSessionService.ConnectUser(forceDisconnect);
    if (this._componentUtilityService.WasSuccessful(connectResult)) {
      await this._userOptionService.SetUserOptionMapList();
      this.routeToRequestedPage();
    }
    else {
      this._appRouter.RouteToLogin();
    }
  }

  private routeToRequestedPage() {
    if (RoutePath.TitleCommitments.equalsIgnoreCase(this._requestRoutePage)) {
      this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
    } else {
      this._appRouter.RouteToDefault();
    }
  }
}
