<moon-loading *ngIf="MSLoading"></moon-loading>
<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <moon-survey-table
        [MSSurveyList]="MSSurveyList"
        [MSLoading]="MSLoading"
        (MSOnEmitReload)="MSOnEmitReload()"
    ></moon-survey-table>
</div>