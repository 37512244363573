// Moon Auto Generated (1.0.0) at 11/26/2023 11:22:45 PM
import {
    DatePipe, NgClass, NgFor, NgIf, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                            from "@angular/common";
import {
    Component, EventEmitter, Input, OnInit,
    Output,
    ViewChild, ViewEncapsulation
}                                            from "@angular/core";
import { FormsModule }                       from "@angular/forms";
// Third Party imports
import { SelectItem }                        from "primeng/api";
import { CalendarModule }                    from "primeng/calendar";
import { DialogModule }                      from "primeng/dialog";
import { DialogService }                     from "primeng/dynamicdialog";
import { MultiSelectModule }                 from "primeng/multiselect";
import { Table, TableModule }                from "primeng/table";
// Moon imports
import { FindChatLogParameter }              from "@moon-maintainer/api/request/find-chat-log.parameter";
import { ChatLogFind }                       from "@moon-maintainer/api/response/chat-log-find.response";
import { ChatLogComponent }                  from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { MoonCustomDropdownComponent }       from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonTableHeaderComponent }          from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                        from '@moon-shared/constants/field-names';
import { MoonTemplateDirective }             from "@moon-shared/directives/moon-template.directive";
import { MoonDatePipe }                      from "@moon-shared/pipes/moon-date.pipe";
import { TableColumn }                       from '@moon-shared/types/table-column.type';

@Component({
    selector: "chat-log-table",
    templateUrl: "./chat-log-table.component.html",
    styleUrls: ['./chat-log-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        TableModule, MultiSelectModule, FormsModule, CalendarModule, DialogModule,
        MoonDatePipe, MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, DatePipe, MoonTemplateDirective, ChatLogComponent
    ],
    providers: [DialogService]
})
export class ChatLogTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSChatLogList: ChatLogFind[];
    @Input() public MSLoading = true;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.ChatLogID.toCamelCase(), header: FieldNames.ChatLogID.getDisplayName(), class: 'w-5per'},
		{field: FieldNames.UserSessionID.toCamelCase(), header: FieldNames.UserSessionID.getDisplayName(), class: 'w-5per'},
		{field: FieldNames.InputData.toCamelCase(), header: FieldNames.InputData.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.OutputData.toCamelCase(), header: FieldNames.OutputData.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.OverrideData.toCamelCase(), header: FieldNames.OverrideData.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ActionName.toCamelCase(), header: FieldNames.ActionName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ActionContext.toCamelCase(), header: FieldNames.ActionContext.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.EntityType.toCamelCase(), header: FieldNames.EntityType.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.EntityID.toCamelCase(), header: FieldNames.EntityID.getDisplayName(), class: 'w-35per'},
		{field: FieldNames.SkillNumber.toCamelCase(), header: FieldNames.SkillNumber.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.SkillType.toCamelCase(), header: FieldNames.SkillType.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.SkillName.toCamelCase(), header: FieldNames.SkillName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.SkillVersion.toCamelCase(), header: FieldNames.SkillVersion.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.SkillMessageHashMD5.toCamelCase(), header: FieldNames.SkillMessageHashMD5.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ModelType.toCamelCase(), header: FieldNames.ModelType.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.DeploymentName.toCamelCase(), header: FieldNames.DeploymentName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.MaxOutputTokens.toCamelCase(), header: FieldNames.MaxOutputTokens.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.SystemTokens.toCamelCase(), header: FieldNames.SystemTokens.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ExampleTokens.toCamelCase(), header: FieldNames.ExampleTokens.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.InputTokens.toCamelCase(), header: FieldNames.InputTokens.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.RequestTokens.toCamelCase(), header: FieldNames.RequestTokens.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.RequestCost.toCamelCase(), header: FieldNames.RequestCost.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ResponseTokens.toCamelCase(), header: FieldNames.ResponseTokens.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ResponseCost.toCamelCase(), header: FieldNames.ResponseCost.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.TotalCost.toCamelCase(), header: FieldNames.TotalCost.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.Temperature.toCamelCase(), header: FieldNames.Temperature.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.TopP.toCamelCase(), header: FieldNames.TopP.getDisplayName(), class: 'w-5per'},
		{field: FieldNames.FrequencyPenalty.toCamelCase(), header: FieldNames.FrequencyPenalty.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.PresencePenalty.toCamelCase(), header: FieldNames.PresencePenalty.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.StopSequence.toCamelCase(), header: FieldNames.StopSequence.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.DurationInMs.toCamelCase(), header: FieldNames.DurationInMs.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.HasOverride.toCamelCase(), header: FieldNames.HasOverride.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ErrorMessage.toCamelCase(), header: FieldNames.ErrorMessage.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.TraceJson.toCamelCase(), header: FieldNames.TraceJson.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.InputFormat.toCamelCase(), header: FieldNames.InputFormat.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.FileOutputFormat.toCamelCase(), header: FieldNames.FileOutputFormat.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.MultipleFileProcessing.toCamelCase(), header: FieldNames.MultipleFileProcessing.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.TraceJson.toCamelCase(), header: FieldNames.TraceJson.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.LastUpdated.toCamelCase(), header: FieldNames.LastUpdated.getDisplayName(), class: 'w-25per'},

        // Column PlaceHolder - DO NOT REMOVE
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSSkillTypeSelectItems: SelectItem[] = [];
    public MSEntityTypeSelectItems: SelectItem[] = [];
    @Input() public MSFindChatLogParameter: FindChatLogParameter;
    @Output() public MSFindChatLogParameterChange: EventEmitter<FindChatLogParameter> = new EventEmitter();
    @ViewChild("MSTable") MSTable: Table;
    public MSShowChatLog: boolean = false;
    public MSChatLogID: number | null;
    constructor(
        public DialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = false;
        this.MSDisplayFind = false;
        this.setSelectItemOptions();
    }

    public async MSOnClickItem(data: ChatLogFind): Promise<void> {
        this.MSChatLogID = data.chatLogID;
        this.MSShowChatLog = true;
    }
    
    public OnSearchInput(searchInput: string): void {
        this.MSTable.filterGlobal(searchInput, "contains");
    }
    public MSOnClickDateFilterSearch(): void {
        this.MSFindChatLogParameterChange.emit(this.MSFindChatLogParameter);
    }
    private setSelectItemOptions(): void {
        if (this.MSChatLogList) {
            const setSkillType = [...new Set(this.MSChatLogList.map(item => item.skillType))];
            const setEntityType = [...new Set(this.MSChatLogList.map(item => item.entityType))];
            this.MSSkillTypeSelectItems = this.setItems(setSkillType);
            this.MSEntityTypeSelectItems = this.setItems(setEntityType);
        }
    }

    private setItems(setOfItems: (string | null)[]): SelectItem[] {
        const selectItems: SelectItem[] = [];
        setOfItems.map((element) => {
            selectItems.push({
                label: String(element),
                value: String(element)
            });
        });
        return selectItems;
    }
}
