import { NgClass, NgIf }                                        from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
// Third party imports
import { ButtonModule }                                         from 'primeng/button';
import { RippleModule }                                         from 'primeng/ripple';
// MS Imports
import { PdfThumbnailPage }                                     from '@moon-maintainer/title-commitment-advanced/title-commitment-pdf';

@Component({
  selector: 'moon-pdf-page-preview',
  standalone: true,
  imports: [
    NgIf, NgClass,
    ButtonModule, RippleModule,
  ],
  templateUrl: './pdf-page-preview.component.html',
  styleUrl: './pdf-page-preview.component.scss'
})
export class PdfPagePreviewComponent {
  @Input() public MSPdfThumbnailPage: PdfThumbnailPage;
  @Input() public MSIsMarkedPage: boolean = false;
  @Output() public MSPreviousPageEvent: EventEmitter<null> = new EventEmitter();
  @Output() public MSNextPageEvent: EventEmitter<null> = new EventEmitter();
  @Output() public MSPageMarkedEvent: EventEmitter<number> = new EventEmitter();
  public MSOnClickPreviousPage(): void {
    this.MSPreviousPageEvent.emit();
  }
  public MSOnClickNextPage(): void {
    this.MSNextPageEvent.emit();
  } 

  @HostListener('window:keyup', ['$event'])
  public MSOnKeyPress($event: KeyboardEvent) {
    if ($event.key === "ArrowRight") {
      this.MSOnClickNextPage();
    }
    else if ($event.key === "ArrowLeft") {
      this.MSOnClickPreviousPage();
    }
    else if ($event.key === "Enter") {
      this.MSMarkAsExceptionPage();
    }
  }
  
  public MSMarkAsExceptionPage(): void {
    // need to manually set the marked page, since the value is not being updated automatically.
    this.MSIsMarkedPage = true;
    this.MSPageMarkedEvent.emit(this.MSPdfThumbnailPage.page);
  }
}
