<moon-form-page
    *ngIf="!MSLoading"
    [MSParentForm]="MSContactCustomerForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    [MSDisplaySaveButton]="false"
    (MSOnClickEventSubmit)="MSOnClickSubmit()"
    (MSOnClickEventCancel)="MSOnClickCancel()"
>
    <div
        [formGroup]="MSContactCustomerForm"
        class="pb-4"
    >
        <div class="text-center"><span>Please describe your issue here</span></div>
        <moon-form-control
            [MSFormControl]="MSContactCustomerForm.controls.message"
            [MSLabel]="'Please describe your issue here'"
            [MSDisplayLabel]="false"
            [MSInputSize]="12"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="message.value"
            >
                <textarea
                    #message
                    pInputTextarea
                    [formControl]="MSContactCustomerForm.controls.message"
                > </textarea>
            </div>
        </moon-form-control>

        <div class="ml-8">
            <moon-form-control
                [MSFormControl]="MSContactCustomerForm.controls.includeSummary"
                [MSLabel]="'Include title summary'"
                [MSLabelSize]="6"
            >
                <p-checkbox
                    [formControl]="MSContactCustomerForm.controls.includeSummary"
                    [binary]="true"
                ></p-checkbox>
            </moon-form-control>
        </div>  

    </div>

    <div class="flex justify-content-center">
        <p-progressBar
             *ngIf="MSIsFormSubmitting"
             mode="indeterminate"
             styleClass="h-1px"
        >
        </p-progressBar>
        
        <div class="flex justify-content-center">
            <button
                type="submit"
                pButton
                pRipple
                label="Submit"
                class="w-init p-button-raised mr-2"
                [disabled]="MSIsFormSubmitting"
            > </button>
            <button
                pButton
                pRipple
                type="button"
                label="Cancel"
                [disabled]="MSIsFormSubmitting"
                class="w-init p-button-raised p-button-secondary"
                (click)="MSOnClickCancel()"
            > </button>
        </div>
    </div>
</moon-form-page>