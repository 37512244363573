<!-- Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                (MSOnClickEventRemove)="OnClickRemove()">
    <ng-container *ngIf="!MSLoading">
        <moon-email-whitelist-form #MSForm
                        [MSEmailWhitelistID]="MSEmailWhitelistID"
                        [MSEmailWhitelistGet]="MSEmailWhitelistGet">
        </moon-email-whitelist-form>
    </ng-container>
</moon-edit-page>
