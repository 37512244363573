import { NgFor }                              from '@angular/common';
import { Component }                          from '@angular/core';
import { FormGroup, ReactiveFormsModule }     from '@angular/forms';
// Third party imports
import { SelectItem }                         from 'primeng/api';
import { ButtonModule }                       from 'primeng/button';
import { DropdownModule }                     from 'primeng/dropdown';
import { RadioButtonModule }                  from 'primeng/radiobutton';
import { RippleModule }                       from 'primeng/ripple';
import { AutoFocusModule }                    from 'primeng/autofocus';
// Moon Imports
import { ConsumerRouter }                     from '@moon-consumer/consumer.router';
import { TitleCommitmentWizardService }       from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { getAllStates }                       from "@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-add-endorsement/states.select-items";
import { TitleCommitmentUpload }              from '@moon-public/api/request/title-commitment.upload';
import { MoonFormControlComponent }           from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { FormGroupOf }                        from '@moon-shared/types/form-group-of.type';
import { MoonEnterKeyupDirective }            from '@moon-shared/directives/moon-enter-keyup.directive';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
@Component({
  selector: 'moon-title-commitment-add-endorsement',
  standalone: true,
  imports: [
    NgFor, ReactiveFormsModule, AutoFocusModule, MoonEnterKeyupDirective, AutoCompleteModule,
    ButtonModule, DropdownModule, RadioButtonModule, RippleModule,
    MoonFormControlComponent,
  ],
  providers: [ConsumerRouter],
  templateUrl: './title-commitment-add-endorsement.component.html',
  styleUrl: './title-commitment-add-endorsement.component.scss'
})
export class TitleCommitmentAddEndorsementComponent {
  public MSStates: SelectItem<string>[] = getAllStates(); 
  public MSFilteredSelectedState: SelectItem[]; 
  public get MSTitleCommitmentForm(): FormGroup<FormGroupOf<TitleCommitmentUpload>> {
    return this._titleCommitmentWizardService.MSTitleCommitmentForm;
  } constructor(
    private _titleCommitmentWizardService: TitleCommitmentWizardService
  ) { }

  public NSFilterState(event: AutoCompleteCompleteEvent) {
    this.MSFilteredSelectedState = this.MSStates.filter((state: SelectItem) => {
      return (
        state.label!
              .toLocaleLowerCase()
              .indexOf(event.query.toLocaleLowerCase()) !== -1
      );
    });
  }

  public MSOnSelectState(selectedItem: SelectItem): void {
    this.MSTitleCommitmentForm.controls.propertyState.setValue(selectedItem.value);
  }
}


