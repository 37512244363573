import { NgIf, SlicePipe }          from '@angular/common';
import { Component, OnInit }        from '@angular/core';
// Third party imports
import { ConfirmationService }      from 'primeng/api';
import { ButtonModule }             from 'primeng/button';
import { ProgressBarModule }        from 'primeng/progressbar';
import { RippleModule }             from 'primeng/ripple';
import { MoonstoneApp }             from 'src/moon-config/moonstone-app';
// MS Imports
import { AppRouter }                from '@app/app.router';
import { DiagnosticApiService }     from '@moon-core/api/diagnostic-api.service';
import { DatabaseDto }              from '@moon-core/api/response/database.response';
import { UserSession }              from '@moon-core/api/response/user-session.response';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { Diagnostic }               from '@moon-core/models/diagnostic.model';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { MsalWrapperService }       from '@moon-core/services/msal-wrapper-service';
import { UserOptionService }        from '@moon-core/services/user-option.service';
import { UserSessionService }       from '@moon-core/services/user-session.service';
import { DefaultValues }            from '@moon-shared/constants/default-values';
import { BooleanString }            from '@moon-shared/types/boolean-string.type';

@Component({
  selector: 'moon-database-connect',
  standalone: true,
  imports: [
    NgIf, SlicePipe,
    ProgressBarModule, ButtonModule, RippleModule,
  ],
  providers: [DiagnosticApiService],
  templateUrl: './database-connect.component.html',
  styleUrl: './database-connect.component.scss'
})
export class DatabaseConnectComponent implements OnInit {
  public MSDiagnostic: DatabaseDto = new Diagnostic();
  public MSDisplayMessage: string = String.empty;
  public MSLoading: boolean = false;
  public MSWebApplicationVersion: string = MoonstoneApp.ApplicationVersion;
  public MSLoggedUserName: string;
  public MSShowReloadDiagnosticButton: boolean = false;

  constructor(
    private _msalWrapperService: MsalWrapperService,
    private _componentUtilityService: ComponentUtilityService,
    private _diagnosticApiService: DiagnosticApiService,
    private _userSessionService: UserSessionService,
    private _userOptionService: UserOptionService,
    private _appRouter: AppRouter,
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.loadApiDiagnosticAndConnectToDatabase();
    this.MSLoggedUserName = this._msalWrapperService.GetLoggedUserName;
  }


  public MSOnClickReloadDiagnosticButton() {
    this.loadApiDiagnosticAndConnectToDatabase();
  }

  public MSSignOutFromAzure() {
    this._confirmationService.confirm({
      message: `Are you sure to change your user? This will terminate your session from all active Moonstone applications.`,
      accept: async () => {
        try {
          if (this._userSessionService.IsConnectedToDatabase()) {
            await this._userSessionService.DisconnectUserAsync();
          }
        }
        finally {
          this._msalWrapperService.DisconnectFromAzure();
        }
      },
    });
  }

  private async loadApiDiagnosticAndConnectToDatabase() {
    this.MSLoading = true;
    this.MSDisplayMessage = "Loading, Please Wait.";
    try {
      const apiResult: ApiContentResult<DatabaseDto> = await this._diagnosticApiService.GetDatabaseDto(MoonstoneApp.ApplicationName);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSDiagnostic = apiResult.content;
        this._msalWrapperService.MSDiagnostic = apiResult.content;
        await this.dbConnectUser(DefaultValues.Y);
      }
      else {
        this.MSShowReloadDiagnosticButton = true;
        this.MSDisplayMessage = "Loading failed.";
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  private async dbConnectUser(forceDisconnect: BooleanString) {
    this.MSDisplayMessage = "Connecting to Moonstone";
    const connectResult: ApiContentResult<UserSession> = await this._userSessionService.ConnectUser(forceDisconnect)
    if (this._componentUtilityService.WasSuccessful(connectResult)) {
      await this._userOptionService.SetUserOptionMapList();
      this._appRouter.RouteToDefault();
    }
    else {
      const separator = DefaultValues.Separator;
      this.MSDisplayMessage = "Connection failed!";
      if (connectResult.apiError?.problemDetail?.detail) {
        this.MSDisplayMessage += separator + connectResult.apiError?.problemDetail?.detail
      }
    }
  }
}
