import { Injectable }                                           from "@angular/core";
// MS Imports
import { MoonConfig, MoonEnvironmentConfig, MoonMsalConfig, }   from "@moon-core/api/response/moon-environment-config.response";
import { environment }                                          from 'src/environments/environment';

@Injectable()
export class MoonAppConfigService {
    private readonly _prodConfigFilePath = "moon-config/moon-config.json";
    private readonly _debugRunConfigFilePath = `moon-config/staging/moon-${environment.b2c ? 'b2c-' : String.empty}config.json`;
    private _moonEnvironmentConfig: MoonEnvironmentConfig;

    constructor() { }

    public MSLoadAppConfigJson(): Promise<MoonEnvironmentConfig> {
        const moonConfigFilePath = environment.production ? this._prodConfigFilePath : this._debugRunConfigFilePath;

        return fetch(moonConfigFilePath)
            .then((response: Response) => response.json());
    }
    public MSLoadAppConfig(): Promise<void> {
        return this.MSLoadAppConfigJson()
            .then((config) => {
                this._moonEnvironmentConfig = config;
            })
            .catch((error) => {
                console.log(error)
            });
    }

    public GetMoonConfig(): MoonConfig {
        return this._moonEnvironmentConfig.moon;
    }

    public GetMsalConfig(): MoonMsalConfig {
        return this._moonEnvironmentConfig.msal;
    }

    public GetAppInsightsInstrumentationKey(): string {
        return this._moonEnvironmentConfig.appInsightsInstrumentationKey;
    }
}