// Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM
import { NgIf }                     from '@angular/common';
import { Component, OnInit }        from '@angular/core';
// Moon Imports
import { AppBreadcrumbService }     from '@app-layout/services/app.breadcrumb.service';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { UserInfoController }       from '@moon-maintainer/api/user-info.controller';
import { FindUserInfoParameter }    from "@moon-maintainer/api/request/find-user-info.parameter";
import { UserInfoFind }             from "@moon-maintainer/api/response/user-info-find.response";
import { UserInfoTableComponent }   from '@moon-maintainer/user-info/user-info-table/user-info-table.component';
import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }           from '@moon-shared/constants/constant-string';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, UserInfoTableComponent],
  providers: [UserInfoController]
})
export class UserInfoComponent implements OnInit {

  public MSUserInfoList: UserInfoFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.UserInfo.getDisplayName();
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _userInfoController: UserInfoController
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadUserInfoList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }

  private async loadUserInfoList() {
    try {
      this.MSLoading = true;
      const findParameter = new FindUserInfoParameter();
      const apiResult: ApiContentResult<UserInfoFind[]> = await this._userInfoController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSUserInfoList = apiResult.content;
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
