// Moon Auto Generated (1.5.0) at 2/13/2024 5:42:29 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { ChatSetting } from "@moon-maintainer/api/request/chat-setting.request";
import { ConstantString } from "@moon-shared/constants/constant-string";
import { RoutePath }            from "@moon-shared/constants/route-path";

@Injectable()
export class TitleExceptionController {
  
  private getBaseUrl(titleSummaryID: number): string {
    return this._moonApi.combineUrl(this._moonApi.getBaseUrl(RoutePath.TitleSummary),
    titleSummaryID, RoutePath.TitleException);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/TitleException/{titleExceptionID}/TitleDocumentBlob
   */
  public async PostTitleDocumentBlob(titleSummaryID: number, titleExceptionID: number, file: File
  ): Promise<ApiResult> {
    
    const formData = new FormData();
    formData.set('inputFile', file);

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleExceptionID, ConstantString.TitleDocumentBlob);
    return this._moonApi.postAsync(url, formData);
  }
  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleException/{titleExceptionID}/PromptExceptionTypeAndAction
   */
  public async PromptExceptionTypeAndAction(titleSummaryID: number, titleExceptionID: number, chatSetting: ChatSetting | null
  ): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleExceptionID, RoutePath.PromptExceptionTypeAndAction);
    return this._moonApi.putAsync(url, chatSetting);
  }
  
  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/ChatSetting
   */
  public async GetChatSetting(titleSummaryID: number, titleExceptionID: number): Promise<ApiContentResult<ChatSetting>> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleExceptionID, RoutePath.ChatSetting);
    return this._moonApi.getAsync<ChatSetting>(url);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/TitleException/{titleExceptionID}/ChatOverride
   */
  public async ChatOverride(titleSummaryID: number, titleExceptionID: number)
    : Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleExceptionID, RoutePath.ChatOverride);
    return this._moonApi.postAsync(url, {});
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleException/{titleExceptionID}/OverrideExceptionType
   */
  public async OverrideExceptionType(titleSummaryID: number, titleExceptionID: number, exceptionType: string
  ): Promise<ApiResult> {
    const form = new FormData();
    form.set('exceptionType', exceptionType);
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleExceptionID, RoutePath.OverrideExceptionType);
    return this._moonApi.putAsync(url, form);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleException/{titleExceptionID}/OverrideRecommendedAction
   */
  public async OverrideRecommendedAction(titleSummaryID: number, titleExceptionID: number, recommendedAction: string
  ): Promise<ApiResult> {
    const form = new FormData();
    form.set('recommendedAction', recommendedAction);
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleExceptionID, RoutePath.OverrideRecommendedAction);
    return this._moonApi.putAsync(url, form);
  }
  
  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/TitleException/{titleExceptionID}/ClearLogMessage
   */
  public async ClearLogMessage(titleSummaryID: number, titleExceptionID: number): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(titleSummaryID), titleExceptionID, RoutePath.ClearLogMessage);
    return this._moonApi.putAsync(url, null);
  }

}
