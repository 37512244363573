import { NgIf }                                                     from '@angular/common';
import { Component, EventEmitter, Input, Output }                   from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }  from '@angular/forms';
// Third party imports
import { ButtonModule }                                             from 'primeng/button';
import { InputTextareaModule }                                      from 'primeng/inputtextarea';
import { RippleModule }                                             from 'primeng/ripple';
// MS Imports
import { ComponentUtilityService }                                  from '@moon-core/services/component-utility.service';
import { TitleSummaryController }                                   from '@moon-public/api/title-summary.controller';
import { OcrResult }                                                from '@moon-maintainer/title-commitment-advanced/summary-result/summary-result.component';
import { MoonFormControlComponent }                                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MessageSeverity }                                          from '@moon-shared/constants/message-severity';
import { MoonMessageService }                                       from '@moon-shared/services/moon-message.service';

@Component({
  selector: 'moon-summary-ocr',
  standalone: true,
  imports: [
    NgIf, ReactiveFormsModule,
    ButtonModule, InputTextareaModule, RippleModule,
    MoonFormControlComponent
  ],
  templateUrl: './summary-ocr.component.html',
  styleUrl: './summary-ocr.component.scss'
})
export class SummaryOcrComponent {
  @Input() public MSTitleSummaryID!: number;
  @Input() public MSOcr: OcrResult;
  @Input() public MSIsProcessiing: boolean = false;

  public MSEditOcrContent: boolean = false;
  public MSOcrFormGroup: FormGroup<{ content: FormControl<string> }>;
  @Output() MSOnOcrChange: EventEmitter<any> = new EventEmitter();
  constructor(
    private _moonMessageService: MoonMessageService,
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
  ) { }

  public MSToggleOcrEdit() {
    this.MSEditOcrContent = !this.MSEditOcrContent;
    if (this.MSOcrFormGroup == null) {
      this.initializeOcrFormGroup();
    }
  }

  public MSSaveOcrContent() {
    this.MSOcrFormGroup.markAllAsTouched();
    this.MSOcrFormGroup.updateValueAndValidity();
    if (this.MSOcrFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid recommended action.")
      return;
    }
    this.doOverrideOcr();
  }
  private initializeOcrFormGroup() {
    this.MSOcrFormGroup = new FormGroup({
      content: new FormControl<string>(this.MSOcr.overrideContent ?? this.MSOcr.content,
        {
          nonNullable: true,
          validators: [Validators.required]
        })
    });
  }


  private async doOverrideOcr() {
    this.MSIsProcessiing = true;
    try {
      const apiResult = await this._titleSummaryController.OverrideOcr(this.MSTitleSummaryID, this.MSOcr.pageNumber, this.MSOcrFormGroup.getRawValue().content);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Ocr content updated successfully.");
        this.MSOnOcrChange.emit();
      }
    } finally {
      this.MSIsProcessiing = false;
    }
  }
}
