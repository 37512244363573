<div [ngClass]="{'hidden': MSShowPdfPreviewPage}">
    <h1 class="text-xl text-center font-normal my-3">
        Please mark pages that contain the list of exceptions (typically Schedule B - Part II).
    </h1>
    <hr class="m-1">

    <moon-loading
        *ngIf="MSLoading; else pdfLoadedContent"
        class="moon-pdf-loading-spinner"
        [MSLoadingMessage]="MSLoadingMessage"
    ></moon-loading>

    <ng-template #pdfLoadedContent>
        <div
            class="moon-pdf-viewer-container"
        >
            <div
                id="moon-pdf-indicator"
                class="flex flex-column sm:flex-row flex-wrap align-items-center justify-content-center py-2 px-4 gap-4 w-full overflow-auto"
            >
                <div
                    *ngFor="let item of MSTitleCommitmentPdfBlob.pdfThumbnails; let i=index"
                    class="flex flex-column gap-1"
                >
                    <a
                        class="moon-thumnail-image-container relative"
                        [ngClass]="{'marked': MSTitleCommitmentPdfBlob.IsPageMarked(item.page) }"
                        (click)="MSTitleCommitmentPdfBlob.activePdfPage = i+1"
                        id="moon-thumbnail-page-{{item.page}}"
                    >
                        <img
                            title="Click to preview this page"
                            src="{{item.url}}"
                            alt="item.page"
                            class="moon-thumbnail-image cursor-pointer"
                            (click)="MSOnClickPreview(i)"
                        />
                        <div class="flex justify-content-center gap-2 absolute top-0 left-0 ">
                            <button
                                #markButton
                                pButton
                                pRipple
                                [title]="(MSTitleCommitmentPdfBlob.IsPageMarked(i+1) ? 'Unmark' : 'Mark') + ' as exception page'"
                                [label]="MSTitleCommitmentPdfBlob.IsPageMarked(i+1) ? 'Unmark' : 'Mark'"
                                class="w-min h-min ml-1 mt-1 moon-mark-unmark-btn "
                                [ngClass]="{'marked-page' : MSTitleCommitmentPdfBlob.IsPageMarked(item.page)}"
                                (click)="MSTitleCommitmentPdfBlob.MarkUnmarkAsExceptionPage(item.page);markButton.blur()"
                            ></button>
                        </div>
                    </a>
                    <div class="pagenumber pb-1 text-center">{{item.page}}</div>
                </div>
            </div>
        </div>
    </ng-template>

    <pdf-viewer
        *ngIf="MSTitleCommitmentPdfBlob.pdfSrc"
        id="moon-pdf-viewer"
        class="moon-pdf-viewer"
        [ngClass]="{'moon-marked-as-exception' : MSTitleCommitmentPdfBlob.IsCurrentPageMarked()}"
        [src]="MSTitleCommitmentPdfBlob.pdfSrc"
        [page]="MSTitleCommitmentPdfBlob.activePdfPage"
        [show-all]="true"
        [zoom-scale]="'page-height'"
        (pageChange)="MSTitleCommitmentPdfBlob.OnPdfPageChanged($event)"
        (after-load-complete)="MSOnPdfLoadCompleted($event)"
    ></pdf-viewer>

    <div class="moon-wizard-buttons">
        <div class="w-8rem sm:w-20rem text-center font-bold">Marked Pages:
            {{MSTitleCommitmentPdfBlob.GetExceptionPagesListInCsv()}}
        </div>
    </div>
</div>


<p-dialog
    *ngIf="MSShowPdfPreviewPage;"
    [(visible)]="MSShowPdfPreviewPage"
    styleClass="w-full h-full max-h-full bg-maskbg moon-dialog-no-header"
    position="top"
    [draggable]="false"
    [modal]="true"
    [blockScroll]="true"
    (visibleChange)="MSOnClosePreviewDialog($event)"
>
    <moon-pdf-page-preview
        *ngIf="MSTitleCommitmentPdfBlob.pdfThumbnails[MSPreviewPageNumber] as page"
        [MSPdfThumbnailPage]="page"
        [MSIsMarkedPage]="MSTitleCommitmentPdfBlob.IsPageMarked(MSPreviewPageNumber + 1)"
        (MSNextPageEvent)="MSOnPNextPageEvent()"
        (MSPreviousPageEvent)="MSOnPreviousPageEvent()"
        (MSPageMarkedEvent)="MSMarkUnmarkAsExceptionPage($event)"
    ></moon-pdf-page-preview>
</p-dialog>