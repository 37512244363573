import { CommonModule }                        from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//Third Party Imports
import { Table, TableModule }                  from 'primeng/table';
import { TabViewModule }                       from 'primeng/tabview';
import {DialogModule}                          from 'primeng/dialog';
import { TooltipModule }                       from 'primeng/tooltip';
import { ButtonModule }                        from 'primeng/button';
//Moon Imports
import { MoonDatePipe }                        from '@moon-shared/pipes/moon-date.pipe';
import { ApiContentResult }                    from '@moon-core/models/api-result';
import { ComponentUtilityService }             from '@moon-core/services/component-utility.service';
import { TitleCommitmentLogGet }                  from '@moon-public/api/response/title-commitment-log/title-commitment-log-get.response';
import { TitleSummaryController }           from '@moon-public/api/title-summary.controller';
import { MoonLoadingComponent }                from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonTableHeaderComponent }            from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { FieldNames }                          from '@moon-shared/constants/field-names';
import { TableColumn }                         from '@moon-shared/types/table-column.type';
import {MessageSeverity}                       from '@moon-shared/constants/message-severity';
import { MoonMessageService }                  from '@moon-shared/services/moon-message.service';
import {ChatLogComponent}                      from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';

@Component({
  selector: 'moon-title-combined-log',
  standalone: true,
  imports: [
    CommonModule, TableModule, DialogModule, TabViewModule, ButtonModule, TooltipModule, MoonDatePipe,
    MoonLoadingComponent, MoonTableHeaderComponent, ChatLogComponent
  ],
  templateUrl: './title-combined-log.component.html',
  styleUrls: ['./title-combined-log.component.scss'],
})
export class TitleReviewLogComponent implements OnInit {
  @Input() MSTitleSummaryID: number;
  @ViewChild('MSTable') MSTable: Table;
  public MSLoading: boolean = true;
  public MSTitleCommitmentLog: TitleCommitmentLogGet;
  public MSSelectedTabIndex: number = 0;
  public MSShowChatLog: boolean = false;
  public MSShowChatLogIDForOutput: number;
  @Output() public MSOnTitleReviewError: EventEmitter<null> = new EventEmitter();
  public MSTableColumns: TableColumn[] = [
    { field: FieldNames.ChatLogID.toCamelCase(), header: FieldNames.ChatLogID.getDisplayName(), class: 'w-5per' },
    { field: FieldNames.FileName.toCamelCase(), header: FieldNames.FileName.getDisplayName(), class: 'w-15per', sortable: true },
    { field: FieldNames.SkillName.toCamelCase(), header: FieldNames.SkillName.getDisplayName(), class: 'w-20per', sortable: true },
    { field: FieldNames.DeploymentName.toCamelCase(), header: FieldNames.DeploymentName.getDisplayName(), class: 'w-15per', sortable: true },
    { field: FieldNames.LastUpdated.toCamelCase(), header: FieldNames.LastUpdated.getDisplayName(), class: 'w-10per', sortable: true },
    { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions.getDisplayName(), class: 'w-10per'},
  ];

  public MSTableExceptionColumns: TableColumn[] = [
    { field: FieldNames.ChatLogID.toCamelCase(), header: FieldNames.ChatLogID.getDisplayName(), class: 'w-5per' },
    { field: FieldNames.Part.toCamelCase(), header: FieldNames.Part.getDisplayName(), class: 'w-15per', sortable: true },
    { field: FieldNames.SkillName.toCamelCase(), header: FieldNames.SkillName.getDisplayName(), class: 'w-20per', sortable: true },
    { field: FieldNames.DeploymentName.toCamelCase(), header: FieldNames.DeploymentName.getDisplayName(), class: 'w-15per', sortable: true },
    { field: FieldNames.LastUpdated.toCamelCase(), header: FieldNames.LastUpdated.getDisplayName(), class: 'w-10per', sortable: true },
    { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions.getDisplayName(), class: 'w-10per'},
  ];

  constructor(
    private _componentUtilityService: ComponentUtilityService,
    private _titleSummaryController: TitleSummaryController,
    private _moonMessageService: MoonMessageService
  ) { }

  ngOnInit(): void {
    this.loadTitleCommitmentByID();
  }

  public OnSearchInput(table: Table, searchInput: string): void {
    table.filterGlobal(searchInput, "contains");
  }

  public MSOnClickChatLogOutput(chatLogID: number): void {
    if (chatLogID !== null) {
      this.MSShowChatLog = true;
      this.MSShowChatLogIDForOutput = chatLogID;
    } else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "No Chat Log found.");
    }
  }

  private async loadTitleCommitmentByID(): Promise<void> {
    this.MSLoading = true;

    try {
      const apiResult: ApiContentResult<TitleCommitmentLogGet> =
        await this._titleSummaryController.GetTitleSummaryLog(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleCommitmentLog = apiResult.content;
        this.MSTitleCommitmentLog.titleSummaryLogGetList.map(a => {a.lastUpdatedAt = a.lastUpdatedAtUtc.toLocalDate();})
        this.MSTitleCommitmentLog.titleExceptionLogGetList.map(a => {a.lastUpdatedAt = a.lastUpdatedAtUtc.toLocalDate();})
        this.MSTitleCommitmentLog.titleDocumentLogGetList.map(a => {a.lastUpdatedAt = a.lastUpdatedAtUtc.toLocalDate();})
      }
      else{
        this.MSOnTitleReviewError.emit();
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
