// Moon Auto Generated (1.0.0) at 11/26/2023 11:22:45 PM
import { Injectable }                     from "@angular/core";
// MS Imports
import { ApiContentResult, ApiResult }    from '@moon-core/models/api-result';
import { MoonApiClient }                  from '@moon-core/services/moon-api.client';
import { FindChatLogParameter }           from "@moon-maintainer/api/request/find-chat-log.parameter";
import { ChatLogDataOverride }            from "@moon-maintainer/api/request/chat-log-data-override.request";
import { ChatLogDataGet }                 from "@moon-maintainer/api/response/chat-log-data-get.response";
import { ChatLogFind }                    from "@moon-maintainer/api/response/chat-log-find.response";
import { ChatLogGet }                     from "@moon-maintainer/api/response/chat-log-get.response";
import { ConstantString }                 from "@moon-shared/constants/constant-string";
import { RoutePath }                      from "@moon-shared/constants/route-path";

@Injectable()
export class ChatLogController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.ChatLog);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
  
/**
 *   POST: Maintainer/v1/ChatLog/find
 */
  public async Find(findParameter: FindChatLogParameter
    ): Promise<ApiContentResult<ChatLogFind[]>> {
    
      const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Find);
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Maintainer/v1/ChatLog
 */
  public async GetList(
    ): Promise<ApiContentResult<ChatLogGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<ChatLogGet[]>(url);
  }
  
/**
 *   GET: Maintainer/v1/ChatLog/{chatLogID}
 */
  public async GetByID(chatLogID: number
    ): Promise<ApiContentResult<ChatLogGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), chatLogID);
    return this._moonApi.getAsync<ChatLogGet>(url);
  }

  public async GetChatLogData(chatLogID: number
    ): Promise<ApiContentResult<ChatLogDataGet>> {
    
      const url = this._moonApi.combineUrl(this.getBaseUrl(), chatLogID, ConstantString.ChatLogData);
    return this._moonApi.getAsync<ChatLogDataGet>(url);
  }
  
  /**
   * PUT Maintainer/v1/ChatLog/{chatLogID}/Override")]
   */
  public async OverrideChatLogData(inputData: ChatLogDataOverride, chatLogID: number) {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), chatLogID, RoutePath.Override);
    return this._moonApi.putAsync(url, inputData);
  }

/**
 *   POST: Maintainer/v1/ChatLog/{chatLogID}/SaveTestData
 */
  public async SaveTestData(chatLogID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), chatLogID, RoutePath.SaveTestData);
    return this._moonApi.postAsync(url, {});
  }

}
