import { Injectable } from '@angular/core';
// MS Imports
import { DatabaseDto } from '@moon-core/api/response/database.response';
import { MoonApiClient } from '@moon-core/services/moon-api.client';
import { ApiContentResult } from '@moon-core/models/api-result';

@Injectable()
export class DiagnosticApiService {

  constructor(private _moonApiClient: MoonApiClient) { }

  private get baseUrl(): string {
    return `/Diagnostic`;
  }

  public async GetDatabaseDto(environmentName: string): Promise<ApiContentResult<DatabaseDto>> {

    const url = `${this.baseUrl}/connection/${environmentName}`;
    return this._moonApiClient.getAsync<DatabaseDto>(url);
  }

  /** For Diagnostic page only */
  public async FetchDatabaseDto(apiUrl: string, environmentName: string): Promise<DatabaseDto> {
    const url = `${apiUrl}/${this.baseUrl}/connection/${environmentName}`;
    return fetch(url).then(res => res.json() as unknown as DatabaseDto);
  }
}
