// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import {
  DecimalPipe,
  NgClass, NgFor, NgIf, NgStyle,
  NgSwitch, NgSwitchCase,
  NgSwitchDefault
}                                           from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter, Input, OnDestroy, OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
}                                           from "@angular/core";
import { FormsModule }                      from "@angular/forms";
// Third Party imports
import { PrimeIcons, SelectItem }           from "primeng/api";
import { ButtonModule }                     from "primeng/button";
import { DialogModule }                     from "primeng/dialog";
import { DropdownModule }                   from "primeng/dropdown";
import { DynamicDialogRef }                 from "primeng/dynamicdialog";
import { InputSwitchModule }                from "primeng/inputswitch";
import { InputTextModule }                  from "primeng/inputtext";
import { MultiSelectModule }                from "primeng/multiselect";
import { RippleModule }                     from "primeng/ripple";
import { Table, TableModule }               from "primeng/table";
import { TagModule }                        from "primeng/tag";
import { TieredMenuModule }                 from "primeng/tieredmenu";
// Moon imports
import { ConsumerRouter }                   from "@moon-consumer/consumer.router";
import { UserSessionService }               from "@moon-core/services/user-session.service";
import { SurveyFind }                       from "@moon-consumer/api/response/survey-find.response";
import { SurveyGet }                        from "@moon-consumer/api/response/survey-get.response";
import { MoonCustomDropdownComponent }      from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonLoadingComponent }             from "@moon-shared/components/moon-loading/moon-loading.component";
import { MoonTableHeaderComponent }         from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }           from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                       from '@moon-shared/constants/field-names';
import { MoonTemplateDirective }            from "@moon-shared/directives/moon-template.directive";
import { MoonDatePipe }                     from "@moon-shared/pipes/moon-date.pipe";
import { TableColumn }                      from '@moon-shared/types/table-column.type';
import { SurveyStatus }                     from "@moon-consumer/survey/survey-status";

@Component({
  selector: "moon-survey-table",
  templateUrl: "./survey-table.component.html",
  styleUrls: ['./survey-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
      NgFor, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle, MoonDatePipe, DecimalPipe,
      TableModule, ButtonModule, RippleModule, InputTextModule, DropdownModule, InputSwitchModule, FormsModule, TieredMenuModule, TagModule, DialogModule, MultiSelectModule,
      MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonLoadingComponent, MoonTemplateDirective],
  providers: [ConsumerRouter]
})
export class SurveyTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public MSPageTitle: string;
  @Input() public MSSurveyList: SurveyFind[];
  @Input() public MSLoading = false;
  @Output() public MSOnEmitReload = new EventEmitter();
  @ViewChild("MSTable") MSTable: Table;
  public MSSelectedStatus: string = SurveyStatus.Complete;
  public MSB2CStatusFilterOptions: SelectItem[] = [
      { label: SurveyStatus.Processing.getDisplayName(), value: SurveyStatus.Processing },
      { label: SurveyStatus.Error.getDisplayName(), value: SurveyStatus.Error },
      { label: SurveyStatus.Complete, value: SurveyStatus.Complete }
  ];
  public MSDownloading: boolean = false;
  public MSTableColumns: TableColumn[] = [
      { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-5per', },
      { field: FieldNames.FileName.toCamelCase(), header: FieldNames.Name, class: 'w-25per', },
      {
          field: FieldNames.CreatedAt.toCamelCase(),
          header: FieldNames.Date.getDisplayName(),
          class: 'w-15per',
          sortable: true
      },
      {
          field: FieldNames.Status.toCamelCase(),
          header: FieldNames.Status.getDisplayName(),
          class: 'w-10per',
          showColumnFilter: true,
          filterOptions: this.MSB2CStatusFilterOptions
      },

      // Column PlaceHolder - DO NOT REMOVE
      { field: FieldNames.ResultButtons.toCamelCase(), header: FieldNames.Results, class: 'w-45per' }
  ];

  public MSEnableSearch: boolean;
  public MSDisplayFind: boolean;
  public MSDisplayCreate: boolean;
  public MSDisplaySecondary: boolean;

  public MSDynamicDialogRef?: DynamicDialogRef;
  public MSShowDocumentUpload: boolean = false;
  public MSShowContactSupport: boolean = false;
  public MSSelectedSurvey: SurveyGet | null;

  public MSIsChunkedSelectItemList: SelectItem[] = [
      { label: 'Yes', value: 'Y' },
      { label: 'No', value: 'N' },
  ]
  constructor(
      private _consumerRouter: ConsumerRouter,
      private _userSessionService: UserSessionService,
      private _changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
      this.MSEnableSearch = true;
      this.MSDisplayCreate = true;
      this.MSDisplayFind = false;
      this.MSDisplaySecondary = true;
  }
  ngAfterViewInit() {
      this.MSDisplaySecondary = this.MSSurveyList.length > 0;
      this.MSTable.filteredValue = this.getDefaultFilteredSurvey();
      this._changeDetection.detectChanges();
  }


  ngOnDestroy() {
      this.MSDynamicDialogRef?.close()
  }

  public MSGetStatusIcon(surveyFind: SurveyFind): string {
      if (SurveyStatus.Processing.equalsIgnoreCase(surveyFind.status))
          return PrimeIcons.HOURGLASS;
      else if (SurveyStatus.Intake.equalsIgnoreCase(surveyFind.status))
          return PrimeIcons.TIMES;
      else if (SurveyStatus.Error.equalsIgnoreCase(surveyFind.status))
          return PrimeIcons.TIMES;
      else if (SurveyStatus.Complete.equalsIgnoreCase(surveyFind.status))
          return PrimeIcons.CHECK;
      return String.empty;
  }

  public MSDisableRow(surveyFind: SurveyFind): boolean{
    console.log(surveyFind.status);
    return false;
      //return !SurveyStatus.Complete.equalsIgnoreCase(surveyFind.status);
  } 

  public OnClickItem(surveyFind: SurveyFind): void {
      if (this.MSDisableRow(surveyFind)) {
          return;
      } 

      //this._consumerRouter.RouteToTitleUserPage(surveyFind.surveyID);
  }

  public OnClickCreate(): void {
      this._consumerRouter.RouteToSurveyAdd();
  }

  public OnClickFind(): void {
      this._consumerRouter.RouteToSurvey();
  }

  public MSReloadList() {
      this.MSOnEmitReload.emit();
  }

  public OnSearchInput(MSTable: Table, searchInput: string): void {
      MSTable.filterGlobal(searchInput, "contains");
  }

  public MSIsSurveyProcessing(Survey: SurveyGet): boolean {
      return SurveyStatus.Processing.equalsIgnoreCase(Survey.status);
  }
  public MSIsStatusIntake(Survey: SurveyGet): boolean {
      return SurveyStatus.Intake.equalsIgnoreCase(Survey.status);
  }
  public async MSOnClickUploadTitleDocument(Survey: SurveyFind): Promise<void> {
      this.MSSelectedSurvey = Survey;
      this.MSShowDocumentUpload = true;
  }

  public async MSOnClickContactSupport(Survey: SurveyFind): Promise<void> {
      this.MSSelectedSurvey = Survey;
      this.MSShowContactSupport = true;
  }
  
  public MSOnAllDocumentDownloaded(): void {
      this.MSShowDocumentUpload = false;
      this.MSReloadList();
  }

  public MSIsUserMaintainer(): boolean {
      return this._userSessionService.IsMaintainer();
  }

  private getDefaultFilteredSurvey(): SurveyFind[] {
      return this.MSSurveyList;
  }
}
