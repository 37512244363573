import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, SlicePipe }  from '@angular/common';
import { Component, HostListener, OnInit }                                                  from '@angular/core';
import {
  FormsModule, ReactiveFormsModule,
}                                                                                           from '@angular/forms';
// Third party imports
import { PdfViewerModule }                                                                  from 'ng2-pdf-viewer';
import { ConfirmationService, MenuItem, Message }                                           from 'primeng/api';
import { BadgeModule }                                                                      from 'primeng/badge';
import { ButtonModule }                                                                     from 'primeng/button';
import { ConfirmDialogModule }                                                              from 'primeng/confirmdialog';
import { DropdownModule }                                                                   from 'primeng/dropdown';
import { InputNumberModule }                                                                from 'primeng/inputnumber';
import { InputTextModule }                                                                  from 'primeng/inputtext';
import { MessagesModule }                                                                   from 'primeng/messages';
import { RadioButtonModule }                                                                from 'primeng/radiobutton';
import { RippleModule }                                                                     from 'primeng/ripple';
import { StepsModule }                                                                      from 'primeng/steps';
// MS Imports
import { AppBreadcrumbService }                                                             from '@app-layout/services/app.breadcrumb.service';
import { ConsumerRouter }                                                                   from '@moon-consumer/consumer.router';
import { TitleCommitmentWizardService }                                                     from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { ComponentUtilityService }                                                          from '@moon-core/services/component-utility.service';
import { UserSessionService }                                                               from '@moon-core/services/user-session.service';
import { MaintainerRouter }                                                                 from '@moon-maintainer/maintainer.router';
import { TitleSummaryController }                                                        from '@moon-public/api/title-summary.controller';
import { TitleIntakeController }                                                            from '@moon-public/api/title-intake.controller';
import { MoonFormControlComponent }                                                         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }                                                             from '@moon-shared/components/moon-loading/moon-loading.component';
import { FieldNames }                                                                       from '@moon-shared/constants/field-names';
import { RoutePath }                                                                        from '@moon-shared/constants/route-path';
import { MoonDragDropDirective }                                                            from '@moon-shared/directives/file-upload-drag-drop.directive';

@Component({
  selector: 'moon-add-title-commitment-wizard',
  templateUrl: './add-title-commitment-wizard.component.html',
  styleUrls: ['./add-title-commitment-wizard.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe, FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, SlicePipe, DecimalPipe,
    ButtonModule, RippleModule, BadgeModule, InputNumberModule, InputTextModule, MessagesModule, ConfirmDialogModule, DropdownModule, RadioButtonModule, StepsModule,
    PdfViewerModule,
    MoonDragDropDirective, MoonFormControlComponent, MoonLoadingComponent
  ],
  providers: [TitleSummaryController, ConfirmationService, TitleCommitmentWizardService, TitleIntakeController]
})
export class AddTitleCommitmentWizardComponent implements OnInit {

  public get MSLoading(): boolean {
    return this._titleCommitmentWizardService.MSLoading;
  }

  public get MSLoadingMessage(): string {
    return this._titleCommitmentWizardService.MSLoadingMessage;
  }

  public MSWizardMessages: Message[] = [];

  public MSWizardSteps: MenuItem[] = [
    { label: RoutePath.CommitmentType.getDisplayName(), routerLink: RoutePath.CommitmentType },
    { label: RoutePath.MatterName.getDisplayName(), routerLink: RoutePath.MatterName },
    { label: RoutePath.UploadFile.getDisplayName(), routerLink: RoutePath.UploadFile },
    { label: RoutePath.Endorsement.getDisplayName(), routerLink: RoutePath.Endorsement },
    { label: FieldNames.MarkScheduleOfExceptions, routerLink: RoutePath.MarkScheduleOfExceptions },
    { label: RoutePath.UploadTitleDocument.getDisplayName(), routerLink: RoutePath.UploadTitleDocument },
    { label: RoutePath.Final.getDisplayName(), routerLink: RoutePath.Final },
  ];

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _userSessionService: UserSessionService,
    private _maintainerRouter: MaintainerRouter,
    private _componentUtilityService: ComponentUtilityService,
    private _consumerRouter: ConsumerRouter,
    private _titleCommitmentWizardService: TitleCommitmentWizardService
  ) {
  }
  public get MSIsUserInFinalStep(): boolean {
    return this._titleCommitmentWizardService.IsCurrentStep(RoutePath.Final);
  }
  public MSGetLabelForNextButton(): string {
    if (this._titleCommitmentWizardService.IsCurrentStep(RoutePath.UploadTitleDocument))
      return 'Submit for Processing';
    return 'Next';
  }

  public async MSOnClickNext() {
    
    this.MSWizardMessages = [];
    const result = await this._titleCommitmentWizardService.GoToNextStep();
    this._componentUtilityService.WasJobStepSuccessful(result);
  }

/* 
  public MSOnClickGoBack() {
    this._titleCommitmentWizardService.GoToLastStep()
  }
  
  public MSGetLabelForGoBackButton(): string {
    if (this._titleCommitmentWizardService.IsCurrentStep(RoutePath.SelectCommitmentType))
      return 'Cancel';
    return 'Go back';
  } */

  ngOnInit(): void {
    this.setBreadcrumb();
  }
  
  private setBreadcrumb() {
    const pageBreadcrumb: MenuItem[] = [];
    if (this._userSessionService.IsMaintainer()) {
      pageBreadcrumb.push({
        label: RoutePath.TitleCommitments.getDisplayName(), command: () => {
          this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
        }
      })
    }
    else {
      pageBreadcrumb.push({
        label: RoutePath.TitleCommitments.getDisplayName(), command: () => {
          this._consumerRouter.RouteToTitleCommitmentsSimple();
        }
      })
    }

    pageBreadcrumb.push({ label: `${RoutePath.Add} Title Commitment`, routerLink: null })


    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }


  @HostListener('window:keyup', ['$event'])
  public MSOnKeyPress($event: KeyboardEvent) {
    if (this._titleCommitmentWizardService.MSDisabledSubmitOnEnterKey)
      return;

    if ($event.key === "Enter")
      this.MSOnClickNext();
  }
}
