// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
import { NgIf }                             from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }                from '@angular/core';
import { ActivatedRoute }                   from '@angular/router';
// Third Party Imports
import { ConfirmationService, MenuItem }    from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }             from '@app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }      from '@moon-core/models/api-result';

import { MoonLoadingComponent }             from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }                   from '@moon-shared/constants/constant-string';
import { FieldNames }                       from '@moon-shared/constants/field-names';
import { RoutePath }                        from '@moon-shared/constants/route-path';

import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { SkillTestDataGet }                 from '@moon-maintainer/api/response/skill-test-data-get.response';
import { SkillTestDataController }          from '@moon-maintainer/api/skill-test-data.controller';
import { MaintainerRouter }                 from "@moon-maintainer/maintainer.router";
import { SkillTestDataFormComponent }       from '@moon-maintainer/skill-test-data/skill-test-data-form/skill-test-data-form.component';
import { SkillTestDataTableComponent }      from '@moon-maintainer/skill-test-data/skill-test-data-table/skill-test-data-table.component';
@Component({
    selector: 'moon-skill-test-data-add-page',
    templateUrl: './skill-test-data-edit-page.component.html',
    styleUrls: ['./skill-test-data-edit-page.component.css'],
    standalone: true,
    imports: [
      NgIf, MoonLoadingComponent, MoonEditPageComponent, 
      SkillTestDataTableComponent, SkillTestDataFormComponent
    ],
    providers: [
      SkillTestDataController, 
      MaintainerRouter
    ]
})
export class SkillTestDataEditPageComponent implements OnInit {
  public MSSkillNumber: string = String.empty;
  // public MSSkillID: number;
  public MSSkillTestDataID: number | null;
  public MSSkillTestDataGet: SkillTestDataGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.SkillTestData.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSDisplayRouteBackButton: boolean = true;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _skillTestDataController: SkillTestDataController,
    private _confirmationService: ConfirmationService,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSSkillTestDataID){
      await this.getSkillTestDataByID(this.MSSkillTestDataID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSSkillTestDataID){
      this.doRemove(this.MSSkillTestDataID);
    }
  }

  public OnClickRouteBack() {
    this._maintainerRouter.ToSkillTestData(this.MSSkillNumber ?? String.empty);
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSSkillTestDataID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    const editPageBreadcrumb: MenuItem[] = [
      { label: RoutePath.Skill, routerLink: this._maintainerRouter.SkillRouteLink() }, 
      { label: RoutePath.SkillTestData.getDisplayName(), routerLink: this._maintainerRouter.SkillTestData(this.MSSkillNumber ?? String.empty) },
      { label: this.MSSkillTestDataID ? RoutePath.Edit : RoutePath.Add, routerLink: null }
    ];
    
    this._appBreadcrumbService.setItems(editPageBreadcrumb);
  }

  private readRouteParameters() {  

    const skillNumber = this._activatedRoute.snapshot.paramMap.get(FieldNames.SkillNumber);
    this.MSSkillNumber = skillNumber ?? String.empty;
    
    const skillTestDataID = this._activatedRoute.snapshot.paramMap.get(FieldNames.SkillTestDataID);
    this.MSSkillTestDataID = skillTestDataID ? +skillTestDataID : null;
  }

  private async getSkillTestDataByID(skillTestDataID: number) {
    if (skillTestDataID) {
      const apiResult: ApiContentResult<SkillTestDataGet> = await this._skillTestDataController.GetByID(this.MSSkillNumber, skillTestDataID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSSkillTestDataGet = apiResult.content;
      }
    }
  }

  private async doRemove(skillTestDataID: number) {
    this._confirmationService.confirm({
      message: `Are you sure you want to remove?`,
      accept: async() => {
        const apiResult: ApiResult = await this._skillTestDataController.Delete(this.MSSkillNumber, skillTestDataID);
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
            this._maintainerRouter.ToSkillTestData(this.MSSkillNumber);
        }
      },
    });
  }
}
