// Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM
import { Component, Input, OnInit }                 from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { ActivatedRoute }                           from '@angular/router';
// Third Party Imports
import { SharedModule, SelectItem }                 from 'primeng/api';
import { InputNumberModule }                        from 'primeng/inputnumber';
import { InputTextModule }                          from 'primeng/inputtext';
import {AutoCompleteModule}                         from 'primeng/autocomplete';
import { DropdownModule }                           from 'primeng/dropdown';
// Moon Imports
import { IApiResult }                               from '@moon-core/models/api-result';
import { ComponentUtilityService }                  from '@moon-core/services/component-utility.service';
import {ApiContentResult}                           from '@moon-core/models/api-result';
import { NodeEnvironmentUserGroupMapController }    from '@moon-maintainer/api/node-environment-user-group-map.controller';
import { NodeEnvironmentUserGroupMap }              from '@moon-maintainer/api/request/node-environment-user-group-map.request';
import { NodeEnvironmentUserGroupMapGet }           from "@moon-maintainer/api/response/node-environment-user-group-map-get.response";
import { MaintainerRouter }                         from '@moon-maintainer/maintainer.router';
import { NodeEnvironmentController }                from '@moon-maintainer/api/node-environment.controller';
import { NodeEnvironmentGet }                       from '@moon-maintainer/api/response/node-environment-get.response';
import { UserGroupController }                      from '@moon-maintainer/api/user-group.controller';
import { UserGroupGet }                             from '@moon-maintainer/api/response/user-group-get.response';
import { MoonFormControlComponent }                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }                    from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                              from '@moon-shared/types/form-group-of.type';
import { RouteParameter }                           from '@moon-shared/constants/route-parameter';

@Component({
    selector: 'node-environment-user-group-map-form',
    templateUrl: './node-environment-user-group-map-form.component.html',
    styleUrls: ['./node-environment-user-group-map-form.component.css'],
    standalone: true,
    imports: [ 
        FormsModule, ReactiveFormsModule, SharedModule,
        InputTextModule, InputNumberModule,
        MoonFormPageComponent, MoonFormControlComponent, AutoCompleteModule, DropdownModule
    ],
    providers: [NodeEnvironmentUserGroupMapController, NodeEnvironmentController, UserGroupController]
})
export class NodeEnvironmentUserGroupMapFormComponent implements OnInit {
    @Input() public MSNodeEnvironmentUserGroupMapGet: NodeEnvironmentUserGroupMapGet;
    @Input() public MSNodeEnvironmentUserGroupMapList: NodeEnvironmentUserGroupMapGet[];
    @Input() public MSNodeEnvironmentUserGroupMapID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<NodeEnvironmentUserGroupMap>> = new FormGroup<FormGroupOf<NodeEnvironmentUserGroupMap>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		userGroupID: new FormControl(0, {nonNullable: true }),
		nodeEnvironmentID: new FormControl(0, {nonNullable: true }),
		sortKey: new FormControl(0, {nonNullable: true }),
		status: new FormControl('', {nonNullable: true }),
        userGroupSelectItem: new FormControl(null, {nonNullable: true, validators: Validators.required }),
        nodeEnvironmentSelectItem: new FormControl(null, {nonNullable: true, validators: Validators.required }),
        statusSelectItem: new FormControl(null, {nonNullable: true, validators: Validators.required })
    });
    
    public MSIsFormSubmitting: boolean;
    public MSDisableUserGroup: boolean;
    public MSDisableEnvironment: boolean;
    public MSEnvironmentName = String.empty;
    public MSUserGroupStatusSelectItem: SelectItem[] = [
        {label: 'Active', value: 'ACT'},
        {label: 'Disabled', value: 'DIS'},
        {label: 'Locked', value: 'LCK'},
    ]
    public MSFilteredNodeEnvironmentSelectItem: SelectItem[] = [];
    public MSFilteredUserGroupSelectItem: SelectItem[] = [];
    private _userGroupSelectItem: SelectItem[] = [];
    private _nodeEnvironmentSelectItem: SelectItem[] = [];
    private _nodeEnvironmentID: number;

    // Declare Options PlaceHolder

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _nodeEnvironmentUserGroupMapController: NodeEnvironmentUserGroupMapController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter,
        private _nodeEnvironmentController: NodeEnvironmentController,
        private _userGroupController: UserGroupController,
    ) {
    }

    ngOnInit(): void {
        this.getSelectItems();
        this.readRouteParameters();
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSNodeEnvironmentUserGroupMapGet);
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    public MSFilterEnvironment(event: any): void {
        this.MSFilteredNodeEnvironmentSelectItem = this._nodeEnvironmentSelectItem.filter((item: SelectItem) => {
            return (
                item.label!
                    .toLocaleLowerCase()
                    .indexOf(event.query.toLocaleLowerCase()) !== -1
            );
        });
    }

    public MSFilterUserGroup(event: any): void {
        this.MSFilteredUserGroupSelectItem = this._userGroupSelectItem.filter((item: SelectItem) => {
            return (
                item.label!
                    .toLocaleLowerCase()
                    .indexOf(event.query.toLocaleLowerCase()) !== -1
            );
        });
    }

    public MSSetSelectedNodeEnvironment(selectedItem: SelectItem): void {
        this.MSParentForm.controls.nodeEnvironmentID.setValue(selectedItem.value);
    }
    
    public MSSetSelectedUserGroup(selectedItem: SelectItem): void {
        this.MSParentForm.controls.userGroupID.setValue(selectedItem.value);
    }

    public OnChangeUserGroupStatus(selectItem: SelectItem) {
        this.MSParentForm.controls.status.setValue(selectItem.value);
    }
    private doInitializeFormControls() {
        const userGroupStatus: SelectItem | null = this.MSUserGroupStatusSelectItem.find(a=> a.value == 'ACT') ?? null;
        this.MSParentForm.controls.statusSelectItem.setValue(userGroupStatus);
        this.MSParentForm.controls.statusSelectItem.markAsPristine();
    }

    private readRouteParameters() {
        const nodeEnvironmentID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.NodeEnvironmentID);
        this._nodeEnvironmentID = +nodeEnvironmentID!
    }

    private doMapGetDataToFormControl(nodeEnvironmentUserGroupMap: NodeEnvironmentUserGroupMapGet) {
        if (!nodeEnvironmentUserGroupMap) {
            this.setReadonlyFlag(false);
            return;
        }        
        this.MSNodeEnvironmentUserGroupMapGet = nodeEnvironmentUserGroupMap;
        this.setReadonlyFlag(true);
        this.setDefaultValue();
        this.MSParentForm.patchValue(nodeEnvironmentUserGroupMap);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSNodeEnvironmentUserGroupMapID
            ? await this._nodeEnvironmentUserGroupMapController.Update(this.MSNodeEnvironmentUserGroupMapID, this.MSParentForm.getRawValue())
            : await this._nodeEnvironmentUserGroupMapController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private setDefaultValue() {
        const userGroupStatus: SelectItem | null = this.MSUserGroupStatusSelectItem.find(item=> item.value == this.MSNodeEnvironmentUserGroupMapGet?.status) ?? null;
        
        this.MSParentForm.controls.statusSelectItem.setValue(userGroupStatus);
        this.MSParentForm.controls.statusSelectItem.markAsPristine();
    }
    
    private async getSelectItems(): Promise<void> {
        const apiResult: ApiContentResult<UserGroupGet[]> = await this._userGroupController.GetList();
        const apiNodeEnvironmentResult: ApiContentResult<NodeEnvironmentGet[]> = await this._nodeEnvironmentController.GetList();
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._userGroupSelectItem = this.doMapUserGroupItems(apiResult.content);
        }

        if (this._componentUtilityService.WasSuccessful(apiNodeEnvironmentResult)) {
            this._nodeEnvironmentSelectItem = this.doMapEnvironmentItems(apiNodeEnvironmentResult.content);
        }
        // Set Options PlaceHolder
    }

    private doMapUserGroupItems(userGroupList: UserGroupGet[]): SelectItem[] {
        if (userGroupList === null) return [];
    
        const selectItems: SelectItem[] = []
    
        const addedUserGroupList = this.MSNodeEnvironmentUserGroupMapList.map(item => item.userGroupID);
        const newUserGroupList = this.MSDisableUserGroup ? userGroupList : userGroupList.filter(item => !addedUserGroupList.includes(item.userGroupID));
        
        newUserGroupList.map((userGroup: UserGroupGet) => {
            selectItems.push({
                label: userGroup.userGroupName,
                value: userGroup.userGroupID
            })
        });

        if(!this.MSNodeEnvironmentUserGroupMapID) {
            this.MSFilteredUserGroupSelectItem = this._userGroupSelectItem;
            this.MSParentForm.controls.userGroupSelectItem.setValue(selectItems[0]!);
            this.MSParentForm.controls.userGroupSelectItem.markAsPristine();
        }
        else{
            const user =  selectItems.find(item => item.value === this.MSNodeEnvironmentUserGroupMapGet.userGroupID) ?? null;
            this.MSParentForm.controls.userGroupSelectItem.setValue(user);
            this.MSParentForm.controls.userGroupSelectItem.markAsPristine();
        }
        return selectItems;
    }

    private doMapEnvironmentItems(environmentList: NodeEnvironmentGet[]): SelectItem[] {
        if (environmentList === null) return [];
    
        const selectItems: SelectItem[] = []
    
        environmentList.map((env: NodeEnvironmentGet) => {
            selectItems.push({
                label: env.environmentName,
                value: env.nodeEnvironmentID
            })
        });

        if(!this.MSNodeEnvironmentUserGroupMapID) {
            this.MSFilteredUserGroupSelectItem = this._nodeEnvironmentSelectItem;
            const env =  selectItems.find(item => item.value === this._nodeEnvironmentID);
            this.MSSetSelectedNodeEnvironment(env!);
            this.MSParentForm.controls.nodeEnvironmentSelectItem.setValue(env!);
            this.MSParentForm.controls.nodeEnvironmentSelectItem.markAsPristine();
            this.MSParentForm.controls.nodeEnvironmentID.markAsPristine();
        }
        else {
            const env =  selectItems.find(item => item.value === this.MSNodeEnvironmentUserGroupMapGet.nodeEnvironmentID) ?? null;
            this.MSParentForm.controls.nodeEnvironmentSelectItem.setValue(env);
            this.MSParentForm.controls.nodeEnvironmentSelectItem.markAsPristine();
        }
        return selectItems;
    }

    private setReadonlyFlag(value: boolean) {
        this.MSDisableEnvironment = !value;
        this.MSDisableUserGroup = value;
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToNodeEnvironmentUserGroupMap(this._nodeEnvironmentID);
    }
}
