import { NgIf }                                                     from '@angular/common';
import { ChangeDetectorRef, Component, OnInit }                     from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { ButtonModule }                                             from 'primeng/button';
import { DropdownModule }                                           from 'primeng/dropdown';
import { InputTextareaModule }                                      from 'primeng/inputtextarea';
import { RippleModule }                                             from 'primeng/ripple';
// MS Imports
import { AppBreadcrumbService }                                     from '@app-layout/services/app.breadcrumb.service';
import { BlackLinesComponent }                                      from '@moon-shared/components/black-lines/black-lines.component';
import { MoonFormControlComponent }                                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { RoutePath }                                                from '@moon-shared/constants/route-path';
import { BlackLinesMode, BlackLinesModes }                          from '@moon-shared/types/black-lines-algorithm.type';
import { FormGroupOf }                                              from '@moon-shared/types/form-group-of.type';


const defaultOriginalText = "The quick brown fox jumps over the lazy dog.\nThe dog did not notice anyway.";
const defaultModifiedText = "A fast, brown fox jumps over the sleeping doggy.\nThe dog did not notice anyway.";

interface BlackLineForm {
  originalText: string;
  modifiedText: string;
  blackLineMode: BlackLinesMode;
}

@Component({
  selector: 'moon-black-line-utility',
  templateUrl: './black-line-utility.component.html',
  styleUrls: ['./black-line-utility.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MoonFormControlComponent, InputTextareaModule, ButtonModule, RippleModule, DropdownModule, NgIf, BlackLinesComponent]
})
export class BlackLineUtilityComponent implements OnInit{
  public MSShowBlackLine: boolean = false;
  public MSBlackLineModes = BlackLinesModes;
  public MSBlackLineForm = new FormGroup<FormGroupOf<BlackLineForm>>({
    originalText: new FormControl<string>(defaultOriginalText, { nonNullable: true }),
    modifiedText: new FormControl<string>(defaultModifiedText, { nonNullable: true }),
    blackLineMode: new FormControl<BlackLinesMode>('Word Mode', { nonNullable: true })
  });

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _appBreadcrumbService: AppBreadcrumbService
  ) {
  }
  
  ngOnInit(){
    this.setBreadcrumb();
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetSimple([RoutePath.BlacklineUtility]);
  }

  public MSApplyBlackline() {
    this.MSShowBlackLine = false;
    this._changeDetectorRef.detectChanges();
    this.MSShowBlackLine = true;
  }
}
