<div id="moon-matching-clause">
    <div class="flex flex-column xl:flex-row xl:align-items-start p-1 gap-4">
        <div
            class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div class="flex flex-column align-items-center sm:align-items-start gap-2 w-full">
                <div class="flex align-items-center w-full">
                    <span class="white-space-pre-wrap">{{ MSOriginalChunk.content }}</span>
                </div>
                <div class="flex align-items-center w-full">
                    <p-fieldset
                        legend="Black Line"
                        styleClass="moon-fieldset p-0 m-0"
                    >
                        <ng-template pTemplate="content">
                            <ms-black-lines
                                [MSOriginalText]="MSOriginalSearchText"
                                [MSModifiedText]="MSOriginalChunk.content"
                            ></ms-black-lines>
                        </ng-template>
                    </p-fieldset>
                </div>
            </div>
        </div>
    </div>
    
    <p-dataView
        #dv
        [value]="MSSameChunk"
        [paginator]="true"
        [rows]="20"
        [showCurrentPageReport]="true"
    >
        <ng-template
            let-sameChunks
            pTemplate="list"
        >
        <ng-container *ngFor="let sameChunk of sameChunks">
            <div class="col-12">
                <div class="flex flex-column xl:flex-row xl:align-items-start py-3 gap-4">
                    <div
                        class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div class="flex flex-column align-items-center sm:align-items-start gap-2 w-full">
                            <div class="flex align-items-center flex-wrap w-full">
                                <div class="flex gap-2 text-lg font-bold">
                                    {{ sameChunk.documentName }}
                                </div>
                            </div>
                            <!-- Source Metadata Tag -->
                            <div class="flex align-items-center flex-wrap gap-1">
                                <span *ngIf="sameChunk.year">
                                    <p-tag value="Year: {{sameChunk.year}}"></p-tag>
                                </span>
                                <ng-container *ngFor="let metadata of sameChunk.documentMetaData | keyvalue">
                                    <span *ngIf="metadata.value !== ''">
                                        <p-tag
                                            value="{{metadata.key?.toString()?.getDisplayName()}}: {{metadata.value}}"></p-tag>
                                    </span>
                                </ng-container>
                                <span *ngIf="sameChunk.environmentName">
                                    <p-tag value="Environment: {{sameChunk.environmentName}}"></p-tag>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        </ng-template>
    </p-dataView>
</div>