export class TitleCommitmentStatus {
  public static readonly AdminReview = "AdminReview" as const;
  public static readonly Intake = "Intake" as const;
  public static readonly Processing = "Processing" as const;
  public static readonly Error = "Error" as const;
  public static readonly CLOSED = "CLOSED" as const;
  public static readonly Complete = "Complete" as const;
}
export class TitleStep {
  public static readonly BackgroundQueue = "BackgroundQueue" as const;
  public static readonly TitleOcr = "TitleOcr" as const;
  public static readonly DocumentOcr = "DocumentOcr" as const;
  public static readonly DownloadPdfs = "DownloadPdfs" as const;
  public static readonly PromptTitleSummary = "PromptTitleSummary" as const;
  public static readonly PromptTitleDocument = "PromptTitleDocument" as const;
  public static readonly PromptTitleException = "PromptTitleException" as const;
  public static readonly ManualReview = "ManualReview" as const;
  public static readonly End = "End" as const;
}