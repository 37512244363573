<moon-edit-page
    [MSLoading]="MSLoading"
    [MSPageTitle]="MSPageTitle"
    [MSDisplayRemoveButton]="MSDisplayRemoveButton"
>
    <moon-notification-form
        #MSForm
        *ngIf="!MSLoading"
        [MSMoonNotificationID]="MSMoonNotificationID"
        [MSMoonNotificationGet]="MSMoonNotificationGet"
    ></moon-notification-form>
</moon-edit-page>