import { Injectable }               from '@angular/core';
// Third party imports
import { MenuItem }                 from 'primeng/api';
// Moon Imports
import { CollaboratorMenuItems }    from '@moon-collaborator/collaborator.menu';
import { ConsumerMenuItems }        from '@moon-consumer/consumer.menu';
import { UserSessionService }       from '@moon-core/services/user-session.service';
import { HistoryMenuItems }         from '@moon-maintainer/history.menu';
import { MaintainerMenuItems }      from '@moon-maintainer/maintainer.menu';
import { PreviewMenuItems }         from '@moon-public/preview.menu';
import { PublicMenuItems }          from '@moon-public/public.menu';
import { UserOptionService }        from '@moon-core/services/user-option.service';
import { ConsumerRouter }           from '@moon-consumer/consumer.router';
import { MaintainerRouter }         from '@moon-maintainer/maintainer.router';
@Injectable()
export class MoonMenuItemService {

    private _menuItems: MenuItem[] = [];

    constructor(private _userSessionService: UserSessionService, private _userOptionService: UserOptionService, 
        private _consumerRouter: ConsumerRouter,
        private _maintainerRouter: MaintainerRouter
    ) { }

    public GetMenuItems() {

        this._menuItems = this.getUserMenuItems();
        return this._menuItems;
    }


    private getUserMenuItems(): MenuItem[] {
        const menuItems: MenuItem[] = [ConsumerMenuItems(this._userOptionService, this._userSessionService, this._consumerRouter, this._maintainerRouter)];

        if (!this._userSessionService.IsB2CUser()) {
            menuItems.push(PublicMenuItems);
        }
        if (this._userSessionService.IsCollaborator()) {
            menuItems.push(CollaboratorMenuItems);
        }
        if (this._userSessionService.IsPreviewAvailable()) {
            menuItems.push(PreviewMenuItems);
        }
        if (this._userSessionService.IsMaintainer()) {
            menuItems.push(MaintainerMenuItems);
            menuItems.push(HistoryMenuItems);
        }
        return menuItems;
    }
}