// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
import { Injectable }                       from "@angular/core";
// MS Imports
import { ApiBlobResult, ApiContentResult, 
         ApiResult }                        from '@moon-core/models/api-result';
import { MoonApiClient }                    from '@moon-core/services/moon-api.client';
import { ChatSetting }                      from "@moon-maintainer/api/request/chat-setting.request";
import { SkillTestData }                    from "@moon-maintainer/api/request/skill-test-data.request";
import { SkillTestDataGet }                 from "@moon-maintainer/api/response/skill-test-data-get.response";
import { SkillTestDataResult }              from "@moon-maintainer/models/skill-test-data-result.model";
import { SkillTestSummary }                 from "@moon-maintainer/models/skill-test-summary.model";
import { ConstantString }                   from "@moon-shared/constants/constant-string";
import { RoutePath }                        from "@moon-shared/constants/route-path";

@Injectable()
export class SkillTestDataController {
  
  private getBaseUrl(skillNumber: string): string {
    const baseUrl = this._moonApi.getMaintainerUrl(ConstantString.Skill);
    return this._moonApi.combineUrl(baseUrl, skillNumber, ConstantString.SkillTestData);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/Skill/{skillNumber}/SkillTestData
 */
  public async Create(skillTestData: SkillTestData
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl(skillTestData.skillNumber);
    return this._moonApi.postWithResultAsync(url, skillTestData);
  }
  
/**
 *   GET: Maintainer/v1/Skill/{skillNumber}/SkillTestData/{documentID}
 */
  public async GetByID(skillNumber: string, skillTestDataID: number
    ): Promise<ApiContentResult<SkillTestDataGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(skillNumber), skillTestDataID);
    return this._moonApi.getAsync<SkillTestDataGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/Skill/{skillNumber}/SkillTestData/{documentID}
 */
  public async Update(skillTestDataID: number, skillTestData: SkillTestData
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(skillTestData.skillNumber), skillTestDataID);
    return this._moonApi.putAsync(url, skillTestData);
  }
  
/**
 *   DELETE: Maintainer/v1/Skill/{skillNumber}/SkillTestData/{documentID}
 */
  public async Delete(skillNumber: string, skillTestDataID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(skillNumber), skillTestDataID);
    return this._moonApi.deleteAsync(url);
  }
  
/**
 *   POST: Maintainer/v1/Skill/{skillNumber}/SkillTestData/{skillTestDataID}/Test
 */
  public async Test(skillNumber: string, skillTestDataID: number, chatSetting: ChatSetting
    ): Promise<ApiContentResult<SkillTestDataResult>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(skillNumber), skillTestDataID, RoutePath.Test);
    return this._moonApi.postWithResultAsync(url, chatSetting);
  }
  
  /**
   * GET: Maintainer/v1/Skill/{skillNumber}/SkillTestData/Export
   */
  public async ExportAsync(skillNumber: string
  ): Promise<ApiBlobResult> {
      const url = this._moonApi.combineUrl(this.getBaseUrl(skillNumber), ConstantString.Export);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   * POST: Maintainer/v1/Skill/{skillNumber}/SkillTestData/Import
   */
  public async ImportAsync(skillNumber: string, inputFile: File
    ): Promise<ApiResult> {

      const formFile = new FormData();
      formFile.append('formFile', inputFile);
      const url = this._moonApi.combineUrl(this.getBaseUrl(skillNumber), ConstantString.Import);
      return this._moonApi.postAsync(url, formFile);
    }

  /**
   *   POST: Maintainer/v1/Skill/{skillNumber}/SkillTestData/SaveTestResult
   */
  public async SaveTestResult(skillNumber: string, skillTestSummary: SkillTestSummary
    ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(skillNumber), RoutePath.SaveTestResult);
    return this._moonApi.postAsync(url, skillTestSummary);
  }
}
