import { Component, OnInit } from "@angular/core";
import { AppComponent } from "@app/app.component";
import { UserSessionService } from "@moon-core/services/user-session.service";
import { MenuItem } from "primeng/api";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { MoonMenuItemService } from "@app/moon-menu-item.service";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";

@Component({
    selector: "app-menu",
    templateUrl: "./app.menu.component.html",
    styleUrls: ["./app.menu.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, AppMenuitemComponent, AsyncPipe]
})
export class AppMenuComponent implements OnInit {

    public MSMenuItems: MenuItem[];
    
    constructor(
        public app: AppComponent,
        private _userSessionService: UserSessionService,
        private _moonMenuItemService: MoonMenuItemService
    ) { }

    ngOnInit() {
        this.MSMenuItems = this._moonMenuItemService.GetMenuItems();
    }

    public get MSShowPagesRoute(): boolean {
        return this._userSessionService.IsConnectedToDatabase();
    }
}
