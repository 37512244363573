// Moon Auto Generated (1.0.0) at 11/22/2023 10:52:50 AM
import {
    NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                                   from "@angular/common";
import {
    Component, EventEmitter, Input, OnChanges, OnInit,
    Output,
    ViewChild, ViewEncapsulation
}                                                   from "@angular/core";
    
import { FormControl, FormGroup, FormsModule }      from "@angular/forms";
// Third Party imports
import { FilterService, SelectItem }                from "primeng/api";
import { ButtonModule }                             from "primeng/button";
import { DropdownModule }                           from "primeng/dropdown";
import { InputNumberModule }                        from "primeng/inputnumber";
import { InputTextModule }                          from "primeng/inputtext";
import { MultiSelectModule }                        from "primeng/multiselect";
import { ColumnFilter, Table, TableModule }         from "primeng/table";
// Moon imports
import { DocumentController }                       from "@moon-collaborator/api/document.controller";
import { DocumentFind }                             from "@moon-collaborator/api/response/document-find.response";
import { DocumentGet }                              from "@moon-collaborator/api/response/document-get.response";
import { DocumentMetaData }                         from "@moon-collaborator/api/response/document-metadata.response";
import { CollaboratorRouter }                       from '@moon-collaborator/collaborator.router';
import { IApiResult }                               from "@moon-core/models/api-result";
import { ComponentUtilityService }                  from "@moon-core/services/component-utility.service";
import { MoonCustomDropdownComponent }              from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonLoadingComponent }                     from "@moon-shared/components/moon-loading/moon-loading.component";
import { MoonTableHeaderComponent }                 from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }                   from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                               from '@moon-shared/constants/field-names';
import { FieldValues }                              from "@moon-shared/constants/field-values";
import { MoonDatePipe }                             from "@moon-shared/pipes/moon-date.pipe";
import { FormGroupOf }                              from "@moon-shared/types/form-group-of.type";
import { TableColumn }                              from '@moon-shared/types/table-column.type';

@Component({
    selector: "document-table",
    templateUrl: "./document-table.component.html",
    styleUrls: ['./document-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
        InputTextModule, MultiSelectModule, DropdownModule, TableModule, ButtonModule, InputNumberModule,
        MoonLoadingComponent, MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, FormsModule, MoonDatePipe
    ],
    providers: [
        ColumnFilter,
        FilterService,
        DocumentController, 
        CollaboratorRouter
    ],
})
export class DocumentTableComponent implements OnInit, OnChanges {
    @Input() public MSSearchTerm: string;
    @Input() public MSPageTitle: string;
    @Input() public MSEnvironmentName: string;
    @Input() public MSDocumentList: DocumentFind[];
    @Input() public MSLoading = true;
    @Output() public MSEventReloadPage = new EventEmitter();

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.DocumentName.toCamelCase(), header: FieldNames.Document.getDisplayName(), class: 'w-22per'},
		{field: FieldNames.CategoryName.toCamelCase(), header: FieldNames.CategoryName.getDisplayName(), class: 'w-9per'}, 
		{field: FieldNames.IsChunked.toCamelCase(), header: FieldNames.IsChunked.getDisplayName(), class: 'w-8per'},
		{field: FieldNames.TotalTokens.toCamelCase(), header: FieldNames.TotalTokens.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.TotalVectors.toCamelCase(), header: FieldNames.TotalVectors.getDisplayName(), class: 'w-6per'},
		{field: FieldNames.LawFirmName.toCamelCase(), header: FieldNames.LawFirmName.getDisplayName(), class: 'w-8per'},
		{field: FieldNames.Author.toCamelCase(), header: FieldNames.Author.getDisplayName(), class: 'w-8per'},
		{field: FieldNames.PropertyState.toCamelCase(), header: FieldNames.PropertyState.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.PropertyType.toCamelCase(), header: FieldNames.PropertyType.getDisplayName(), class: 'w-10per'},
		{field: FieldNames.MatterName.toCamelCase(), header: FieldNames.MatterName.getDisplayName(), class: 'w-10per'},
        // Column PlaceHolder - DO NOT REMOVE
		{field: FieldNames.DocumentDate.toCamelCase(), header: FieldNames.DocumentDate.getDisplayName(), class: 'w-10per'},
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplaySecondaryButton: boolean;
    public MSSecondaryButtonToolTip: string;
    public MSSecondaryButtonIcon: string;
    
    @ViewChild("MSTable") MSTable: Table;
    public MSSelectedDocuments: DocumentGet[] = [];
    public MSIsProcessRunning: boolean;
    public MSIsChunkedSelectItemList: SelectItem[] = [
        {label: 'Yes', value: 'Y'},
        {label: 'No', value: 'N'},
    ]
    
    public MSParentForm: FormGroup<FormGroupOf<DocumentMetaData>> = new FormGroup<FormGroupOf<DocumentMetaData>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		documentName: new FormControl('', {nonNullable: true }),
		documentDate: new FormControl('', {nonNullable: true }),
		categoryName: new FormControl('', {nonNullable: true }),
		lawFirmName: new FormControl('', {nonNullable: true }),
		author: new FormControl('', {nonNullable: true }),
        propertyState: new FormControl('', {nonNullable: true }),
        propertyType: new FormControl('', {nonNullable: true }),
        matterName: new FormControl('', {nonNullable: true }),
    });
    public MSSaving = false;

    constructor(
        private _collaboratorRouter: CollaboratorRouter,
        private _documentController: DocumentController,     
        private _componentUtilityService: ComponentUtilityService,

    ) {}

    ngOnInit(): void {
        this.MSEnableSearch = false;
        this.MSDisplayCreate = false;
        this.MSDisplayFind = false;
    }
    
    ngOnChanges() {
        if (this.MSTable) {
            this.MSTable.filterGlobal(this.MSSearchTerm, "contains");
        }
    }

    public MSOnClickUploadDocument(): void {
        this._collaboratorRouter.ToDocumentUpload(this.MSEnvironmentName);
    }

    public async OnChangeDocumentName(document: DocumentFind) {
        const fileExtension = this.GetFileExtension(document.documentName ?? String.empty);
        if(fileExtension == null || fileExtension != FieldValues.FileExtension_WORD) {
            document.documentName += document.documentName +  `${FieldValues.FileExtension_WORD}`
        }

        this.MSSaving = true;
        const apiGetResult: IApiResult = await this._documentController.GetByID(this.MSEnvironmentName, document.documentID);
        if (!this._componentUtilityService.WasSuccessful(apiGetResult)) {
            this.MSSaving = false;
            return;
        }

        const apiUpdateResult: IApiResult = await this._documentController.Update(this.MSEnvironmentName, document.documentID, document);
        if (this._componentUtilityService.WasSubmitSuccessful(apiUpdateResult, this.MSParentForm)) {
            this.MSSaving = false;
            this.MSEventReloadPage.emit();
        } else this.MSSaving = false;
    }

    public async OnChangeMetadataField(document: DocumentFind) {
        this.setDocumentMetadataParentForm(document)

        this.MSSaving = true;
        const apiResult: IApiResult = await this._documentController.UpdateMetaData(this.MSEnvironmentName, this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.MSSaving = false;
            this.MSEventReloadPage.emit();
        } else this.MSSaving = false;
    }

    private setDocumentMetadataParentForm(document: DocumentFind) : DocumentMetaData {
        const documentMetadata =  {
            documentName: document.documentName ?? String.empty,
            documentDate: document.documentDate,
            categoryName: document.categoryName,
            lawFirmName: document.lawFirmName,
            author: document.author,
            propertyState: document.propertyState,
            propertyType: document.propertyType,
            matterName: document.matterName,
        }

        this.MSParentForm.patchValue(documentMetadata);
        return documentMetadata;
    }

    public GetFileExtension(_name: string): string {
        const splitName = _name.split(/[\s.]+/);
        const extension = splitName[splitName.length - 1];

        return extension ? "." + extension : String.empty;
    }
}
