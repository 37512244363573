<div>
    <div
        [id]="'moon-ocr-page-'+ MSOcr.pageNumber"
        class="text-right font-italic"
    >
        Page {{MSOcr.pageNumber}}
        <span
            *ngIf="MSOcr.overrideContent"
            class="text-gray-400 text-sm"
        >(Edited)
        </span>
    </div>
    <div class="white-space-pre-wrap">

        <div *ngIf="MSEditOcrContent; else showOcr">
            <form
                [formGroup]="MSOcrFormGroup"
                (ngSubmit)="MSSaveOcrContent()"
            >
                <moon-form-control
                    [MSFormControl]="MSOcrFormGroup.controls.content"
                    [MSLabel]="'Ocr Content'"
                    [MSDisplayLabel]="false"
                    [MSInputSize]="12"
                    [MSErrorMessageSize]="12"
                >
                    <div
                        class="grow-wrap"
                        [attr.textarea-value]="ocrContent.value"
                    >
                        <textarea
                            id="inputtext"
                            #ocrContent
                            pInputTextarea
                            [formControl]="MSOcrFormGroup.controls.content"
                        ></textarea>
                    </div>
                </moon-form-control>
                <button
                    type="submit"
                    pButton
                    pRipple
                    label="Save"
                    class="p-button-raised mr-2 mb-2"
                    *ngIf="MSOcrFormGroup?.dirty"
                    [disabled]="MSIsProcessiing"
                ></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    [disabled]="MSIsProcessiing"
                    class="p-button-raised p-button-secondary mr-2 mb-2"
                    (click)="MSToggleOcrEdit()"
                ></button>
            </form>
        </div>
        <ng-template #showOcr>
            <span
                title="Click to edit"
                class="hover:surface-hover"
                (click)="MSToggleOcrEdit()"
            >{{MSOcr.overrideContent ?? MSOcr.content}}
            </span>
            <i
                class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                (click)="MSToggleOcrEdit()"
            ></i>
        </ng-template>
    </div>
</div>