// Moon Auto Generated (1.2.0) at 12/29/2023 7:46:03 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
       }                                from '@moon-core/models/api-result';
import { MoonApiClient }                from '@moon-core/services/moon-api.client';
import { FindUserSessionParameter }     from "@moon-maintainer/api/request/find-user-session.parameter";
import { UserSessionLogFind }           from "@moon-maintainer/api/response/user-session-log-find.response";
import { UserSessionLogGet }            from "@moon-maintainer/api/response/user-session-log-get.response";
import { ConstantString }               from "@moon-shared/constants/constant-string";

@Injectable()
export class UserSessionLogController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.UserSessionLog);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/UserSession/find
 */
  public async Find(findParameter: FindUserSessionParameter
    ): Promise<ApiContentResult<UserSessionLogFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Maintainer/v1/UserSession
 */
  public async GetList(
    ): Promise<ApiContentResult<UserSessionLogGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<UserSessionLogGet[]>(url);
  }

/**
 *   GET: Maintainer/v1/UserSession/{documentID}
 */
  public async GetByID(userSessionID: number
    ): Promise<ApiContentResult<UserSessionLogGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), userSessionID);
    return this._moonApi.getAsync<UserSessionLogGet>(url);
  }

}
