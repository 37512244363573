// Moon Auto Generated (1.0.0) at 12/6/2023 8:54:48 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiBlobResult, ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { MezzLoan }                 from "@moon-public/api/request/mezz-loan.request";
import { FindMezzLoanParameter }    from "@moon-public/api/request/find-mezz-loan.parameter";
import { MezzLoanChunkBatch }       from "@moon-public/api/request/mezz-loan-chunk-batch.request";
import { MezzLoanFind }             from "@moon-public/api/response/mezz-loan-find.response";
import { MezzLoanGet }              from "@moon-public/api/response/mezz-loan-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";
import { MezzLoanChunkRevisionGet } from "@app/moon-public/api/response/mezz-loan-chunk-revision-get.response";
import { MezzLoanChunkGet } from "@app/moon-public/api/response/mezz-loan-chunk-get.response";

@Injectable()
export class MezzLoanController {
  
  private getBaseUrl(): string {
    return this._moonApi.getStandardUrl(ConstantString.MezzLoan);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Public/v1/MezzLoan/find
 */
  public async Find(findParameter: FindMezzLoanParameter
    ): Promise<ApiContentResult<MezzLoanFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Public/v1/MezzLoan
 */
  public async GetList(
    ): Promise<ApiContentResult<MezzLoanGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<MezzLoanGet[]>(url);
  }
  
/**
 *   POST: Public/v1/MezzLoan
 */
  public async Create(mezzLoan: MezzLoan
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, mezzLoan);
  }
  
/**
 *   GET: Public/v1/MezzLoan/{documentID}
 */
  public async GetByID(mezzLoanID: number
    ): Promise<ApiContentResult<MezzLoanGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), mezzLoanID);
    return this._moonApi.getAsync<MezzLoanGet>(url);
  }
  
/**
 *   PUT: Public/v1/MezzLoan/{documentID}
 */
  public async Update(mezzLoanID: number, mezzLoan: MezzLoan
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), mezzLoanID);
    return this._moonApi.putAsync(url, mezzLoan);
  }
  
/**
 *   DELETE: Public/v1/MezzLoan/{documentID}
 */
  public async Delete(mezzLoanID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), mezzLoanID);
    return this._moonApi.deleteAsync(url);
  }

  /**
   * POST: Public/v1/MezzLoan/Import
   */
  public async ImportAsync(inputFile: File
    ): Promise<ApiResult> {
    
    const formFile = new FormData();
    formFile.append('formFile', inputFile);

    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Import);
    return this._moonApi.postAsync(url, formFile);
  }

    /**
   * GET: Public/v1/MezzLoan/{mezzLoanID}/Download/{documentType}
   */
    public async GetBlobAsync(mezzLoanID: number, documentType: string): Promise<ApiBlobResult> {
    
        const url = this._moonApi.combineUrl(this.getBaseUrl(), mezzLoanID, ConstantString.Download, documentType);
        return this._moonApi.getBlobAsync(url);
      }

  // Chunk Start

  /**
   * GET: Public/v1/MezzLoan/{mezzLoanID}/Chunk
   */
  public async GetListChunkAsync(mezzLoanID: number): Promise<ApiContentResult<MezzLoanChunkGet[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), mezzLoanID, ConstantString.Chunk);
    return this._moonApi.getAsync<MezzLoanChunkGet[]>(url);
  }
  
  // Chunk End

  // Revision Start

  /**
   * POST: Public/v1/MezzLoan/Chunk/{mezzLoanChunkID}/Revise
   */
  public async ReviseChunkAsync(mezzLoanChunkID: number): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Chunk, mezzLoanChunkID, ConstantString.Revise);
    return this._moonApi.putAsync(url, null);
  }
  
  /**
   * POST: Public/v1/MezzLoan/Chunk/Revise
   */
  public async ReviseChunkBatchAsync(mezzLoanChunkBatch: MezzLoanChunkBatch): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), ConstantString.Chunk, ConstantString.Revise);
    return this._moonApi.putAsync(url, mezzLoanChunkBatch);
  }
  
  /**
   * GET: Public/v1/MezzLoan/{mezzLoanID}/Revision
   */
  public async GetListRevisionAsync(mezzLoanID: number): Promise<ApiContentResult<MezzLoanChunkRevisionGet[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), mezzLoanID, ConstantString.Revision);
    return this._moonApi.getAsync<MezzLoanChunkRevisionGet[]>(url);
  }
  
  /**
 *   PUT: Public/v1/MezzLoan/{mezzLoanID}/Remerge
 */
  public async Remerge(mezzLoanID: number): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), mezzLoanID, ConstantString.Remerge);
    return this._moonApi.putAsync(url, {});
  }
  
  // Revision End

}
