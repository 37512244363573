// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import {
    DecimalPipe,
    NgClass, NgFor, NgIf, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault
}                                           from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter, Input, OnDestroy, OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
}                                           from "@angular/core";
import { FormsModule }                      from "@angular/forms";
// Third Party imports
import { PrimeIcons, SelectItem }           from "primeng/api";
import { ButtonModule }                     from "primeng/button";
import { DialogModule }                     from "primeng/dialog";
import { DropdownModule }                   from "primeng/dropdown";
import { DynamicDialogRef }                 from "primeng/dynamicdialog";
import { InputSwitchModule }                from "primeng/inputswitch";
import { InputTextModule }                  from "primeng/inputtext";
import { MultiSelectModule }                from "primeng/multiselect";
import { RippleModule }                     from "primeng/ripple";
import { Table, TableModule }               from "primeng/table";
import { TagModule }                        from "primeng/tag";
import { TieredMenuModule }                 from "primeng/tieredmenu";
// Moon imports
import { ConsumerRouter }                   from "@moon-consumer/consumer.router";
import { TitleDocumentsUploadComponent }    from "@moon-consumer/title-commitment/title-documents-upload/title-documents-upload.component";
import { UserSessionService }               from "@moon-core/services/user-session.service";
import { MaintainerRouter }                 from "@moon-maintainer/maintainer.router";
import { TitleCommitment }                     from "@moon-public/api/request/title-commitment.request";
import { TitleCommitmentFind }                 from "@moon-public/api/response/title-commitment-find.response";
import { TitleCommitmentGet }                  from "@moon-public/api/response/title-commitment-get.response";
import { TitleCommitmentStatus }               from "@moon-maintainer/title-commitment-advanced/models/processing-status";
import { MoonCustomDropdownComponent }      from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonLoadingComponent }             from "@moon-shared/components/moon-loading/moon-loading.component";
import { MoonTableHeaderComponent }         from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonTablePageComponent }           from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { FieldNames }                       from '@moon-shared/constants/field-names';
import { MoonTemplateDirective }            from "@moon-shared/directives/moon-template.directive";
import { MoonDatePipe }                     from "@moon-shared/pipes/moon-date.pipe";
import { TableColumn }                      from '@moon-shared/types/table-column.type';

@Component({
    selector: "moon-title-commitment-simple-table",
    templateUrl: "./title-commitment-simple-table.component.html",
    styleUrls: ['./title-commitment-simple-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle, MoonDatePipe, DecimalPipe,
        TableModule, ButtonModule, RippleModule, InputTextModule, DropdownModule, InputSwitchModule, FormsModule, TieredMenuModule, TagModule, DialogModule, MultiSelectModule,
        TitleDocumentsUploadComponent,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonLoadingComponent, MoonTemplateDirective],
    providers: [ConsumerRouter]
})
export class TitleCommitmentSimpleTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public MSPageTitle: string;
    @Input() public MSTitleCommitmentList: TitleCommitmentFind[];
    @Input() public MSLoading = false;
    @Output() public MSOnEmitReload = new EventEmitter();
    @ViewChild("MSTable") MSTable: Table;
    public MSSelectedStatus: string = TitleCommitmentStatus.Complete;
    public MSB2CStatusFilterOptions: SelectItem[] = [
        { label: TitleCommitmentStatus.Processing.getDisplayName(), value: TitleCommitmentStatus.Processing },
        { label: TitleCommitmentStatus.Error.getDisplayName(), value: TitleCommitmentStatus.Error },
        { label: TitleCommitmentStatus.Complete, value: TitleCommitmentStatus.Complete }
    ];
    public MSDownloading: boolean = false;
    public MSTableColumns: TableColumn[] = [
        { field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-5per', },
        { field: FieldNames.MatterName.toCamelCase(), header: FieldNames.Name, class: 'w-25per', },
        {
            field: FieldNames.CreatedAt.toCamelCase(),
            header: FieldNames.Date.getDisplayName(),
            class: 'w-15per',
            sortable: true
        },
        {
            field: FieldNames.B2CStatus.toCamelCase(),
            header: FieldNames.Status.getDisplayName(),
            class: 'w-10per',
            showColumnFilter: true,
            filterOptions: this.MSB2CStatusFilterOptions
        },

        // Column PlaceHolder - DO NOT REMOVE
        { field: FieldNames.ResultButtons.toCamelCase(), header: FieldNames.Results, class: 'w-45per' }
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSDisplaySecondary: boolean;

    public MSDynamicDialogRef?: DynamicDialogRef;
    public MSShowDocumentUpload: boolean = false;
    public MSShowContactSupport: boolean = false;
    public MSSelectedTitleCommitment: TitleCommitmentGet | null;

    public MSIsChunkedSelectItemList: SelectItem[] = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
    ]
    constructor(
        private _consumerRouter: ConsumerRouter,
        private _maintainerRouter: MaintainerRouter,
        private _userSessionService: UserSessionService,
        private _changeDetection: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
        this.MSDisplaySecondary = true;
    }
    ngAfterViewInit() {
        this.MSDisplaySecondary = this.MSTitleCommitmentList.length > 0;
        this.MSTable.filteredValue = this.getDefaultFilteredTitleCommitment();
        this._changeDetection.detectChanges();
    }


    ngOnDestroy() {
        this.MSDynamicDialogRef?.close()
    }

    public MSGetStatusIcon(titleCommitment: TitleCommitmentFind): string {
        if (TitleCommitmentStatus.Processing.equalsIgnoreCase(titleCommitment.b2CStatus))
            return PrimeIcons.HOURGLASS;
        else if (TitleCommitmentStatus.Intake.equalsIgnoreCase(titleCommitment.b2CStatus))
            return PrimeIcons.TIMES;
        else if (TitleCommitmentStatus.Error.equalsIgnoreCase(titleCommitment.b2CStatus))
            return PrimeIcons.TIMES;
        else if (TitleCommitmentStatus.Complete.equalsIgnoreCase(titleCommitment.b2CStatus))
            return PrimeIcons.CHECK;
        return String.empty;
    }
    public MSToggleAdvancedView(): void {
        this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
    }

    public MSDisableRow(title: TitleCommitment): boolean{
        return !TitleCommitmentStatus.Complete.equalsIgnoreCase(title.status);
    } 

    public OnClickItem(titleCommitment: TitleCommitmentFind): void {
        if (this.MSDisableRow(titleCommitment)) {
            return;
        } 

        this._consumerRouter.RouteToTitleUserPage(titleCommitment.titleSummaryID);
        console.log(titleCommitment.matterDisplayName);
    }

    public OnClickCreate(): void {
        this._consumerRouter.RouteToTitleCommitmentsAdd();
    }

    public OnClickFind(): void {
        this._consumerRouter.RouteToTitleCommitmentsSimple();
    }
    public MSReloadList() {
        this.MSOnEmitReload.emit();
    }
    public OnSearchInput(MSTable: Table, searchInput: string): void {
        MSTable.filterGlobal(searchInput, "contains");
    }

    public MSIsTitleCommitmentProcessing(titleCommitment: TitleCommitmentGet): boolean {
        return TitleCommitmentStatus.Processing.equalsIgnoreCase(titleCommitment.status);
    }
    public MSIsStatusIntake(titleCommitment: TitleCommitmentGet): boolean {
        return TitleCommitmentStatus.Intake.equalsIgnoreCase(titleCommitment.status);
    }
    public async MSOnClickUploadTitleDocument(titleCommitment: TitleCommitmentFind): Promise<void> {
        this.MSSelectedTitleCommitment = titleCommitment;
        this.MSShowDocumentUpload = true;
    }

    public async MSOnClickContactSupport(titleCommitment: TitleCommitmentFind): Promise<void> {
        this.MSSelectedTitleCommitment = titleCommitment;
        this.MSShowContactSupport = true;
    }
    
    public MSOnAllDocumentDownloaded(): void {
        this.MSShowDocumentUpload = false;
        this.MSReloadList();
    }

    public MSIsUserMaintainer(): boolean {
        return this._userSessionService.IsMaintainer();
    }

    private getDefaultFilteredTitleCommitment(): TitleCommitmentFind[] {
        return this.MSTitleCommitmentList;
    }
}
