<!-- Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSMoonNotificationList"
    [MSDisplayCreateButton]="MSDisplayCreate"
    [MSDisplayFindButton]="MSDisplayFind"
    (MSEventOnClickCreate)="OnClickCreate()"
    (MSEventOnClickFind)="OnClickFind()"
    [MSNumberOfFilteredTableRows]="MSNumberOfFilteredValues"
>
    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [rowHover]="true"
        [value]="MSMoonNotificationList"
        selectionMode="single"
        [loading]="MSLoading"
        (onFilter)="MSOnFilter($event.filteredValue)"
        styleClass="p-datatable-gridlines p-datatable-striped"
    >
        <ng-template pTemplate="caption">
            <moon-table-header
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="MSDisplayCreate"
                [MSDisplayFindButton]="MSDisplayFind"
                (MSEventOnClickCreate)="OnClickCreate()"
                (MSEventOnClickFind)="OnClickFind()"
                (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
            >
            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }"
                >
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <td
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{
                    'actions-column': col.field === 'actions',
                    clickable: col.field !== 'actions'
                }"
                    (click)="col.field === 'actions' || OnClickItem(rowData)"
                >
                    <div [ngSwitch]="col.field">
                        <div
                            *ngSwitchCase="'actions'"
                            class="text-center"
                        >
                            <moon-custom-dropdown [model]="rowData.actions">
                                <i class="pi pi-ellipsis-v"></i>
                            </moon-custom-dropdown>
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>