<!-- Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<form
  *ngIf="!MSLoading"
  [formGroup]="MSParentForm"
  (ngSubmit)="OnClickSubmit()"
>
  <p-panel [toggleable]="true" [collapsed]="MSCollapseSearch">
    <ng-template pTemplate="icons">
      <button
        class="p-panel-header-icon p-link mr-2"
        type="button"
        (click)="MSTriggerExpandCollapse()"
      >
        <span *ngIf="!MSCollapseSearch" class="pi pi-chevron-up"></span>
        <span *ngIf="MSCollapseSearch" class="pi pi-chevron-down"></span>
      </button>
    </ng-template>
    <ng-template pTemplate="header">
      <div class="flex align-items-center gap-2 grid">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-arrow-left"
          (click)="OnClickRouteBack()"
          class="p-button-rounded p-button-text mr-2 mb-2"
          pTooltip="Go Back"
          tooltipPosition="bottom"
        ></button>
        <span class="font-bold">{{ MSPageTitle }} </span>
      </div>
    </ng-template>

    <div class="m-0">
      <div class="grid col-12 -mt-5">
        <div class="col-6">
          <p-fieldset legend="Skill Information"
            ><moon-form-control
              [MSLabel]="'Deployment'"
              [MSFormControl]="MSParentForm.controls.deploymentID"
              [MSLabelSize]="4"
              [MSInputSize]="8"
            >
              <p-dropdown
                [options]="MSDeploymentSelectItems"
                formControlName="deploymentID"
                optionLabel="label"
                optionValue="value"
              >
                <ng-template let-option pTemplate="item">
                  {{ option.label }} <br />
                  <small> {{ option.title }} </small>
                </ng-template>
              </p-dropdown>
            </moon-form-control>
            <moon-form-control
              [MSLabel]="'Max Output Tokens'"
              [MSFormControl]="MSParentForm.controls.maxOutputTokens"
              [MSLabelSize]="4"
              [MSInputSize]="8"
            >
              <input
                type="number"
                formControlName="maxOutputTokens"
                pInputText
              />
            </moon-form-control>
            <moon-form-control
              [MSLabel]="'Stop Sequence'"
              [MSFormControl]="MSParentForm.controls.stopSequence"
              [MSLabelSize]="4"
              [MSInputSize]="8"
            >
              <input
                type="text"
                formControlName="stopSequence"
                pInputText
              /> </moon-form-control
          ></p-fieldset>
        </div>

        <div class="col-6">
          <p-fieldset legend="Controls">
            <moon-form-control
              [MSLabel]="'Temperature'"
              [MSFormControl]="MSParentForm.controls.temperature"
              [MSLabelSize]="4"
              [MSInputSize]="8"
            >
              <div class="flex align-items-center gap-4">
                <p-slider
                  [min]="0"
                  [max]="1"
                  [step]="0.025"
                  formControlName="temperature"
                  class="w-full"
                  (onChange)="
                    MSSetFormControlValue(
                      'temperature',
                      $event.value!.toString()
                    )
                  "
                >
                </p-slider>
                <p-inputNumber
                  #Temp
                  class="w-8rem"
                  type="number"
                  formControlName="temperature"
                  [step]="0.1"
                  [min]="0"
                  [max]="1"
                  (onInput)="MSSetFormControlValue('temperature', $event.value)"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                />
              </div>
            </moon-form-control>

            <moon-form-control
              [MSLabel]="'Top P'"
              [MSFormControl]="MSParentForm.controls.topP"
              [MSLabelSize]="4"
              [MSInputSize]="8"
            >
              <div class="flex align-items-center gap-4">
                <p-slider
                  [min]="0"
                  [max]="1"
                  [step]="0.025"
                  formControlName="topP"
                  class="w-full"
                  (onChange)="
                    MSSetFormControlValue('topP', $event.value!.toString())
                  "
                >
                </p-slider>
                <p-inputNumber
                  class="w-8rem"
                  type="number"
                  formControlName="topP"
                  [step]="0.1"
                  [min]="0"
                  [max]="1"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  (onInput)="MSSetFormControlValue('topP', $event.value)"
                />
              </div>
            </moon-form-control>

            <moon-form-control
              [MSLabel]="'Frequency Penalty'"
              [MSFormControl]="MSParentForm.controls.frequencyPenalty"
              [MSLabelSize]="4"
              [MSInputSize]="8"
            >
              <div class="flex align-items-center gap-4">
                <p-slider
                  [min]="0"
                  [max]="2"
                  [step]="0.025"
                  formControlName="frequencyPenalty"
                  class="w-full"
                  (onChange)="
                    MSSetFormControlValue(
                      'frequencyPenalty',
                      $event.value!.toString()
                    )
                  "
                >
                </p-slider>
                <p-inputNumber
                  class="w-8rem"
                  type="number"
                  formControlName="frequencyPenalty"
                  [step]="0.1"
                  [min]="0"
                  [max]="2"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  (onInput)="
                    MSSetFormControlValue('frequencyPenalty', $event.value)
                  "
                />
              </div>
            </moon-form-control>

            <moon-form-control
              [MSLabel]="'Presence Penalty'"
              [MSFormControl]="MSParentForm.controls.presencePenalty"
              [MSLabelSize]="4"
              [MSInputSize]="8"
            >
              <div class="flex align-items-center gap-4">
                <p-slider
                  [min]="0"
                  [max]="2"
                  [step]="0.025"
                  formControlName="presencePenalty"
                  class="w-full"
                  (onChange)="
                    MSSetFormControlValue(
                      'presencePenalty',
                      $event.value!.toString()
                    )
                  "
                >
                </p-slider>
                <p-inputNumber
                  class="w-8rem"
                  type="number"
                  formControlName="presencePenalty"
                  [step]="0.1"
                  [min]="0"
                  [max]="2"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  (onInput)="
                    MSSetFormControlValue('presencePenalty', $event.value)
                  "
                />
              </div>
            </moon-form-control>
          </p-fieldset>
        </div>
      </div>
    </div>
  </p-panel>

  <br />

  <div class="card moon-card" *ngIf="!MSLoading">
    <moon-skill-test-data-table
      #MSTableComponent
      [MSPageTitle]="'Skill Test Data'"
      [MSSkillTestDataList]="MSSkillTestDataList"
      [MSLoading]="MSLoading"
      [MSSkillNumber]="MSSkillNumber"
      (MSRefreshEvent)="MSRefreshPage()"
    ></moon-skill-test-data-table>
  </div>

  <div class="ml-2">
    <moon-form-sticky-footer>
      <button
        type="submit"
        pButton
        pRipple
        label="Run Test"
        class="p-button-raised mr-2 mb-2"
        *ngIf="MSParentForm?.dirty"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="Cancel"
        class="p-button-raised p-button-secondary mr-2 mb-2"
        (click)="OnClickCancel()"
      ></button>
    </moon-form-sticky-footer>
  </div>
</form>

<p-dialog
  header="Test Result"
  [modal]="true"
  [(visible)]="MSShowTestResult"
  [style]="{ width: '80rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <div class="card">
    <p-table
      [value]="MSSkillTestResultList"
      [tableStyle]="{ 'min-width': '60rem' }"
      styleClass="p-datatable-gridlines p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Input</th>
          <th>Output</th>
          <th>Result</th>
          <th>Difference</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-testResult>
        <tr>
          <td>{{ testResult.inputData }}</td>
          <td>{{ testResult.outputData }}</td>
          <td>{{ testResult.resultContent }}</td>
          <td>
            <ms-black-lines
              #blackLinesComponent
              [MSOriginalText]="testResult.outputData"
              [MSModifiedText]="testResult.resultContent"
              [MSBlackLineAlgorithm]="'Semantic Search'"
            ></ms-black-lines>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="flex justify-content-end gap-2 mt-2">
      <p-button label="Ok" (onClick)="MSShowTestResult = false" />
    </div>
  </div>
</p-dialog>
