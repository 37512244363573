<form
    [formGroup]="MSTitleCommitmentForm"
    class="px-2 md:px-8 moon-title-commitment-wizard-form"
>
    <moon-form-control
        class="p-fluid p-5 fadeinright animation-duration-100 "
        [MSFormControl]="MSTitleCommitmentForm.controls.summaryType"
        [MSLabel]="'Select Commitment Type:'"
        [MSErrorLabel]="'Commitment type'"
        [MSLabelSize]="12"
        [MSInputSize]="12"
        [MSErrorMessageSize]="12"
    >
        <div class="flex flex-column align-items-center gap-2 justify-content-center sm:flex-row ">
            <p-radioButton
                *ngFor="let type of MSTitleCommitmentTypes"
                id="{{type}}"
                label="{{type}}"
                name="summaryType"
                formControlName="summaryType"
                [value]="type"
                (onClick)="MSOnClickNext()"
            > {{type}}
            </p-radioButton>
        </div>
    </moon-form-control>
</form>