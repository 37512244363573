<!-- Chat Log Viewer -->
<ng-container *ngIf="MSChatLog as log">

    <span pTooltip="{{MSShowTestDataTooltip}}">
        <button
            [disabled]="!MSShowTestData"
            pButton
            pRipple
            class="p-button-raised p-button-outlined ml-2"
            label="Save Test Data"
            (click)="MSSaveTestData()"
        >
        </button>
    </span>

    <p-fieldset
        legend="Output"
        [toggleable]="true"
    >
        <div *ngIf="MSShowEdit()">
            <i
                class="pi pi-pencil text-500 hover:text-900 cursor-pointer"
                title="Click to edit"
                (click)="MSToggleOutputEdit()"
            ></i>
        </div>

        <div *ngIf="MSChatLogData?.outputData">
            <div *ngIf="MSEditOutput; else showOutputReadonly">
                <form
                    [formGroup]="MSParentForm"
                    (ngSubmit)="MSOnClickSaveResult()"
                >
                    <moon-form-control
                        [MSFormControl]="MSParentForm.controls.outputData"
                        [MSLabel]="'Output Data'"
                        [MSDisplayLabel]="false"
                        [MSInputSize]="12"
                        [MSErrorMessageSize]="12"
                    >
                        <div
                            class="grow-wrap"
                            [attr.textarea-value]="outputText.value"
                        >
                            <textarea
                                id="outputData"
                                #outputText
                                pInputTextarea
                                [formControl]="MSParentForm.controls.outputData"
                            ></textarea>
                        </div>
                    </moon-form-control>
                    <button
                        type="submit"
                        pButton
                        pRipple
                        label="Save"
                        class="moon-min-button p-button-raised mr-2 mb-2"
                        *ngIf="MSParentForm?.dirty"
                        [disabled]="MSSaving"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Cancel"
                        [disabled]="MSSaving"
                        class="moon-min-button p-button-raised p-button-secondary mr-2 mb-2"
                        (click)="MSToggleOutputEdit()"
                    ></button>
                </form>
            </div>
            <ng-template #showOutputReadonly>
                <span
                    (click)="MSToggleOutputEdit()"
                    class="cursor-pointer hover:surface-hover mb-2 white-space-pre-line"
                    title="Click to edit"
                > {{MSChatLogData?.outputData}}
                </span>
            </ng-template>
        </div>

    </p-fieldset>

    
    <p-fieldset *ngIf="MSChatLogData?.originalData"
        legend="Original Output"
        [toggleable]="true"
    >
        <div *ngIf="MSChatLogData?.originalData">
                <span
                    (click)="MSToggleOutputEdit()"
                    class="cursor-pointer hover:surface-hover mb-2 white-space-pre-line"
                    title="Click to edit"
                > {{MSChatLogData?.originalData}}
                </span>
        </div>

    </p-fieldset>


    <p-fieldset
        legend="Prompt"
        [toggleable]="true"
    >

        <div class="mb-2">
            <span class="font-bold">Chat log ID</span>:
            {{log.chatLogID}}
        </div>
        <div class="mb-2">
            <span class="font-bold">User</span>:
            {{log.lastUpdatedBy}} ({{log.userSessionID}}) on {{log.lastUpdatedAt | moondate }}
        </div>
        <div
            class="mb-2"
            *ngIf="log.entityType"
        >
            <span class="font-bold">Entity</span>: {{log.entityType}}({{log.entityID}})
        </div>
        <div
            class="mb-2"
            *ngIf="log.actionName"
        >
            <span class="font-bold">Action</span>: {{log.actionName}}
        </div>
        <div
            class="mb-2"
            *ngIf="log.actionContext"
        >
            <span class="font-bold">Context</span>: {{log.actionContext}}
        </div>
        <div
            class="mb-2"
            *ngIf="log.skillName"
        >
            <div class="flex justify-content-between">
                <div>
                    <span class="font-bold">Skill</span>: {{log.skillName}} v{{log.skillVersion}} ({{log.skillNumber}})
                </div>
                <div>
                    <button
                        *ngIf="MSIsCurrentUserMaintainer"
                        pButton
                        pRipple
                        class="p-button-raised p-button-outlined ml-2"
                        label="Go to test"
                        (click)="MSGoToTest()"
                    >
                    </button>
                </div>
            </div>

        </div>
        <div
            class="mb-2"
            *ngIf="log.deploymentName"
        >
            <span class="font-bold">Deployment name</span>: {{log.deploymentName}}
            <small>[{{ log.modelName }} v{{ log.modelVersion }} ({{ log.region }}) (Cap: {{ log.capacityK_TPM}})]</small>
        </div>
        <div class="mb-2">
            <span class="font-bold">Tokens</span>:
            Max Output: {{log.maxOutputTokens}},
            System: {{log.systemTokens}},
            Example: {{log.exampleTokens}},
            Input: {{log.inputTokens}},
            Request: {{log.requestTokens}},
            Response: {{log.responseTokens}}
        </div>
        <div class="mb-2">
            <span class="font-bold">Cost</span>:
            {{log.requestCost}} + {{log.responseCost}} = {{log.requestCost + log.responseCost}}
        </div>
        <div
            class="mb-2"
            *ngIf="log.durationInMs"
        >
            <span class="font-bold">Duration</span>: {{log.durationInMs / 1000}}s
        </div>
        <div class="mb-2">
            <span class="font-bold">Temperature</span>: {{log.temperature}}
        </div>
        <div class="mb-2">
            <span class="font-bold">Top P</span>: {{log.topP}}
        </div>

        <div class="mb-2">
            <span class="font-bold">Trace JSON</span>: {{log.traceJson}}
        </div>

        <div class="mb-2">
            <span class="font-bold">Has Override</span>: {{log.hasOverride}}
        </div>
        
        <div class="mb-2">
            <span class="font-bold">Error Message</span>: {{log.errorMessage}}
        </div>
    </p-fieldset>

    <p-fieldset
        legend="Input"
        [toggleable]="true"
    >
        <div class="mb-2 white-space-pre-line flex justify-content-between">
            <div>{{MSChatLogData?.inputData}}</div>
            <button
                pButton
                pRipple
                class="p-button-raised p-button-outlined ml-2 h-min"
                icon="pi pi-copy"
                label="Copy input"
                (click)="MSCopyInputToClipboard()"
            ></button>
        </div>
    </p-fieldset>
</ng-container>