<div class="p-2">
    <h1 class="text-xl">{{MSApplicationName}} Diagnostic</h1>

    <ul>
        <li>Application Version: {{MSApplicationVersion}}</li>
        <li>Config Loaded: {{MoonEnvironmentConfig ? 'Yes': 'Failed'}}</li>
        <ng-container *ngIf="MSDiagnostic">
            <hr>
            <li>API Name: {{MSDiagnostic.apiName}}</li>
            <li>API Version: {{MSDiagnostic.apiVersion}}</li>
            <hr>
            <li>Database Name: {{MSDiagnostic.databaseName}}</li>
            <li>Database Version: -</li>
        </ng-container>
    </ul>
</div>