import { CommonModule, DatePipe }           from '@angular/common';
import { Component, OnDestroy, OnInit }                from '@angular/core';
import { FormsModule }                      from '@angular/forms';
//Third Party Imports
import { TimelineModule }                   from 'primeng/timeline';
import { TooltipModule }                    from 'primeng/tooltip';
import { CardModule }                       from 'primeng/card';
import { PrimeIcons }                       from 'primeng/api';
import { InputSwitchModule }                from 'primeng/inputswitch';
import { Subscription }                     from 'rxjs';
//Moon Imports
import { AppBreadcrumbService }             from '@app-layout/services/app.breadcrumb.service';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { ApiContentResult }                 from '@moon-core/models/api-result';
import { HistoryController }                from '@moon-maintainer/api/history.controller';
import { HistoryGet }                       from '@moon-maintainer/api/response/history-get.response';
import { HistoryTableComponent }            from '@moon-maintainer/history/history-table/history-table.component';
import { HistoryActionName }                from '@moon-maintainer/models/history/history-action-names.model';
import { MoonLoadingComponent }             from '@moon-shared/components/moon-loading/moon-loading.component';
import { BlackLinesComponent }              from '@moon-shared/components/black-lines/black-lines.component';
import { EmptyStateComponent }              from '@moon-shared/components/empty-state/empty-state.component';
import { SearchFieldComponent }             from '@moon-shared/components/search-field/search-field.component';
import { ConstantString }                   from '@moon-shared/constants/constant-string';
import { MoonDatePipe }                     from '@moon-shared/pipes/moon-date.pipe';
import { HistoryService }                   from '@moon-shared/services/history.service';

@Component({
  selector: 'history',
  standalone: true,
  imports: [
    CommonModule, FormsModule, DatePipe,
    TimelineModule, TooltipModule, CardModule, TooltipModule, InputSwitchModule,
    MoonDatePipe, MoonLoadingComponent, EmptyStateComponent, BlackLinesComponent, HistoryTableComponent, SearchFieldComponent,
  ],
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [HistoryController ],
})
export class HistoryComponent implements OnInit, OnDestroy {
  public MSLoading: boolean;
  public MSTimeSwitch: boolean = false;
  public MSBlank: string = 'Blank';
  public MSSearchTerm: string = String.empty;
  public MSHistoryList: HistoryGet[];
  public MSFilteredHistoryList: HistoryGet[];
  public MSPageTitle: string = ConstantString.History.getDisplayName();

  private entityTypeSubscription: Subscription;
  private entityIDSubscription: Subscription;

  private _pageSpecificHistory: boolean = false;
  private _pageSpecificEntityType: string | null;
  private _pageSpecificEntityID: number | null;
  constructor(
    private _historyController: HistoryController,
    private _componentUtilityService: ComponentUtilityService,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _historyService: HistoryService
  ) {}

  async ngOnInit() {
    this.getHistoryValues();
    this.setBreadcrumb();
    await this.getAllHistoryList();
    this.setDefaultValues();
  }

  public MSSearchFilter(searchString: string) {
    this.MSSearchTerm = searchString;

    this.MSFilteredHistoryList = searchString
                ? this.filterData(this.MSFilteredHistoryList, searchString)
                : this.MSHistoryList;
  }

  private setBreadcrumb() {
    if (this._pageSpecificHistory) return;
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  private async getAllHistoryList(): Promise<void> {
    this.MSLoading = true;

    try {
      if (!this._pageSpecificHistory) {
        const apiResult: ApiContentResult<HistoryGet[]> = await await this._historyController.GetList();
        this.historySuccess(apiResult);
      }
      else {
        if (this._pageSpecificEntityID)
        {
          const apiResult: ApiContentResult<HistoryGet[]> = await await this._historyController.GetHistoryByEntityID(this._pageSpecificEntityType!, this._pageSpecificEntityID!);
          this.historySuccess(apiResult);
        }
        else {
          if(!this._pageSpecificEntityType) return;
          const apiResult: ApiContentResult<HistoryGet[]> = await await this._historyController.GetHistoryByEntityType(this._pageSpecificEntityType);
          this.historySuccess(apiResult);
        }
      }
    }
    finally {

      this.MSLoading = false;
    }
  }

  private historySuccess(apiResult: ApiContentResult<HistoryGet[]>): void {
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSHistoryList = this.MSFilteredHistoryList = apiResult.content;
    }
  }

  private setDefaultValues(): void {
    this.MSHistoryList = this.MSHistoryList?.map(
      (item: HistoryGet) => {
        return this.UpdateLocalProperties(item);
      }
    );
  }

  private UpdateLocalProperties(item: HistoryGet): HistoryGet {
    item.enableBlackLine = item.originalValue?.length >= 100 || item.modifiedValue?.length >= 100;
    item.localDate = new Date(item.lastUpdatedAtUTC + 'Z');
    if (item.propertyName)
      item.propertyName = item.propertyName?.getDisplayName();

    switch (item.actionName) {
      case action.ADD:
        this.setIconsAndColors(
          item,
          PrimeIcons.PLUS,
          'green',
          HistoryActionName.Added
        );
        break;
      case action.UPDATE:
        this.setIconsAndColors(
          item,
          PrimeIcons.PENCIL,
          '#fbc02d',
          HistoryActionName.Updated
        );
        break;
      case action.REMOVE:
        this.setIconsAndColors(
          item,
          PrimeIcons.TRASH,
          'red',
          HistoryActionName.Deleted
        );
        break;
      default:
        this.setIconsAndColors(
          item,
          PrimeIcons.INFO_CIRCLE,
          '#2AB7CA',
          item.actionName
        );
        break;
    }
    return item;
  }
  private setIconsAndColors(
    item: HistoryGet,
    icon: string,
    color: string,
    actionTaken: string | null
  ): HistoryGet {
    item.icon = icon;
    item.color = color;
    item.actionName = actionTaken;
    return item;
  }

  private filterData(arr: any[], searchString: string) {
    return arr.filter(
      (data) =>
        JSON.stringify(data)
          .toLowerCase()
          .indexOf(searchString.toLowerCase()) !== -1
    );
  }

  private getHistoryValues(): void {
    this._pageSpecificHistory = this._historyService.MSShowButton;
    this.entityTypeSubscription = this._historyService.GetHistoryEntityType().subscribe ((value)=> this._pageSpecificEntityType= value)
    this.entityIDSubscription = this._historyService.GetHistoryEntityID().subscribe ((value)=> this._pageSpecificEntityID= value)
  }

  ngOnDestroy(): void {
    if (this.entityTypeSubscription) this.entityTypeSubscription.unsubscribe();
    if (this.entityIDSubscription) this.entityIDSubscription.unsubscribe();
  }
}

class action {
  public static readonly ADD: string = 'ADD';
  public static readonly UPDATE: string = 'UPDATE';
  public static readonly DELETE: string = 'DELETE';
  public static readonly REMOVE: string = 'REMOVE';
  public static readonly GET: string = 'GET';
}
