
export class DefaultValues {
    public static readonly NoID = -999 as const;
    public static readonly MaxFileSize = 107374182400 as const; //100 MB
    public static readonly B2C = 'B2C' as const;
    public static readonly USR = 'USR' as const;
    public static readonly N = 'N' as const;
    public static readonly Y = 'Y' as const;
    public static readonly Separator: string = '\r\n';
    public static readonly CsvSeparator = ',' as const;
}
