<moon-loading *ngIf="MSLoading"></moon-loading>
<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <moon-title-commitment-advanced-table
        [MSTitleCommitmentList]="MSTitleCommitmentList"
        [MSLoading]="MSLoading"
        (MSOnEmitReload)="MSOnEmitReload()"
    ></moon-title-commitment-advanced-table>
</div>
