// Moon Auto Generated (1.0.0) at 11/29/2023 4:33:42 PM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { NodeEnvironmentUserGroupMap }                 from "@moon-maintainer/api/request/node-environment-user-group-map.request";
import { NodeEnvironmentUserGroupMapGet }              from "@moon-maintainer/api/response/node-environment-user-group-map-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";

@Injectable()
export class NodeEnvironmentUserGroupMapController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.NodeEnvironmentUserGroupMap);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   GET: Maintainer/v1/NodeEnvironmentUserGroupMap
 */
  public async GetList(
    ): Promise<ApiContentResult<NodeEnvironmentUserGroupMapGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<NodeEnvironmentUserGroupMapGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/NodeEnvironmentUserGroupMap
 */
  public async Create(nodeEnvironmentUserGroupMap: NodeEnvironmentUserGroupMap
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, nodeEnvironmentUserGroupMap);
  }
  
/**
 *   GET: Maintainer/v1/NodeEnvironmentUserGroupMap/{documentID}
 */
  public async GetByID(nodeEnvironmentUserGroupMapID: number
    ): Promise<ApiContentResult<NodeEnvironmentUserGroupMapGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), nodeEnvironmentUserGroupMapID);
    return this._moonApi.getAsync<NodeEnvironmentUserGroupMapGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/NodeEnvironmentUserGroupMap/{documentID}
 */
  public async Update(nodeEnvironmentUserGroupMapID: number, nodeEnvironmentUserGroupMap: NodeEnvironmentUserGroupMap
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), nodeEnvironmentUserGroupMapID);
    return this._moonApi.putAsync(url, nodeEnvironmentUserGroupMap);
  }
  
/**
 *   DELETE: Maintainer/v1/NodeEnvironmentUserGroupMap/{documentID}
 */
  public async Delete(nodeEnvironmentUserGroupMapID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), nodeEnvironmentUserGroupMapID);
    return this._moonApi.deleteAsync(url);
  }
}
