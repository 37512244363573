import { CommonModule }                         from '@angular/common';
import { Component, OnInit }                            from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
// Third party imports
import { FieldsetModule }                         from 'primeng/fieldset';
import { SliderModule }                           from 'primeng/slider';
import { InputNumberModule }                      from 'primeng/inputnumber';
import { DynamicDialogConfig, DynamicDialogRef }  from 'primeng/dynamicdialog';
import { ProgressBarModule }                      from 'primeng/progressbar';
import { ButtonModule }                           from 'primeng/button';
import { RippleModule }                           from 'primeng/ripple';
import { InputTextModule }                        from 'primeng/inputtext';
import { DropdownModule }                         from 'primeng/dropdown';
import { SelectItem }                             from 'primeng/api';
// Moon imports             
import { ComponentUtilityService }                from '@moon-core/services/component-utility.service';
import { ApiContentResult }                       from '@moon-core/models/api-result';

import { MoonFormPageComponent }                  from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                            from '@moon-shared/types/form-group-of.type';
import { MoonFormControlComponent }               from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonMessageService }                     from '@moon-shared/services/moon-message.service';
import { MessageSeverity }                        from '@moon-shared/constants/message-severity';

import { DeploymentGet }                          from '@moon-maintainer/api/response/deployment-get.response';
import { DeploymentController }                   from '@moon-maintainer/api/deployment.controller';
import { SkillController }                        from '@moon-maintainer/api/skill.controller';

import { TitleDocumentController }                from '@moon-public/api/title-document.controller';
import { ChatSetting }                            from '@moon-maintainer/api/request/chat-setting.request';

@Component({
  selector: 'moon-skill-advanced-edit-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    InputTextModule, DropdownModule,
    ProgressBarModule, ButtonModule, RippleModule,
    MoonFormControlComponent, MoonFormPageComponent,
    FieldsetModule, SliderModule, InputNumberModule,
  ],
  templateUrl: './skill-advanced-edit-modal.component.html',
  styleUrls: ['./skill-advanced-edit-modal.component.scss'],
  providers: [
    SkillController,
    DeploymentController,
    TitleDocumentController,
  ]
})
export class SkillAdvancedEditModalComponent implements OnInit {
  public MSLoading: boolean = true;
  public MSDeploymentSelectItems: SelectItem[] = [{ label: 'Select Deployment', value: null }];

  public MSParentForm: FormGroup<FormGroupOf<ChatSetting>> = new FormGroup<FormGroupOf<ChatSetting>>({
    // FormBuilder PlaceHolder - DO NOT REMOVE
    deploymentID: new FormControl(null, { nonNullable: true }),
    maxOutputTokens: new FormControl(0, { nonNullable: true }),
    temperature: new FormControl(0, { validators: [Validators.min(0), Validators.max(1)], nonNullable: true }),
    topP: new FormControl(0, { validators: [Validators.min(0), Validators.max(1)], nonNullable: true }),
    frequencyPenalty: new FormControl(0, { validators: [Validators.min(0), Validators.max(2)], nonNullable: true }),
    presencePenalty: new FormControl(0, { validators: [Validators.min(0), Validators.max(2)], nonNullable: true }),
    stopSequence: new FormControl(),
  });
  
  public MSIsFormSubmitting: boolean = false;
  constructor(
    private _dynamicDialogConfig: DynamicDialogConfig<ChatSetting>,
    private _dynamicDialogRef: DynamicDialogRef,
    private _moonMessageService: MoonMessageService,
    private _deploymentController: DeploymentController,
    private _componentUtilityService: ComponentUtilityService
  ) { }

  async ngOnInit() {
    try{
      await this.loadDeploymentList();

      const data: ChatSetting | undefined = this._dynamicDialogConfig.data;
      if (data == null) {
        this._moonMessageService.showToastMessage(MessageSeverity.Info, "No Item found.");
        this._dynamicDialogRef.close();
        return;
      }
      
      this.MSParentForm.patchValue(data);
    }
    finally{
      this.MSLoading = false;
    }
  }
  
  private async loadDeploymentList() {
    const apiResult: ApiContentResult<DeploymentGet[]> = await this._deploymentController.GetList();
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      const deploymentGets = apiResult.content;
      this.MSDeploymentSelectItems = deploymentGets.map(deployment => {
        return {
          label: deployment.deploymentName ?? String.empty,
          value: deployment.deploymentID ?? 0,
          title: `${deployment.modelName} v${deployment.modelVersion} (${deployment.region}) (Input: ${deployment.modelInputTokens} Output: ${deployment.modelOutputTokens})` };
      });
    }
  }

  public MSSetFormControlValue(propertyKey: string, event: string | number | null): void {
    if (event == null) {
      event = 0;
    }
    this.MSParentForm.get(propertyKey)?.setValue(+event);
  }

  public async MSOnClickSubmit() {

    if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
      this._dynamicDialogRef.close(this.MSParentForm.value);
    }
  }

  public MSOnClickCancel() {
    this._dynamicDialogRef.close();
  }
}
