// Moon Auto Generated (1.0.0) at 11/27/2023 6:19:25 PM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { DocumentCategory }                 from "@moon-collaborator/api/request/document-category.request";
import { DocumentCategoryGet }              from "@moon-collaborator/api/response/document-category-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";

@Injectable()
export class DocumentCategoryController {
  
  private getBaseUrl(environmentName: string): string {
    return this._moonApi.getCollaboratorUrl(environmentName, ConstantString.DocumentCategory);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   GET: Collaborator/v1/{environmentName}/DocumentCategory
 */
  public async GetList(environmentName: string
    ): Promise<ApiContentResult<DocumentCategoryGet[]>> {
    
    const url = this.getBaseUrl(environmentName);
    return this._moonApi.getAsync<DocumentCategoryGet[]>(url);
  }
  
/**
 *   POST: Collaborator/v1/{environmentName}/DocumentCategory
 */
  public async Create(environmentName: string, documentCategory: DocumentCategory
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl(environmentName);
    return this._moonApi.postWithResultAsync(url, documentCategory);
  }
  
/**
 *   GET: Collaborator/v1/{environmentName}/DocumentCategory/{documentID}
 */
  public async GetByID(environmentName: string, documentCategoryID: number
    ): Promise<ApiContentResult<DocumentCategoryGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentCategoryID);
    return this._moonApi.getAsync<DocumentCategoryGet>(url);
  }
  
/**
 *   PUT: Collaborator/v1/{environmentName}/DocumentCategory/{documentID}
 */
  public async Update(environmentName: string, documentCategoryID: number, documentCategory: DocumentCategory
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentCategoryID);
    return this._moonApi.putAsync(url, documentCategory);
  }
  
/**
 *   DELETE: Collaborator/v1/{environmentName}/DocumentCategory/{documentID}
 */
  public async Delete(environmentName: string, documentCategoryID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(environmentName), documentCategoryID);
    return this._moonApi.deleteAsync(url);
  }
}
