<!-- Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>

<div
    class="card moon-card"
    *ngIf="!MSLoading"
>
    <airplane-table
        [MSPageTitle]="MSPageTitle"
        [MSAirplaneList]="MSAirplaneList"
        [MSLoading]="MSLoading"
        (MSReloadList)="MSLoadAirplaneList()"
    ></airplane-table>
</div>