import { CommonModule }                                               from '@angular/common';
import { Component, EventEmitter, Input, Output }                     from '@angular/core';
import { ReactiveFormsModule }    from '@angular/forms';
// Third party imports
import { BadgeModule }                                                from 'primeng/badge';
import { ButtonModule }                                               from 'primeng/button';
import { DialogModule }                                               from 'primeng/dialog';
import { FileSelectEvent, FileUpload, FileUploadModule }       from 'primeng/fileupload';
import { InputTextModule }                                            from 'primeng/inputtext';
import { InputTextareaModule }                                        from 'primeng/inputtextarea';
import { RippleModule }                                               from 'primeng/ripple';
// Moon Imports
import { ComponentUtilityService }                                    from '@moon-core/services/component-utility.service';
import { TitleExceptionGet }                                          from '@moon-public/api/response/title-exception.response';
import { TitleExceptionController }                                   from '@moon-public/api/title-exception.controller';
import { ChatLogComponent }                                           from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { MoonFormControlComponent }                                   from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonMessageService }                                         from '@moon-shared/services/moon-message.service';
import { FieldValues }                                                from '@moon-shared/constants/field-values';

@Component({
  selector: 'moon-title-exception-customer',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    ButtonModule, RippleModule, BadgeModule, InputTextModule, InputTextareaModule, DialogModule, FileUploadModule,
    ChatLogComponent, MoonFormControlComponent,
  ],
  templateUrl: './title-exception-customer.component.html',
  styleUrls: ['./title-exception-customer.component.scss'],
  providers: [TitleExceptionController]
})
export class TitleExceptionCustomerComponent {
  public MSUploadingDocument: boolean = false;
  public MSSaving: boolean = false;
  @Input() public MSTitleException: TitleExceptionGet;
  @Output() public MSOnTitleExceptionChange = new EventEmitter();
  public MSShowChatLog: boolean = false;
  public MSChatLogID: number;
  constructor(
    private _moonMessageService: MoonMessageService,
    private _titleExceptionController: TitleExceptionController,
    private _componentUtilityService: ComponentUtilityService
  ) { }

  public async MSOnClickUploadDocument(event: FileSelectEvent, fileUpload: FileUpload) {
    const file = event.files[0];
    try {
      if (this._componentUtilityService.IsFileValid(file, { fileExtensions: [FieldValues.FileExtension_PDF] })) {
        this.MSUploadingDocument = true;
        const apiResult = await this._titleExceptionController.PostTitleDocumentBlob(this.MSTitleException.titleSummaryID, this.MSTitleException.titleExceptionID, file);

        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess('Title document upload successful.');
          this.MSOnTitleExceptionChange.emit();
        }
      }
    }
    finally {
      fileUpload.clear();
      this.MSUploadingDocument = false;
    }
  }

}
