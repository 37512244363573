// Moon Auto Generated (1.0.0) at 11/26/2023 10:53:04 PM
import { NgIf }                                 from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild, OnDestroy }                    from '@angular/core';
import { ActivatedRoute }                       from '@angular/router';
// Third Party Imports
import { MenuItem }                             from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }                 from '@app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }          from '@moon-core/models/api-result';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';

import { MoonLoadingComponent }                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }                from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }                       from '@moon-shared/constants/constant-string';
import { DefaultValues }                        from '@moon-shared/constants/default-values';
import { RouteParameter }                       from '@moon-shared/constants/route-parameter';
import { RoutePath }                            from '@moon-shared/constants/route-path';

import { SkillExampleGet }                      from '@moon-maintainer/api/response/skill-example-get.response';
import { SkillExampleController }               from '@moon-maintainer/api/skill-example.controller';
import { SkillController }                      from '@moon-maintainer/api/skill.controller';
import { MaintainerRouter }                     from '@moon-maintainer/maintainer.router';
import { SkillExampleFormComponent }            from '@moon-maintainer/skill-example/skill-example-form/skill-example-form.component';
import { SkillExampleTableComponent }           from '@moon-maintainer/skill-example/skill-example-table/skill-example-table.component';
import { HistoryService }                       from '@moon-shared/services/history.service';

@Component({
    selector: 'skill-example-add-page',
    templateUrl: './skill-example-edit-page.component.html',
    styleUrls: ['./skill-example-edit-page.component.css'],
    standalone: true,
    imports: [
      NgIf, MoonLoadingComponent, 
      MoonEditPageComponent, 
      SkillExampleTableComponent, 
      SkillExampleFormComponent,
    ],
    providers: [
      SkillController, 
      SkillExampleController,
      MaintainerRouter,
    ]
})
export class SkillExampleEditPageComponent implements OnInit, OnDestroy {

  public MSSkillID: number | null;
  public MSSkillExampleID: number | null;
  public MSSkillExampleGet: SkillExampleGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.SkillExample.getDisplayName();
  public MSEnvironmentName = String.empty;
  public MSDisplayRouteBackButton: boolean = true;
  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _skillExampleController: SkillExampleController,
    private _maintainerRouter: MaintainerRouter,
    private _historyService: HistoryService
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSSkillExampleID){
      this.setHistoryEntityValues(true, ConstantString.SkillExample, this.MSSkillExampleID);
      await this.getSkillExampleByID(this.MSSkillExampleID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSSkillExampleID){
      this.doRemove(this.MSSkillExampleID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSSkillExampleID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    const editPageBreadcrumb: MenuItem[] = [
      { label: RoutePath.Skill, routerLink: this._maintainerRouter.SkillRouteLink() }, 
      { label: RoutePath.SkillExample.getDisplayName(), routerLink: this._maintainerRouter.SkillExampleRouteLink(this.MSSkillID ?? 0) },
      { label: this.MSSkillExampleID ? RoutePath.Edit : RoutePath.Add, routerLink: null }
    ];
    
    this._appBreadcrumbService.setItems(editPageBreadcrumb);
  }
  
  private readRouteParameters() {
    const skillID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.SkillID);
    const skillExampleID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.SkillExampleID);

    this.MSSkillID = skillID ? +skillID : null;
    this.MSSkillExampleID = skillExampleID ? +skillExampleID : null;
  }

  private async getSkillExampleByID(skillExampleID: number) {
    if (skillExampleID) {
      const apiResult: ApiContentResult<SkillExampleGet> = await this._skillExampleController.GetByID(skillExampleID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSSkillExampleGet = apiResult.content;
      }
    }
  }

  private async doRemove(skillExampleID: number) {
    const apiResult: ApiResult = await this._skillExampleController.Delete(skillExampleID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToSkillExample(this.MSSkillID ?? DefaultValues.NoID);
    }
  }

  public OnClickRouteBack() {
    this._maintainerRouter.ToSkillExample(this.MSSkillID ?? 0);
  }

  private setHistoryEntityValues(showButton: boolean, entityType: string | null, entityID: number | null): void {
    this._historyService.setHistoryEntityValues(showButton, entityType, entityID, null);
  }

  ngOnDestroy(): void {
    this.setHistoryEntityValues(false, null, null);
  }
}
