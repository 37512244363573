<div class="p-4 pt-0"></div>

<p-progressBar
    *ngIf="MSLoading"
    mode="indeterminate"
    styleClass="h-1px"
></p-progressBar>

<moon-form-page
    *ngIf="!MSLoading"
    [MSParentForm]="MSParentForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    [MSDisplaySaveButton]="false"
    (MSOnClickEventSubmit)="MSOnClickSubmit()"
    (MSOnClickEventCancel)="MSOnClickCancel()"
>
    <div
        [formGroup]="MSParentForm"
        class="pb-4"
    >
        <div class="col-8">
            <moon-form-control
                [MSLabel]="'Deployment'"
                [MSFormControl]="MSParentForm.controls.deploymentID"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >

                <p-dropdown
                    [options]="MSDeploymentSelectItems"
                    formControlName="deploymentID"
                    optionLabel="label"
                    optionValue="value"
                >
                    <ng-template
                        let-option
                        pTemplate="item"
                    >
                        {{ option.label }} <br />
                        <small> {{option.title}} </small>
                    </ng-template>
                </p-dropdown>
            </moon-form-control>

            <moon-form-control
                [MSLabel]="'Max Output Tokens'"
                [MSFormControl]="MSParentForm.controls.maxOutputTokens"
                [MSLabelSize]="4"
                [MSInputSize]="8"
            >
                <input
                    type="number"
                    formControlName="maxOutputTokens"
                    pInputText
                />
            </moon-form-control>
            
            <p-fieldset legend="Controls">
                <moon-form-control
                    [MSLabel]="'Temperature'"
                    [MSFormControl]="MSParentForm.controls.temperature"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="1"
                            [step]="0.025"
                            formControlName="temperature"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('temperature', $event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            #Temp
                            class="w-8rem"
                            type="number"
                            formControlName="temperature"
                            [step]="0.1"
                            [min]="0"
                            [max]="1"
                            (onInput)="MSSetFormControlValue('temperature', $event.value)"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                        />
                    </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Top P'"
                    [MSFormControl]="MSParentForm.controls.topP"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="1"
                            [step]="0.025"
                            formControlName="topP"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('topP', $event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            class="w-8rem"
                            type="number"
                            formControlName="topP"
                            [step]="0.1"
                            [min]="0"
                            [max]="1"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                            (onInput)="MSSetFormControlValue('topP', $event.value)"
                        />
                    </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Frequency Penalty'"
                    [MSFormControl]="MSParentForm.controls.frequencyPenalty"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="2"
                            [step]="0.025"
                            formControlName="frequencyPenalty"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('frequencyPenalty',$event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            class="w-8rem"
                            type="number"
                            formControlName="frequencyPenalty"
                            [step]="0.1"
                            [min]="0"
                            [max]="2"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                            (onInput)="MSSetFormControlValue('frequencyPenalty', $event.value)"
                        />
                    </div>
                </moon-form-control>

                <moon-form-control
                    [MSLabel]="'Presence Penalty'"
                    [MSFormControl]="MSParentForm.controls.presencePenalty"
                    [MSLabelSize]="4"
                    [MSInputSize]="8"
                >
                    <div class="flex align-items-center gap-4">
                        <p-slider
                            [min]="0"
                            [max]="2"
                            [step]="0.025"
                            formControlName="presencePenalty"
                            class="w-full"
                            (onChange)="MSSetFormControlValue('presencePenalty',$event.value!.toString())"
                        >
                        </p-slider>
                        <p-inputNumber
                            class="w-8rem"
                            type="number"
                            formControlName="presencePenalty"
                            [step]="0.1"
                            [min]="0"
                            [max]="2"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="2"
                            (onInput)="MSSetFormControlValue('presencePenalty', $event.value)"
                        />
                    </div>
                </moon-form-control>
            </p-fieldset>
        </div>

    </div>
    
    <div>
        <div
            class="progress-bar"
            *ngIf="MSIsFormSubmitting"
        >
            Please wait...
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <button
            type="submit"
            pButton
            pRipple
            label="Save"
            class="w-init p-button-raised mr-2"
            [disabled]="MSIsFormSubmitting"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Cancel"
            [disabled]="MSIsFormSubmitting"
            class="w-init p-button-raised p-button-secondary"
            (click)="MSOnClickCancel()"
        ></button>
    </div>
</moon-form-page>