import { FormGroup } from '@angular/forms';
import { ModifiedFields } from './../models/modified-fields.model';
import { Injectable } from '@angular/core';

@Injectable()
export class ModifiedFieldsService {
  constructor() {}

  public GetModifiedValuesFromObjects(modifedData: Object, originalData: Object): ModifiedFields[] {
    const modifiedValues: ModifiedFields[] = [];

    for (const [key, modifiedValue] of Object.entries(modifedData)) {

      const originalValue = originalData[key as keyof Object];
      if (originalValue !== modifiedValue) {
        const modifiedFields = { PropertyName: key, OriginalValue: String(originalValue), ModifiedValue: String(modifiedValue) };
        modifiedValues.push(modifiedFields);
      }
    }
    
    return modifiedValues;
  }

  public GetModifiedValue<T>(parentForm: FormGroup, originalData: T): ModifiedFields[] {
    return Object.keys(parentForm.controls)
      .filter((key) => parentForm.get(key)?.dirty)
      .reduce<ModifiedFields[]>((obj, key) => {
        const parentFormControlValue = parentForm.get(key)?.value;
        const originalValue = originalData[key as keyof T];

        if (parentFormControlValue !== originalValue) {
          obj.push({
            PropertyName: key,
            OriginalValue: String(originalValue),
            ModifiedValue: String(parentFormControlValue),
          });
        }
        return obj;
      }, []);
  }
}
