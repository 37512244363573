// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
import { Component, Input, OnInit,
    ViewEncapsulation }                 from "@angular/core";
import { NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { Table, TableModule }           from "primeng/table";
// Moon imports
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { TableColumn }                  from '@moon-shared/types/table-column.type'
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { MoonNotificationFind }         from "@moon-maintainer/api/response/moon-notification-find.response";
import { MoonNotificationGet }          from "@moon-maintainer/api/response/moon-notification-get.response";

@Component({
    selector: "moon-notification-table",
    templateUrl: "./moon-notification-table.component.html",
    styleUrls: ['./moon-notification-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle,
            TableModule, ButtonModule, InputTextModule,
            MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent],
    providers: [MaintainerRouter]
})
export class MoonNotificationTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSMoonNotificationList: MoonNotificationFind[];
    @Input() public MSLoading = true;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.NotificationType.toCamelCase(), header: FieldNames.NotificationType.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.NotificationDescription.toCamelCase(), header: FieldNames.NotificationDescription.getDisplayName(), class: 'w-25per'},

        // Column PlaceHolder - DO NOT REMOVE
        {field: FieldNames.Actions.toCamelCase(), header: FieldNames.Actions, class: 'w-5per'}
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;
    public MSNumberOfFilteredValues: number;

    constructor(private _maintainerRouter: MaintainerRouter) {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = true;
        this.MSDisplayFind = false;
    }

    public OnClickItem(item: MoonNotificationGet): void {
        this._maintainerRouter.RouteToMoonNotificationSubscription(item.notificationType, item.moonNotificationID);
    }

    public OnClickCreate(): void {
        this._maintainerRouter.RouteToMoonNotificationCreate();
    }

    public OnClickFind(): void {
        this._maintainerRouter.RouteToMoonNotification();
    }

    public OnSearchInput(MoonNotificationTable: Table, searchInput: string): void {
        MoonNotificationTable.filterGlobal(searchInput, "contains");
    }

    public MSOnFilter(eventItems: MoonNotificationGet[]) {
        this.MSNumberOfFilteredValues = eventItems.length;
    }
}
