import { DecimalPipe, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, OnDestroy, OnInit }                 from '@angular/core';
import { FormGroup, FormsModule }                       from '@angular/forms';
//Third Party Imports
import { PdfViewerModule }                              from 'ng2-pdf-viewer';
import { PDFDocumentProxy }                             from 'pdfjs-dist/types/src/display/api';
import { ButtonModule }                                 from 'primeng/button';
import { DialogModule }                                 from 'primeng/dialog';
import { InputNumberModule }                            from 'primeng/inputnumber';
import { RippleModule }                                 from 'primeng/ripple';
// MS Imports
import { ConsumerRouter }                               from '@moon-consumer/consumer.router';
import { TitleCommitmentWizardService }                 from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { PdfPagePreviewComponent }                      from '@moon-consumer/title-commitment/add-title-commitment-wizard/title-commitment-mark-exception-page/pdf-page-preview/pdf-page-preview.component';
import { TitleCommitmentUpload }                        from '@moon-public/api/request/title-commitment.upload';
import { TitleCommitmentPdf }                           from '@moon-maintainer/title-commitment-advanced/title-commitment-pdf';
import { MoonLoadingComponent }                         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MessageSeverity }                              from '@moon-shared/constants/message-severity';
import { MoonEnterKeyupDirective }                      from '@moon-shared/directives/moon-enter-keyup.directive';
import { MoonMessageService }                           from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                                  from '@moon-shared/types/form-group-of.type';

@Component({
  selector: 'moon-title-commitment-mark-page',
  standalone: true,
  imports: [
    DecimalPipe, FormsModule, NgClass, NgFor, NgIf, SlicePipe,
    ButtonModule, InputNumberModule, RippleModule, DialogModule,
    PdfViewerModule,
    MoonLoadingComponent, MoonEnterKeyupDirective, PdfPagePreviewComponent
  ],
  templateUrl: './title-commitment-mark-exception-page.component.html',
  styleUrl: './title-commitment-mark-exception-page.component.scss',
  providers: [ConsumerRouter]
})
export class TitleCommitmentMarkExceptionPageComponent implements OnInit, OnDestroy {
  public MSLoading: boolean = false;
  public MSLoadingMessage: string = 'Loading PDF';
  public get MSTitleCommitmentForm(): FormGroup<FormGroupOf<TitleCommitmentUpload>> {
    return this._titleCommitmentWizardService.MSTitleCommitmentForm;
  }
  public get MSTitleCommitmentPdfBlob(): TitleCommitmentPdf {
    return this._titleCommitmentWizardService.MSTitleCommitmentPdfBlob;
  }
  public MSShowPdfPreviewPage: boolean = false;
  public MSPreviewPageNumber!: number;

  constructor(
    private _titleCommitmentWizardService: TitleCommitmentWizardService,
    private _moonMessageService: MoonMessageService,
    private _consumerRouter: ConsumerRouter,
  ) { }
  ngOnDestroy(): void {
    this.MSTitleCommitmentPdfBlob.pdfSrc = null;
  }
  ngOnInit() {
    const pdfFile: File = this.MSTitleCommitmentForm.controls.inputFile?.getRawValue() as File;
    if (pdfFile == null) {
      this._moonMessageService.showMessages(MessageSeverity.Warn, "Cannot retrieve file. Please fill the form again.");
      this._consumerRouter.RouteToTitleCommitmentsAdd();
      return;
    }

    this.MSLoading = true;
    this.MSTitleCommitmentPdfBlob.ReadContentFromFile(pdfFile);
  }
  public async MSOnPdfLoadCompleted(event: PDFDocumentProxy): Promise<void> {
    try {
      await this.MSTitleCommitmentPdfBlob.OnPdfLoadComplete(event);
    }
    finally {
      this.MSLoading = false;
    }
  }
  public MSMarkUnmarkAsExceptionPage($event: number): void {
    this.MSTitleCommitmentPdfBlob.MarkUnmarkAsExceptionPage($event);
  }

  public MSOnClickPreview(pageNumber: number): void {
    this.MSPreviewPageNumber = pageNumber;
    this.MSShowPdfPreviewPage = true;
    this._titleCommitmentWizardService.MSDisabledSubmitOnEnterKey = true;
  }

  public MSOnClosePreviewDialog(visible: boolean) {
    if (visible === false) {
      this._titleCommitmentWizardService.MSDisabledSubmitOnEnterKey = false;
    }
  }
  
  public MSOnPNextPageEvent() {
    if (this.MSPreviewPageNumber < (this.MSTitleCommitmentPdfBlob.totalPdfPage - 1))
      this.MSPreviewPageNumber = this.MSPreviewPageNumber + 1
  }
  public MSOnPreviousPageEvent() {
    if (this.MSPreviewPageNumber > 0)
      this.MSPreviewPageNumber = this.MSPreviewPageNumber - 1
  }
}
