<li
    class="py-2 pl-4 border-bottom-1 "
    [ngClass]="MSTitleDocument.hasError ? 'border-top-1 border-red-500' : 'surface-border'"
>
    <div class="flex flex-1 gap-3 my-2 align-items-start">
        <div class="flex flex-column">
            <div class="flex gap-2">
                <h1 class="text-base mb-1">
                    {{MSDocumentIndexNumber+1}}.&nbsp;{{MSTitleDocument.textList}}
                </h1>
                <div
                    *ngIf="MSTitleDocument.numberOfPages"
                    class="text-sm"
                >
                    Pages - {{MSTitleDocument.numberOfPages}}
                </div>
            </div>
            <div
                *ngIf="MSTitleDocument.fileSize"
                class="text-sm"
            >
                Size: {{MSTitleDocument.fileSize / 1024 | number:'1.0-2'}} kb
            </div>
            <div
                *ngIf="MSTitleDocument.fileUrl"
                class="text-sm"
            >
                Url:
                <a
                    class="cursor-pointer hover:text-blue-500"
                    [href]="MSTitleDocument.fileUrl"
                    target="_blank"
                >
                    {{MSTitleDocument.fileUrl | slice:0:80}}
                    <span *ngIf="MSTitleDocument.fileUrl.length > 80">...</span>
                </a>
            </div>
        </div>
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleDocument.exceptionType"
    >
        <span class="font-bold">Exception Type</span>:
        <div>
            <span>
                {{MSTitleDocument.exceptionType}}
            </span>
            <!-- <span
                *ngIf="MSTitleDocument.overrideExceptionType"
                class="text-gray-400 text-sm"
            >(Edited)
            </span> -->
        </div>
    </div>
    <div
        class="mb-2"
        *ngIf="MSTitleDocument.recommendedAction"
    >
        <span class="font-bold">Recommended Action</span>:
        <div>
            <span>{{MSTitleDocument.recommendedAction}}
            </span>
            <!-- <span
                *ngIf="MSTitleDocument.overrideRecommendedAction"
                class="text-gray-400 text-sm"
            >(Edited)
            </span> -->
        </div>
    </div>

    <div
        class="mb-2"
        *ngIf="MSTitleDocument.summaryText"
    >
        <span class="font-bold">Summary</span>:
        <div>
            <!-- <span
                *ngIf="MSTitleDocument.overrideSummaryText"
                class="text-gray-400 text-sm"
            >(Edited)
            </span> -->
            <span>
                <ng-container *ngIf="MSTitleDocument.summaryObject; else showSummaryText">
                    <div
                        *ngFor="let keyValue of MSTitleDocument.summaryObject | keyvalue:MSPreserveOriginalOrderInKeyValuePipe">
                        <span class="font-bold">{{keyValue.key}}</span> : {{keyValue.value}}
                    </div>
                </ng-container>
                <ng-template #showSummaryText>
                    {{MSTitleDocument.summaryText }}
                </ng-template>
            </span>
        </div>
    </div>
</li>
