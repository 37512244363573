import { DecimalPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, SlicePipe }   from '@angular/common';
import { Component, OnInit }                                                              from '@angular/core';
import {
  FormsModule, ReactiveFormsModule,
}                                                                                 from '@angular/forms';
// Third party imports
import { PdfViewerModule }                                                        from 'ng2-pdf-viewer';
import { ConfirmationService, MenuItem }                                          from 'primeng/api';
import { BadgeModule }                                                            from 'primeng/badge';
import { ButtonModule }                                                           from 'primeng/button';
import { ConfirmDialogModule }                                                    from 'primeng/confirmdialog';
import { DropdownModule }                                                         from 'primeng/dropdown';
import { InputNumberModule }                                                      from 'primeng/inputnumber';
import { InputTextModule }                                                        from 'primeng/inputtext';
import { RadioButtonModule }                                                      from 'primeng/radiobutton';
import { RippleModule }                                                           from 'primeng/ripple';
import { StepsModule }                                                            from 'primeng/steps';
// MS Imports
import { ConsumerRouter }                                                         from '@moon-consumer/consumer.router';
import { AppBreadcrumbService }                                                   from '@app-layout/services/app.breadcrumb.service';
import { AirplaneWizardService }                                                  from '@moon-consumer/airplane/add-airplane-wizard/service/airplane-wizard.service';
import { MoonFormControlComponent }                                               from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }                                                   from '@moon-shared/components/moon-loading/moon-loading.component';
import { RoutePath }                                                              from '@moon-shared/constants/route-path';
import { MoonDragDropDirective }                                                  from '@moon-shared/directives/file-upload-drag-drop.directive';

@Component({
  selector: 'moon-add-airplane-wizard',
  templateUrl: './add-airplane-wizard.component.html',
  styleUrls: ['./add-airplane-wizard.component.scss'],
  standalone: true,
  imports: [
    FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, SlicePipe, DecimalPipe,
    ButtonModule, RippleModule, BadgeModule, InputNumberModule, InputTextModule, ConfirmDialogModule, DropdownModule, RadioButtonModule, StepsModule,
    PdfViewerModule,
    MoonDragDropDirective, MoonFormControlComponent, MoonLoadingComponent
  ],
  providers: [ConsumerRouter, ConfirmationService, AirplaneWizardService]
})
export class AddAirplaneWizardComponent implements OnInit {

  public MSLoading: boolean = false;
  public MSWizardSteps: MenuItem[] = [
    { label: RoutePath.UploadFile.getDisplayName(), routerLink: RoutePath.UploadFile },
    { label: RoutePath.MarkTermPage.getDisplayName(), routerLink: RoutePath.MarkTermPage },
    { label: RoutePath.Process.getDisplayName(), routerLink: RoutePath.Process }
  ] 
  public MSCompletedSteps: Set<string> = new Set;
  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.setBreadcrumb();
  }
  
  private setBreadcrumb() {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.Airplane.getDisplayName(), routerLink: [RoutePath.Airplane] },
      { label: `${RoutePath.Add} Airplane`, routerLink: null }
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }
}
