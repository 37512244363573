// Moon Auto Generated (1.0.0) at 11/27/2023 10:27:34 AM
import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
//Third Party Imports
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
// Moon Imports
import { AppBreadcrumbService } from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult } from '@moon-core/models/api-result';
import { ComponentUtilityService } from '@moon-core/services/component-utility.service';
import { SearchLogController } from '@moon-maintainer/api/search-log.controller';
import { FindSearchLogParameter } from "@moon-maintainer/api/request/find-search-log.parameter";
import { SearchLogFind } from "@moon-maintainer/api/response/search-log-find.response";
import { SearchLogTableComponent } from '@moon-maintainer/search-log/search-log-table/search-log-table.component';
import { MoonLoadingComponent } from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString } from '@moon-shared/constants/constant-string';
import { DbListResult } from '@moon-shared/models/db-list-result.response';
import { MoonMessageService } from '@moon-shared/services/moon-message.service';
import { MessageSeverity } from '@moon-shared/constants/message-severity';
import { DefaultValues } from '@moon-shared/constants/default-values';

@Component({
  selector: 'search-log',
  templateUrl: './search-log.component.html',
  styleUrls: ['./search-log.component.css'],
  standalone: true,
  imports: [NgIf,
    MoonLoadingComponent, SearchLogTableComponent, CalendarModule, FormsModule, ButtonModule],
  providers: [SearchLogController]
})
export class SearchLogComponent implements OnInit {

  public MSSearchLogList: SearchLogFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.SearchLog.getDisplayName();
  public MSEnvironmentName = String.empty;

  public MSDateFrom: Date = new Date();
  public MSDateTo: Date = new Date();

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _searchLogController: SearchLogController,
    private _moonMessageService: MoonMessageService
  ) { }

  ngOnInit() {
    this.setInitialDate();
    this.setBreadcrumb();
    this.loadSearchLogList();
  }

  public MSOnClickSearch(): void {
    this.loadSearchLogList();
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }

  private setInitialDate(): void {
    this.MSDateFrom.setDate(this.MSDateTo.getDate() - 7);
  }

  private async loadSearchLogList() {
    this.MSLoading = true;

    const findParameter = new FindSearchLogParameter();

    findParameter.dateFrom = this.convertToUtc(this.MSDateFrom);
    findParameter.dateTo = this.convertToUtc(this.MSDateTo);

    const apiResult: ApiContentResult<DbListResult<SearchLogFind>[]> = await this._searchLogController.Find(findParameter);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSSearchLogList = [];

      let infoMessages: string[] = [];

      for (const dbResult of apiResult.content) {
        if (dbResult.success) {
          const logs: SearchLogFind[] = dbResult.content;
          logs?.map(log => log.lastUpdatedAt = log.lastUpdatedAtUtc.toLocalDate());
          this.MSSearchLogList.push(...logs);
          infoMessages.push(`${dbResult.environmentName}: Logs load success.`)
        }
        else{
          infoMessages.push(`${dbResult.environmentName} ${dbResult.message}`)
        }
      }

      this._moonMessageService.showToastMessage(MessageSeverity.Info, infoMessages.join(DefaultValues.Separator.repeat(2)));
    }
    this.MSSearchLogList.orderByDescending("lastUpdatedAt");
    this.MSLoading = false;
  }

  private convertToUtc(date: Date): Date {
    return (new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)));
  }
}
