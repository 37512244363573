// Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM
import { Injectable }                   from "@angular/core";
// MS Imports
import {
  ApiBlobResult, ApiContentResult,
  ApiResult,
  IApiResult
}                                       from '@moon-core/models/api-result';
import { MoonApiClient }                from '@moon-core/services/moon-api.client';
import { ChatSetting }                  from "@moon-maintainer/api/request/chat-setting.request";
import { TitleSummaryInternalNotes }    from "@moon-maintainer/api/request/title-summary-internal-notes.request";
import { FindTitleCommitmentParameter } from "@moon-public/api/request/find-title-commitment.parameter";
import { TitleCommitment }              from "@moon-public/api/request/title-commitment.request";
import { DocumentOcr }                  from '@moon-public/api/response/document-ocr.response';
import { TitleCommitmentFind }          from "@moon-public/api/response/title-commitment-find.response";
import { TitleCommitmentFull }          from "@moon-public/api/response/title-commitment-full.response";
import { TitleCommitmentLogGet }        from '@moon-public/api/response/title-commitment-log/title-commitment-log-get.response';
import { TitleCommitmentOcrGet }        from "@moon-public/api/response/title-commitment-log/title-commitment-ocr-get.response";
import { TitleDocumentMap }             from "@moon-public/api/response/title-document-map.response";
import { TitleExceptionMap }            from "@moon-public/api/response/title-exception-map.response";
import { ConstantString }               from "@moon-shared/constants/constant-string";
import { DefaultValues }                from "@moon-shared/constants/default-values";
import { RoutePath }                    from "@moon-shared/constants/route-path";

@Injectable()
export class TitleSummaryController {

  private getBaseUrl(): string {
    return this._moonApi.getBaseUrl(ConstantString.TitleSummary);
  }

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   POST: Public/v1/TitleSummary/Find
   */
  public async Find(findParameter: FindTitleCommitmentParameter
  ): Promise<ApiContentResult<TitleCommitmentFind[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), RoutePath.Find);
    return this._moonApi.postWithResultAsync(url, findParameter);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/Full
   */
  public async GetFullByID(titleSummaryID: number
  ): Promise<ApiContentResult<TitleCommitmentFull>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Full);
    return this._moonApi.getAsync<TitleCommitmentFull>(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleSummaryLog
   */
  public async GetTitleSummaryLog(titleSummaryID: number
  ): Promise<ApiContentResult<TitleCommitmentLogGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.TitleSummaryLog);
    return this._moonApi.getAsync<TitleCommitmentLogGet>(url);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}
   */
  public async Update(titleSummaryID: number, titleSummary: TitleCommitment
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID);
    return this._moonApi.putAsync(url, titleSummary);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/UpdateInternalNotes
   */
  public async UpdateInternalNotes(titleSummaryInternalNotes: TitleSummaryInternalNotes): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryInternalNotes.titleSummaryID, ConstantString.UpdateInternalNotes);
    return this._moonApi.putAsync(url, titleSummaryInternalNotes);
  }

  /**
   *   DELETE: Public/v1/TitleSummary/{titleSummaryID}
   */
  public async Delete(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID);
    return this._moonApi.deleteAsync(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleExceptionMapList
   */
  public async GetExceptionMapList(titleSummaryID: number
  ): Promise<ApiContentResult<TitleExceptionMap[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.TitleExceptionMapList);
    return this._moonApi.getAsync<TitleExceptionMap[]>(url);
  }

  // /**
  //  *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument
  //  */
  // public async GetDocumentList(titleSummaryID: number
  // ): Promise<ApiContentResult<TitleDocumentGet[]>> {

  //   const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.TitleDocument);
  //   return this._moonApi.getAsync<TitleDocumentGet[]>(url);
  // }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocumentMapList
   */
  public async GetDocumentMapList(titleSummaryID: number
  ): Promise<ApiContentResult<TitleDocumentMap[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.TitleDocumentMapList);
    return this._moonApi.getAsync<TitleDocumentMap[]>(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/ExceptionPagesPdfBlob
   */
  public async GetExceptionPagesPdfBlob(titleSummaryID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ExceptionPagesPdfBlob);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/PdfBlob
   */
  public async GetPdfBlob(titleSummaryID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PdfBlob);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/DocxBlob/{blobType}
   */
  public async GetDocxBlob(titleSummaryID: number, blobType: string
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.DocxBlob, blobType);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/Ocr
   */
  public async GetOcr(titleSummaryID: number
  ): Promise<ApiContentResult<DocumentOcr>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Ocr);
    return this._moonApi.getAsync<DocumentOcr>(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/GetOcrSkillData
   */
  public async GetOcrSkillData(titleSummaryID: number
  ): Promise<ApiContentResult<TitleCommitmentOcrGet>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.OcrSkillData);
    return this._moonApi.getAsync<TitleCommitmentOcrGet>(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/GetTitleSummaryOcrText
   */
  public async GetTitleSummaryOcrText(titleSummaryID: number
  ): Promise<IApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PromptTitleSummaryReviseOcrText);
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/BatchOcr/{skipAlreadyCompleted}
   */
  public async OcrPdfs(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.BatchOcr, DefaultValues.N);
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/BatchOcr/{skipAlreadyCompleted}
   */
  public async OcrRemainingPdfs(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.BatchOcr, DefaultValues.Y);
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/BatchDownload/Pdfs
   */
  public async BatchDownloadPdfs(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.BatchDownload, RoutePath.Pdfs);
    return this._moonApi.postAsync(url, null);
  }
  
  // Prompts Start

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/TitleDocument/ChatSetting
   */
  public async GetChatSetting(titleSummaryID: number): Promise<ApiContentResult<ChatSetting>> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ChatSetting);
    return this._moonApi.getAsync<ChatSetting>(url);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/PromptTitleSummary
   */
  public async PromptTitleSummary(titleSummaryID: number, chatSetting: ChatSetting | null = null): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.PromptTitleSummary);
    return this._moonApi.postAsync(url, chatSetting);
  }
  
  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/BatchPrompt/DocumentClassify
   */
  public async BatchPromptDocumentClassify(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.BatchPrompt, RoutePath.DocumentClassify);
    return this._moonApi.postAsync(url, null);
  }
  
  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/BatchPrompt/DocumentSummarize
   */
  public async BatchPromptDocumentSummarize(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.BatchPrompt, RoutePath.DocumentSummarize);
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/BatchPrompt/TitleException
   */
  public async BatchPromptTitleException(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.BatchPrompt, RoutePath.TitleException);
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/BatchPrompt/All
   */
  public async BatchPromptAll(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.BatchPrompt, RoutePath.All);
    return this._moonApi.postAsync(url, null);
  }

  // Prompts End

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/ChatOverride
   */
  public async ChatOverride(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ChatOverride);
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   POST: Public/v1/TitleSummary/{titleSummaryID}/Complete
   */
  public async Complete(titleSummaryID: number
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Complete);
    return this._moonApi.postAsync(url, null);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/ExportPdf
   */
  public async ExportPdf(titleSummaryID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ExportPdf);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   GET: Public/v1/TitleSummary/{titleSummaryID}/ExportOcr
   */
  public async ExportOcr(titleSummaryID: number
  ): Promise<ApiBlobResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ExportOcr);
    return this._moonApi.getBlobAsync(url);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/Page/{pageNumber}/OverrideOcr")]
  
   */
  public async OverrideOcr(titleSummaryID: number, pageNumber: number, overrideValue: string): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.Page, pageNumber, RoutePath.OverrideOcr);
    
    const formBody = new FormData();
    formBody.set('overrideValue', overrideValue);

    return this._moonApi.putAsync(url, formBody);
  }

  /**
   *   PUT: Public/v1/TitleSummary/{titleSummaryID}/ClearLogMessage
   */
  public async ClearTitleSummaryLogMessage(titleSummaryID: number): Promise<ApiResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), titleSummaryID, RoutePath.ClearLogMessage);
    return this._moonApi.putAsync(url, null);
  }

}
