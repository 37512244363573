// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
import { Component, Input, OnInit }                 from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
         ReactiveFormsModule }                      from '@angular/forms';
// Third Party Imports      
import { SharedModule }                             from 'primeng/api';
import { InputTextareaModule }                      from 'primeng/inputtextarea';
import { InputTextModule }                          from 'primeng/inputtext';
import { InputNumberModule }                        from 'primeng/inputnumber';
// Moon Imports     
import { IApiResult }                               from '@moon-core/models/api-result';
import { ComponentUtilityService }                  from '@moon-core/services/component-utility.service';

import { MoonFormControlComponent }                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }                    from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                              from '@moon-shared/types/form-group-of.type';

import { SkillTestData }                            from '@moon-maintainer/api/request/skill-test-data.request';
import { SkillTestDataGet }                         from "@moon-maintainer/api/response/skill-test-data-get.response";
import { SkillTestDataController }                  from '@moon-maintainer/api/skill-test-data.controller';
import { MaintainerRouter }                         from "@moon-maintainer/maintainer.router";

@Component({
    selector: 'moon-skill-test-data-form',
    templateUrl: './skill-test-data-form.component.html',
    styleUrls: ['./skill-test-data-form.component.css'],
    standalone: true,
    imports: [ 
        FormsModule, ReactiveFormsModule, SharedModule,
        MoonFormPageComponent, MoonFormControlComponent,
        FormsModule, ReactiveFormsModule, SharedModule,
        InputTextareaModule, InputTextModule, InputNumberModule, 
    ],
    providers: [SkillTestDataController]
})
export class SkillTestDataFormComponent implements OnInit {
    @Input() public MSSkillTestDataGet: SkillTestDataGet;
    @Input() public MSSkillTestDataID: number | null;
    @Input() public MSSkillNumber: string = String.empty;
    @Input() public MSParentForm: FormGroup<FormGroupOf<SkillTestData>> = new FormGroup<FormGroupOf<SkillTestData>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		skillNumber: new FormControl(String.empty, {nonNullable: true }),
		inputData: new FormControl(String.empty, {nonNullable: true }),
		outputData: new FormControl(String.empty, {nonNullable: true }),
    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _skillTestDataController: SkillTestDataController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSSkillTestDataGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls() {        
        this.MSParentForm.controls.skillNumber.setValue(this.MSSkillNumber ?? String.empty);
    }

    private doMapGetDataToFormControl(skillTestData: SkillTestDataGet) {
        if (!skillTestData) {
            return;
        }        
        this.MSSkillTestDataGet = skillTestData;
        this.MSParentForm.patchValue(skillTestData);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSSkillTestDataID
            ? await this._skillTestDataController.Update(this.MSSkillTestDataID, this.MSParentForm.getRawValue())
            : await this._skillTestDataController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        // Place holder
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToSkillTestData(this.MSSkillNumber ?? String.empty);
    }
}
