// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
import { CommonModule, NgIf }                            from '@angular/common';
import { Component, OnInit }                             from '@angular/core';
import { ActivatedRoute, Router }                        from '@angular/router';
//Third Party Imports
import { MenuItem }                                      from 'primeng/api';
import { TableModule, Table }                            from 'primeng/table';
import { ButtonModule }                                  from 'primeng/button';
// Moon Imports
import { AppBreadcrumbService }                          from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult }                              from '@moon-core/models/api-result';
import { ComponentUtilityService }                       from '@moon-core/services/component-utility.service';
import { MoonNotificationController }                    from '@moon-maintainer/api/moon-notification.controller';
import { MoonNotificationSubscriptionController }        from '@moon-maintainer/api/moon-notification-subscription.controller';
import { MoonUserNotificationSubscriptionGet }           from '@moon-maintainer/api/response/moon-user-notification-subscription-get.response';
import { MaintainerRouter }                              from '@moon-maintainer/maintainer.router';
import { MoonNotificationTableComponent }                from '@moon-maintainer/moon-notification/moon-notification-table/moon-notification-table.component';
import { MoonLoadingComponent }                          from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }                         from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { FieldNames }                                    from '@moon-shared/constants/field-names';
import { TableColumn }                                   from '@moon-shared/types/table-column.type';
import { MoonTablePageComponent }                        from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { RoutePath }                                     from '@moon-shared/constants/route-path';
import { MoonTableHeaderComponent }                      from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonNotificationDeliveryGet }                   from '@moon-maintainer/api/response/moon-notification-delivery-get.response';

@Component({
  selector: 'moon-notification-delivery-page',
  templateUrl: './moon-notification-delivery-page.component.html',
  styleUrls: ['./moon-notification-delivery-page.component.css'],
  standalone: true,
  imports: [
    NgIf,
    CommonModule,
    ButtonModule,
    TableModule,
    MoonLoadingComponent,
    MoonEditPageComponent,
    MoonNotificationTableComponent,
    MoonTablePageComponent,
    MoonTableHeaderComponent,
  ],
  providers: [
    MoonNotificationController,
    MoonNotificationSubscriptionController,
    MaintainerRouter,
  ],
})
export class MoonNotificationDeliveryPageComponent implements OnInit {
  public MSLoading: boolean = true;
  public MSDisplayRemoveButton: boolean = false;
  public MSDisplayFind: boolean = false;
  public MSDisplayCreate: boolean = false;
  public MSEnableSearch: boolean = true;

  public MSMoonNotificationID: number | null;
  public MSMoonNotificationType: string | null;
  public MSPageTitle: string = RoutePath.Notification.getDisplayName();
  public MSDeliveryList: MoonNotificationDeliveryGet[];
  public MSNumberOfFilteredValues: number;
  public MSTableColumns: TableColumn[] = [
    {
      field: FieldNames.SubscriberUserName.toCamelCase(),
      header: FieldNames.SubscriberUserName.getDisplayName(),
      class: 'w-25per',
    },
    {
      field: FieldNames.EmailAddress.toCamelCase(),
      header: FieldNames.EmailAddress.getDisplayName(),
      class: 'w-25per',
    },
    {
      field: FieldNames.BatchIdentifier.toCamelCase(),
      header: FieldNames.BatchIdentifier.getDisplayName(),
      class: 'w-25per',
    },
    {
      field: FieldNames.LastUpdatedBy.toCamelCase(),
      header: FieldNames.LastUpdatedBy.getDisplayName(),
      class: 'w-25per',
    },
    {
      field: FieldNames.LastUpdatedAtUtc.toCamelCase(),
      header: FieldNames.LastUpdatedAtUtc.getDisplayName(),
      class: 'w-25per',
    },
  ];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _moonNotificationController: MoonNotificationController
  ) {
    const navigation = this._router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        notificationType: string | null;
      };
      this.MSMoonNotificationType = state?.notificationType;
    }
    this.MSPageTitle += this.MSMoonNotificationType
      ? `: ${this.MSMoonNotificationType.getDisplayName()}`
      : String.empty;
  }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSMoonNotificationID) {
      await this.getListByMoonNotificationID(this.MSMoonNotificationID);
    }
  }

  // Start: Button Click Event Handlers

  public OnSearchInput(subscriptionTable: Table, searchInput: string): void {
    subscriptionTable.filterGlobal(searchInput, 'contains');
  }

  public MSOnFilter(eventItems: MoonUserNotificationSubscriptionGet[]): void {
    this.MSNumberOfFilteredValues = eventItems.length;
  }
  
  // End: Button Click Event Handlers

  private setBreadcrumb(): void {
    const items: MenuItem[] = [
      {
        label: RoutePath.Notification.getDisplayName(),
        routerLink: RoutePath.Notification,
      },
      {
        label: RoutePath.Delivery,
        routerLink: null,
      },
    ];
    this._appBreadcrumbService.setItems(items);
  }

  private readRouteParameters(): void {
    const moonNotificationID = this._activatedRoute.snapshot.paramMap.get(
      FieldNames.MoonNotificationID
    );
    this.MSMoonNotificationID = moonNotificationID ? +moonNotificationID : null;
  }

  private async getListByMoonNotificationID(
    moonNotificationID: number
  ): Promise<void> {
    try {
      this.MSLoading = true;
      if (moonNotificationID) {
        const apiResult: ApiContentResult<MoonNotificationDeliveryGet[]> =
          await this._moonNotificationController.GetListMoonNotificationDelivery(
            moonNotificationID
          );
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this.MSDeliveryList = apiResult.content;
        }
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
