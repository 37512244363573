<moon-loading *ngIf="MSLoading"></moon-loading>
<div class="clearfix"></div>

<ng-container *ngIf="!MSLoading && MSTitleCommitment as titleCommitment">
    <div id="title-commitment">
        <ng-template #loading>
            <moon-loading></moon-loading>
        </ng-template>
        <!-- Show list if there is any title summary already added else show drop files input -->
        <p-toolbar styleClass="mb-1 gap-2">
            <ng-template pTemplate="left">
                <div class="w-full flex">
                    <button
                        pButton
                        pRipple
                        icon="pi pi-arrow-left"
                        title="Go back"
                        class="h-min"
                        (click)="MSOnClickGoBack()"
                        class="p-button-raised moon-button"
                    ></button>
                    <button
                        pButton
                        pRipple
                        title="Refresh"
                        class="p-button-raised moon-button"
                        icon="pi pi-refresh"
                        type="button"
                        (click)="MSOnRefreshClick()"
                    ></button>

                    <moon-dropdown-button
                        [MSLabel]="'Batch Continue'"
                        [MSDisabled]="MSApplying"
                        [MSButtons]="MSBatchContinueMenuItems"
                    ></moon-dropdown-button>

                    <moon-dropdown-button
                        [MSLabel]="'Batch Run Prompts'"
                        [MSDisabled]="MSApplying"
                        [MSButtons]="MSBatchPromptMenuItems"
                    ></moon-dropdown-button>

                    <button
                        *ngIf="MSShowMarkAsComplete"
                        pButton
                        pRipple
                        type="button"
                        label="Mark as Complete"
                        class="p-button-raised"
                        title="Mark as Complete"
                        (click)="MSOnMarkAsComplete()"
                    ></button>
                    <moon-dropdown-button
                        [MSLabel]="'Download'"
                        [MSDisabled]="MSApplying"
                        [MSButtons]="MSDownloadButtons"
                    ></moon-dropdown-button>

                    <button
                        #btn
                        type="button"
                        pButton
                        pRipple
                        [icon]="'pi pi-book'"
                        [pTooltip]="'Internal Notes'"
                        (click)="MSOnClickUpdateInternalNotes()"
                    > </button>
               </div>
            </ng-template>
            <ng-template pTemplate="right">
                <button
                    type="button"
                    pButton
                    title="View all title commitment logs"
                    icon="pi pi-history"
                    [disabled]="MSApplying"
                    (click)="MSOnClickTitleCommitmentLog()"
                ></button>
            </ng-template>
        </p-toolbar>

        <div class="card">
            <div
                class="py-2"
                [ngClass]="titleCommitment.hasError ? 'border-top-1 border-bottom-1 border-red-500' : ''"
            >
                <div class="flex flex-1 gap-3 mb-2 text-lg align-items-center">
                    <div class="flex flex-column">
                        <div class="flex gap-1">
                            <div>{{titleCommitment.matterName}} : {{titleCommitment.fileName}}</div>
                            <div *ngIf="titleCommitment.hasError">
                                <p-badge
                                    severity="danger"
                                    value="Error"
                                ></p-badge>
                            </div>
                        </div>
                        <div>
                            <span class="text-sm mr-2">
                                Status: {{titleCommitment.status}}
                            </span>
                            <span class="text-sm mr-2">
                                Current step: {{titleCommitment.currentStep}}
                            </span>
                            <span class="text-sm mr-2">
                                Endorsement: {{titleCommitment.propertyState}}
                            </span>
                            <span class="text-sm mr-2">
                                Type: {{titleCommitment.summaryType}}
                            </span>
                            <span class="text-sm mr-2">
                                Exception pages: {{titleCommitment.exceptionPagesCsv}}
                            </span>
                            <span
                                *ngIf="titleCommitment.fileSize"
                                class="text-sm mr-2"
                            >
                                Size - {{titleCommitment.fileSize / 1024 | number:'1.0-2'}} kb
                            </span>
                        </div>
                        <div
                            *ngIf="titleCommitment.logMessage"
                            class="text-sm mt-1"
                        >
                            <div>
                                <span class="font-bold">Log Messages:</span>
                                <button
                                    pButton
                                    pRipple
                                    class="h-min w-min p-0 m-0 ml-2 bg-unset hover:underline text-primary text-base"
                                    label="Clear Log Messages"
                                    title="Clear Log Messages"
                                    (click)="MSClearLogMessage()"
                                > </button>
                            </div>
                            <div class="white-space-pre-line">{{titleCommitment.logMessage}}</div>
                        </div>
                    </div>

                    <div class="ml-auto flex">
                        <button
                            pButton
                            class="moon-action-button p-button-raised"
                            icon="pi pi-file-pdf"
                            label="Summary Review"
                            title="Summary Review"
                            [disabled]="MSApplying"
                            (click)="MSOnClickPdfReviewForSummary(titleCommitment.titleSummaryID, titleCommitment.exceptionPagesCsv, titleCommitment.chatLogID)"
                        > </button>

                        <moon-dropdown-button
                            [MSLabel]="'Title Prompt'"
                            [MSDisabled]="MSApplying"
                            [MSButtons]="MSTitlePromptMenuItems"
                        ></moon-dropdown-button>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="titleCommitment.titleExceptionGetList?.length"
            class="card"
        >
            <div
                *ngFor="let titleException of titleCommitment.titleExceptionGetList"
                class="py-2"
            >
                <moon-title-exception
                    [MSTitleException]="titleException"
                    (MSOnTitleExceptionChange)="MSLoadTitleCommitment()"
                ></moon-title-exception>
                <ul
                    *ngFor="let document of titleException.titleDocumentGetList; let i=index"
                    class="list-none p-0 m-0 moon-document-grid"
                >
                    <moon-title-document
                        [MSApplying]="MSApplying"
                        [MSTitleDocument]="document"
                        [MSTitleCommitmentType]="titleCommitment.summaryType"
                        [MSDocumentIndexNumber]="i"
                        (MSOnTitleDocumentChange)="MSLoadTitleCommitment()"
                    ></moon-title-document>
                </ul>
            </div>
        </div>
    </div>

</ng-container>

<!-- Chat Log Viewer -->
<p-dialog
    header="Chat Log"
    [(visible)]="MSShowChatLog"
    styleClass="w-90vw"
    position="top"
    [draggable]="false"
>
    <moon-chat-log
        *ngIf="MSShowChatLog"
        [MSChatLogID]="MSChatLogID"
        (MSOnOverrideChatLogData)="MSOnOverrideChatLogData()"
    ></moon-chat-log>
</p-dialog>

<!-- Endorsement Chat Log Viewer -->
<p-dialog
    header="Endorsement Chat Log"
    [(visible)]="MSShowEndorsementChatLog"
    styleClass="w-90vw"
    position="top"
    [draggable]="false"
>
    <moon-chat-log
        *ngIf="MSShowEndorsementChatLog"
        [MSChatLogID]="MSEndorsementChatLogID"
    ></moon-chat-log>
</p-dialog>

<!-- Title Combined Log -->
<p-dialog
    [(visible)]="MSShowTitleCombinedLog"
    styleClass="w-90vw h-full moon-dialog-no-header"
    position="top"
    [draggable]="false"
    [modal]="true"
>
    <moon-title-combined-log
        *ngIf="MSShowTitleCombinedLog"
        [MSTitleSummaryID]="MSTitleSummaryID"
        (MSOnTitleReviewError)="MSOnTitleCombinedLogClosed()"
    ></moon-title-combined-log>
</p-dialog>

<p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
    styleClass="w-30rem"
    acceptButtonStyleClass="p-button-text"
    rejectButtonStyleClass="p-button-text"
></p-confirmDialog>

<p-dialog
    [(visible)]="MSShowOcrConfirmation"
    header="Confirmation"
    [modal]="true"
    key="confirm-ocr"
    styleClass="w-30rem"
>
    <p class="mb-5">
        Do you want to skip the documents that already have OCR?
    </p>

    <div class="flex justify-content-end gap-2">
        <button
            pButton
            pRipple
            [disabled]="MSLoading"
            label="No, Ocr all"
            title="No, Ocr all"
            (click)="MSOcrAll()"
        > </button>
        <button
            pButton
            pRipple
            [disabled]="MSLoading"
            label="Yes, only remaining"
            title="Yes, only remaining"
            (click)="MSOcrRemaining()"
        > </button>
    </div>
</p-dialog>

<p-dialog
    header="Rerunning all prompts"
    [(visible)]="MSIsRerunInProgress"
    styleClass="w-50vw"
    position="center"
    [modal]="true"
    [draggable]="false"
    [closeOnEscape]="false"
    [closable]="false"
>
    <p>This process may take a while to run. Please check back in a while.</p>
    <button
        pButton
        pRipple
        icon="pi pi-arrow-left"
        label="Go To Title Commitments"
        title="Go To Title Commitments"
        class="h-min"
        (click)="MSOnClickGoBack()"
        class="p-button-raised moon-button"
    >
    </button>
</p-dialog>