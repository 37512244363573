// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
import { NgIf,NgFor,NgClass }               from '@angular/common';
import { Component, OnInit, ViewChild }     from '@angular/core';
import { ActivatedRoute }                   from '@angular/router';
import { FormControl, FormGroup,
         FormsModule,
         ReactiveFormsModule, Validators }  from '@angular/forms';

// Third Party imports
import { DialogModule }                     from 'primeng/dialog';
import { 
  ConfirmationService, MenuItem, PrimeIcons, 
  SelectItem, SharedModule }                from 'primeng/api';
import { ButtonModule }                     from 'primeng/button';
import { DropdownModule }                   from 'primeng/dropdown';
import { SliderModule }                     from 'primeng/slider';
import { InputNumberModule }                from 'primeng/inputnumber';
import { InputTextareaModule }              from 'primeng/inputtextarea';
import { InputTextModule }                  from 'primeng/inputtext';
import { FieldsetModule }                   from 'primeng/fieldset';
import { TableModule }                      from 'primeng/table';
import { PanelModule }                      from 'primeng/panel';

// Moon Imports
import { AppBreadcrumbService }             from '@app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }      from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';

import { MoonMessageService }               from '@moon-shared/services/moon-message.service';
import { MessageSeverity }                  from '@moon-shared/constants/message-severity';
import { MoonLoadingComponent }             from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                   from '@moon-shared/constants/constant-string';
import { FieldNames }                       from '@moon-shared/constants/field-names';
import { DefaultValues }                    from '@moon-shared/constants/default-values';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';
import { BlackLinesComponent }              from '@moon-shared/components/black-lines/black-lines.component';
import { MoonEditPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { RoutePath }                        from '@moon-shared/constants/route-path';

import { SkillController }                  from '@moon-maintainer/api/skill.controller';
import { SkillTestDataController }          from '@moon-maintainer/api/skill-test-data.controller';
import { SkillTestDataGet }                 from '@moon-maintainer/api/response/skill-test-data-get.response';
import { DeploymentGet }                    from '@moon-maintainer/api/response/deployment-get.response';
import { DeploymentController }             from '@moon-maintainer/api/deployment.controller';
import { SkillGet }                         from '@moon-maintainer/api/response/skill-get.response';
import { ChatSetting }                      from '@moon-maintainer/api/request/chat-setting.request';
import { SkillTestSummary }                 from '@moon-maintainer/models/skill-test-summary.model';
import { SkillTestDataResult }              from '@moon-maintainer/models/skill-test-data-result.model';

import { SkillTestDataResultGet }           from '@moon-maintainer/models/skill-test-data-result-get.model';
import { SkillTestDataTableComponent }      from '@moon-maintainer/skill-test-data/skill-test-data-table/skill-test-data-table.component';
import { MaintainerRouter }                 from '@moon-maintainer/maintainer.router';
import { FormStickyFooterComponent }        from '@moon-shared/components/form-sticky-footer/form-sticky-footer.component';

@Component({
  selector: 'moon-skill-test-data',
  templateUrl: './skill-test-data.component.html',
  styleUrls: ['./skill-test-data.component.css'],
  standalone: true,
  imports: [
    NgIf, NgFor, NgClass, ReactiveFormsModule,
    ButtonModule, DropdownModule, InputNumberModule, SliderModule, DialogModule, TableModule,
    MoonFormPageComponent, MoonFormControlComponent, MoonEditPageComponent, MoonLoadingComponent, 
    SkillTestDataTableComponent, FormsModule, SharedModule, InputTextareaModule, InputTextModule,
    FieldsetModule, BlackLinesComponent, PanelModule, FormStickyFooterComponent,
  ],
  providers: [DeploymentController, SkillTestDataController, SkillController]
})
export class SkillTestDataComponent implements OnInit {
  public MSSkillNumber: string = String.empty;
  public MSSkillGet: SkillGet;
  public MSSkillTestDataList: SkillTestDataGet[];  
  public MSSkillTestResultList: SkillTestDataResultGet[] = [];
  public MSLoading: boolean = true;
  public MSPageTitle: string = ConstantString.Skill.getDisplayName();
  public MSIsFormSubmitting: boolean;
  public MSEnvironmentName = String.empty;  
  public MSDeploymentSelectItems: SelectItem[] = [{ label: 'Select Deployment', value: null }];
  public MSDisplayRemoveButton = false;
  public MSDisplayRouteBackButton: boolean = true;
  @ViewChild("MSTableComponent") MSTableComponent: SkillTestDataTableComponent;
  public MSShowTestResult: boolean = false;
  public MSScrollHeight = "calc(100vh - 280px)";
  public MSCollapseSearch: boolean = false;  

  public MSParentForm: FormGroup<FormGroupOf<ChatSetting>> = new FormGroup<FormGroupOf<ChatSetting>>({
    // FormBuilder PlaceHolder - DO NOT REMOVE
    deploymentID: new FormControl(DefaultValues.NoID, {nonNullable: true }),
    maxOutputTokens: new FormControl(0, {nonNullable: true }),
    temperature: new FormControl(0,  { validators: [Validators.min(0), Validators.max(1)], nonNullable: true }),
    topP: new FormControl(0, { validators: [Validators.min(0), Validators.max(1)], nonNullable: true }),
    frequencyPenalty: new FormControl(0, { validators: [Validators.min(0), Validators.max(2)], nonNullable: true }),
    presencePenalty: new FormControl(0, { validators: [Validators.min(0), Validators.max(2)], nonNullable: true }),
    stopSequence: new FormControl(),
  });

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _maintainerRouter: MaintainerRouter,
    private _skillController: SkillController,
    private _skillTestDataController: SkillTestDataController,
    private _deploymentController: DeploymentController,
    private _confirmationService: ConfirmationService,
    private _moonMessageService: MoonMessageService
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    this.loadSkillTestDataList();
    this.loadDeploymentList();
    
    if (this.MSSkillNumber){
      await this.getSkillByNumber(this.MSSkillNumber);
    }
  }
  
  private async getSkillByNumber(skillNumber: string) {
    if (skillNumber) {
      const apiResult: ApiContentResult<SkillGet> = await this._skillController.GetByNumber(skillNumber);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSSkillGet = apiResult.content;
        this.MSPageTitle += `: ${this.MSSkillGet.skillName}`;
           this.doMapGetDataToFormControl(this.MSSkillGet);
      }
    }
  }

  private doMapGetDataToFormControl(skillGet: SkillGet) {
    if (!skillGet) {
        return;
    }        
    this.MSSkillGet = skillGet;
    this.MSParentForm.patchValue(skillGet);
    this.MSParentForm.markAsDirty();
  }

  private setBreadcrumb() {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.Skill, routerLink: this._maintainerRouter.SkillRouteLink() }, 
      { label: RoutePath.SkillTestData.getDisplayName(), routerLink: null }
    ];
    
    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }
  
  private readRouteParameters() {
    const skillNumber = this._activatedRoute.snapshot.paramMap.get(FieldNames.SkillNumber);
    this.MSSkillNumber = skillNumber ?? String.empty;
  }

  private async loadSkillTestDataList() {    
    try {
      this.MSLoading = true;
      const apiResult: ApiContentResult<SkillTestDataGet[]> = await this._skillController.GetSkillTestDataList(this.MSSkillNumber);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.onGetListSuccess( apiResult.content);
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private onGetListSuccess(skillTestDataList: SkillTestDataGet[]) {
    if (skillTestDataList === null) {
        this.MSLoading = true;
        return;
    }

    this.MSSkillTestDataList = skillTestDataList.map((item: SkillTestDataGet) => {
        item.actions = this.getActions(item);
        return item;
    });
    this.MSLoading = false;
  } 

  public MSSetFormControlValue(propertyKey: string, event: string | number | null): void {
    if (event == null) {
        event = 0;
    }
    this.MSParentForm.get(propertyKey)?.setValue(+event);
  }

  private async loadDeploymentList() {
    const apiResult: ApiContentResult<DeploymentGet[]> = await this._deploymentController.GetList();
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        const deploymentGets = apiResult.content;
        this.MSDeploymentSelectItems = deploymentGets.map(deployment => {
            return { label: deployment.deploymentName ?? String.empty, value: deployment.deploymentID, title: `${deployment.modelName} v${deployment.modelVersion} (${deployment.region}) (Input: ${deployment.modelInputTokens} Output: ${deployment.modelOutputTokens})` };
        });
    }
  }
  
  public OnClickSubmit() {
    if (this.MSIsFormSubmitting) {
        return;
    }
    if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
        this.doPerformTest();
    }
  }

  public MSRefreshPage() {
    this.loadSkillTestDataList();
  }

  public OnClickCancel() {
    this.routeToBasePage();
  }

  private async doPerformTest() {
    this.MSSkillTestResultList = [];
    try {
      this.MSIsFormSubmitting = true;

      const chatSetting: ChatSetting = this.MSParentForm.getRawValue();
      const selectedItems = this.MSTableComponent?.MSSelectedSkillTestDataList;
      if(!selectedItems.length) {
        this._moonMessageService.showToastMessage(MessageSeverity.Info, "Please select the row.");
        return;
      }
      
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Processing. Please wait.");

      selectedItems.forEach(async (testData: SkillTestDataGet) => {

        const apiContentResult: ApiContentResult<SkillTestDataResult> = await this._skillTestDataController.Test(testData.skillNumber, testData.skillTestDataID, chatSetting);
        
        const testResult = new SkillTestDataResultGet();
        testResult.inputData = testData.inputData;
        testResult.outputData = testData.outputData;
        testResult.skillTestDataID = testData.skillTestDataID;
        
        if (apiContentResult.success) {
          const skillTestData: SkillTestDataResult = apiContentResult.content;
          testResult.resultContent = skillTestData.resultContent;
        }
        else {
          testResult.message = apiContentResult.message;
        }
        this.MSSkillTestResultList.push(testResult);
        this.MSShowTestResult = true;
      });

      const skillTestSummary = new SkillTestSummary();
      skillTestSummary.skillNumber = this.MSSkillNumber;
      skillTestSummary.chatSetting = chatSetting;
      // const apiResult: ApiResult = await this._skillTestDataController.SaveTestResult(skillTestSummary);
      // if (this._componentUtilityService.WasSuccessful(apiResult)) {
      //   console.log("Place holder");
      // }
    }
    finally {
      this.MSIsFormSubmitting = false;
    }
  }
  
  private routeToBasePage(): void {
    this._maintainerRouter.ToSkill();
  }

  public OnClickRouteBack() {
    this.routeToBasePage();
  }

  private getActions(item: SkillTestDataGet) {
    return [
        {
            icon: PrimeIcons.PENCIL,
            label: 'Edit Item',
            command: () => {
                this._maintainerRouter.ToSkillTestDataEdit(item.skillNumber, item.skillTestDataID);
            }
        },
        {
            icon: PrimeIcons.TRASH,
            label: 'Delete Item',
            command: () => {
                this._confirmationService.confirm({
                    message: `Are you sure you want to remove?`,
                    accept: () => {
                        this.doRemove(item.skillTestDataID);
                    },
                });
            
            }
        }
    ];
  }

  private async doRemove(skillTestDataID: number) {
    const apiResult: ApiResult = await this._skillTestDataController.Delete(this.MSSkillNumber, skillTestDataID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.loadSkillTestDataList();
    }
  }

  public MSTriggerExpandCollapse(): void {
    this.MSCollapseSearch = !this.MSCollapseSearch;
  }
}
