// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
import { NgIf }                                 from '@angular/common';
import { Component, OnInit }                    from '@angular/core';
//Third Party Imports
import {ConfirmationService, PrimeIcons}        from 'primeng/api';
// Moon Imports
import { AppBreadcrumbService }                 from '@app/app-layout/services/app.breadcrumb.service';
import {ApiContentResult, ApiResult}            from '@moon-core/models/api-result';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';
import { MoonNotificationController }           from '@moon-maintainer/api/moon-notification.controller';
import { FindMoonNotificationParameter }        from "@moon-maintainer/api/request/find-moon-notification.parameter";
import { MoonNotificationFind }                 from "@moon-maintainer/api/response/moon-notification-find.response";
import { MaintainerRouter }                     from '@moon-maintainer/maintainer.router';
import { MoonNotificationTableComponent }       from '@moon-maintainer/moon-notification/moon-notification-table/moon-notification-table.component';
import { MoonLoadingComponent }                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonMessageService }                   from '@moon-shared/services/moon-message.service';
import { RoutePath }                            from '@moon-shared/constants/route-path';

@Component({
  selector: 'moon-notification',
  templateUrl: './moon-notification.component.html',
  styleUrls: ['./moon-notification.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, MoonNotificationTableComponent],
  providers: [MoonNotificationController, MaintainerRouter]
})
export class MoonNotificationComponent implements OnInit {

  public MSMoonNotificationList: MoonNotificationFind[];
  public MSLoading: boolean = true;
  public MSPageTitle: string = RoutePath.Notification.getDisplayName();
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _moonNotificationController: MoonNotificationController,
    private _maintainerRouter: MaintainerRouter,
    private _confirmationService: ConfirmationService,
    private _moonMessageService: MoonMessageService
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadMoonNotificationList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  private async loadMoonNotificationList() {    
    try {
      this.MSLoading = true;
      const findParameter = new FindMoonNotificationParameter();
      const apiResult: ApiContentResult<MoonNotificationFind[]> = await this._moonNotificationController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.onGetListSuccess(apiResult.content)
        this.MSMoonNotificationList = apiResult.content;
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private onGetListSuccess(moonNotificationList: MoonNotificationFind[]) {
    if (moonNotificationList === null) {
        return;
    }

    this.MSMoonNotificationList = moonNotificationList.map((moonNotification: MoonNotificationFind) => {
        moonNotification.actions = this.getActions(moonNotification);
        return moonNotification;
    });
}

  private getActions(moonNotification: MoonNotificationFind) {
    return [
      {
        icon: PrimeIcons.PENCIL,
        label: 'Edit',
        command: () => {
          this._maintainerRouter.RouteToMoonNotificationEdit(moonNotification.moonNotificationID);
        }
      },
      {
        icon: PrimeIcons.HISTORY,
        label: 'History',
        command: () => {
          this._maintainerRouter.RouteToMoonNotificationDelivery(moonNotification.notificationType, moonNotification.moonNotificationID);
        }
      },
      {
        icon: PrimeIcons.TRASH,
        label: 'Delete Item',
        command: () => {
          this._confirmationService.confirm({
            message: `Are you sure you want to remove ${moonNotification.notificationType.getDisplayName()}`,
            accept: () => {
              this.doRemove(moonNotification.moonNotificationID);
            },
          });
        }
      },
      {
        icon: PrimeIcons.SEND,
        label: 'Test',
        command: () => {
          this.doTest(moonNotification.moonNotificationID);
        }
      }
    ];
  }

  private async doRemove(moonNotificationID: number): Promise<void> {
    const apiResult: ApiResult = await this._moonNotificationController.Delete(moonNotificationID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Removed Successfully.");
        this.loadMoonNotificationList();
    }
  }
  
  private async doTest(moonNotificationID: number): Promise<void> {
    const apiResult: ApiResult = await this._moonNotificationController.Test(moonNotificationID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Notification requested successfully.");
    }
  }
}
