// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
import { Component, Input, OnInit }         from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
// Third Party Imports
import { SharedModule }                     from 'primeng/api';
import { InputTextModule }                  from 'primeng/inputtext';
import { InputTextareaModule }              from 'primeng/inputtextarea';
// Moon Imports
import { IApiResult }                       from '@moon-core/models/api-result';
import { ComponentUtilityService }          from '@moon-core/services/component-utility.service';
import { MoonNotification }                 from '@moon-maintainer/api/request/moon-notification.request';
import { MoonNotificationGet }              from "@moon-maintainer/api/response/moon-notification-get.response";
import { MoonNotificationController }       from '@moon-maintainer/api/moon-notification.controller';
import { MaintainerRouter }                 from "@moon-maintainer/maintainer.router";
import { MoonFormControlComponent }         from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }            from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                      from '@moon-shared/types/form-group-of.type';
import { MoonMessageService }               from '@moon-shared/services/moon-message.service';
import { MessageSeverity }                  from '@moon-shared/constants/message-severity';

@Component({
    selector: 'moon-notification-form',
    templateUrl: './moon-notification-form.component.html',
    styleUrls: ['./moon-notification-form.component.css'],
    standalone: true,
    imports: [ FormsModule, ReactiveFormsModule, SharedModule, InputTextModule, InputTextareaModule,
                MoonFormPageComponent, MoonFormControlComponent],
    providers: [MoonNotificationController, MaintainerRouter]
})
export class MoonNotificationFormComponent implements OnInit {
    @Input() public MSMoonNotificationGet: MoonNotificationGet;
    @Input() public MSMoonNotificationID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<MoonNotification>> = new FormGroup<FormGroupOf<MoonNotification>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		notificationType: new FormControl('', {nonNullable: true, validators: [Validators.required, Validators.maxLength(100)] }),
		notificationDescription: new FormControl('', {nonNullable: true, validators: [Validators.required] }),

    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _moonNotificationController: MoonNotificationController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter,
        private _messageService: MoonMessageService
    ) {
    }

    ngOnInit(): void {
        this.doMapGetDataToFormControl(this.MSMoonNotificationGet);
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit(): void {
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel(): void {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doMapGetDataToFormControl(moonNotification: MoonNotificationGet): void{
        if (!moonNotification) {
            return;
        }        
        this.MSMoonNotificationGet = moonNotification;
        this.MSParentForm.patchValue(moonNotification);
    }

    private async doSubmitForm(): Promise<void> {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSMoonNotificationID
            ? await this._moonNotificationController.Update(this.MSMoonNotificationID, this.MSParentForm.getRawValue())
            : await this._moonNotificationController.Create(this.MSParentForm.getRawValue());
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this._messageService.showToastMessage(MessageSeverity.Success, "Notification created successfully.");
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToMoonNotification();
    }
}
