<ng-container>
  <div class="card moon-card">
    <div class="grid">
      <div class="moon-back-button">
          <button
              pButton
              pRipple
              type="button"
              icon="pi pi-arrow-left"
              (click)="MSOnClickGoBack()"
              class="p-button-rounded p-button-text mr-2 mb-2"
              pTooltip="Go Back"
              tooltipPosition="bottom"
          ></button>
      </div>
      <div class="mt-2">
        <h5 class="font-bold">Survey</h5>
      </div>
  </div>
    <div class="card m-0">
      <form [formGroup]="MSSurveyForm" class="px-2 md:px-8">
        <!-- File Input -->
        <moon-form-control
          class="col-12 moon-title-commitment-file-input"
          [MSFormControl]="MSSurveyForm.controls.inputFile"
          [MSErrorLabel]="'File'"
          [MSLabelSize]="12"
          [MSInputSize]="12"
          [MSErrorMessageSize]="12"
          [MSDisplayLabel]="false"
        >
          <p-fileUpload
            #fileUploader
            class="w-full cursor-pointer"
            [accept]="'application/pdf'"
            [auto]="true"
            [customUpload]="true"
            [mode]="'advanced'"
            [showUploadButton]="false"
            [showCancelButton]="false"
            (onUpload)="MSHandleFileInput($event.files)"
            (onSelect)="MSHandleFileInput($event.files)"
            (onRemove)="MSClearSelectedFile()"
          >
            <ng-template pTemplate="content">
              <div
                *ngIf="fileUploader.files.length > 0"
                moonEnterKeyup
                (MSOnEnterKeyUp)="MSOnClickUpload()"
              ></div>
              <div
                class="w-full md:h-20rem xl:h-25rem py-3 moon-cursor-copy"
                onkeypress=""
                (click)="fileUploader.advancedFileInput.nativeElement.click()"
              >
                <div
                  class="h-full flex flex-column justify-content-center align-items-center"
                >
                  <i class="pi pi-upload text-900 text-2xl mb-3"></i>
                  <span class="text-xl mb-3">Upload or Drag File Here</span>
                </div>
              </div>
            </ng-template>

            <ng-template pTemplate="file">
              <p-table [value]="[MSSurveyForm.value.inputFile]">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="name" class="white-space-nowrap">
                      Name <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="size" class="white-space-nowrap">
                      File Size <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-file>
                  <tr>
                    <td>
                      <div class="flex align-items-center">
                        <i
                          [class]="'text-xl text-primary mr-2 ' + file.icon"
                        ></i>
                        <span>{{ file.name }}</span>
                      </div>
                    </td>
                    <td>
                      <span>{{ file.size / 1024 | number : "1.0-2" }} kb</span>
                    </td>

                    <td class="text-center">
                      <button
                        pButton
                        pRipple
                        class="p-button-danger p-button-text p-button-rounded mr-2 cursor-pointer"
                        icon="pi pi-times"
                        (click)="MSClearSelectedFile()"
                      ></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-template>
          </p-fileUpload>
        </moon-form-control>
      </form>
      <hr class="-mt-1" />
      <div class="grid w-full">
        <div class="col-12 text-center">
          <div class="moon-progress-bar" *ngIf="MSUploading">
            <p-progressBar mode="indeterminate"></p-progressBar>
          </div>

          <div>
            <button
              class="moon-next-button"
              pButton
              pRipple
              icon="pi pi-upload"
              class="p-button-raised mr-2 moon-button"
              (click)="MSOnClickUpload()"
              tabindex="1"
              label="Process"
              [disabled]="MSUploading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
