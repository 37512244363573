import { JsonPipe, NgClass, NgIf }                                    from "@angular/common";
import { Component, Input, OnInit, ViewEncapsulation }      from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators }                  from "@angular/forms";
// Third party imports
import { TooltipModule }                                    from "primeng/tooltip";

@Component({
    selector: "moon-form-control",
    templateUrl: "./moon-form-control.component.html",
    styleUrls: ["./moon-form-control.component.css"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf, NgClass, ReactiveFormsModule, JsonPipe,
        TooltipModule
    ],
})
export class MoonFormControlComponent implements OnInit {
    @Input() MSLabel: string;
    @Input() MSSecondLabel: string;
    @Input() MSErrorLabel: string;
    @Input() MSFormControl: FormControl;
    @Input() MSLabelSize: number;
    @Input() MSInputSize: number;
    @Input() MSErrorMessageSize: number;
    @Input() MSHint: string;
    @Input() MSIsRequired: any;
    @Input() MSDisplayErrMsg: boolean = true;
    @Input() MSDisplayLabel: boolean = true;
    public MSErrorMessageStyleClass: string;

    MSHasRequiredValidator = false;

    ngOnInit(): void {
        this.MSHasRequiredValidator = this.MSFormControl?.hasValidator(Validators.required);
        this.setErrorMessageStyleClass();
    }

    private setErrorMessageStyleClass() {

        if (this.MSErrorMessageSize) {
            this.MSErrorMessageStyleClass = `md:col-${this.MSErrorMessageSize} `;
            return;
        }

        if (this.MSLabelSize) {
            this.MSErrorMessageStyleClass = `md:col-${12 - this.MSLabelSize} `;
            
            if (this.MSLabelSize != 12)
                this.MSErrorMessageStyleClass += `col-offset-${this.MSLabelSize} `
        }
    }
}
