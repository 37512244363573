import { Clipboard }                    from '@angular/cdk/clipboard';
import { CommonModule }                 from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output }     from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
}                                       from '@angular/forms';
import { ActivatedRoute }               from '@angular/router';
// Third party imports
import { ButtonModule }                 from 'primeng/button';
import { FieldsetModule }               from 'primeng/fieldset';
import { InputTextareaModule }          from 'primeng/inputtextarea';
import { RippleModule }                 from 'primeng/ripple';
import { TooltipModule }                from 'primeng/tooltip';
// MS Imports
import { ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { UserSessionService }           from '@moon-core/services/user-session.service';
import { ChatLogController }            from '@moon-maintainer/api/chat-log.controller';
import { ChatLogDataGet }               from '@moon-maintainer/api/response/chat-log-data-get.response';
import { ChatLogGet }                   from '@moon-maintainer/api/response/chat-log-get.response';
import { MaintainerRouter }             from '@moon-maintainer/maintainer.router';
import { TitleSummaryController }    from '@moon-public/api/title-summary.controller';
import { MoonFormControlComponent }     from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { DefaultValues }                from '@moon-shared/constants/default-values';
import { MessageSeverity }              from '@moon-shared/constants/message-severity';
import { RouteParameter }               from '@moon-shared/constants/route-parameter';
import { MoonDatePipe }                 from '@moon-shared/pipes/moon-date.pipe';
import { MoonMessageService }           from '@moon-shared/services/moon-message.service';
import { ChatLogDataOverride } from '@moon-maintainer/api/request/chat-log-data-override.request';

@Component({
  selector: 'moon-chat-log',
  standalone: true,
  templateUrl: './chat-log.component.html',
  styleUrls: ['./chat-log.component.scss'],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, 
    ButtonModule, RippleModule, FieldsetModule,InputTextareaModule, TooltipModule, 
    MoonFormControlComponent, MoonDatePipe
  ],
  providers: [ChatLogController, MaintainerRouter, TitleSummaryController]
})
export class ChatLogComponent implements OnInit {

  @Input() public MSChatLogID: number | null;
  @Input() public MSEditResult: boolean = false;
  @Output() public MSOnOverrideChatLogData = new EventEmitter();

  public MSEditOutput: boolean;
  public MSChatLog: ChatLogGet | null;
  public MSChatLogData: ChatLogDataGet | null;
  public MSIsCurrentUserMaintainer: boolean = false;
  public MSNewOutput: string;
  public MSSaving: boolean;
  public MSTitleSummaryID: number | null;
  public MSParentForm: FormGroup<{ outputData: FormControl<string> }>;
  public MSShowTestDataTooltip: string;

  constructor(
    private _componentUtilityService: ComponentUtilityService,
    private _chatLogController: ChatLogController,
    private _clipboard: Clipboard,
    private _userSessionService: UserSessionService,
    private _maintainerRouter: MaintainerRouter,
    private _moonMessageService: MoonMessageService,
    private _activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.MSIsCurrentUserMaintainer = this._userSessionService.IsMaintainer();
    if (this.MSChatLogID) {
      await this.LoadChatLogData(this.MSChatLogID);
    }
    this.MSTitleSummaryID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleSummaryID) ?? DefaultValues.NoID);
  }

  public MSGoToTest(): void {
    this._maintainerRouter.ToSkillTest(this.MSChatLog?.skillName);
  }

  public MSSaveTestData(): void {
    this.doSaveTestData();
  }

  public MSCopyInputToClipboard(): void {
    if (this.MSChatLogData?.inputData)
      this._clipboard.copy(this.MSChatLogData.inputData);
  }
  
  public MSToggleOutputEdit(): void {
    this.MSEditOutput = !this.MSEditOutput;
    if (this.MSParentForm == null) {
      this.initializeTypeFormGroup();
    }
  }

  public async MSOnClickSaveResult(): Promise<void> {
    if (!this.MSChatLogID) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Can't find chat log data to edit.")
      return;
    }

    this.MSParentForm.markAllAsTouched();
    this.MSParentForm.updateValueAndValidity();
    if (this.MSParentForm.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid OutputData.")
      return;
    }

    if (!String.isValidJson((this.MSParentForm.getRawValue().outputData))) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid JSON: OutputData.")
      return;
    }

    if (this.MSTitleSummaryID == DefaultValues.NoID) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid Title Summary.")
      return;
    }

    try {
      this.MSSaving = true;
      const inputData: ChatLogDataOverride = {
        overrideData: this.MSParentForm.getRawValue().outputData
      }
      const updateResult: ApiResult = await this._chatLogController.OverrideChatLogData(inputData, this.MSChatLogID);
      if (this._componentUtilityService.WasSuccessful(updateResult)) {
        this.MSOnOverrideChatLogData.emit();
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Output Data saved successfully.");
        this.MSToggleOutputEdit();
        this.LoadChatLogData(this.MSChatLogID);
      }
    }
    finally {
      this.MSSaving = false;
    }
  }

  public MSShowEdit() {
    return this.MSEditResult && this.MSChatLogID;
  }

  private initializeTypeFormGroup(): void {
    this.MSParentForm = new FormGroup({
      outputData: new FormControl<string>(this.MSChatLogData?.outputData ?? String.empty, { nonNullable: true, })
    });
  }

  private async LoadChatLogData(chatLogID: number) {

    const getLogResult: ApiContentResult<ChatLogGet> = await this._chatLogController.GetByID(chatLogID);
    if (this._componentUtilityService.WasSuccessful(getLogResult)) {
      this.MSChatLog = getLogResult.content;
      this.MSChatLog.lastUpdatedAt = this.MSChatLog.lastUpdatedAtUtc.toLocalDate();

      const getLogDataResult: ApiContentResult<ChatLogDataGet> = await this._chatLogController.GetChatLogData(chatLogID);
      if (this._componentUtilityService.WasSuccessful(getLogDataResult)) {
        this.MSChatLogData = getLogDataResult.content;
      }
    }
  }

  private async doSaveTestData(): Promise<void> {

    if (!this.MSChatLogID) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Can't find chat log data to save.")
      return;
    }

    try {
      this.MSSaving = true;
      const updateResult: ApiResult = await this._chatLogController.SaveTestData(this.MSChatLogID);
      if (this._componentUtilityService.WasSuccessful(updateResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Test Data saved successfully.");
        this.LoadChatLogData(this.MSChatLogID);      
      }
    }
    finally {
      this.MSSaving = false;
    }
  }

  public get MSShowTestData() : boolean {
    const showTestData = this.MSIsCurrentUserMaintainer && this.MSChatLog?.skillTestDataID == null;
    if(!showTestData) this.MSShowTestDataTooltip = 'Test Data is Already Saved.';
    return showTestData;
  }
}
