import {
  CommonModule, DecimalPipe, NgClass, NgFor,
  NgIf, NgSwitch, NgSwitchCase, SlicePipe
}                                                      from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { ButtonModule }                                from 'primeng/button';
import { FileUpload, FileUploadModule }                from 'primeng/fileupload';
import { InputTextModule }                             from 'primeng/inputtext';
import { RadioButtonModule }                           from 'primeng/radiobutton';
import { RippleModule }                                from 'primeng/ripple';
import { TableModule }                                 from 'primeng/table';
// MS Imports
import { ConsumerRouter }                              from '@moon-consumer/consumer.router';
import { TitleCommitmentWizardService }                from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { TitleCommitmentUpload }                       from '@moon-public/api/request/title-commitment.upload';
import { MoonFormControlComponent }                    from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonDragDropDirective }                       from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonFileUpload }                              from '@moon-shared/models/moon-file-upload.model';
import { MoonMessageService }                          from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                                 from '@moon-shared/types/form-group-of.type';
import { MoonEnterKeyupDirective }                     from '@moon-shared/directives/moon-enter-keyup.directive';

@Component({
  selector: 'moon-title-commitment-file',
  standalone: true,
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, SlicePipe, DecimalPipe,
    RadioButtonModule, ButtonModule, RippleModule, InputTextModule, FileUploadModule, TableModule,
    MoonFormControlComponent, MoonDragDropDirective, MoonEnterKeyupDirective
  ],
  providers: [ConsumerRouter],
  templateUrl: './title-commitment-upload-file.component.html',
  styleUrl: './title-commitment-upload-file.component.scss'
})
export class TitleCommitmentUploadFileComponent implements OnInit, AfterViewInit {
  public MSTitleCommitmentForm!: FormGroup<FormGroupOf<TitleCommitmentUpload>>;
  @ViewChild('fileUploader') public MSFileUploader: FileUpload;
  constructor(
    private _moonMessageService: MoonMessageService,
    private _titleCommitmentWizardService: TitleCommitmentWizardService,
    private _changeDetectionRef: ChangeDetectorRef
  ) {  }

  ngOnInit() {
    this.setTitleCommitmentFromWizardService();
  }
  ngAfterViewInit() {
    this.setFileUploadFromForm();
    this._changeDetectionRef.detectChanges();
  }
  private setFileUploadFromForm() {
    const inputFile = this.MSTitleCommitmentForm.value.inputFile;
    if (inputFile != null) {
      this.MSFileUploader.files.push(inputFile);
      this.MSFileUploader.upload();
    }
  }

  private setTitleCommitmentFromWizardService() {
    this.MSTitleCommitmentForm = this._titleCommitmentWizardService.MSTitleCommitmentForm;
  }

  public MSHandleFileInput(files: File[]) {
    if (!files?.length) return;

    const uploadedFile = files[0];

    if (uploadedFile) {
      this.updateFileControl(uploadedFile);
      this._titleCommitmentWizardService.GoToNextStep();
    }
  }

  public MSOnFileDrop(file: MoonFileUpload) {
    if (file && file.file.size > 0) {
      this.updateFileControl(file.file);
      return;
    }

    this._moonMessageService.toastInfo(
      "The file you trying to upload is not extracted from zip or may be empty. Please check and try again!"
    );
  }
  private updateFileControl(file: File) {
    this.MSTitleCommitmentForm.controls.inputFile.setValue(file);
    this.MSTitleCommitmentForm.markAsDirty();
    this.MSTitleCommitmentForm.controls.inputFile.updateValueAndValidity();
    this.MSTitleCommitmentForm.updateValueAndValidity();
  }

  public MSClearSelectedFile() {
    this.MSTitleCommitmentForm.controls.inputFile.setValue(null);
    this.MSTitleCommitmentForm.updateValueAndValidity();
  }

}
