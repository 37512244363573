<!-- Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                [MSDisplayRouteBackButton]="MSDisplayRouteBackButton"
                (MSOnClickEventRemove)="OnClickRemove()"
                (MSOnClickEventRouteBack)="OnClickRouteBack()">
    <ng-container *ngIf="!MSLoading">
        <user-info-form #MSForm
                       [MSUserInfoID]="MSUserInfoID"
                       [MSUserInfoGet]="MSUserInfoGet"
                       [MSUserOptionMapFind]="MSUserOptionMapFind">
        </user-info-form>
    </ng-container>
</moon-edit-page>
