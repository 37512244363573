import { CommonModule }                                            from '@angular/common';
import { Component, EventEmitter, Input, Output }                  from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
// Third party imports
import { ButtonModule }                                            from 'primeng/button';
import { FileUpload, FileUploadHandlerEvent, FileUploadModule }    from 'primeng/fileupload';
import { InputTextModule }                                         from 'primeng/inputtext';
import { RippleModule }                                            from 'primeng/ripple';
import { ConfirmationService }                                     from "primeng/api";
// Moon Imports
import { ComponentUtilityService }                                 from '@moon-core/services/component-utility.service';
import { TitleDocumentMap }                                        from '@moon-public/api/response/title-document-map.response';
import { TitleDocumentController }                                 from '@moon-public/api/title-document.controller';
import { MoonFormControlComponent }                                from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MessageSeverity }                                         from '@moon-shared/constants/message-severity';
import { MoonMessageService }                                      from '@moon-shared/services/moon-message.service';
import { FieldValues }                                             from '@moon-shared/constants/field-values';

@Component({
  selector: 'moon-document-mapping',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    ButtonModule, RippleModule, InputTextModule, FileUploadModule,
    MoonFormControlComponent,],
  templateUrl: './document-mapping.component.html',
  styleUrls: ['./document-mapping.component.scss']
})
export class DocumentMappingComponent {
  @Input() public MSTitleDocumentNumber: number;
  @Input() public MSTitleSummaryID: number;
  @Input() public MSTitleDocumentMap: TitleDocumentMap;
  @Input() public MSShowOcrButton: boolean = true;
  public MSOverrideExceptionNumber: boolean = false;
  public MSExceptionNumberFormGroup: FormGroup<{ exceptionNumberJson: FormControl<string> }>;
  public MSSaving: boolean = false;
  @Output() public MSOnTitleDocumentChange = new EventEmitter();

  constructor(
    private _titleDocumentController: TitleDocumentController,
    private _componentUtilityService: ComponentUtilityService,
    private _confirmationService: ConfirmationService,
    private _moonMessageService: MoonMessageService
  ) { }

  public async MSOnClickOcr() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.PostOcr(this.MSTitleSummaryID, this.MSTitleDocumentMap.titleDocumentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess('Ocr successful.');
        this.MSOnTitleDocumentChange.emit();
      }
    }
    finally {
      this.MSSaving = false;
    }
  }

  public async MSOnClickDelete() {
    
    this._confirmationService.confirm({
        message: `Are you sure to delete this document?`,
        accept: async () => {
            await this.doDelete();
        },
    });
  }

  private async doDelete(): Promise<void> {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.Delete(this.MSTitleSummaryID, this.MSTitleDocumentMap.titleDocumentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess('Delete successful.');
        this.MSOnTitleDocumentChange.emit();
      }
    }
    finally {
      this.MSSaving = false;
    }
  }

  public async MSOnClickOverrideDocument(event: FileUploadHandlerEvent, fileUpload: FileUpload) {
    if (event.files.length > 0 && event.files[0]) {
      try {
        if (this._componentUtilityService.IsFileValid(event.files[0], { fileExtensions: [FieldValues.FileExtension_PDF] })) {
          const apiResult = await this._titleDocumentController.PutBlob(this.MSTitleSummaryID, this.MSTitleDocumentMap.titleDocumentID, event.files[0]);
          if (this._componentUtilityService.WasSuccessful(apiResult)) {
            this._moonMessageService.toastSuccess('Title document upload successful.');
            this.MSOnTitleDocumentChange.emit();
          }
        }
      }
      finally {
        fileUpload.clear();
      }
    }
  }
  public MSToggleExceptionNumberOverride() {
    this.MSOverrideExceptionNumber = !this.MSOverrideExceptionNumber;
    if (this.MSExceptionNumberFormGroup == null) {
      this.initializeExceptionNumberFormGroup();
    }
  }
  public MSSaveExceptionNumber() {
    this.MSExceptionNumberFormGroup.markAllAsTouched();
    this.MSExceptionNumberFormGroup.updateValueAndValidity();
    if (this.MSExceptionNumberFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid recommended action.")
      return;
    }
    this.doOverrideExceptionNumber();
  }

  private async doOverrideExceptionNumber() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.OverrideExceptionNumber(this.MSTitleSummaryID, this.MSTitleDocumentMap.titleDocumentID, this.MSExceptionNumberFormGroup.getRawValue().exceptionNumberJson);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Exception number updated successfully.");
        this.MSOnTitleDocumentChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }

  private initializeExceptionNumberFormGroup() {
    this.MSExceptionNumberFormGroup = new FormGroup({
      exceptionNumberJson: new FormControl<string>(this.MSTitleDocumentMap.exceptionNumberJson,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(100)]
        })
    });
  }
}
