<div class="p-2 sm:p-8">
    <div class="flex flex-column text-center justify-content-center align-items-center">
        <div class="text-2xl font-bold text-900 mb-4">Your airplane term has been uploaded!</div>
        <div class="flex justify-content-center align-items-center w-full my-2">
            <i class="moon-checkmark pi pi-check"></i>
        </div>
        <div class="font-bold mt-3 mb-1">
            Once your summary is complete we will email it to you.
        </div>
        <div class="font-bold mb-4">
            The process usually takes a few minutes depending on how many exceptions are included and the size of the
            exception documents.
        </div>
        <div class="font-bold mb-2">
            You can view the current status of all submissions by clicking the following link:
        </div>
        <button
            pButton
            pRipple
            id="moon-go-to-airplane-button"
            label="Airplane Page."
            (click)="MSGoToAirplane()"
        > </button>

    </div>
</div>