import { DecimalPipe, NgClass, NgFor, NgIf, 
  NgSwitch, NgSwitchCase, SlicePipe }         from '@angular/common';
import { Component, OnInit }                  from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
// Third party imports
import { PdfViewerModule }                    from 'ng2-pdf-viewer';
import { MenuItem, PrimeIcons }               from 'primeng/api';
import { ButtonModule }                       from 'primeng/button';
import { DialogService }                      from 'primeng/dynamicdialog';
import { InputSwitchModule }                  from 'primeng/inputswitch';
import { InputTextModule }                    from 'primeng/inputtext';
import { RadioButtonModule }                  from 'primeng/radiobutton';
import { RippleModule }                       from 'primeng/ripple';
import { StepsModule }                        from 'primeng/steps';
// MS Imports
import { AppBreadcrumbService }               from '@app-layout/services/app.breadcrumb.service';
import { SurveyTableComponent }               from '@moon-consumer/survey/survey-table/survey-table.component';
import { ApiBlobResult, ApiContentResult }    from '@moon-core/models/api-result';
import { ComponentUtilityService }            from '@moon-core/services/component-utility.service';
import { FindSurveyParameter }                from '@moon-consumer/api/request/find-survey.parameter';
import { SurveyFind }                         from '@moon-consumer/api/response/survey-find.response';
import { SurveyController }                   from '@moon-consumer/api/survey.controller';
import { MoonLoadingComponent }               from '@moon-shared/components/moon-loading/moon-loading.component';
import { RoutePath }                          from '@moon-shared/constants/route-path';
import { MoonDragDropDirective }              from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonMessageService }                 from '@moon-shared/services/moon-message.service';
import { SurveyStatus }                       from '@moon-consumer/survey/survey-status';

@Component({
  selector: 'moon-survey',
  standalone: true,
  imports: [
    DecimalPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, SlicePipe, FormsModule, ReactiveFormsModule,
    PdfViewerModule,
    ButtonModule, InputSwitchModule, InputTextModule, RadioButtonModule, RippleModule, StepsModule,
    MoonDragDropDirective, MoonLoadingComponent, SurveyTableComponent
  ],
  providers: [SurveyController, DialogService],
  templateUrl: './survey.component.html',
  styleUrl: './survey.component.scss',
})
export class SurveyComponent implements OnInit {

  public MSLoading: boolean;
  public MSSurveyList: SurveyFind[];
  
  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    
    // private _confirmationService: ConfirmationService,
    private _surveyController: SurveyController,
    private _moonMessageService: MoonMessageService,
  ) { }
  async ngOnInit() {
    this.setBreadcrumb();
    this.loadSurveyList();
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(RoutePath.Survey.getDisplayName());
  }

  private async loadSurveyList() {
    try {
      this.MSLoading = true;
      const findParameter = new FindSurveyParameter();
      const apiResult: ApiContentResult<SurveyFind[]> = await this._surveyController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSSurveyList = apiResult.content;
        this.MSSurveyList.map(surveyFind => {
          surveyFind.actions = this.getActions(surveyFind);
          surveyFind.resultButtons = this.getResultButtons(surveyFind);
          //surveyFind.lastUpdatedAt = surveyFind.lastUpdatedAtUtc.toLocalDate();
          //surveyFind.createdAt = surveyFind.createdAtUtc.toLocalDate();
          return surveyFind;
        });
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private getActions(surveyFind: SurveyFind): MenuItem[] {
    const actionBtns: MenuItem[] = [
      { icon: PrimeIcons.FILE_PDF, label: 'View Uploaded File', command: () => this.onClickDownloadUploadedPdf(surveyFind) }
    ];

    if (SurveyStatus.Processing.equalsIgnoreCase(surveyFind.status)) {
      return actionBtns;
    }

    // actionBtns.push({ separator: true });
    // actionBtns.push({ icon: PrimeIcons.TRASH, label: 'Delete', command: () => this.removeItem(surveyFind) });
    return actionBtns;
  }

  // private removeItem(survey: SurveyFind) {
  //   this._confirmationService.confirm({
  //     message: `Are you sure you want to remove ${survey.fileName}`,
  //     accept: () => {
  //       this.doRemove(survey.surveyID);
  //     },
  //   });
  // }

  // private async doRemove(surveyID: number) {
  //   const apiResult: ApiResult = await this._surveyController.Delete(surveyID);
  //   if (this._componentUtilityService.WasSuccessful(apiResult)) {
  //     this._moonMessageService.toastSuccess("Removed Successfully.")
  //     this.loadSurveyList();
  //   }
  // }
  
  private getResultButtons(surveyFind: SurveyFind): MenuItem[] {
    //if (SurveyStatus.Processing.equalsIgnoreCase(surveyFind.status)) {
      //return [];
    //}

    const actionBtns: MenuItem[] = [];

    //if (String.isTrueValue(surveyFind.allowDownloadWord)) {
      actionBtns.push({ icon: PrimeIcons.DOWNLOAD, label: RoutePath.Exceptions, command: () => this.OnDownloadWordSummaryClick(surveyFind) });
    //}


    return actionBtns;
  }

  private async onClickDownloadUploadedPdf(surveyFind: SurveyFind) {
    this._moonMessageService.toastInfo("Downloading uploaded pdf. Please wait.");

    const apiResult: ApiBlobResult = await this._surveyController.GetBlobUploaded(surveyFind.surveyID);
    if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Pdf downloaded successful.");
    }
  }

  public async OnDownloadWordSummaryClick(surveyFind: SurveyFind): Promise<void> {
    this._moonMessageService.toastInfo("Processing. Please wait.");

    const apiResult: ApiBlobResult = await this._surveyController.GetBlobWordSummary(surveyFind.surveyID);
    if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Download Word summary successful.");
    }
  }
  
  public async MSOnEmitReload() {
    this.loadSurveyList();
  }

}
