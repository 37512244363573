<div id="summarize">
    <moon-loading *ngIf="MSLoading"></moon-loading>
    <div
        *ngIf="!MSLoading"
        [formGroup]="MSSummarizeForm"
        class="mb-3"
    >
        <div class="card">
            <div class="grid col-12 gap-1 m-1 mb-0 p-0">
                <div class="clol-4 p-fluid function-dropdown">
                    <moon-form-control
                        [MSInputSize]="12"
                        [MSFormControl]="MSSummarizeForm.controls.skillID"
                        [MSDisplayLabel]="false"
                        [MSLabel]="'Function'"
                    >
                        <p-dropdown
                            [options]="MSSummarizeSkillList"
                            optionLabel="skillName"
                            optionValue="skillID"
                            [formControl]="MSSummarizeForm.controls.skillID"
                            (onChange)="MSSKillChange($event)"
                        ></p-dropdown>
                    </moon-form-control>
                </div>
                <!-- <div class="col-2 p-0 p-fluid">
                    <moon-form-control
                        [MSLabelSize]="4"
                        [MSInputSize]="8"
                        [MSFormControl]="MSSummarizeForm.controls.inputMode"
                        [MSLabel]="'Input'"
                    >
                        <p-dropdown
                            [formControl]="MSSummarizeForm.controls.inputMode"
                            [options]="[{label: 'Text', value: 'chat'}, {label: 'PDF', value: 'file'}]"
                        ></p-dropdown>
                    </moon-form-control>
                </div>
                <div class="col-2 p-0 p-fluid">
                    <moon-form-control
                        [MSLabelSize]="4"
                        [MSInputSize]="8"
                        [MSFormControl]="MSSummarizeForm.controls.outputMode"
                        [MSLabel]="'Output'"
                    >
                        <p-dropdown
                            [formControl]="MSSummarizeForm.controls.outputMode"
                            [options]="[{label: 'Text', value: 'text'}, {label: 'Word Document', value: 'file'}]"
                        ></p-dropdown>
                    </moon-form-control>
                </div> -->
                <div class="col-4">
                    <div class="buttons">
                        <button
                            pButton
                            pRipple
                            class="p-button-raised moon-apply-summarize-button"
                            label="Apply"
                            title="Summarize"
                            [disabled]="MSApplying"
                            (click)="MSApply()"
                        >
                        </button>

                        <!-- <button *ngIf="MSSummarizeForm.value.inputMode === 'file'"
                            pButton
                            pRipple
                            class="p-button-raised moon-apply-summarize-button"
                            label="Summarize and Append"
                            title="Summarize and Append"
                            [disabled]="MSApplying"
                            (click)="MSApplyChatAndAppend()"
                        >
                        </button> -->
                        <span *ngIf="MSSummarizeForm.value.isTest">Test Mode ON</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid">
            <div
                id="summarize-input-container"
                class="col"
            >
                <div class="card">
                    <!-- File Mode Input -->
                    <ng-template #fileModeInput>
                        <!-- <div
                            class="col-12 h-full "
                            moonDragDrop
                            [allowDrop]="true"
                            allowMultiple="false"
                            [fileType]="['text/plain','application/pdf']"
                            (outputFileDropped)="MSOnFileDrop($event)"
                        > -->
                        <!-- Show drag drop when file is not yet uploaded -->
                        <ng-template #fileNotUploaded>
                            <div class="h-full flex flex-column gap-2 justify-content-center moon-file-drop-container">
                                <div>
                                    <span class="pi pi-download mr-2"></span> Drop your file here or
                                </div>
                                <div>
                                    <moon-form-control
                                        [MSFormControl]="MSSummarizeForm.controls.inputFiles"
                                        [MSLabel]="'Input file'"
                                        [MSInputSize]="12"
                                        [MSDisplayLabel]="false"
                                    >
                                        <p-fileUpload
                                            #MSFileUpload
                                            styleClass="p-button-raised"
                                            mode="basic"
                                            class="mr-2 mb-2 inline-block"
                                            [accept]="'text/plain, application/pdf'"
                                            [auto]="true"
                                            [multiple]="true"
                                            [chooseLabel]="'Click here to upload'"
                                            [customUpload]="true"
                                            [maxFileSize]="MSMaxFileSize"
                                            (uploadHandler)="MSHandleFileInput($event.files)"
                                        ></p-fileUpload>
                                    </moon-form-control>
                                </div>
                            </div>
                        </ng-template>
                        <!-- Show name of the file and size when file is uploaded -->
                        <ng-container *ngIf="MSSummarizeForm.controls.inputFiles.value; else fileNotUploaded">
                            <div class="h-full">
                                <div class="mb-2">
                                    <p-fileUpload
                                        #MSFileUpload
                                        styleClass="p-button-raised"
                                        mode="basic"
                                        class="inline-block"
                                        [accept]="'text/plain, application/pdf'"
                                        [auto]="true"
                                        [multiple]="true"
                                        [chooseLabel]="'Add more'"
                                        [customUpload]="true"
                                        [maxFileSize]="MSMaxFileSize"
                                        (uploadHandler)="MSHandleFileInput($event.files, MSFileUpload)"
                                    ></p-fileUpload>
                                    <!-- Clear button to clear the file -->
                                    <button
                                        pButton
                                        pRipple
                                        class="p-button-raised"
                                        [label]="MSOrderList.selection.length ? 'Clear Selected (' + MSOrderList.selection.length + ')' :  'Clear File'"
                                        [disabled]="MSApplying"
                                        (click)="MSClearSelectedFile(MSOrderList)"
                                    >
                                    </button>
                                </div>

                                <div class="line-break-anywhere">
                                    <p-orderList
                                        #MSOrderList
                                        [value]="MSFileList"
                                        header="Files"
                                        [dragdrop]="true"
                                    >
                                        <ng-template
                                            let-file
                                            pTemplate="item"
                                        >
                                            <div>{{file.name}} - {{ file.size/1024 | number:'1.0-2'}} kb</div>
                                        </ng-template>
                                    </p-orderList>
                                </div>
                            </div>
                        </ng-container>
                        <!-- </div> -->
                    </ng-template>
                    <!-- Text Mode Input -->
                    <ng-container *ngIf="MSSummarizeForm.controls.inputMode.value === 'chat';else fileModeInput">
                        <div class="moon-card-body grid">
                            <div class="col-12 inline-block">
                                <moon-form-control
                                    [MSInputSize]="12"
                                    [MSFormControl]="MSSummarizeForm.controls.inputText"
                                    [MSDisplayLabel]="false"
                                    [MSLabel]="'Input Text'"
                                >
                                    <span class="p-fluid relative">
                                        <textarea
                                            pInputTextarea
                                            placeholder="Write text"
                                            [formControl]="MSSummarizeForm.controls.inputText"
                                        ></textarea>
                                        <span class="token-detail">
                                            {{MSSummarizeForm.value.inputToken}}
                                        </span>
                                    </span>
                                </moon-form-control>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
            <div
                id="summarize-output-container"
                class="col"
            >
                <div class="card moon-card relative">
                    <div class="absolute moon-output-helper-button">
                        <button
                            pButton
                            pRipple
                            class="p-button-raised moon-apply-summarize-button"
                            icon="pi pi-copy"
                            pTooltip="Copy Output to Clipboard"
                            tooltipPosition="left"
                            (click)="MSCopyOutputToClipboard()"
                        >
                        </button>

                        <button
                            pButton
                            pRipple
                            class="p-button-raised moon-apply-summarize-button"
                            icon="pi pi-refresh"
                            pTooltip="Clear Output"
                            tooltipPosition="left"
                            (click)="MSClearOutput()"
                        >
                        </button>
                    </div>
                    <div class="p-fluid">
                        <div
                            *ngIf="MSApplying; else skillOutput"
                            class="flex justify-content-center align-items-center h-30rem"
                        >
                            <p-progressSpinner
                                aria-label="Loading"
                                styleClass="w-4rem h-4rem"
                                strokeWidth="4"
                                animationDuration=".5s"
                            ></p-progressSpinner>
                        </div>
                        <ng-template #skillOutput>
                            <div
                                [ngClass]="MSCheckCondition() ? 'moon-empty-output' : 'moon-output summarize-output-card2'"
                                class="card summarize-output-card white-space-pre-line overflow-y-auto"
                            >
                                {{MSSkillOutput}}
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="MSTimeElapsedForSkillDuration"
        class="text-right text-600"
    >
        Response time: ({{MSTimeElapsedForSkillDuration / 1000 | number: '1.0-3'}}s)
    </div>
</div>