// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
        ApiResult }                         from '@moon-core/models/api-result';
import { MoonApiClient }                    from '@moon-core/services/moon-api.client';
import { MoonNotification }                 from "@moon-maintainer/api/request/moon-notification.request";
import { FindMoonNotificationParameter }    from "@moon-maintainer/api/request/find-moon-notification.parameter";
import { MoonNotificationFind }             from "@moon-maintainer/api/response/moon-notification-find.response";
import { MoonNotificationGet }              from "@moon-maintainer/api/response/moon-notification-get.response";
import { MoonNotificationDeliveryGet }      from "@moon-maintainer/api/response/moon-notification-delivery-get.response";
import { ConstantString }                   from "@moon-shared/constants/constant-string";
import { RoutePath }                        from "@moon-shared/constants/route-path";

@Injectable()
export class MoonNotificationController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.Notification);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/MoonNotification/find
 */
  public async Find(findParameter: FindMoonNotificationParameter
    ): Promise<ApiContentResult<MoonNotificationFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Maintainer/v1/MoonNotification
 */
  public async GetList(
    ): Promise<ApiContentResult<MoonNotificationGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<MoonNotificationGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/MoonNotification
 */
  public async Create(moonNotification: MoonNotification
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, moonNotification);
  }
  
/**
 *   GET: Maintainer/v1/MoonNotification/{moonNotificationID}
 */
  public async GetByID(moonNotificationID: number
    ): Promise<ApiContentResult<MoonNotificationGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonNotificationID);
    return this._moonApi.getAsync<MoonNotificationGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/MoonNotification/{moonNotificationID}
 */
  public async Update(moonNotificationID: number, moonNotification: MoonNotification
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonNotificationID);
    return this._moonApi.putAsync(url, moonNotification);
  }
  
/**
 *   DELETE: Maintainer/v1/MoonNotification/{moonNotificationID}
 */
  public async Delete(moonNotificationID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonNotificationID);
    return this._moonApi.deleteAsync(url);
  }
  
/**
 *   POST: Maintainer/v1/MoonNotification/{moonNotificationID}/Test
 */
  public async Test(moonNotificationID: number
    ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonNotificationID, RoutePath.Test);
    return this._moonApi.postAsync(url, {});
  }

/**
 *   GET: Maintainer/v1/MoonNotification/{moonNotificationID}/Delivery
 */
  public async GetListMoonNotificationDelivery(moonNotificationID: number
    ): Promise<ApiContentResult<MoonNotificationDeliveryGet[]>> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), moonNotificationID, RoutePath.Delivery);
    return this._moonApi.getAsync<MoonNotificationDeliveryGet[]>(url);
  }

}
