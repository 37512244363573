import { Injectable } from '@angular/core';
import { MoonApiClient } from '@moon-core/services/moon-api.client';
import { ApiResult } from '@moon-core/models/api-result';
import { SearchRating } from '@moon-collaborator/api/request/search-rating.request';
import { ConstantString } from '@moon-shared/constants/constant-string';

@Injectable()
export class SearchRatingController {

  constructor(private _moonApi: MoonApiClient) { }

  private getBaseUrl(environmentName: string): string {
    return this._moonApi.getCollaboratorUrl(environmentName, ConstantString.SearchRating);
  }

  public async Rate(environmentName: string, searchRating: SearchRating
    ): Promise<ApiResult> {
    
    const url = this.getBaseUrl(environmentName);
    return this._moonApi.postAsync(url, searchRating);
  }
}
