// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
import {inject}                                             from '@angular/core';
import { Route, Router }                                    from "@angular/router";
import { MsalGuard }                                        from "@azure/msal-angular";
// MS Imports
import { SkillTestDataComponent }                           from '@moon-maintainer/skill-test-data/skill-test-data.component';
import { SkillTestDataEditPageComponent }                   from '@moon-maintainer/skill-test-data/skill-test-data-edit-page/skill-test-data-edit-page.component';
import { MoonNotificationComponent }                        from '@moon-maintainer/moon-notification/moon-notification.component';
import { MoonNotificationEditPageComponent }                from '@moon-maintainer/moon-notification/moon-notification-edit-page/moon-notification-edit-page.component';
import { EmailWhitelistComponent }                          from '@moon-maintainer/email-whitelist/email-whitelist.component';
import { EmailWhitelistEditPageComponent }                  from '@moon-maintainer/email-whitelist/email-whitelist-edit-page/email-whitelist-edit-page.component';
import { DeploymentComponent }                              from '@moon-maintainer/deployment/deployment.component';
import { DeploymentEditPageComponent }                      from '@moon-maintainer/deployment/deployment-edit-page/deployment-edit-page.component';
import { MaintainerRouteGuard }                             from "@moon-core/guards/maintainer-route.guard";
import { UserSessionGuard }                                 from "@moon-core/guards/user-session.guard";
import { BlackLineUtilityComponent }                        from "@moon-maintainer/black-line-utility/black-line-utility.component";
import { ChatLogComponent }                                 from "@moon-maintainer/chat-log/chat-log.component";
import { NodeEnvironmentUserGroupMapEditPageComponent }     from "@moon-maintainer/node-environment-user-group-map/node-environment-user-group-map-edit-page/node-environment-user-group-map-edit-page.component";
import { NodeEnvironmentUserGroupMapComponent }             from "@moon-maintainer/node-environment-user-group-map/node-environment-user-group-map.component";
import { NodeEnvironmentEditPageComponent }                 from "@moon-maintainer/node-environment/node-environment-edit-page/node-environment-edit-page.component";
import { NodeEnvironmentComponent }                         from "@moon-maintainer/node-environment/node-environment.component";
import { SearchLogComponent }                               from "@moon-maintainer/search-log/search-log.component";
import { SkillExampleEditPageComponent }                    from "@moon-maintainer/skill-example/skill-example-edit-page/skill-example-edit-page.component";
import { SkillExampleComponent }                            from "@moon-maintainer/skill-example/skill-example.component";
import { SkillEditPageComponent }                           from "@moon-maintainer/skill/skill-edit-page/skill-edit-page.component";
import { SkillTestComponent }                               from "@moon-maintainer/skill/skill-test/skill-test.component";
import { SkillComponent }                                   from "@moon-maintainer/skill/skill.component";
import { UserGroupEditPageComponent }                       from '@moon-maintainer/user-group/user-group-edit-page/user-group-edit-page.component';
import { UserGroupComponent }                               from '@moon-maintainer/user-group/user-group.component';
import { UserInfoEditPageComponent }                        from "@moon-maintainer/user-info/user-info-edit-page/user-info-edit-page.component";
import { UserInfoComponent }                                from "@moon-maintainer/user-info/user-info.component";
import { UserSessionComponent }                             from '@moon-maintainer/user-session/user-session.component';
import { HistoryComponent }                                 from "@moon-maintainer/history/history.component";
import { TemplateEditPageComponent }                        from '@moon-maintainer/template/template-edit-page/template-edit-page.component';
import { RouteParameter }                                   from "@moon-shared/constants/route-parameter";
import { RoutePath }                                        from "@moon-shared/constants/route-path";
import { TemplateComponent }                                from '@moon-maintainer/template/template.component';
import {MoonNotificationSubscriptionPageComponent}          from '@moon-maintainer/moon-notification/moon-notification-subscription-page/moon-notification-subscription-page.component';
import {MoonNotificationDeliveryPageComponent}              from '@moon-maintainer/moon-notification/moon-notification-delivery-page/moon-notification-delivery-page.component';
import {DocumentPdfReviewComponent}                         from '@moon-maintainer/title-commitment-advanced/document-pdf-review/document-pdf-review.component';
import {SummaryPdfReviewComponent}                          from '@moon-maintainer/title-commitment-advanced/summary-pdf-review/summary-pdf-review.component';
import {TitleAdminPageComponent}                            from '@moon-maintainer/title-commitment-advanced/title-admin-page/title-admin-page.component';
import {TitleCommitmentAdvancedComponent}                   from '@moon-maintainer/title-commitment-advanced/title-commitment-advanced.component';
import {UserOptionService}                                  from '@moon-core/services/user-option.service';
import {DefaultValues}                                      from '@moon-shared/constants/default-values';

const TitleCommitmentRouteGuard = () => {
    const titleCommitmentOptionValue = inject(UserOptionService).MSUserOption.ShowTitleCommitment;
    if (DefaultValues.Y.equalsIgnoreCase(titleCommitmentOptionValue)) {
        return true
    }
    inject(Router).navigate([RoutePath.Error], { skipLocationChange: true });
    return false;
}

export const MaintainerRoutes: Route[] = [
    {
        path: `${RoutePath.TitleCommitmentsAdvanced}`, canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard, TitleCommitmentRouteGuard],
        children: [
            { path: String.empty, component: TitleCommitmentAdvancedComponent, canActivate: [MaintainerRouteGuard] },
            { path: String.createRoute(RouteParameter.TitleSummaryID.toRouteParam(), RoutePath.Detail), component: TitleAdminPageComponent, canActivate: [MaintainerRouteGuard] },
            {
                path: String.createRoute(
                    RouteParameter.TitleSummaryID.toRouteParam(), RoutePath.Documents,
                    RouteParameter.TitleDocumentID.toRouteParam(), RoutePath.PdfReview
                ),
                component: DocumentPdfReviewComponent,
                canActivate: [MaintainerRouteGuard]
            },
            { path: String.createRoute(RouteParameter.TitleSummaryID.toRouteParam(), RoutePath.PdfReview), component: SummaryPdfReviewComponent, canActivate: [MaintainerRouteGuard] },
        ]
    },
    {
        path: RoutePath.ChatLog,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: ChatLogComponent },
            { path: String.createRoute(RouteParameter.SkillNumber.toRouteParam()), component: ChatLogComponent },
        ]
    },

    {
        path: RoutePath.SearchLog, component: SearchLogComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },

    {
        path: RoutePath.History, component: HistoryComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },

    {
        path: RoutePath.UserInfo,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: UserInfoComponent },
            { path: RoutePath.Add, component: UserInfoEditPageComponent },
            { path: String.createRoute(RouteParameter.UserInfoID.toRouteParam(), RoutePath.Edit), component: UserInfoEditPageComponent }],
    },

    {
        path: RoutePath.NodeEnvironment,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: NodeEnvironmentComponent },
            { path: RoutePath.Add, component: NodeEnvironmentEditPageComponent },
            { path: String.createRoute(RouteParameter.NodeEnvironmentID.toRouteParam(), RoutePath.Edit), component: NodeEnvironmentEditPageComponent },
            {
                path: String.createRoute(RouteParameter.NodeEnvironmentID.toRouteParam(), RoutePath.NodeEnvironmentUserGroupMap),
                children: [
                    { path: RoutePath.Empty, component: NodeEnvironmentUserGroupMapComponent },
                    { path: RoutePath.Add, component: NodeEnvironmentUserGroupMapEditPageComponent },
                    { path: String.createRoute(RouteParameter.NodeEnvironmentUserGroupMapID.toRouteParam(), RoutePath.Edit), component: NodeEnvironmentUserGroupMapEditPageComponent },
                ]
            }
        ]
    },

    {
        path: RoutePath.Skill,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: SkillComponent },
            { path: RoutePath.Add, component: SkillEditPageComponent },
            { path: String.createRoute(RouteParameter.SkillID.toRouteParam(), RoutePath.Edit), component: SkillEditPageComponent },
            { path: String.createRoute(RoutePath.Test), component: SkillTestComponent },
            {
                path: String.createRoute(RouteParameter.SkillID.toRouteParam(), RoutePath.SkillExample),
                children: [
                    { path: RoutePath.Empty, component: SkillExampleComponent },
                    { path: RoutePath.Add, component: SkillExampleEditPageComponent },
                    { path: String.createRoute(RouteParameter.SkillExampleID.toRouteParam(), RoutePath.Edit), component: SkillExampleEditPageComponent },
                ]
            },
            { 
                path: String.createRoute(RouteParameter.SkillNumber.toRouteParam(), RoutePath.SkillTestData),
                children: [
                    { path: RoutePath.Empty, component: SkillTestDataComponent },
                    { path: RoutePath.Add, component: SkillTestDataEditPageComponent },
                    { path: String.createRoute(RouteParameter.SkillTestDataID.toRouteParam(), RoutePath.Edit), component: SkillTestDataEditPageComponent },
                ]
             },
        ]
    },

    {
        path: RoutePath.UserGroup,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: UserGroupComponent },
            { path: RoutePath.Add, component: UserGroupEditPageComponent },
            { path: String.createRoute(RouteParameter.UserGroupID.toRouteParam(), RoutePath.Edit), component: UserGroupEditPageComponent },
        ]
    },
    {
        path: RoutePath.UserSessionLog,
        component: UserSessionComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],

    },
    {
        path: RoutePath.BlacklineUtility,
        component: BlackLineUtilityComponent,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard]
    },
    {
        path: RoutePath.Template,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: TemplateComponent },
            { path: RoutePath.Add, component: TemplateEditPageComponent },
            { path: String.createRoute(RouteParameter.TemplateID.toRouteParam(), RoutePath.Edit), component: TemplateEditPageComponent },
        ]
    },
   { 
        path: RoutePath.Deployment,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: DeploymentComponent },
            { path: RoutePath.Add, component: DeploymentEditPageComponent },
            { path: String.createRoute(RouteParameter.DeploymentID.toRouteParam(), RoutePath.Edit), component: DeploymentEditPageComponent },
        ]
    },
   { 
        path: RoutePath.EmailWhitelist,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: EmailWhitelistComponent },
            { path: RoutePath.Add, component: EmailWhitelistEditPageComponent },
            { path: String.createRoute(RouteParameter.EmailWhitelistID.toRouteParam(), RoutePath.Edit), component: EmailWhitelistEditPageComponent },
        ]
    },
   { 
        path: RoutePath.Notification,
        canActivate: [MsalGuard, UserSessionGuard, MaintainerRouteGuard],
        children: [
            { path: RoutePath.Empty, component: MoonNotificationComponent },
            { path: RoutePath.Add, component: MoonNotificationEditPageComponent },
            { path: String.createRoute(RouteParameter.MoonNotificationID.toRouteParam(), RoutePath.Edit), component: MoonNotificationEditPageComponent },
            { path: String.createRoute(RouteParameter.MoonNotificationID.toRouteParam(), RoutePath.Subscription), component: MoonNotificationSubscriptionPageComponent },
            { path: String.createRoute(RouteParameter.MoonNotificationID.toRouteParam(), RoutePath.Delivery), component: MoonNotificationDeliveryPageComponent },
        ]
    },
    // Placeholder for route
];
