import { CommonModule, KeyValue, NgIf }                             from '@angular/common';
import { Component, Input, OnInit }           from '@angular/core';
import { ReactiveFormsModule }  from '@angular/forms';
// Third party imports
import { BadgeModule }                                              from 'primeng/badge';
import { ButtonModule }                                             from 'primeng/button';
import { ConfirmDialogModule }                                      from 'primeng/confirmdialog';
import { DialogModule }                                             from 'primeng/dialog';
import { DialogService }     from 'primeng/dynamicdialog';
import { RippleModule }                                             from 'primeng/ripple';
// MS Imports
import { TitleDocumentGet }                                         from '@moon-public/api/response/title-document.response';
import { TitleDocumentController }                                  from '@moon-public/api/title-document.controller';
import { ChatLogComponent }                                         from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { DropdownButtonComponent }                                  from '@moon-shared/components/dropdown-button/dropdown-button.component';
import { MoonFormControlComponent }                                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { ConstantString }                                           from '@moon-shared/constants/constant-string';

@Component({
  selector: 'moon-title-document-customer',
  standalone: true,
  imports: [
    CommonModule, NgIf, ReactiveFormsModule,
    BadgeModule, ButtonModule, RippleModule, DialogModule, ConfirmDialogModule,
    DropdownButtonComponent, ChatLogComponent, MoonFormControlComponent
  ],
  templateUrl: './title-document-customer.component.html',
  styleUrls: ['./title-document-customer.component.scss'],
  providers: [
    TitleDocumentController,
    DialogService
  ]
})
export class TitleDocumentCustomerComponent implements OnInit {
  public MSIsDocumentManualUpload: boolean = false;
  @Input() public MSDocumentIndexNumber: number;
  @Input() public MSTitleDocument: TitleDocumentGet;

  constructor(
  ) { }

  ngOnInit(): void {
    this.checkForManualUpload();
  }

  private checkForManualUpload(): void {
    this.MSIsDocumentManualUpload = ConstantString.ManualUpload.equalsIgnoreCase(this.MSTitleDocument.referenceType);
  }

  public MSPreserveOriginalOrderInKeyValuePipe = (_a: KeyValue<number, string>, _b: KeyValue<number, string>): number => {
    return 0;
  }

}
