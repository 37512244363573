import { Injectable } from '@angular/core';
// MS Imports
import { MoonApiClient } from '@moon-core/services/moon-api.client';
import { ApiContentResult } from '@moon-core/models/api-result';
import { EnvironmentGet } from './response/environment-get.response';
import { MoonstoneApp } from 'src/moon-config/moonstone-app';
import { ConstantString } from '@moon-shared/constants/constant-string';

@Injectable()
export class EnvironmentApiService {
    
  constructor(private _moonClient: MoonApiClient) { }
    
  private get baseUrl(): string {
    return `${ConstantString.StandardVersion1}/Environment`;
  }

  public async GetEnvironmentList(): Promise<ApiContentResult<EnvironmentGet[]>> {
        
    const url = `${this.baseUrl}/${MoonstoneApp.ApplicationName}`;
    return this._moonClient.getAsync<EnvironmentGet[]>(url);
  }
}
