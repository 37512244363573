import {
  CommonModule, DecimalPipe, NgClass, NgFor,
  NgIf, NgSwitch, NgSwitchCase, SlicePipe
}                                                       from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild }  from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule }  from '@angular/forms';
// Third party imports
import { ButtonModule }                                 from 'primeng/button';
import { FileUpload, FileUploadModule }                 from 'primeng/fileupload';
import { InputTextModule }                              from 'primeng/inputtext';
import { RadioButtonModule }                            from 'primeng/radiobutton';
import { RippleModule }                                 from 'primeng/ripple';
import { TableModule }                                  from 'primeng/table';
// MS Imports
import { AirplaneWizardService }                        from '@moon-consumer/airplane/add-airplane-wizard/service/airplane-wizard.service';
import { Airplane }                                     from '@moon-consumer/api/request/airplane.request';
import { ConsumerRouter }                               from '@moon-consumer/consumer.router';
import { MoonFormControlComponent }                     from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MessageSeverity }                              from '@moon-shared/constants/message-severity';
import { RoutePath }                                    from '@moon-shared/constants/route-path';
import { MoonDragDropDirective }                        from '@moon-shared/directives/file-upload-drag-drop.directive';
import { MoonEnterKeyupDirective }                      from '@moon-shared/directives/moon-enter-keyup.directive';
import { MoonFileUpload }                               from '@moon-shared/models/moon-file-upload.model';
import { MoonMessageService }                           from '@moon-shared/services/moon-message.service';
import { FormGroupOf }                                  from '@moon-shared/types/form-group-of.type';

@Component({
  selector: 'moon-airplane-file',
  standalone: true,
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, SlicePipe, DecimalPipe,
    RadioButtonModule, ButtonModule, RippleModule, InputTextModule, FileUploadModule, TableModule,
    MoonFormControlComponent, MoonDragDropDirective, MoonEnterKeyupDirective
  ],
  templateUrl: './airplane-upload-file.component.html',
  styleUrl: './airplane-upload-file.component.scss'
})
export class AirplaneUploadFileComponent implements OnInit, AfterViewInit {
  public MSAirplaneForm!: FormGroup<FormGroupOf<Airplane>>;
  @ViewChild('fileUploader') public MSFileUploader: FileUpload;
  constructor(
    private _moonMessageService: MoonMessageService,
    private _consumerRouter: ConsumerRouter,
    private _airplaneWizardService: AirplaneWizardService
  ) { }

  ngOnInit() {
    this.setTitleCommitmentFromWizardService();
  }
  ngAfterViewInit() {
    this.setFileUploadFromForm();
  }
  private setFileUploadFromForm() {
    const inputFile = this.MSAirplaneForm.value.inputFile;
    if (inputFile != null) {
      this.MSFileUploader.files.push(inputFile);
      this.MSFileUploader.upload();
    }
  }

  private setTitleCommitmentFromWizardService() {
    this.MSAirplaneForm = this._airplaneWizardService.MSAirplaneForm;
  }

  public MSHandleFileInput(files: File[]) {
    if (!files?.length) return;

    const uploadedFile = files[0];

    if (uploadedFile) {
      this.updateFileControl(uploadedFile);
      this.routeToMarkPage();
    }
  }

  public MSOnFileDrop(file: MoonFileUpload) {
    if (file && file.file.size > 0) {
      this.updateFileControl(file.file);
      return;
    }

    this._moonMessageService.toastInfo(
      "The file you trying to upload is not extracted from zip or may be empty. Please check and try again!"
    );
  }
  private updateFileControl(file: File) {
    this.MSAirplaneForm.controls.inputFile.setValue(file);
    this.MSAirplaneForm.markAsDirty();
    this.MSAirplaneForm.controls.inputFile.updateValueAndValidity();
    this.MSAirplaneForm.updateValueAndValidity();
  }

  public MSClearSelectedFile() {
    this.MSAirplaneForm.controls.inputFile.setValue(null);
    this.MSAirplaneForm.updateValueAndValidity();
  }

  public MSOnClickNext(): void {
    if (this.MSAirplaneForm.controls.inputFile?.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, 'File is required.');
    }
    else {
      this.routeToMarkPage();
    }
  }
  private routeToMarkPage() {
    this._consumerRouter.RouteToAirplane(RoutePath.Add, RoutePath.MarkTermPage);
  }

  public MSOnClickGoBack(): void {
    this._consumerRouter.RouteToAirplane();
  }

}
