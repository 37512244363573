// Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM
import { NgIf }                     from '@angular/common';
import { Component, OnInit }        from '@angular/core';
// Moon Imports
import { AppBreadcrumbService }     from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult }         from '@moon-core/models/api-result';
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { AirplaneController }       from '@moon-consumer/api/airplane.controller';
import { FindAirplaneParameter }    from "@moon-consumer/api/request/find-airplane.parameter";
import { AirplaneFind }             from "@moon-consumer/api/response/airplane-find.response";
import { AirplaneTableComponent }   from '@moon-consumer/airplane/airplane-table/airplane-table.component';
import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }           from '@moon-shared/constants/constant-string';

@Component({
  selector: 'airplane',
  templateUrl: './airplane.component.html',
  styleUrls: ['./airplane.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, AirplaneTableComponent],
  providers: [AirplaneController]
})
export class AirplaneComponent implements OnInit {

  public MSAirplaneList: AirplaneFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.Airplane;
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _airplaneController: AirplaneController
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.MSLoadAirplaneList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }

  public async MSLoadAirplaneList() {
    try {
      this.MSLoading = true;
      const findParameter = new FindAirplaneParameter();
      const apiResult: ApiContentResult<AirplaneFind[]> = await this._airplaneController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSAirplaneList = apiResult.content;
        this.MSAirplaneList.map(airplaneFind => {
          Object.safelyDefineProperty(airplaneFind, 'readableProcessingStatus', airplaneFind.processingStatus.getDisplayName());
        });
      }
    } finally {
      this.MSLoading = false;
    }
  }
}
