import { NgFor }                              from '@angular/common';
import { Component }                          from '@angular/core';
import { FormGroup, ReactiveFormsModule }     from '@angular/forms';
// Third party imports
import { ButtonModule }                       from 'primeng/button';
import { RadioButtonModule }                  from 'primeng/radiobutton';
import { RippleModule }                       from 'primeng/ripple';
import { AutoFocusModule }                    from 'primeng/autofocus';
import { InputTextModule }                    from 'primeng/inputtext';
// Moon Imports
import { ConsumerRouter }                     from '@moon-consumer/consumer.router';
import { TitleCommitmentWizardService }       from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { TitleCommitmentUpload }              from '@moon-public/api/request/title-commitment.upload';
import { MoonFormControlComponent }           from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { FormGroupOf }                        from '@moon-shared/types/form-group-of.type';
import { MoonEnterKeyupDirective }            from '@moon-shared/directives/moon-enter-keyup.directive';
@Component({
  selector: 'moon-title-commitment-add-matter-name',
  standalone: true,
  imports: [
    NgFor, ReactiveFormsModule, AutoFocusModule, MoonEnterKeyupDirective,
    ButtonModule, InputTextModule, RadioButtonModule, RippleModule,
    MoonFormControlComponent,
  ],
  providers: [ConsumerRouter],
  templateUrl: './title-commitment-add-matter-name.component.html',
  styleUrl: './title-commitment-add-matter-name.component.scss'
})
export class TitleCommitmentAddMatterNameComponent  {
  public get MSTitleCommitmentForm(): FormGroup<FormGroupOf<TitleCommitmentUpload>> {
    return this._titleCommitmentWizardService.MSTitleCommitmentForm;
  }  constructor(
    private _titleCommitmentWizardService: TitleCommitmentWizardService
  ) { }
}
