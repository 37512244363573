// Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { EmailWhitelist }                 from "@moon-maintainer/api/request/email-whitelist.request";
import { FindEmailWhitelistParameter }    from "@moon-maintainer/api/request/find-email-whitelist.parameter";
import { EmailWhitelistFind }             from "@moon-maintainer/api/response/email-whitelist-find.response";
import { EmailWhitelistGet }              from "@moon-maintainer/api/response/email-whitelist-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";

@Injectable()
export class EmailWhitelistController {
  
  private getBaseUrl(): string {
    return this._moonApi.getMaintainerUrl(ConstantString.EmailWhitelist);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Maintainer/v1/EmailWhitelist/find
 */
  public async Find(findParameter: FindEmailWhitelistParameter
    ): Promise<ApiContentResult<EmailWhitelistFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Maintainer/v1/EmailWhitelist
 */
  public async GetList(
    ): Promise<ApiContentResult<EmailWhitelistGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<EmailWhitelistGet[]>(url);
  }
  
/**
 *   POST: Maintainer/v1/EmailWhitelist
 */
  public async Create(emailWhitelist: EmailWhitelist
    ): Promise<ApiContentResult<number>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, emailWhitelist);
  }
  
/**
 *   GET: Maintainer/v1/EmailWhitelist/{documentID}
 */
  public async GetByID(emailWhitelistID: number
    ): Promise<ApiContentResult<EmailWhitelistGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), emailWhitelistID);
    return this._moonApi.getAsync<EmailWhitelistGet>(url);
  }
  
/**
 *   PUT: Maintainer/v1/EmailWhitelist/{documentID}
 */
  public async Update(emailWhitelistID: number, emailWhitelist: EmailWhitelist
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), emailWhitelistID);
    return this._moonApi.putAsync(url, emailWhitelist);
  }
  
/**
 *   DELETE: Maintainer/v1/EmailWhitelist/{documentID}
 */
  public async Delete(emailWhitelistID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), emailWhitelistID);
    return this._moonApi.deleteAsync(url);
  }
}
