// Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM
import { NgIf }                                     from '@angular/common';
import { Component, OnInit }                        from '@angular/core';
//Third Party Imports
import { ConfirmationService, PrimeIcons }          from 'primeng/api';
import { MaintainerRouter }                         from '@moon-maintainer/maintainer.router';
// Moon Imports
import { AppBreadcrumbService }                     from '@app/app-layout/services/app.breadcrumb.service';
import {ApiContentResult, ApiResult}                from '@moon-core/models/api-result';
import { ComponentUtilityService }                  from '@moon-core/services/component-utility.service';
import { EmailWhitelistController }                 from '@moon-maintainer/api/email-whitelist.controller';
import { FindEmailWhitelistParameter }              from "@moon-maintainer/api/request/find-email-whitelist.parameter";
import { EmailWhitelistFind }                       from "@moon-maintainer/api/response/email-whitelist-find.response";
import { EmailWhitelistTableComponent }             from '@moon-maintainer/email-whitelist/email-whitelist-table/email-whitelist-table.component';
import { MoonLoadingComponent }                     from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                           from '@moon-shared/constants/constant-string';
import { MoonMessageService }                       from '@moon-shared/services/moon-message.service';

@Component({
  selector: 'moon-email-whitelist',
  templateUrl: './email-whitelist.component.html',
  styleUrls: ['./email-whitelist.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, EmailWhitelistTableComponent],
  providers: [EmailWhitelistController, MaintainerRouter]
})
export class EmailWhitelistComponent implements OnInit {

  public MSEmailWhitelistList: EmailWhitelistFind[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.EmailWhitelist.getDisplayName();
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _emailWhitelistController: EmailWhitelistController,
    private _maintainerRouter: MaintainerRouter,
    private _confirmationService: ConfirmationService,
    private _moonMessageService: MoonMessageService
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadEmailWhitelistList();
  }
  
  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  public async MSOnEmitReload() {
    this.loadEmailWhitelistList();
  }
  private async loadEmailWhitelistList(): Promise<void> {    
    try {
      this.MSLoading = true;
      const findParameter = new FindEmailWhitelistParameter();
      const apiResult: ApiContentResult<EmailWhitelistFind[]> = await this._emailWhitelistController.Find(findParameter);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSEmailWhitelistList = apiResult.content.map((emailWhitelist: EmailWhitelistFind) => {
          emailWhitelist.lastUpdatedAt = emailWhitelist.lastUpdatedAtUtc.toLocalDate();
          emailWhitelist.actions = this.getActions(emailWhitelist);
          return emailWhitelist;
        });
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private getActions(emailWhitelist: EmailWhitelistFind) {
    return [
      {
        icon: PrimeIcons.PENCIL,
        label: 'Edit',
        command: () => {
          this._maintainerRouter.RouteToEmailWhitelistEdit(emailWhitelist.emailWhitelistID);
        }
      },
      {
        icon: PrimeIcons.TRASH,
        label: 'Delete Item',
        command: () => {
          this._confirmationService.confirm({
            message: `Are you sure you want to remove ${emailWhitelist.whitelistValue}`,
            accept: () => {
              this.doRemove(emailWhitelist.emailWhitelistID);
            },
          });
        }
      }
    ];
  }

  private async doRemove(emailWhitelistID: number): Promise<void> {
    const apiResult: ApiResult = await this._emailWhitelistController.Delete(emailWhitelistID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Removed Successfully.");
        this.loadEmailWhitelistList();
    }
  }
}
