import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DiagnosticApiService } from '@moon-core/api/diagnostic-api.service';
import { DatabaseDto } from '@moon-core/api/response/database.response';
import { MoonEnvironmentConfig } from '@moon-core/api/response/moon-environment-config.response';
import { MoonAppConfigService } from '@moon-core/services/moon-app-config.service';
import { MoonstoneApp } from 'src/moon-config/moonstone-app';

@Component({
  selector: 'moon-diagnostic',
  standalone: true,
  imports: [NgIf],
  templateUrl: './diagnostic.component.html',
  styleUrl: './diagnostic.component.scss',
  providers: [DiagnosticApiService]
})
export class DiagnosticComponent implements OnInit {
  public MSApplicationName: string = String.empty;
  public MSApplicationVersion: string = String.empty;
  public MoonEnvironmentConfig!: MoonEnvironmentConfig;
  public MSDiagnostic!: DatabaseDto;

  constructor(
    private _configService: MoonAppConfigService,
    private _diagnosticController: DiagnosticApiService,
  ) {
  }
  ngOnInit() {
    this.loadApplicationNameAndVersion();
    this.loadAppConfig();
    this.loadAPIDiagnostic();
  }


  private async loadAPIDiagnostic() {
    const apiUrl = this._configService.GetMoonConfig().apiUrl;
    const diagnosticResult = await this._diagnosticController.FetchDatabaseDto(apiUrl, this.MSApplicationName);
    this.MSDiagnostic = diagnosticResult;
  }

  private loadApplicationNameAndVersion() {
    this.MSApplicationName = MoonstoneApp.ApplicationName;
    this.MSApplicationVersion = MoonstoneApp.ApplicationVersion;
  }

  private async loadAppConfig() {
    this.MoonEnvironmentConfig = await this._configService.MSLoadAppConfigJson();
  }
}
