<div
    id="add-title-commitment"
    [formGroup]="MSTitleCommitmentForm"
    class="mb-3 card moon-card"
>
    <div class="grid">
        <div
            id="summarize-input-container"
            class="col"
        >
            <div class="card">
                <!-- File Input -->
                <div
                    class="col-12 h-full "
                    moonDragDrop
                    [allowDrop]="true"
                    allowMultiple="false"
                    [fileType]="['application/x-zip-compressed']"
                    (outputFileDropped)="MSOnFileDrop($event)"
                >
                    <!-- Show drag drop when file is not yet uploaded -->
                    <ng-template #fileNotUploaded>
                        <div class="h-full flex flex-column gap-2 justify-content-center moon-file-drop-container">
                            <div>
                                <span class="pi pi-download mr-2"></span> Drop your file here or
                            </div>
                            <div>
                                <moon-form-control
                                    [MSFormControl]="MSTitleCommitmentForm.controls.inputFile"
                                    [MSLabel]="'Input file'"
                                    [MSInputSize]="12"
                                    [MSDisplayLabel]="false"
                                >
                                    <input
                                        #fileInput
                                        type="file"
                                        (change)="MSHandleFileInput(fileInput.files)"
                                        accept="application/x-zip-compressed"
                                    />
                                </moon-form-control>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Show name of the file and size when file is uploaded -->
                    <ng-container *ngIf="MSTitleCommitmentForm.controls.inputFile.value; else fileNotUploaded">
                        <div class="h-full flex justify-content-evenly align-items-center">
                            <div class="line-break-anywhere">
                                {{MSTitleCommitmentForm.controls.inputFile.value.name}} -
                                {{ MSTitleCommitmentForm.controls.inputFile.value.size/1024 | number:'1.0-2'}} kb
                            </div>
                            <div>
                                <moon-form-control
                                    [MSFormControl]="MSTitleCommitmentForm.controls.hasMetadata"
                                    MSLabel="Has Metadata?"
                                    [MSLabelSize]="8"
                                    [MSInputSize]="4"
                                >
                                    <p-checkbox
                                        [ngModel]="MSTitleCommitmentForm.value.hasMetadata === 'Y'"
                                        [ngModelOptions]="{standalone: true}"
                                        [binary]="true"
                                        (onChange)="MSOnHasMetadataChanged($event)"
                                    ></p-checkbox>
                                </moon-form-control>
                            </div>
                            <div>
                                <!-- Clear button to clear the file -->
                                <button
                                    pButton
                                    pRipple
                                    class="p-button-raised moon-apply-extract-button"
                                    label="Clear File"
                                    title="Clear File"
                                    (click)="MSClearSelectedFile()"
                                >
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group btn-row">
        <button
            [disabled]="MSUploading"
            pButton
            pRipple
            class="p-button-raised moon-apply-summarize-button"
            label="Upload"
            title="Upload"
            (click)="MSUploadDocument()"
        >
        </button>

        <button
            [disabled]="MSUploading"
            pButton
            pRipple
            class="p-button-raised moon-apply-summarize-button"
            label="Cancel"
            (click)="MSCancelUpload()"
        >
        </button>
    </div>
</div>

<moon-loading
    *ngIf="MSUploading"
    [MSLoadingMessage]="'Uploading documents...'"
></moon-loading>