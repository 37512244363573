import { Clipboard }                            from '@angular/cdk/clipboard';
import { Component, HostListener, OnInit, ViewChild }      from '@angular/core';
import { 
  FormControl, FormGroup, 
  FormsModule, ReactiveFormsModule,
  Validators }                                  from '@angular/forms';
  import { CommonModule, DecimalPipe, NgIf }    from '@angular/common';
  // Third Party Imports
import { ProgressSpinnerModule }                from 'primeng/progressspinner';
import { TooltipModule }                        from 'primeng/tooltip';
import { DropdownModule }                       from 'primeng/dropdown';
import { RippleModule }                         from 'primeng/ripple';
import { InputTextareaModule }                  from 'primeng/inputtextarea';
import { ButtonModule }                         from 'primeng/button';
import { ProgressBarModule }                    from 'primeng/progressbar';
// Moon-Imports
import { AppBreadcrumbService }                 from '@app-layout/services/app.breadcrumb.service';

import { ApiContentResult }                     from '@moon-core/models/api-result';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';

import { PerformanceTimer }                     from '@moon-shared/helper/performance-timer/performance-timer';
import { BlackLinesComponent }                  from '@moon-shared/components/black-lines/black-lines.component';
import { MessageSeverity }                      from '@moon-shared/constants/message-severity';
import { FormGroupOf }                          from '@moon-shared/types/form-group-of.type';
import { MoonLoadingComponent }                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonFormControlComponent }             from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonMessageService }                   from '@moon-shared/services/moon-message.service';

import { ChatSkillController }                  from '@moon-public/api/chat-skill.controller';
import { ChatParameterForm }                    from '@moon-public/models/chat-parameter-form';
import { ChatResult }                           from '@moon-public/api/response/chat-result.response';

import { SkillGet }                             from '@moon-maintainer/api/response/skill-get.response';
import { MoonFileUpload } from '@app/moon-shared/models/moon-file-upload.model';

@Component({
  selector: 'ms-revise',
  templateUrl: './revise.component.html',
  styleUrls: ['./revise.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BlackLinesComponent,
    NgIf, MoonLoadingComponent, FormsModule, ReactiveFormsModule, 
    MoonFormControlComponent, DropdownModule,
    ButtonModule, RippleModule, InputTextareaModule, 
    TooltipModule, ProgressSpinnerModule, 
    ProgressBarModule, DecimalPipe,
  ],
  providers: [
    ChatSkillController
  ]
})
export class ReviseComponent implements OnInit {
  private readonly _skillName = "revise";
  private readonly defaultSkillOutput: string = "Output";
  public MSApplying: boolean = false;
  public MSElapsedTimeForSkillDuration: number | null;
  public MSLoading: boolean = false;
  public MSReviseForm = new FormGroup<FormGroupOf<ChatParameterForm>>({
    isTest: new FormControl(false, { nonNullable: true }),
    skillType: new FormControl( this._skillName, { nonNullable: true }),
    skillName: new FormControl('', { nonNullable: true, validators: Validators.required }),
    inputText: new FormControl('', { nonNullable: true }),
    inputToken: new FormControl(0, { nonNullable: true, validators: Validators.required }),
    inputMode: new FormControl( "chat", { nonNullable: true, validators: Validators.required }),
    inputFile: new FormControl(),
    skillID: new FormControl()
  });
  public MSSkills: string[] = [];
  public MSSkillOutput: string = this.defaultSkillOutput;

  @ViewChild("blackLine") public MSBlackLinesComponent: BlackLinesComponent

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _clipboard: Clipboard,
    private _chatSkillController: ChatSkillController,
    private _componentUtilityService: ComponentUtilityService,
    private _moonMessageService: MoonMessageService,
  ) { }

  ngOnInit(): void {
    this.initialize();
  }


  public MSHandleFileInput(files: FileList | null) {
    if (!files?.length) return;

    const uploadedFile = files.item(0);

    if (uploadedFile)
      this.updateFileControl(uploadedFile);
  }

  public MSOnFileDrop(file: MoonFileUpload) {
    if (file && file.file.size > 0) {
      this.updateFileControl(file.file);
      return;
    }

    this._moonMessageService.toastInfo(
      "The file you trying to upload is not extracted from zip or may be empty. Please check and try again!"
    );
  }


  public MSCheckCondition(): boolean {
    return this.MSSkillOutput === this.defaultSkillOutput || this.MSSkillOutput === String.empty;
  }

  private updateFileControl(file: File) {
    this.MSReviseForm.controls.inputFile.setValue(file);
    this.MSReviseForm.markAsDirty();
    this.MSReviseForm.controls.inputFile.updateValueAndValidity();
    this.MSReviseForm.updateValueAndValidity();
  }


  public MSClearSelectedFile() {
    this.MSReviseForm.controls.inputFile.setValue(null);
    this.MSReviseForm.updateValueAndValidity();
  }

  public async MSApplyChat() {
    this.MSElapsedTimeForSkillDuration = null;
    this.MSApplying = true;
    this._moonMessageService.showToastMessage(MessageSeverity.Info, "Processing. Please wait...");
    const performanceTimer: PerformanceTimer = PerformanceTimer.start();
    try {
    
    const chatParameterForm: ChatParameterForm =  this.MSReviseForm.getRawValue();
    
    if (chatParameterForm.inputMode == "file-summary")
      await this.getFileSummary(chatParameterForm);
    else
      await this.getSummary(chatParameterForm);
    } finally{
      this.MSApplying = false;
      this.MSElapsedTimeForSkillDuration = performanceTimer.getElapsedTime();
    }
  }

  private async getSummary(chatParameterForm: ChatParameterForm) {

    const apiResult: ApiContentResult<ChatResult> = await this._chatSkillController.RunChat(chatParameterForm);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      const chatResult: ChatResult = apiResult.content;
      this.MSReviseForm.controls.inputToken.setValue(chatResult.inputTokens);
      this.MSSkillOutput = chatResult.content;
    }
  }
  
  public async getFileSummary(chatParameterForm: ChatParameterForm) {
    
    const formData = new FormData();
    formData.set('isTest', chatParameterForm.isTest.toString());
    formData.set('skillType', chatParameterForm.skillType.toString());
    formData.set('skillName', chatParameterForm.skillName.toString());
    if (chatParameterForm.inputFile)
        formData.set('inputFile', chatParameterForm.inputFile);

    const apiResult: ApiContentResult<ChatResult> = await this._chatSkillController.RunFileSummaryChat(formData);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      const chatResult: ChatResult = apiResult.content;
      this.MSSkillOutput = chatResult.content ?? String.empty;
    }
  }

  public MSResetPage() {
    this.MSReviseForm.reset();
    this.MSClearOutput();
  }

  public MSClearOutput() {
    this.MSBlackLinesComponent.MSResetBlackLine();
    this.MSSkillOutput = this.defaultSkillOutput;
  }

  public MSCopyOutputToClipboard() {
    this._clipboard.copy(this.MSSkillOutput);
    this._moonMessageService.showToastMessage(MessageSeverity.Success, "Copied.");
  }

  private initialize() {
    this.setBreadcrumb();
    this.setDefaultReviseForm();
    this.getSkills();
  }
  private setDefaultReviseForm() {
    this.MSReviseForm.controls.skillType.setValue(this._skillName);
  }
  
  public MSFunctions: { label: string, value: string }[] = [];
  
  private async getSkills() {
    this.MSLoading = true;
    
    const apiResult: ApiContentResult<SkillGet[]> = await this._chatSkillController.GetListSkillAsync(this._skillName);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      
      // this.MSSummarizeSkillList = apiResult.content;
      const functions = apiResult.content;
      this.MSFunctions = functions.map(funcName => {
        return { label: funcName.skillName.underscoreToSpace(), value: funcName.skillName };
      })
      const firstFunction = this.MSFunctions[0];
      if (firstFunction) {
        this.MSReviseForm.controls.skillName.setValue(firstFunction.value);
      }
    }

    this.MSLoading = false;
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.setItems([
      { label: "Revise", routerLink: null },
    ]);
  }

  @HostListener('window:keyup', ['$event'])
  public MSOnKeyPress($event: KeyboardEvent) {
    if ($event.ctrlKey && $event.altKey && $event.key == "t")
      this.MSReviseForm.controls.isTest.setValue(!this.MSReviseForm.value.isTest);
  }
}
