<button
    #btn
    type="button"
    class="p-button-raised"
    pButton
    [label]="MSLabel"
    icon="pi pi-angle-down"
    iconPos="right"
    [disabled]="MSDisabled"
    (click)="tieredMenu.toggle($event)"
></button>
<p-tieredMenu
    #tieredMenu
    styleClass="w-15rem"
    [model]="MSButtons"
    [popup]="true"
></p-tieredMenu>