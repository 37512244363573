<div class="p-4 pt-0"></div>

<moon-form-page
    [MSParentForm]="MSTitleReviewEditForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    [MSDisplaySaveButton]="false"
    (MSOnClickEventSubmit)="MSOnClickSubmit()"
    (MSOnClickEventCancel)="MSOnClickCancel()"
>
    <div
        [formGroup]="MSTitleReviewEditForm"
        class="pb-4"
    >
        <!-- <div class="col-12 p-0">
            <moon-form-control
                [MSFormControl]="MSTitleReviewEditForm.controls.status"
                [MSLabel]="'Status'"
                [MSLabelSize]="3"
                [MSInputSize]="9"
            >
                <p-dropdown
                    formControlName="status"
                    [options]="MSStatusOptions"
                    optionLabel="label"
                    optionValue="value"
                ></p-dropdown>
            </moon-form-control>
        </div> -->
        <div class="col-12 p-0">
            <moon-form-control
                class="p-fluid"
                [MSFormControl]="MSTitleReviewEditForm.controls.matterName"
                [MSLabel]="'Matter Name'"
                [MSLabelSize]="3"
                [MSInputSize]="9"
            >
                <input
                    pInputText
                    type="text"
                    formControlName="matterName"
                />
            </moon-form-control>
        </div>
    </div>

    <div>
        <div
            class="progress-bar"
            *ngIf="MSIsFormSubmitting"
        >
            Please wait...
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <button
            type="submit"
            pButton
            pRipple
            label="Save"
            class="w-init p-button-raised mr-2"
            [disabled]="MSIsFormSubmitting"
        ></button>
        <button
            pButton
            pRipple
            type="button"
            label="Cancel"
            [disabled]="MSIsFormSubmitting"
            class="w-init p-button-raised p-button-secondary"
            (click)="MSOnClickCancel()"
        ></button>
    </div>
</moon-form-page>