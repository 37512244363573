import {
    DecimalPipe,
    KeyValuePipe, NgClass, NgFor, NgIf, TitleCasePipe
}                                                       from '@angular/common';
import { Component, Input, OnInit, OnDestroy }                     from '@angular/core';
import { FormsModule, ReactiveFormsModule }             from '@angular/forms';
// Third party imports
import { SharedModule }                                 from 'primeng/api';
import { BadgeModule }                                  from 'primeng/badge';
import { ButtonModule }                                 from 'primeng/button';
import { DataViewModule }                               from 'primeng/dataview';
import { DropdownModule }                               from 'primeng/dropdown';
import { 
    DialogService, DynamicDialogConfig, 
    DynamicDialogModule, DynamicDialogRef 
}                                                       from 'primeng/dynamicdialog';
import { FieldsetBeforeToggleEvent, FieldsetModule }    from 'primeng/fieldset';
import { InputSwitchModule }                            from 'primeng/inputswitch';
import { RatingModule }                                 from 'primeng/rating';
import { RippleModule }                                 from 'primeng/ripple';
import { SkeletonModule }                               from 'primeng/skeleton';
import { TagModule }                                    from 'primeng/tag';
import { TooltipModule }                                from 'primeng/tooltip';
// Moon Imports
import { DocumentController }                           from '@moon-collaborator/api/document.controller';
import { DocumentChunkGet }                             from '@moon-collaborator/api/response/document-chunk-get.response';
import { SearchRatingController }                       from '@moon-collaborator/api/search-rating.controller';
import { ApiBlobResult, ApiContentResult }              from '@moon-core/models/api-result';
import { ComponentUtilityService }                      from '@moon-core/services/component-utility.service';
import { UserSessionService }                           from '@moon-core/services/user-session.service';
import { 
    CloseFavoriteClauseDynamicDialogData, 
    FavoriteClauseModalComponent 
}                                                       from '@moon-public/clause-search/clause-search-data-view/modal/favorite-clause/favorite-clause-modal.component';
import { 
    MatchingClauseModalComponent, 
    SameChunkDynamicDialogData 
}                                                       from '@moon-public/clause-search/clause-search-data-view/modal/matching-clause/matching-clause-modal.component';
import { FilterResultComponent }                        from '@moon-public/clause-search/filter-result/filter-result.component';
import { SearchResultFilter }                           from '@moon-public/clause-search/models/search-result-filter';
import { SimilarChunk }                                 from '@moon-public/clause-search/models/similar-chunk';
import { BlackLinesComponent }                          from '@moon-shared/components/black-lines/black-lines.component';
import { MoonFormControlComponent }                     from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { DefaultValues }                                from '@moon-shared/constants/default-values';
import { MoonMessageService }                           from '@moon-shared/services/moon-message.service';
import { SearchController } from '@moon-public/api/search.controller';

@Component({
    selector: 'ms-search-data-view',
    templateUrl: './clause-search-data-view.component.html',
    styleUrls: ['./clause-search-data-view.component.scss'],
    standalone: true,
    imports: [
        BadgeModule, BlackLinesComponent, ButtonModule, DataViewModule, DecimalPipe, TitleCasePipe,
        DropdownModule, FieldsetModule, FilterResultComponent, FormsModule, KeyValuePipe,
        MoonFormControlComponent, NgFor, NgIf, RatingModule, ReactiveFormsModule, RippleModule, SharedModule,
        SkeletonModule, TooltipModule, InputSwitchModule, NgClass, TagModule, DynamicDialogModule
    ],
    providers: [SearchRatingController, DialogService]
})
export class ClauseSearchDataViewComponent implements OnInit, OnDestroy {
    @Input() public MSOriginalSearchText: string = String.empty;
    @Input() public MSSearchChunkList: SimilarChunk[] = [];
    @Input() public MSSearchResultFilter: SearchResultFilter;
    @Input() public MSTimeElapsedForResult: number | null;
    @Input() public MSIncludeExactMatches: boolean = false;
    @Input() public MSTotalExactMatch: number = 0;
    public MSFilteredSearchChunkList: SimilarChunk[] = [];
    public MSShowFilter: boolean = true;
    public MSDynamicDialogRef?: DynamicDialogRef;
    public MSIsCollaborator: boolean = false;
    public MSIsUserFavoritesAllowed: boolean = false;

    public get MSShowingOutputContent(): string {
        const showingCount = this.MSFilteredSearchChunkList.length;
        const totalChunks = this.MSSearchChunkList.length;

        if (showingCount !== totalChunks) {
            return `Showing ${showingCount} out of ${totalChunks} results`;
        }

        return `Showing ${totalChunks} results`;
    }


    constructor(
        private _userSessionService: UserSessionService,
        private _documentController: DocumentController,
        private _searchController: SearchController,
        private _componentUtilityService: ComponentUtilityService,
        private _moonMessageService: MoonMessageService,
        private _dialogService: DialogService,
    ) { }
    ngOnInit(): void {
        this.MSFilteredSearchChunkList = SearchResultFilter.MSGetGroupedChunkedList(this.MSSearchChunkList);
        this.MSIsCollaborator = this._userSessionService.IsCollaborator();

        this.MSIsUserFavoritesAllowed = this.getAllowFavoriteUserOption();
    }



    private getAllowFavoriteUserOption() {
        const allowFavoriteUserOptionValue = this._userSessionService.GetCurrentUserSession.allowFavoriteSearchResult;
        return DefaultValues.Y.equalsIgnoreCase(allowFavoriteUserOptionValue);
    }

    public MSGetBadgeSeverityForRelevance(relevance: number): string {
        if (relevance > 0.7) return "";
        if (relevance > 0.5) return "info";
        if (relevance > 0.3) return "warning";
        else return "danger";
    }

    public MSOnResultFilterChangedEvent(filterMap: SearchResultFilter) {
        this.MSFilteredSearchChunkList = filterMap.MSApplyFilterAndGroupChunk(this.MSSearchChunkList);
    }

    public MSOnResultFilterClearedEvent() {
        this.MSFilteredSearchChunkList = SearchResultFilter.MSGetGroupedChunkedList(this.MSSearchChunkList);
    }


    public async MSOnAdditionInfoBeforeToggle(event: FieldsetBeforeToggleEvent, similarResult: SimilarChunk) {
        if (event.collapsed) {
            if (!similarResult.neighborChunkList) {

                if (!similarResult.environmentName) {
                    this._moonMessageService.toastInfo("Environment name is missing to get additional info.");
                    return;
                }

                const apiResult: ApiContentResult<DocumentChunkGet[]> =
                    await this._searchController.GetChunkListOrdinalAsync(similarResult.environmentName, similarResult.documentID, similarResult.ordinal);
                if (this._componentUtilityService.WasSuccessful(apiResult)) {
                    similarResult.neighborChunkList = apiResult.content;
                }
            }
        }
    }

    public MSOnClickSaveAsFavorite(similarChunk: SimilarChunk) {
        const dynamicDialogConfig: DynamicDialogConfig<SimilarChunk> = {
            header: 'Favorite Clause',
            styleClass: 'w-50per moon-modal-wrapper',
            data: similarChunk
        };

        this.MSDynamicDialogRef = this._dialogService.open(FavoriteClauseModalComponent, dynamicDialogConfig);

        this.MSDynamicDialogRef.onClose.subscribe(
            (data: CloseFavoriteClauseDynamicDialogData) => {
                if (data?.event === 'success') {
                    similarChunk.savedAsFavorite = data.favoriteClauseForm.savedAsFavorite;
                    similarChunk.favoriteNotes = data.favoriteClauseForm.favoriteNotes;
                    similarChunk.favoriteLastUpdatedBy = this._userSessionService.GetCurrentUserSession.preferredUserName;
                    // this.MSSearchChunkList.orderByDescending(FieldNames.savedAsFavorite, FieldNames.relevance);
                    // this.MSFilteredSearchChunkList = this.MSSearchResultFilter.MSApplyFilter(this.MSSearchChunkList);
                }
        })
    }


    public async MSOnClickShowOtherMatches(similarChunk: SimilarChunk) {

        const matchingDocuments = similarChunk.matchingDocuments;

        const sameChunks: SimilarChunk[] = this.MSSearchChunkList.filter(
            chunkFromAllChunks => matchingDocuments.some(doc =>
                doc.environmentName === chunkFromAllChunks.environmentName &&
                doc.documentChunkID === chunkFromAllChunks.documentChunkID
            ));

        const dynamicDialogConfig: DynamicDialogConfig<SameChunkDynamicDialogData> = {
            styleClass: 'w-80per',
            header: 'Result Clause',
            data: {
                MSOriginalSearchText: this.MSOriginalSearchText,
                MSOriginalChunk: similarChunk,
                MSSameChunks: sameChunks
            }
        };
        this.MSDynamicDialogRef = this._dialogService.open(MatchingClauseModalComponent, dynamicDialogConfig);
    }

    public async MSOnClickDownloadDocument(similarChunk: SimilarChunk): Promise<void> {
        if (!similarChunk.environmentName) {
            this._moonMessageService.toastInfo("Environment name is missing to download the document.");
            return;
        }

        const apiResult: ApiBlobResult = await this._documentController.GetBlobAsync(similarChunk.environmentName, similarChunk.documentID);
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this._moonMessageService.toastSuccess("Download completed.");
        }
    }

    ngOnDestroy() {
        this.MSDynamicDialogRef?.close();
    }
}
