import { CommonModule }             from '@angular/common';
import { Component, OnInit }        from '@angular/core';
import { ActivatedRoute, Router }   from '@angular/router';
// Third party imports
import { SharedModule, MenuItem }   from 'primeng/api';
import { ButtonModule }             from 'primeng/button';
import { InputNumberModule }        from 'primeng/inputnumber';
import { ProgressSpinnerModule }    from 'primeng/progressspinner';
import { RippleModule }             from 'primeng/ripple';
import { TabMenuModule }            from 'primeng/tabmenu';
// Moon imports
import { AppBreadcrumbService }     from '@app-layout/services/app.breadcrumb.service';
import { PublicRouter }             from '@app/moon-public/public.router';
import { TitleDocumentController }  from '@moon-public/api/title-document.controller';
import { TitleSummaryController }from '@moon-public/api/title-summary.controller';
import { PdfDisplayComponent }      from '@moon-maintainer/title-commitment-advanced/pdf-display/pdf-display.component';
import { SummaryResultComponent }   from '@moon-maintainer/title-commitment-advanced/summary-result/summary-result.component';
import { DefaultValues }            from '@moon-shared/constants/default-values';
import { RouteParameter }           from '@moon-shared/constants/route-parameter';
import { RoutePath }                from '@moon-shared/constants/route-path';
import { FieldNames }               from '@moon-shared/constants/field-names';
import { MaintainerRouter }         from '@moon-maintainer/maintainer.router';

@Component({
  selector: 'summary-pdf-review',
  templateUrl: './summary-pdf-review.component.html',
  styleUrls: ['./summary-pdf-review.component.scss'],
  standalone: true,
  imports: [CommonModule,
    SharedModule, InputNumberModule, ProgressSpinnerModule, TabMenuModule, ButtonModule, RippleModule,
    SummaryResultComponent, PdfDisplayComponent],
  providers: [TitleSummaryController, TitleDocumentController, PublicRouter]
})
export class SummaryPdfReviewComponent implements OnInit{

  public MSTitleSummaryID: number;
  public MSExceptionPagesCSV: string;
  public MSTitleSummaryChatLogID: number | null;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _publicRouter: PublicRouter,
    private _maintinterRouter: MaintainerRouter

  ) { 
    const currentRouterState = this._router.getCurrentNavigation()?.extras.state;
    if (currentRouterState) {
      this.MSExceptionPagesCSV = currentRouterState[FieldNames.ExceptionPagesCsv.toCamelCase()];
      this.MSTitleSummaryChatLogID = currentRouterState[FieldNames.ChatLogID.toCamelCase()];
    }
  }

  ngOnInit() {
    this.MSTitleSummaryID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleSummaryID) ?? DefaultValues.NoID);
    this.setBreadcrumb();
  }
  public MSOnClickRouteBack() {
    this._publicRouter.RouteToTitleCommitmentsDetail(this.MSTitleSummaryID)
  }

  private setBreadcrumb() {
    const items: MenuItem[] = [
      {
        label: RoutePath.TitleCommitments.getDisplayName(),
        command: () => {
          this._maintinterRouter.RouteToTitleCommitmentsAdvanced();
        },
      },
      {
        label: RoutePath.Detail,
        command: () => {
          this._publicRouter.RouteToTitleCommitmentsDetail(this.MSTitleSummaryID)
        }
      },
      {
        label: RoutePath.PdfReview,
        routerLink: null,
      },
    ];
    this._appBreadcrumbService.setItems(items);
  }

}
