<!-- Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM -->
<moon-edit-page [MSLoading]="MSLoading"
                [MSPageTitle]="MSPageTitle"
                [MSDisplayRemoveButton]="MSDisplayRemoveButton"
                [MSDisplayRouteBackButton]="MSDisplayRouteBackButton"
                (MSOnClickEventRemove)="OnClickRemove()"
                (MSOnClickEventRouteBack)="OnClickRouteBack()">
    <ng-container *ngIf="!MSLoading">
        <moon-skill-test-data-form #MSForm
                       [MSSkillNumber]="MSSkillNumber"
                       [MSSkillTestDataID]="MSSkillTestDataID"
                       [MSSkillTestDataGet]="MSSkillTestDataGet">
        </moon-skill-test-data-form>
    </ng-container>
</moon-edit-page>
