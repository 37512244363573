<div class="card moon-pdf-ocr-container">
    <p-tabView [scrollable]="true">
        <p-tabPanel header="Summary">
            <div>
                <label
                    for="switch1"
                    [ngClass]="{'moon-flipped-font': MSDisplayJson}"
                >Show JSON</label>
                <p-inputSwitch
                    class="moon-switch"
                    inputId="switch1"
                    [(ngModel)]="MSDisplayJson"
                ></p-inputSwitch>
            </div>
            <div>
                <ng-container *ngIf="!MSDisplayJson; else showSummaryText">
                    <div *ngIf="MSSummaryContentParsed">
                        <div *ngFor="let keyValue of MSSummaryContentParsed | keyvalue">
                            <span class="font-bold">{{keyValue.key}}</span> : {{keyValue.value}}
                        </div>
                    </div>
                </ng-container>
                <ng-template #showSummaryText>
                    <p>
                        <span class="white-space-pre-wrap">
                            {{MSSummaryContent}}
                        </span>
                    </p>
                </ng-template>
            </div>
        </p-tabPanel>
        <p-tabPanel header="OCR Data">
            <ng-container *ngFor="let ocr of MSOcrContent">
                <div>
                    <div
                        *ngIf="ocr.pageNumber > 0"
                        [id]="'moon-ocr-page-'+ ocr.pageNumber"
                        class="text-right font-italic"
                    >
                        Page {{ocr.pageNumber}}
                    </div>
                    <div class="white-space-pre-wrap">
                        {{ocr.content}}
                    </div>
                </div>
                <hr>
            </ng-container>
        </p-tabPanel>
        <p-tabPanel header="OCR Skill">
            <div class="grid">
                <div class="col-12">
                    <button
                        *ngIf="MSOcrSkillResult"
                        class="float-right"
                        pButton
                        pRipple
                        icon="pi pi-copy"
                        [disabled]="MSOcrInProgress"
                        pTooltip="Copy Skill Text to Clipboard"
                        tooltipPosition="left"
                        (click)="OnClickCopy($event)"
                    ></button>
                    <button
                        class="float-right"
                        pButton
                        [loading]="MSOcrInProgress"
                        loadingIcon="pi pi-spin pi-spinner"
                        pTooltip="Run OCR Skill"
                        tooltipPosition="top"
                        icon="pi pi-play"
                        (click)="OnClickOCRSkill()"
                    >
                    </button>
                </div>
                <div class="col-12">
                    <p
                        *ngIf="MSOcrSkillResult"
                        class="white-space-pre-wrap"
                    >
                        {{ MSOcrSkillResult.content }}
                    </p>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Exception Chat Log">
            <ng-template #noChatLogID>Exception chat log is not available.</ng-template>
            <moon-chat-log
                *ngIf="MSExceptionChatLogID;else noChatLogID"
                [MSChatLogID]="MSExceptionChatLogID"
                (MSOnOverrideChatLogData)="MSOnOverrideClassifyChatLogData(MSExceptionChatLogID)"
            ></moon-chat-log>
        </p-tabPanel>
        <p-tabPanel header="Summary Chat Log">
            <ng-template #noChatLogID>Summary chat log is not available.</ng-template>
            <moon-chat-log
                *ngIf="MSSummaryChatLogID;else noChatLogID"
                [MSChatLogID]="MSSummaryChatLogID"
                (MSOnOverrideChatLogData)="MSOnOverrideSummarizeChatLogData(MSSummaryChatLogID)"
            ></moon-chat-log>
        </p-tabPanel>
    </p-tabView>
</div>