// Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM
import { NgIf }                         from '@angular/common';
import { Component, OnDestroy, OnInit }            from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
// Moon Imports
import { ApiContentResult }             from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import {ApiResult}                      from '@moon-core/models/api-result';
import { SkillGet }                     from '@moon-maintainer/api/response/skill-get.response';
import { SkillController }              from '@moon-maintainer/api/skill.controller';
import { SkillFormComponent }           from '@moon-maintainer/skill/skill-form/skill-form.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';
import { RouteParameter }               from '@moon-shared/constants/route-parameter';
import {MaintainerRouter}               from '@moon-maintainer/maintainer.router';
import { AppBreadcrumbService }         from '@app-layout/services/app.breadcrumb.service';
import { HistoryService }               from '@moon-shared/services/history.service';

@Component({
    selector: 'moon-skill-add-page',
    templateUrl: './skill-edit-page.component.html',
    styleUrls: ['./skill-edit-page.component.css'],
    standalone: true,
    imports: [
      NgIf, 
      MoonLoadingComponent, 
      MoonEditPageComponent,
      SkillFormComponent,
    ],
    providers: [
      SkillController,
      MaintainerRouter,
    ]
})
export class SkillEditPageComponent implements OnInit, OnDestroy {

  public MSSkillID: number | null;
  public MSSkillGet: SkillGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.Skill;
  public MSEnvironmentName = String.empty;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _skillController: SkillController,
    private _maintainerRouter: MaintainerRouter,
    private _historyService: HistoryService
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSSkillID){
      await this.getSkillByID(this.MSSkillID);
      this.setHistoryEntityValues(true, ConstantString.Skill, this.MSSkillID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSSkillID){
      this.doRemove(this.MSSkillID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSSkillID !== null;
  }

  public MSOnClickGoBack() {
    this._maintainerRouter.ToSkill();
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    this._appBreadcrumbService.SetEditPageBreadcrumb(this.MSPageTitle, ConstantString.Skill, this.MSSkillID);
  }
  
  private readRouteParameters() {
    const skillID = this._activatedRoute.snapshot.paramMap.get(RouteParameter.SkillID);
    this.MSSkillID = skillID ? +skillID : null;
  }

  private async getSkillByID(skillID: number) {
    if (skillID) {
      const apiResult: ApiContentResult<SkillGet> = await this._skillController.GetByID(skillID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this.MSSkillGet = apiResult.content;
          this.MSPageTitle = `${ConstantString.Skill} (${this.MSSkillGet.skillNumber})`;
      }
    }
  }

  private async doRemove(skillID: number) {
    const apiResult: ApiResult = await this._skillController.Delete(skillID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToSkill();
    }
  }

  private setHistoryEntityValues(showButton: boolean, entityType: string | null, entityID: number | null): void {
    this._historyService.setHistoryEntityValues(showButton, entityType, entityID, this.MSSkillGet?.skillName ?? null);
  }

  ngOnDestroy(): void {
    this.setHistoryEntityValues(false, null, null);
  }
}
