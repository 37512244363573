// Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM
import { Component, Input, OnInit }             from '@angular/core';
import { FormControl, FormGroup, FormsModule, 
            ReactiveFormsModule, Validators }   from '@angular/forms';
// Third Party Imports
import { SharedModule }                         from 'primeng/api';
import { InputTextModule }                      from 'primeng/inputtext';
import { InputTextareaModule }                  from 'primeng/inputtextarea';
// Moon Imports
import { IApiResult }                           from '@moon-core/models/api-result';
import { ComponentUtilityService }              from '@moon-core/services/component-utility.service';
import { EmailWhitelist }                       from '@moon-maintainer/api/request/email-whitelist.request';
import { EmailWhitelistGet }                    from "@moon-maintainer/api/response/email-whitelist-get.response";
import { EmailWhitelistController }             from '@moon-maintainer/api/email-whitelist.controller';
import { MaintainerRouter }                     from "@moon-maintainer/maintainer.router";
import { MoonFormControlComponent }             from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent }                from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                          from '@moon-shared/types/form-group-of.type';

@Component({
    selector: 'moon-email-whitelist-form',
    templateUrl: './email-whitelist-form.component.html',
    styleUrls: ['./email-whitelist-form.component.css'],
    standalone: true,
    imports: [ FormsModule, ReactiveFormsModule, SharedModule, InputTextModule, InputTextareaModule,
            MoonFormPageComponent, MoonFormControlComponent],
    providers: [EmailWhitelistController]
})
export class EmailWhitelistFormComponent implements OnInit {
    @Input() public MSEmailWhitelistGet: EmailWhitelistGet;
    @Input() public MSEmailWhitelistID: number | null;
    @Input() public MSParentForm: FormGroup<FormGroupOf<EmailWhitelist>> = new FormGroup<FormGroupOf<EmailWhitelist>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
		whitelistValue: new FormControl('', {nonNullable: true, validators: [Validators.required, Validators.maxLength(500)] }),
		notes: new FormControl(null, {validators: [Validators.maxLength(1000)]}),

    });
    
    public MSIsFormSubmitting: boolean;
    public MSEnvironmentName = String.empty;

    // Declare Options PlaceHolder

    constructor(
        private _emailWhitelistController: EmailWhitelistController,
        private _componentUtilityService: ComponentUtilityService,
        private _maintainerRouter: MaintainerRouter
    ) {
    }

    ngOnInit(): void {
        this.doInitializeFormControls();
        this.doMapGetDataToFormControl(this.MSEmailWhitelistGet);
        this.getSelectItems();
    }

    // Start: Button Click Event Handlers
    public OnClickSubmit() {
        if (this.MSIsFormSubmitting) {
            return;
        }        
        if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
            this.doSubmitForm();
        }
    }

    public OnClickCancel() {
        this.routeToBasePage();
    }

    // End: Button Click Event Handlers

    private doInitializeFormControls() {
    }

    private doMapGetDataToFormControl(emailWhitelist: EmailWhitelistGet) {
        if (!emailWhitelist) {
            return;
        }        
        this.MSEmailWhitelistGet = emailWhitelist;
        this.MSParentForm.patchValue(emailWhitelist);
    }

    private async doSubmitForm() {
        this.MSIsFormSubmitting = true;
        const apiResult: IApiResult = this.MSEmailWhitelistID
            ? await this._emailWhitelistController.Update(this.MSEmailWhitelistID, this.MSParentForm.getRawValue())
            : await this._emailWhitelistController.Create(this.MSParentForm.getRawValue());
            
        if (this._componentUtilityService.WasSubmitSuccessful(apiResult, this.MSParentForm)) {
            this.routeToBasePage();
        }
        this.MSIsFormSubmitting = false;
    }

    private getSelectItems(): void {
        // Set Options PlaceHolder
    }

    private routeToBasePage(): void {
        this._maintainerRouter.ToEmailWhitelist();
    }
}
