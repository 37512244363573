// Moon Auto Generated (1.2.0) at 12/29/2023 7:46:03 AM
import { Component, Input, OnInit,
    ViewEncapsulation }                 from "@angular/core";
import { CommonModule, DatePipe, NgClass, NgFor, NgStyle,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault }                   from "@angular/common";
// Third Party imports
import { ButtonModule }                 from "primeng/button";
import { InputTextModule }              from "primeng/inputtext";
import { Table, TableModule }           from "primeng/table";
import { CalendarModule }               from "primeng/calendar";
// Moon imports
import { UserSessionLogFind }           from "@moon-maintainer/api/response/user-session-log-find.response";
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { MoonTablePageComponent }       from '@moon-shared/components/moon-maintenance-page/moon-table-page/moon-table-page.component';
import { MoonTableHeaderComponent }     from '@moon-shared/components/moon-maintenance-page/moon-table-header/moon-table-header.component';
import { MoonCustomDropdownComponent }  from "@moon-shared/components/moon-custom-dropdown/moon-custom-dropdown.component";
import { FieldNames }                   from '@moon-shared/constants/field-names';
import { MoonDatePipe }                 from "@moon-shared/pipes/moon-date.pipe";
import { TableColumn }                  from '@moon-shared/types/table-column.type'

@Component({
    selector: "user-session-table",
    templateUrl: "./user-session-table.component.html",
    styleUrls: ['./user-session-table.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgStyle, CommonModule,
        TableModule, ButtonModule, InputTextModule, CalendarModule, DatePipe,
        MoonTablePageComponent, MoonTableHeaderComponent, MoonCustomDropdownComponent, MoonDatePipe
    ],
    providers: [MaintainerRouter]
})
export class UserSessionTableComponent implements OnInit {
    @Input() public MSPageTitle: string;
    @Input() public MSUserSessionList: UserSessionLogFind[];
    @Input() public MSLoading = true;
    public MSNumberOfFilteredValues: number;

    public MSTableColumns: TableColumn[] = [
		{field: FieldNames.UserName.toCamelCase(), header: FieldNames.UserName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.PreferredUserName.toCamelCase(), header: FieldNames.PreferredUserName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.EnvironmentName.toCamelCase(), header: FieldNames.EnvironmentName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ObjectIdentifier.toCamelCase(), header: FieldNames.ObjectIdentifier.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ApplicationName.toCamelCase(), header: FieldNames.ApplicationName.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ApplicationVersion.toCamelCase(), header: FieldNames.ApplicationVersion.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.DBVersion.toCamelCase(), header: FieldNames.DBVersion.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ConnectAsAdmin.toCamelCase(), header: FieldNames.ConnectAsAdmin.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.RoleCode.toCamelCase(), header: FieldNames.RoleCode.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ConnectedAt.toCamelCase(), header: FieldNames.ConnectedAt.getDisplayName(), class: 'w-25per'},
		{field: FieldNames.ValidUntil.toCamelCase(), header: FieldNames.ValidUntil.getDisplayName(), class: 'w-25per'},

        // Column PlaceHolder - DO NOT REMOVE
    ];

    public MSEnableSearch: boolean;
    public MSDisplayFind: boolean;
    public MSDisplayCreate: boolean;

    constructor() {}

    ngOnInit(): void {
        this.MSEnableSearch = true;
        this.MSDisplayCreate = false;
        this.MSDisplayFind = false;
    }

    public MSOnFilter(eventItems: UserSessionLogFind[]) { 
        this.MSNumberOfFilteredValues = eventItems.length;
    }
    public OnSearchInput(UserSessionTable: Table, searchInput: string): void {
        UserSessionTable.filterGlobal(searchInput, "contains");
    }
}
