// Moon Auto Generated (1.2.0) at 12/22/2023 6:21:39 AM
import { Injectable }                     from "@angular/core";
// MS Imports
import { ApiContentResult, 
         ApiResult }                      from '@moon-core/models/api-result';
import { MoonApiClient }                  from '@moon-core/services/moon-api.client';
import { UserOptionMap }                  from "@moon-public/api/request/user-option-map.request";
import { UserOptionMapFind }               from "@moon-public/api/response/user-option-map-find.response";
import { ConstantString }                 from "@moon-shared/constants/constant-string";
import { FindUserOptionMapParameter }     from "@moon-public/api/request/find-user-option-map.parameter";
@Injectable()
export class UserOptionMapController {

  private getBaseUrl(): string {
    return this._moonApi.getBaseUrl(ConstantString.UserOptionMap);
  }

  constructor(private _moonApi: MoonApiClient) { }

  /**
   *   POST: Public/v1/UserOptionMap/find
   */
  public async Find(findParameter: FindUserOptionMapParameter): Promise<ApiContentResult<UserOptionMapFind[]>> {

    const url = String.createRoute(this.getBaseUrl(), ConstantString.Find);
    return this._moonApi.postWithResultAsync<FindUserOptionMapParameter, UserOptionMapFind[]>(url, findParameter);
  }

  /**
   *   POST: Public/v1/UserOptionMap
   */
  public async Create(userOptionMap: UserOptionMap
  ): Promise<ApiContentResult<number>> {

    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, userOptionMap);
  }

  /**
   *   PUT: Public/v1/UserOptionMap/{userOptionMapID}
   */
  public async Update(userOptionMapID: number, userOptionMap: UserOptionMap
  ): Promise<ApiResult> {

    const url = this._moonApi.combineUrl(this.getBaseUrl(), userOptionMapID);
    return this._moonApi.putAsync(url, userOptionMap);
  }
}
