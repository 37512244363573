<!-- Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM -->
<moon-table-page [MSPageTitle]="MSPageTitle"
                 [MSItemList]="MSSkillTestDataList"
                 [MSDisplayCreateButton]="MSDisplayCreate"
                 [MSDisplayFindButton]="MSDisplayFind"
                 [MSDisplayUploadButton]="true"
                 (MSEventOnClickCreate)="OnClickCreate()"
                 (MSEventOnClickFind)="OnClickFind()"
                 (MSEventOnUploadFile)="OnUploadFile($event)"
                 >
    <p-table #MSTable
             [columns]="MSTableColumns"
             [rowHover]="true"
             [value]="MSSkillTestDataList"
             [(selection)]="MSSelectedSkillTestDataList"
             selectionMode="single"
             [globalFilterFields]="['inputData', 'outputData']"
             styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
             [loading]="MSLoading"
             [scrollable]="true"
             scrollHeight="flex"
            >
        <ng-template pTemplate="caption">
            <moon-table-header [MSPageTitle]="MSPageTitle"
                               [MSEnableSearch]="MSEnableSearch"
                               [MSDisplayCreateButton]="MSDisplayCreate"
                               [MSDisplayFindButton]="MSDisplayFind"
                               [MSDisplaySecondaryButton]="true"
                               MSSecondaryButtonToolTip="Export Skill Test Data as Json File"
                               [MSSecondaryButtonIcon]="'pi pi-file-export'"
                               [MSDisplayUploadButton]="true"
                               (MSEventOnClickCreate)="OnClickCreate()"
                               (MSEventOnUploadFile)="OnUploadFile($event)"
                               (MSEventOnClickSecondary)="OnClickExport()"
                               (MSEventOnClickFind)="OnClickFind()"
                               (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)">
            </moon-table-header>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="w-4rem text-center">
                    Actions
                </th>
                <th class="w-3rem text-center">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td class="text-center">
                    <div class="text-center">
                      <moon-custom-dropdown [model]="rowData.actions">
                        <i class="pi pi-ellipsis-v text-2xl"></i>
                      </moon-custom-dropdown>
                    </div>
                  </td>
                  <td class="text-center">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                <td *ngFor="let col of columns"
                    [class]="col.class"
                    (click)="col.field === 'actions' || OnClickItem(rowData)">
                    <div [ngSwitch]="col.field">
                        <!-- <div *ngSwitchCase="'actions'"
                             class="text-center">

                            <moon-custom-dropdown [model]="rowData.actions">
                                <i class="pi pi-ellipsis-v text-3xl"></i>
                            </moon-custom-dropdown>
                        </div> -->
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>

