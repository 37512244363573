<p-progressBar
  *ngIf="MSLoading"
  mode="indeterminate"
  styleClass="h-1px"
></p-progressBar>

<div *ngIf="!MSLoading">
  <p-fieldset legend="Internal Notes">
    <div *ngIf="!MSEditNotes" class="grid">
      <div
        class="moon-notes"
        [innerText]="MSTitleSummaryInternalNotes.internalNotes"
      ></div>

      <button
        type="button"
        pButton
        pRipple
        [icon]="'pi pi-pencil'"
        [pTooltip]="'Edit'"
        (click)="MSOnClickEditNotes()"
        class="ml-2 p-button-rounded moon-button"
      ></button>
    </div>

    <moon-form-page
      *ngIf="MSEditNotes"
      [MSParentForm]="MSParentForm"
      [MSIsFormSaving]="MSIsFormSubmitting"
      [MSDisplaySaveButton]="false"
    >
      <div [formGroup]="MSParentForm">
        <div class="col-12">
          <moon-form-control
            [MSDisplayLabel]="false"
            [MSFormControl]="MSParentForm.controls.internalNotes"
            [MSInputSize]="12"
          >
            <div class="grow-wrap" [attr.textarea-value]="internalNotes.value">
              <textarea
                #internalNotes
                id="notes"
                rows="6"
                pInputTextarea
                [formControl]="MSParentForm.controls.internalNotes"
                placeholder="Notes"
              ></textarea>
            </div>
          </moon-form-control>
        </div>
      </div>
    </moon-form-page>
  </p-fieldset>

  <div class="mt-2">
    <div class="progress-bar" *ngIf="MSIsFormSubmitting">
      Please wait...
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>

    <button
      *ngIf="MSEditNotes" 
      type="button"
      pButton
      pRipple
      label="Update"
      class="w-init p-button-raised mr-2"
      [disabled]="MSIsFormSubmitting"
      (click)="MSOnClickSubmit()"
    ></button>

    <button
      pButton
      pRipple
      type="button"
      [label]="MSEditNotes ? 'Cancel' : 'Close' "
      [disabled]="MSIsFormSubmitting"
      class="w-init p-button-raised p-button-secondary"
      (click)="MSOnClickCancel()"
    ></button>
  </div>
</div>
