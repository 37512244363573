import { Component, Input, OnInit }                from '@angular/core';
import { CommonModule }                    from '@angular/common';
import { FormsModule }                     from '@angular/forms';
import { Clipboard }                       from '@angular/cdk/clipboard';
//Third Party Imports
import { SharedModule }                    from 'primeng/api';
import { TabViewModule }                   from 'primeng/tabview';
import { InputSwitchModule }               from 'primeng/inputswitch';
import { ButtonModule }                    from 'primeng/button';
import { TooltipModule }                   from 'primeng/tooltip';
//Naya Imports
import { ApiContentResult, ApiResult }                from '@moon-core/models/api-result';
import { ComponentUtilityService }         from '@moon-core/services/component-utility.service';
import { TitleDocumentController }         from '@moon-public/api/title-document.controller';
import { DocumentOcr }                     from '@moon-public/api/response/document-ocr.response';
import { TitleExceptionGet }               from '@moon-public/api/response/title-exception.response';
import { TitleDocumentGet }                from '@moon-public/api/response/title-document.response';
import { ChatResult }                      from '@moon-public/api/response/chat-result.response';
import { ChatLogComponent }                from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { FieldNames }                      from '@moon-shared/constants/field-names';


type OcrResult = { pageNumber: number, content: string };
type SummaryType = {
  [key: string]: string;
};

@Component({
  selector: 'moon-document-result',
  standalone: true,
  templateUrl: './document-result.component.html',
  styleUrls: ['./document-result.component.scss'],
  imports: [
    CommonModule, SharedModule,
    TabViewModule, InputSwitchModule, FormsModule, ButtonModule, TooltipModule,
    ChatLogComponent
  ],
})
export class DocumentResultComponent implements OnInit {

  @Input() public MSTitleDocumentID: number;
  @Input() public MSTitleSummaryID: number;

  public MSTitleExceptions: TitleExceptionGet[] = [];
  public MSSummaryContent: string;
  public MSSummaryContentParsed: SummaryType;
  public MSOcrContent: OcrResult[];
  public MSDisplayJson: boolean = false;
  public MSOcrInProgress: boolean = false;
  public MSOcrSkillResult: ChatResult;
  public MSExceptionChatLogID: number | null;
  public MSSummaryChatLogID: number | null;

  constructor(
    private _titleDocumentController: TitleDocumentController,
    private _componentUtilityService: ComponentUtilityService,
    private _clipboard: Clipboard
  ) { }
  
  ngOnInit() {
    
    this.loadSummary(this.MSTitleDocumentID);
    this.loadOcr(this.MSTitleDocumentID);
  }

  public async loadSummary(titleDocumentID: number) {
    const apiResult: ApiContentResult<TitleDocumentGet> = await this._titleDocumentController.GetByID(this.MSTitleSummaryID,titleDocumentID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      const titleDocument = apiResult.content;
      this.MSSummaryContent = titleDocument.summaryText;
      this.MSExceptionChatLogID = titleDocument.exceptionChatLogID
      this.MSSummaryChatLogID = titleDocument.summaryChatLogID
      this.tryParseAndSetSummaryContent();
    }
    else {
      this.MSOcrContent = [{pageNumber: -1, content: "Summary data unavailable."}];
    }
  }

  private tryParseAndSetSummaryContent() {
    try {
      this.MSSummaryContentParsed = JSON.parse(this.MSSummaryContent);
    }
    catch (error: unknown) {
      this.MSDisplayJson = true;
    }
  }

  public async loadOcr(titleDocumentID: number) {

    const apiResult: ApiContentResult<DocumentOcr> = await this._titleDocumentController.GetOcr(this.MSTitleSummaryID,titleDocumentID);
    if (apiResult.success) {
      this.MSOcrContent = this.extractOcrContent(apiResult.content);
    }
    else {
      this.MSOcrContent = [{pageNumber: -1, content: "Ocr data unavailable."}];
    }
  }

  public async MSOnOverrideClassifyChatLogData(chatLogID: number): Promise<void>{
    try {
      this.MSOcrInProgress = true;
      const apiResult: ApiResult = await this._titleDocumentController.ChatLogDataOverride(this.MSTitleSummaryID, this.MSTitleDocumentID, chatLogID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.loadSummary(this.MSTitleDocumentID);
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }
  
  public async MSOnOverrideSummarizeChatLogData(chatLogID: number): Promise<void>{
    try {
      this.MSOcrInProgress = true;
      const apiResult: ApiResult = await this._titleDocumentController.ChatLogDataOverride(this.MSTitleSummaryID, this.MSTitleDocumentID, chatLogID);

      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.loadSummary(this.MSTitleDocumentID);
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }
  
  public async OnClickOCRSkill(): Promise<void>{
    try {
      this.MSOcrInProgress = true;
      const apiResult: ApiContentResult<ChatResult> = await this._titleDocumentController.GetTitleDocumentOcrText(this.MSTitleSummaryID,this.MSTitleDocumentID);
      if (!apiResult.success) {
        if (apiResult.apiError.status !== 0)
          this._componentUtilityService.WasSuccessful(apiResult);
      }
      else {
        this.MSOcrSkillResult = apiResult.content;
      }
    } finally {
      this.MSOcrInProgress = false;
    }
  }
  
  public OnClickCopy(e: MouseEvent): void {
    const systemMessage = this.MSOcrSkillResult.content;
    if (systemMessage)
        this._clipboard.copy(systemMessage);
    e.stopPropagation();
  }

  private extractOcrContent(documentOcr: DocumentOcr): OcrResult[] {
    const ocrLines: OcrResult[] = documentOcr.pages.flatMap(a => {
      return { pageNumber: a.pageNumber, content: a.lines.join('\n') };
    });
    return ocrLines.orderBy(FieldNames.PageNumber);
  }

}
