<div class="pages-body login-page flex flex-column">
    <div class="align-self-center mt-auto mb-auto login-card">
        <div class="pages-panel card flex flex-column login-card-inner">
            <div class="pages-header px-3 py-1">
                <img
                    class="moon-logo"
                    id="app-logo"
                    src="assets/layout/images/logo-dark.png"
                    alt="MOONSTONE"
                />
            </div>

            <h4>Welcome</h4>

            <div class="pages-detail mb-2 px-3 white-space-pre-wrap">
                <span>{{MSDisplayMessage | slice: 0:200}}</span>
                <span
                    title="MSDisplayMessage"
                    *ngIf="MSDisplayMessage.length > 200"
                >...</span>
            </div>
            <p-progressBar
                *ngIf="MSLoading"
                mode="indeterminate"
            ></p-progressBar>

            <div class="pages-detail mb-2 px-3">
                <hr />
                <p>
                    <span>
                        Powered by: {{ MSDiagnostic.apiName }} | Version:
                        {{ MSDiagnostic.apiVersion }}<br />
                    </span>
                    <span> Connected To: {{ MSDiagnostic.databaseName }} </span>
                </p>
                <p>Application Version: {{ MSWebApplicationVersion }}</p>
            </div>


            <button
                *ngIf="MSShowReloadDiagnosticButton"
                pButton
                pRipple
                class="login-button mb-2 px-3 login-button"
                [disabled]="MSLoading"
                (click)="MSOnClickReloadDiagnosticButton()"
                label="RELOAD"
                title="Reload Diagnostic and Connect to Moonstone"
            ></button>

            <div class="pages-detail">
                User: {{MSLoggedUserName}} |
                <button
                    *ngIf="MSLoggedUserName"
                    pButton
                    pRipple
                    [disabled]="MSLoading"
                    class="moon-change-user-button"
                    (click)="MSSignOutFromAzure()"
                >Change user?</button>
            </div>
        </div>
    </div>
</div>