<div class="moon-title-upload-wizard card">
    <div class="moon-wizard-steps card">
        <p-steps
            [model]="MSWizardSteps"
            [readonly]="true"
        ></p-steps>
    </div>

    <moon-loading
        *ngIf="MSLoading; else wizardContent"
        class="moon-pdf-loading-spinner"
        [MSLoadingMessage]="MSLoadingMessage"
    ></moon-loading>

    <ng-template #wizardContent>
        <router-outlet></router-outlet>
    </ng-template>

    <div>
        <p-messages 
            [(value)]="MSWizardMessages" 
            [enableService]="false" 
            [closable]="false" />
    </div>

    <div
        *ngIf="!MSIsUserInFinalStep"
        class="moon-wizard-buttons"
    >
        <!--         <button
            [disabled]="MSLoading || MSIsUserInProcessStep()"
            class="moon-go-back-button"
            pButton
            pRipple
            (click)="MSOnClickGoBack()"
            [label]="MSGetLabelForGoBackButton()"
            tabindex="-1"
        ></button> -->
        <button
            [disabled]="MSLoading"
            class="moon-next-button"
            pButton
            pRipple
            (click)="MSOnClickNext()"
            [label]="MSGetLabelForNextButton()"
            tabindex="-1"
        > </button>
    </div>
</div>


<p-confirmDialog
    key="title-wizard"
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
    styleClass="w-30rem"
    acceptButtonStyleClass="p-button-text"
    rejectButtonStyleClass="p-button-text"
></p-confirmDialog>