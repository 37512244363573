import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
// Moon Imports
import { ConsumerRouter } from '@moon-consumer/consumer.router';
import { TitleCommitmentWizardService } from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { TitleCommitmentUpload } from '@moon-public/api/request/title-commitment.upload';
import { TitleCommitmentTypeConstant } from '@moon-maintainer/title-commitment-advanced/models/title-commitment-type.constant';
import { MoonFormControlComponent } from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { FormGroupOf } from '@moon-shared/types/form-group-of.type';
import { MoonEnterKeyupDirective } from '@moon-shared/directives/moon-enter-keyup.directive';
import { MaintainerRouter } from '@moon-maintainer/maintainer.router';

@Component({
  selector: 'moon-title-commitment-select-policy',
  standalone: true,
  imports: [
    NgFor, ReactiveFormsModule,
    ButtonModule, RadioButtonModule, RippleModule,
    MoonFormControlComponent, MoonEnterKeyupDirective
  ],
  providers: [MaintainerRouter, ConsumerRouter],
  templateUrl: './title-commitment-select-policy.component.html',
  styleUrl: './title-commitment-select-policy.component.scss'
})
export class TitleCommitmentSelectPolicyComponent {
  public MSTitleCommitmentTypes = [TitleCommitmentTypeConstant.LoanPolicyCommitment, TitleCommitmentTypeConstant.OwnersPolicyCommitment,];
  public get MSTitleCommitmentForm(): FormGroup<FormGroupOf<TitleCommitmentUpload>> {
    return this._titleCommitmentWizardService.MSTitleCommitmentForm;
  }
  constructor(
    private _titleCommitmentWizardService: TitleCommitmentWizardService
  ) { }

  public MSOnClickNext(): void {
    this._titleCommitmentWizardService.GoToNextStep();
  }
}
