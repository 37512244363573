import { CommonModule }                                             from '@angular/common';
import { Component, Input, ViewChild, OnInit }                              from '@angular/core';
import { FormsModule }                                              from '@angular/forms';
// Third party imports
import { PdfViewerComponent, PdfViewerModule }                      from 'ng2-pdf-viewer';
import { ButtonModule }                                             from 'primeng/button';
import { InputSwitchModule }                                        from 'primeng/inputswitch';
import { ProgressSpinnerModule }                                    from 'primeng/progressspinner';
import { RippleModule }                                             from 'primeng/ripple';
// Moon Imports
import { ApiBlobResult }                                            from '@moon-core/models/api-result';
import { ComponentUtilityService }                                  from '@moon-core/services/component-utility.service';
import { TitleDocumentController }                                  from '@moon-public/api/title-document.controller';
import { TitleSummaryController }                                from '@moon-public/api/title-summary.controller';
import { TitleCommitmentPdf }                                       from '@moon-maintainer/title-commitment-advanced/title-commitment-pdf';

@Component({
  selector: 'moon-pdf-display',
  standalone: true,
  templateUrl: './pdf-display.component.html',
  styleUrls: ['./pdf-display.component.scss'],
  imports: [
    CommonModule, FormsModule,
    ButtonModule, RippleModule, ProgressSpinnerModule, InputSwitchModule, 
    PdfViewerModule
  ],
})
export class PdfDisplayComponent implements OnInit {
  @ViewChild('pdfViewer') private _pdfComponent: PdfViewerComponent;
  @Input() public MSTitleSummaryID: number;
  @Input() public MSTitleDocumentID: number;
  @Input() public MSExceptionPagesCSV: string;
  public MSTitleCommitmentPdfBlob: TitleCommitmentPdf = new TitleCommitmentPdf();
  public MSIsPdfDownloadingAndLoading: boolean = false;
  public MSShowPdfThumbnails: boolean = true;
  private blobDownloadResult: ApiBlobResult;

  constructor(
    private _titleSummaryController: TitleSummaryController,
    private _titleDocumentController: TitleDocumentController,
    private _componentUtilityService: ComponentUtilityService,
  ) { }

  ngOnInit() {
    if (this.MSTitleDocumentID != null) {
      this.loadTitleDocumentByID(this.MSTitleSummaryID, this.MSTitleDocumentID);
    }
    else {
      this.loadTitleCommitmentByID(this.MSTitleSummaryID, this.MSExceptionPagesCSV);
    }
  }

  public MSDownloadPdf() {
    this._componentUtilityService.WasDownloadSuccessful(this.blobDownloadResult);
  }
  
  private async loadTitleCommitmentByID(titleSummaryID: number, exceptionPagesCsv: string) {
    try {
      this.MSIsPdfDownloadingAndLoading = true;

      const apiResult: ApiBlobResult = await this._titleSummaryController.GetExceptionPagesPdfBlob(titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.blobDownloadResult = apiResult;
        this.MSTitleCommitmentPdfBlob.ReadContentFromBlob(apiResult.content);
        this.setExceptionPages(exceptionPagesCsv);
      }
    }
    finally {
      this.MSIsPdfDownloadingAndLoading = false;
    }
  }

  private async loadTitleDocumentByID(titleSummaryID: number, titleDocumentID: number) {
    try {
      this.MSIsPdfDownloadingAndLoading = true;
      const apiResult: ApiBlobResult = await this._titleDocumentController.GetBlob(titleSummaryID, titleDocumentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.blobDownloadResult = apiResult;
        this.MSTitleCommitmentPdfBlob.ReadContentFromBlob(apiResult.content);
      }
    }
    finally {
      this.MSIsPdfDownloadingAndLoading = false;
    }
  }

  public MSSearchPdf(stringToSearch: string) {
    this._pdfComponent.eventBus.dispatch('find', {
      query: stringToSearch, type: 'again', caseSensitive: false, findPrevious: undefined, highlightAll: true, phraseSearch: true
    });
  }

  private setExceptionPages(exceptionPagesCsv: string) {
    if (!String.isNullOrWhiteSpace(exceptionPagesCsv)) {
      const exceptionPages: number[] = exceptionPagesCsv.split(',').map(a => parseInt(a));
      this.MSTitleCommitmentPdfBlob.SetExceptionPages(exceptionPages);
    }
  }

}
