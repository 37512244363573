// Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
// Third Party Imports  
import { SharedModule } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
// Moon Imports 
import { UserInfoController } from '@moon-maintainer/api/user-info.controller';
import { MoonFormControlComponent } from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonFormPageComponent } from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { UserOptionMapFind } from '@moon-public/api/response/user-option-map-find.response';
import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FormGroupOf } from '@moon-shared/types/form-group-of.type';
import { FlatUserOption } from '@moon-core/models/flat-user-option';

@Component({
    selector: 'user-option-map-form',
    templateUrl: './user-option-map-form.component.html',
    styleUrls: ['./user-option-map-form.component.scss'],
    standalone: true,
    imports: [
        NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, FormsModule, ReactiveFormsModule, SharedModule,
        MoonFormPageComponent, MoonFormControlComponent,
        InputTextModule, DropdownModule,
    ],
    providers: [UserInfoController]
})
export class UserOptionMapFormComponent implements OnInit, AfterViewInit {
    @Input() public MSUserInfoID: number | null;
    @Input() public MSUserOptionMapFind: UserOptionMapFind[];
    @Output() public MSUserOptionMapFindChange: EventEmitter<UserOptionMapFind> = new EventEmitter();
    @Output() public MSUserOptionMapFormDirtyEvent: EventEmitter<null> = new EventEmitter();

    // Declare Options PlaceHolder
    public MSUserOptionMapForm: FormGroup<FormGroupOf<FlatUserOption>> = new FormGroup<FormGroupOf<FlatUserOption>>({
        // FormBuilder PlaceHolder - DO NOT REMOVE
        ClauseSearchEnvironment: new FormControl<string[]>({ value: [], disabled: true }, { nonNullable: true, validators: [Validators.required] }),
        ClauseSearchDocumentType: new FormControl<number[]>({ value: [], disabled: true }, { nonNullable: true, validators: [Validators.required] }),
        ShowAirplaneTerms: new FormControl('N', { nonNullable: true, validators: [Validators.required] }),
        ShowTitleCommitment: new FormControl('Y', { nonNullable: true, validators: [Validators.required] }),
        ShowSurvey: new FormControl('N', { nonNullable: true, validators: [Validators.required] })
    });
    constructor(
    ) {
        this.updateOptionValuesFromFormChanges();
    }
    ngOnInit() {
        this.doUpdateFormControls();
    }
    ngAfterViewInit(): void {
        this.checkForFormDirty();
    }
    private updateOptionValuesFromFormChanges(): void {
        this.MSUserOptionMapForm.valueChanges
            .pipe(takeUntilDestroyed())
            .subscribe((res: { [key: string]: any }) => {
                for (const [key, value] of Object.entries(res)) {
                    const foundOption = this.MSUserOptionMapFind.find(option => option.optionName.equalsIgnoreCase(key));
                    if (foundOption) {
                        const optionValue = typeof value === "string" ? value : JSON.stringify(value);
                        foundOption.optionValue = optionValue;
                    }
                }
            });
    }

    private doUpdateFormControls() {
        if (!this.MSUserOptionMapFind)
            return
        this.MSUserOptionMapFind.forEach(a => {
            const control = this.MSUserOptionMapForm.get(a.optionName);
            if (control) {
                const newValue = typeof a.optionValue === "string" ? a.optionValue : JSON.stringify(a.optionValue);
                this.patchValueWithoutTriggeringChanges(control, newValue);
            }
        });
    }

    private patchValueWithoutTriggeringChanges(control: AbstractControl, newValue: string) {
        control?.patchValue(newValue, { emitEvent: false, onlySelf: true });
    }

    private checkForFormDirty() {
        const formChanges = this.MSUserOptionMapForm.valueChanges?.subscribe(() => {
            if (this.MSUserOptionMapForm.touched) {
                this.MSUserOptionMapFormDirtyEvent.emit();
                formChanges?.unsubscribe();
            }
        });
    }
}
