// Moon Auto Generated (1.4.0) at 2/14/2024 10:49:50 AM
import { NgIf }                               from '@angular/common';
import { Component, OnInit }                  from '@angular/core';
// Third Party Imports
import { ConfirmationService, PrimeIcons }    from 'primeng/api';
// Moon Imports         
import { 
  ApiBlobResult, 
  ApiContentResult, ApiResult }               from '@moon-core/models/api-result';
import { ComponentUtilityService }            from '@moon-core/services/component-utility.service';
import { AppBreadcrumbService }               from '@app-layout/services/app.breadcrumb.service';

import { MoonMessageService }                 from '@moon-shared/services/moon-message.service';
import { MoonLoadingComponent }               from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }                     from '@moon-shared/constants/constant-string';

import { TemplateGet }                        from '@moon-maintainer/api/response/template-get.response';
import { TemplateController }                 from '@moon-maintainer/api/template.controller';
import { MaintainerRouter }                   from '@moon-maintainer/maintainer.router';
import { TemplateTableComponent }             from '@moon-maintainer/template/template-table/template-table.component';

@Component({
  selector: 'template-component',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css'],
  standalone: true,
  imports: [
    NgIf,
    MoonLoadingComponent,
    TemplateTableComponent,
  ],
  providers: [
    TemplateController,
    MaintainerRouter
  ]
})
export class TemplateComponent implements OnInit {

  public MSTemplateList: TemplateGet[];
  public MSLoading: boolean = true;
  public MSPageTitle: string = ConstantString.Template;
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _templateController: TemplateController,
    private _maintainerRouter: MaintainerRouter,
    private _confirmationService: ConfirmationService,
    private _moonMessageService: MoonMessageService,
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadTemplateList();
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }

  private async loadTemplateList() {
    try {
      this.MSLoading = true;
      const apiResult: ApiContentResult<TemplateGet[]> = await this._templateController.GetList();
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.onGetListSuccess(apiResult.content)
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private onGetListSuccess(documentCategoryList: TemplateGet[]) {
    if (documentCategoryList === null) {
      return;
    }

    this.MSTemplateList = documentCategoryList.map((template: TemplateGet) => {
      template.lastUpdatedAt = template.lastUpdatedAtUtc.toLocalDate();
      template.actions = this.getActions(template);
      return template;
    });
  }


  private getActions(template: TemplateGet) {
    return [
      {
        icon: PrimeIcons.FILE_WORD,
        label: 'Download',
        command: () => {
          this.downloadFile(template);
        }
      },
      {
        icon: PrimeIcons.PENCIL,
        label: 'Edit Item',
        command: () => {
          this._maintainerRouter.RouteToTemplateEdit(template.templateID);
        }
      },
      {
        icon: PrimeIcons.TRASH,
        label: 'Remove',
        command: () => {
          this._confirmationService.confirm({
            message: `Are you sure you want to remove ${template.templateName}?`,
            accept: () => {
              this.doRemove(template.templateID);
            },
          });
        }
      }
    ];
  }

  private async downloadFile(template: TemplateGet) {
    this._moonMessageService.toastInfo("Processing. Please wait.");
    const apiResult: ApiBlobResult = await this._templateController.GetBlobAsync(template.templateID, template.blobID ?? 0);
    if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download completed.");
    }
}

  private async doRemove(templateID: number) {
    const apiResult: ApiResult = await this._templateController.Delete(templateID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._maintainerRouter.ToTemplate();
      this.loadTemplateList();
    }
  }
  public MSRefreshPage() {
    this.loadTemplateList();
  }
}
