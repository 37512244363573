import { 
  AsyncPipe, DatePipe, DecimalPipe, KeyValuePipe, 
  NgClass, NgFor, NgIf, SlicePipe 
}                                                               from '@angular/common';
import { Component, OnInit }                                    from '@angular/core';
import { FormsModule }                                          from '@angular/forms';
import { ActivatedRoute }                                       from '@angular/router';
// Third party imports
import { ConfirmationService, MenuItem, SharedModule }          from 'primeng/api';
import { BadgeModule }                                          from 'primeng/badge';
import { ButtonModule }                                         from 'primeng/button';
import { ConfirmDialogModule }                                  from 'primeng/confirmdialog';
import { DialogModule }                                         from 'primeng/dialog';
import { DialogService, DynamicDialogConfig }                   from 'primeng/dynamicdialog';
import { InputNumberModule }                                    from 'primeng/inputnumber';
import { InputTextModule }                                      from 'primeng/inputtext';
import { ProgressSpinnerModule }                                from 'primeng/progressspinner';
import { RippleModule }                                         from 'primeng/ripple';
import { SplitButtonModule }                                    from 'primeng/splitbutton';
import { TabMenuModule }                                        from 'primeng/tabmenu';
import { TieredMenuModule }                                     from 'primeng/tieredmenu';
import { ToolbarModule }                                        from 'primeng/toolbar';
// MS Imports
import { AppBreadcrumbService }                                 from '@app-layout/services/app.breadcrumb.service';
import { ApiBlobResult, ApiContentResult, ApiResult }           from '@moon-core/models/api-result';
import { ComponentUtilityService }                              from '@moon-core/services/component-utility.service';
import { ChatLogComponent }                                     from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { TitleDocumentGet }                                     from '@moon-public/api/response/title-document.response';
import { TitleCommitmentFull }                                  from '@moon-public/api/response/title-commitment-full.response';
import { TitleCommitmentGet }                                   from '@moon-public/api/response/title-commitment-get.response';
import { TitleSummaryController }                               from '@moon-public/api/title-summary.controller';
import { PublicRouter }                                         from '@moon-public/public.router';
import { TitleReviewLogComponent }                              from '@moon-maintainer/title-commitment-advanced/modals/title-combined-log/title-combined-log.component';
import { TitleDocumentComponent }                               from '@moon-maintainer/title-commitment-advanced/title-admin-page/title-document/title-document.component';
import { TitleExceptionComponent }                              from '@moon-maintainer/title-commitment-advanced/title-admin-page/title-exception/title-exception.component';
import { MoonLoadingComponent }                                 from '@moon-shared/components/moon-loading/moon-loading.component';
import { DefaultValues }                                        from '@moon-shared/constants/default-values';
import { MessageSeverity }                                      from '@moon-shared/constants/message-severity';
import { RouteParameter }                                       from '@moon-shared/constants/route-parameter';
import { RoutePath }                                            from '@moon-shared/constants/route-path';
import { MoonMessageService }                                   from '@moon-shared/services/moon-message.service';
import { MaintainerRouter }                                     from '@moon-maintainer/maintainer.router';
import { ChatSetting }                                          from '@moon-maintainer/api/request/chat-setting.request';
import { SkillAdvancedEditModalComponent }                      from "@moon-maintainer/title-commitment-advanced/modals/skill-advanced-edit-modal/skill-advanced-edit-modal.component";
import { DropdownButtonComponent }                              from "@moon-shared/components/dropdown-button/dropdown-button.component";
import { TitleSummaryInternalNotes } from '@moon-maintainer/api/request/title-summary-internal-notes.request';
import { TitleSummaryInternalNotesModalComponent } from '@moon-maintainer/title-commitment-advanced/modals/title-summary-internal-notes-modal/title-summary-internal-notes-modal.component';
@Component({
  selector: 'moon-app-title-admin-page',
  templateUrl: './title-admin-page.component.html',
  styleUrls: ['./title-admin-page.component.scss'],
  standalone: true,
  imports: [
    NgClass, NgFor, NgIf, AsyncPipe, ButtonModule, DatePipe, DecimalPipe, FormsModule, KeyValuePipe, SlicePipe,
    BadgeModule, ConfirmDialogModule, DialogModule, InputNumberModule, InputTextModule, ProgressSpinnerModule,
    RippleModule, SharedModule, SplitButtonModule, TabMenuModule, TieredMenuModule, ToolbarModule,
    DropdownButtonComponent, ChatLogComponent, MoonLoadingComponent, TitleDocumentComponent, TitleExceptionComponent, TitleReviewLogComponent,
  ],
  providers: [TitleSummaryController, PublicRouter, MaintainerRouter, ConfirmationService, DialogService]
})
export class TitleAdminPageComponent implements OnInit {
  public MSLoading: boolean = true;
  public MSShowOcrConfirmation: boolean = false;
  public MSShowChatLog: boolean = false;
  public MSShowEndorsementChatLog: boolean = false;
  public MSShowTitleCombinedLog: boolean = false;
  public MSChatLogID: number | null;
  public MSEndorsementChatLogID: number | null;  
  public MSTitleSummaryID: number;
  public MSSelectedTitleDocument: TitleDocumentGet;
  public MSTitleCommitment: TitleCommitmentFull;
  public MSTestMode: boolean = false;
  public MSApplying: boolean = false;
  public MSDownloadButtons: MenuItem[] = [];
  public MSShowMarkAsComplete: boolean = false;
  public MSIsRerunInProgress: boolean = false;
  public MSBatchContinueMenuItems: MenuItem[] = [
    { label: 'Download Links', command: () => { this.doBatchDownloadLinks(); } },
    { label: 'Run Ocr', command: () => { this.doBatchRunOcr(); } }
  ]
  public MSBatchPromptMenuItems: MenuItem[] = [
    {
      label: 'Run all prompts',
      command: () => { this.doBatchRunAllPrompts(); }
    },
    { separator: true },
    {
      label: 'Run all document prompts',
      command: () => { this.doBatchRunAllDocumentPrompts(); }
    },
    {
      label: 'Run all document classify prompts',
      command: () => { this.doBatchRunAllDocumentClassifyPrompts(); }
    },
    {
      label: 'Run all document summarize prompts',
      command: () => { this.doBatchRunAllDocumentSummarizePrompts(); }
    },
    { separator: true },
    {
      label: 'Run all exception summary prompts',
      command: () => { this.doBatchRunAllExceptionPrompts(); }
    }
  ];

  public MSTitlePromptMenuItems: MenuItem[] = [
    {
      label: 'View Create Exceptions Log',
      command: () => { this.doShowChatLogForSummary(); }
    },
    {
      label: 'View Endorsement Log',
      command: () => { this.doShowEndorsementChatLogForSummary(); }
    },
    { separator: true },
    {
      label: 'Run Create Exceptions',
      command: () => { this.onClickPromptSummary(); }
    },
    { separator: true },
    {
      label: 'Run Create Exceptions with Override',
      command: () => { this.onClickPromptSummaryWithOverride(); }
    }
  ];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _publicRouter: PublicRouter,
    private _titleSummaryController: TitleSummaryController,
    private _componentUtilityService: ComponentUtilityService,
    private _moonMessageService: MoonMessageService,
    private _confirmationService: ConfirmationService,
    private _dialogService: DialogService,
    private _maintainerRouter: MaintainerRouter
  ) {
  }

  async ngOnInit() {
    this.MSTitleSummaryID = +(this._activatedRoute.snapshot.paramMap.get(RouteParameter.TitleSummaryID) ?? DefaultValues.NoID);
    this.setBreadcrumb();
    this.MSLoadTitleCommitment();
  }

  public MSLoadTitleCommitment() {
    this.loadTitleCommitmentByID(this.MSTitleSummaryID);
  }

  public MSOnClickTitleCommitmentLog() {
    this.MSShowTitleCombinedLog = true;
  }

  public MSOnTitleCombinedLogClosed() {
    this.MSShowTitleCombinedLog = false;
  }

  public MSClearLogMessage(): void {
    this._confirmationService.confirm({
      message: "This log message will be deleted. Are you sure you want to continue?",
      acceptLabel: "Yes",
      accept: () => this.doClearLogMessages(),
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
  }
  private async loadTitleCommitmentByID(titleSummaryID: number) {
    this.MSLoading = true;
    try {
      const apiResult: ApiContentResult<TitleCommitmentFull> = await this._titleSummaryController.GetFullByID(titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSTitleCommitment = apiResult.content;
        this.setHasErrorAndTryParseDocumentSummary();
        this.showHideDownloadButtons();
        this.MSShowMarkAsComplete = String.isTrueValue(this.MSTitleCommitment.allowMarkAsComplete);
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  private showHideDownloadButtons() {
    this.MSDownloadButtons = [{ label: 'Title Commitment', command: () => this.OnDownloadTitleCommitmentClick() }];

    if (this.MSTitleCommitment.allowDownloadWord) {
      this.MSDownloadButtons.push({ label: RoutePath.Exceptions, command: () => this.OnDownloadWordSummaryClick(RoutePath.Exceptions) });
      this.MSDownloadButtons.push({ label: RoutePath.Summaries, command: () => this.OnDownloadWordSummaryClick(RoutePath.Summaries) });
    }
    if (this.MSTitleCommitment.allowExportPdf) {
      this.MSDownloadButtons.push({ label: 'Exception PDFs', command: () => this.OnExportPdfClick() });
    }
    if (this.MSTitleCommitment.allowExportOcr) {
      this.MSDownloadButtons.push({ label: 'Exception OCR Text', command: () => this.OnExportOcrClick() });
    }
  }

  private setHasErrorAndTryParseDocumentSummary() {

    Object.safelyDefineProperty(this.MSTitleCommitment, "hasError", !String.isNullOrWhiteSpace(this.MSTitleCommitment.logMessage));

    this.MSTitleCommitment.titleExceptionGetList.forEach(exception => {

      Object.safelyDefineProperty(exception, "hasError", !String.isNullOrWhiteSpace(exception.logMessage));

      exception.titleDocumentGetList?.forEach(doc => {
        this.parseDocumentSummaryIfValid(doc);
        Object.safelyDefineProperty(doc, "hasError", !String.isNullOrWhiteSpace(doc.logMessage));
      });
    });
  }


  private parseDocumentSummaryIfValid(document: TitleDocumentGet) {

    const summaryText = document.summaryText;

    if (String.isNullOrWhiteSpace(summaryText))
      return;

    try {
      const parsedValue = JSON.parse(summaryText);
      Object.safelyDeleteProperty(parsedValue, "Type");
      Object.safelyDeleteProperty(parsedValue, "Action");
      Object.safelyDefineProperty(document, "summaryObject", parsedValue);
    }
    catch (_: unknown) { }
  }

  private setBreadcrumb(titleName?: string) {
    const pageBreadcrumb: MenuItem[] = [
      { label: RoutePath.TitleCommitments.getDisplayName(), routerLink: [`${this._publicRouter.TitleCommitmentsRouteLink()}`] },
      { label: titleName ?? RoutePath.Detail, routerLink: null },
    ];

    this._appBreadcrumbService.setItems(pageBreadcrumb);
  }
  public async MSOnRefreshClick() {
    await this.loadTitleCommitmentByID(this.MSTitleSummaryID);
  }

  public MSOnClickGoBack() {
    this._maintainerRouter.RouteToTitleCommitmentsAdvanced();
  }

  // Title Summary Start

  private async onClickPromptSummaryWithOverride() {

    this.MSApplying = true;
    const apiResult: ApiContentResult<ChatSetting> = await this._titleSummaryController.GetChatSetting(this.MSTitleCommitment.titleSummaryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {

      const dynamicDialogConfig: DynamicDialogConfig<ChatSetting> = {
        styleClass: 'w-50per moon-modal-wrapper',
        header: "Title Summary",
        data: apiResult.content
      };

      const dynamicDialogRef = this._dialogService.open(SkillAdvancedEditModalComponent, dynamicDialogConfig);
      dynamicDialogRef.onClose.subscribe(
        async (chatSetting: ChatSetting) => {
          if (chatSetting) {
            this.MSApplying = true;
            try {
              const apiResult: ApiResult = await this._titleSummaryController.PromptTitleSummary(this.MSTitleCommitment.titleSummaryID, chatSetting);
              if (this._componentUtilityService.WasSuccessful(apiResult)) {
                this._moonMessageService.toastSuccess("Prompt title summary successful.");
                this.MSLoadTitleCommitment();
              }
            }
            finally {
              this.MSApplying = false;
            }
          }
        });
    }
    this.MSApplying = false;
  }

  private onClickPromptSummary() {
    if (this.MSTitleCommitment.hasError) {
      this.doPromptSummary();
    }
    else {
      this._confirmationService.confirm({
        message: "Are you sure you want to perform this prompt?",
        acceptLabel: "Yes",
        accept: () => this.doPromptSummary(),
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }

  private async doPromptSummary() {
    this.MSApplying = true;
    const apiResult: ApiResult = await this._titleSummaryController.PromptTitleSummary(this.MSTitleCommitment.titleSummaryID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Prompt title summary successful.");
      this.MSLoadTitleCommitment();
    }
    this.MSApplying = false;
  }
  
  public async doShowChatLogForSummary() {
    this.MSChatLogID = this.MSTitleCommitment?.chatLogID;
    if (this.MSChatLogID !== null) {
      this.MSShowChatLog = true;
    } else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "No Chat Log found.");
    }
  }

  public async doShowEndorsementChatLogForSummary() {
    this.MSEndorsementChatLogID = this.MSTitleCommitment?.endorsementChatLogID;
    if (this.MSEndorsementChatLogID !== null) {
      this.MSShowEndorsementChatLog = true;
    } else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "No Endorsement Chat Log found.");
    }
  }
  
  public async MSOnClickPdfReviewForSummary(titleSummaryID: number, exceptionPagesCsv: string, titleSummaryChatLogID: number | null) {
    this._publicRouter.RouteToSummaryPdfReview(titleSummaryID, exceptionPagesCsv, titleSummaryChatLogID);
  }

  public async MSOnOverrideChatLogData()
    : Promise<void> {
    
    try {
      this.MSApplying = true;
      const apiResult: ApiResult = await this._titleSummaryController.ChatOverride(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSLoadTitleCommitment();
      }
    } finally {
      this.MSApplying = false;
    }
  }
  
  public async MSOnClickUpdateInternalNotes(): Promise<void> {
    const caption = `Title Summary: ${this.MSTitleCommitment.matterName}`;

    const dynamicDialogConfig: DynamicDialogConfig<TitleSummaryInternalNotes> = {
      styleClass: 'w-50per moon-modal-wrapper',
      header: caption,
      data: {
        titleSummaryID: this.MSTitleCommitment.titleSummaryID,
        internalNotes: this.MSTitleCommitment.internalNotes
      }
    };

    const dynamicDialogRef = this._dialogService.open(TitleSummaryInternalNotesModalComponent, dynamicDialogConfig);
    dynamicDialogRef.onClose.subscribe(
      async (internalNotes: TitleSummaryInternalNotes) => {
        this.doUpdateInternalNotes(internalNotes);
      });
  }

  private async doUpdateInternalNotes(internalNotes: TitleSummaryInternalNotes) {
    try {
      this.MSLoading = true;

      const apiResult: ApiResult = await this._titleSummaryController.UpdateInternalNotes(internalNotes);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess(`Internal Notes updated successful.`);
        await this.loadTitleCommitmentByID(this.MSTitleSummaryID);
      }
    }
    finally {
      this.MSLoading = false;
    }
  }
  // Title Summary End

  private doBatchDownloadLinks() {
    this._confirmationService.confirm({
      message: "Are you sure you want to download all links?",
      acceptLabel: "Yes",
      accept: async () => {
        await this.batchDownloadPdfs(this.MSTitleCommitment);
        this.loadTitleCommitmentByID(this.MSTitleSummaryID);
      },
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
    
  }

  private doBatchRunOcr() {
    this.MSShowOcrConfirmation = true;
  }

  public async MSOcrAll() {
    await this.doOcrPdfs(this.MSTitleCommitment);
    this.loadTitleCommitmentByID(this.MSTitleSummaryID);
  }

  public async MSOcrRemaining() {
    await this.doOcrRemainingPdfs(this.MSTitleCommitment);
    this.loadTitleCommitmentByID(this.MSTitleSummaryID);
  }

  public async MSOnMarkAsComplete() {
    this._confirmationService.confirm({
      message: "Are you sure you want to continue?",
      acceptLabel: "Yes",
      accept: () => this.doMarkAsComplete(),
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
  }

  private async doMarkAsComplete() {
    try {
      this.MSLoading = true;
      const promptAllResult: ApiResult = await this._titleSummaryController.Complete(this.MSTitleSummaryID);
      if (this._componentUtilityService.WasSuccessful(promptAllResult)) {
        this._moonMessageService.toastSuccess("Title review marked as complete.");
      }
    } finally {
      this.loadTitleCommitmentByID(this.MSTitleSummaryID);
    }
  }

  private doBatchRunAllPrompts() {
    this._confirmationService.confirm({
      message: "Are you sure you want to continue?",
      acceptLabel: "Yes",
      accept: () => this.doRerunAllPrompts(),
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
  }

  private async doRerunAllPrompts() {
    this.MSIsRerunInProgress = true;
    try {
      const promptAllResult: ApiResult = await this._titleSummaryController.BatchPromptAll(this.MSTitleSummaryID);
      if (!promptAllResult.success) {
        if (promptAllResult.apiError.status !== 0)
          this._componentUtilityService.WasSuccessful(promptAllResult);
      }
      else {
        this._moonMessageService.toastSuccess("Rerun prompt successful.");
      }

    } finally {
      this.loadTitleCommitmentByID(this.MSTitleSummaryID);
      this.MSIsRerunInProgress = false;
    }
  }

  private async doBatchRunAllDocumentPrompts() {
    this.MSIsRerunInProgress = true;
    try {
      await this.doBatchRunAllDocumentClassifyPrompts();
      await this.doBatchRunAllDocumentSummarizePrompts();

    } finally {
      this.loadTitleCommitmentByID(this.MSTitleSummaryID);
      this.MSIsRerunInProgress = false;
    }
  }

  private async doBatchRunAllDocumentClassifyPrompts(): Promise<void> {
    this.MSIsRerunInProgress = true;
    try {
      const promptResult: ApiResult = await this._titleSummaryController.BatchPromptDocumentClassify(this.MSTitleSummaryID);
      if (!promptResult.success) {
        if (promptResult.apiError.status !== 0)
          this._componentUtilityService.WasSuccessful(promptResult);
      }
      else {
        this._moonMessageService.toastSuccess("Rerun all document exception number prompt successful.");
      }

    } finally {
      this.loadTitleCommitmentByID(this.MSTitleSummaryID);
      this.MSIsRerunInProgress = false;
    }
  }

  private async doBatchRunAllDocumentSummarizePrompts(): Promise<void> {
    this.MSIsRerunInProgress = true;
    try {
      const promptResult: ApiResult = await this._titleSummaryController.BatchPromptDocumentSummarize(this.MSTitleSummaryID);
      if (!promptResult.success) {
        if (promptResult.apiError.status !== 0)
          this._componentUtilityService.WasSuccessful(promptResult);
      }
      else {
        this._moonMessageService.toastSuccess("Rerun all document summarize prompt successful.");
      }

    } finally {
      this.loadTitleCommitmentByID(this.MSTitleSummaryID);
      this.MSIsRerunInProgress = false;
    }
  }

  private async doBatchRunAllExceptionPrompts() {
    this.MSIsRerunInProgress = true;
    try {
      const promptResult: ApiResult = await this._titleSummaryController.BatchPromptTitleException(this.MSTitleSummaryID);
      if (!promptResult.success) {
        if (promptResult.apiError.status !== 0)
          this._componentUtilityService.WasSuccessful(promptResult);
      }
      else {
        this._moonMessageService.toastSuccess("Rerun all exception prompt successful.");
      }

    } finally {
      this.loadTitleCommitmentByID(this.MSTitleSummaryID);
      this.MSIsRerunInProgress = false;
    }
  }

  public async OnDownloadWordSummaryClick(blobType: string): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.GetDocxBlob(this.MSTitleCommitment.titleSummaryID, blobType);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download Word summary successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnDownloadTitleCommitmentClick() {
    try {
      this.MSLoading = true;

      const apiResult: ApiBlobResult = await this._titleSummaryController.GetPdfBlob(this.MSTitleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Title commitment downloaded successful.");
      }
    }
    finally {
      this.MSLoading = false;
    }
  }
  public async OnExportPdfClick(): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportPdf(this.MSTitleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export pdf successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  public async OnExportOcrClick(): Promise<void> {
    try {
      this.MSLoading = true;
      const apiResult: ApiBlobResult = await this._titleSummaryController.ExportOcr(this.MSTitleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Export ocr successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }
  private async doOcrPdfs(titleSummaryGet: TitleCommitmentGet) {
    try {
      this.MSShowOcrConfirmation = false;
      this.MSLoading = true;
      const apiResult: ApiResult = await this._titleSummaryController.OcrPdfs(titleSummaryGet.titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Ocr pdfs successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }
  private async doOcrRemainingPdfs(titleSummaryGet: TitleCommitmentGet) {
    try {
      this.MSLoading = true;
      this.MSShowOcrConfirmation = false;
      const apiResult: ApiResult = await this._titleSummaryController.OcrRemainingPdfs(titleSummaryGet.titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Ocr pdfs successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private async batchDownloadPdfs(titleSummaryGet: TitleCommitmentGet) {
    try {
      this.MSLoading = true;
      const apiResult: ApiResult = await this._titleSummaryController.BatchDownloadPdfs(titleSummaryGet.titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Download pdfs successful.");
      }
    } finally {
      this.MSLoading = false;
    }
  }

  private async doClearLogMessages(): Promise<void> {
    this.MSApplying = true;
    try {
      const apiResult: ApiResult = await this._titleSummaryController.ClearTitleSummaryLogMessage(this.MSTitleCommitment.titleSummaryID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Log message cleared successfully.");
        this.MSLoadTitleCommitment();
      }
    }
    finally {
      this.MSApplying = false;
    }
  }
  
  // END Header Menu
  
}
