import { Injectable }                           from "@angular/core";
// Moon Imports
import { IApiResult }                           from "@moon-core/models/api-result";
import { ComponentUtilityService }              from "@moon-core/services/component-utility.service";

import { MessageSeverity }                      from "@moon-shared/constants/message-severity";
import { MoonProcessBaseService }               from "@moon-shared/services/moon-base/common-process.service";
import { MoonDialogService }                    from "@moon-shared/services/moon-dialog.service";
import { MoonMessageService }                   from "@moon-shared/services/moon-message.service";

import { MezzLoanController }                   from "@moon-public/api/mezz-loan.controller";
import { MezzLoanChunkGet }                     from "@moon-public/api/response/mezz-loan-chunk-get.response";
import { PublicRouter }                         from "@moon-public/public.router";

@Injectable()
export class MezzLoanChunkBatchProcessService extends MoonProcessBaseService {
    constructor(
        public override _moonDialogService: MoonDialogService,
        private _mezzLoanController: MezzLoanController,
        private _moonMessageService: MoonMessageService,
        private _publicRouter: PublicRouter,
        private _componentUtilityService: ComponentUtilityService,
    ) {
        super(_moonDialogService);
    }
    
    public async PromptMezzLoanChunk(mezzLoanID: number, mezzLoanChunkList: MezzLoanChunkGet[]) {
        this.SetProcessName("Revising Chunk");
        this.MarkProcessInProgress(String.empty);
        await this.promptMezzLoanChunk(mezzLoanID, mezzLoanChunkList);
    }

    private async promptMezzLoanChunk(mezzLoanID: number, mezzLoanChunkList: MezzLoanChunkGet[]) {
        let index: number = 0;
        const apiResults: IApiResult[] = [];
        for (const mezzLoanChunk of mezzLoanChunkList) {
            let content: string;
            if (mezzLoanChunk.content.length > 200) {
                content = mezzLoanChunk.content.substring(0, 100) + '...';
            } else {
                content = mezzLoanChunk.content;
            }

            this.MarkProcessInProgress(`${++index} of ${mezzLoanChunkList.length} - Ordinal: ${mezzLoanChunk.ordinal}, Token Size: ${mezzLoanChunk.tokenSize} - ${content}`);
    
            const deleteResult =   await this._mezzLoanController.ReviseChunkAsync(mezzLoanChunk.mezzLoanChunkID);        
            apiResults.push(deleteResult);
        }
        if (this._componentUtilityService.WasBatchSuccessfulWithErrorDialog(apiResults)) {
            this.MarkProcessComplete();
            this._moonMessageService.showToastMessage(
                MessageSeverity.Success,
                'Mezz loan Chunk revised successfully.'
            );
            this._publicRouter.RouteToMezzLoanChunk(mezzLoanID);
        }
    }
}
