<!-- Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM -->
<moon-form-page [MSParentForm]="MSParentForm"
                [MSIsFormSaving]="MSIsFormSubmitting"
                (MSOnClickEventSubmit)="OnClickSubmit()"
                (MSOnClickEventCancel)="OnClickCancel()">
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'Whitelist Value'"
            [MSFormControl]="MSParentForm.controls.whitelistValue"
        >
            <input
                type="text"
                formControlName="whitelistValue"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Notes'"
            [MSFormControl]="MSParentForm.controls.notes"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="notes.value"
            >
                <textarea
                    #notes
                    id="notes"
                    pInputTextarea
                    [formControl]="MSParentForm.controls.notes"
                ></textarea>
            </div>
                
        </moon-form-control>

    </div>
</moon-form-page>
