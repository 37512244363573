// Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM
import { NgIf }                         from '@angular/common';
import { Component, ElementRef,
         OnInit, ViewChild }            from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
// Moon Imports
import { AppBreadcrumbService }         from '@app/app-layout/services/app.breadcrumb.service';
import { ApiContentResult, ApiResult }  from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';
import { EmailWhitelistGet }                      from '@moon-maintainer/api/response/email-whitelist-get.response';
import { EmailWhitelistController }               from '@moon-maintainer/api/email-whitelist.controller';
import { MaintainerRouter }             from "@moon-maintainer/maintainer.router";
import { EmailWhitelistFormComponent }            from '@moon-maintainer/email-whitelist/email-whitelist-form/email-whitelist-form.component';
import { EmailWhitelistTableComponent }           from '@moon-maintainer/email-whitelist/email-whitelist-table/email-whitelist-table.component';
import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonEditPageComponent }        from '@moon-shared/components/moon-maintenance-page/moon-edit-page/moon-edit-page.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';
import { FieldNames }                   from '@moon-shared/constants/field-names';

@Component({
    selector: 'moon-email-whitelist-add-page',
    templateUrl: './email-whitelist-edit-page.component.html',
    styleUrls: ['./email-whitelist-edit-page.component.css'],
    standalone: true,
    imports: [NgIf,
              MoonLoadingComponent, MoonEditPageComponent, EmailWhitelistTableComponent, EmailWhitelistFormComponent],
    providers: [EmailWhitelistController, MaintainerRouter]
})
export class EmailWhitelistEditPageComponent implements OnInit {

  public MSEmailWhitelistID: number | null;
  public MSEmailWhitelistGet: EmailWhitelistGet;
  public MSLoading = true;
  public MSPageTitle = ConstantString.EmailWhitelist.getDisplayName();
  public MSEnvironmentName = String.empty;

  @ViewChild("MSForm") MSForm: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _emailWhitelistController: EmailWhitelistController,
    private _maintainerRouter: MaintainerRouter
  ) { }

  async ngOnInit() {
    this.readRouteParameters();
    this.setBreadcrumb();
    if (this.MSEmailWhitelistID){
      await this.getEmailWhitelistByID(this.MSEmailWhitelistID);
    }
    this.MSLoading = false;
  }

  // Start: Button Click Event Handlers

  public OnClickRemove(): void {
    if (this.MSEmailWhitelistID){
      this.doRemove(this.MSEmailWhitelistID);
    }
  }

  public get MSDisplayRemoveButton(): boolean {
    return this.MSEmailWhitelistID !== null;
  }

  // End: Button Click Event Handlers

  private setBreadcrumb() {
    this._appBreadcrumbService.SetEditPageBreadcrumb(this.MSPageTitle, "", this.MSEmailWhitelistID);
  }

  private readRouteParameters() {
    const emailWhitelistID = this._activatedRoute.snapshot.paramMap.get(FieldNames.EmailWhitelistID);
    this.MSEmailWhitelistID = emailWhitelistID ? +emailWhitelistID : null;
  }

  private async getEmailWhitelistByID(emailWhitelistID: number) {
    if (emailWhitelistID) {
      const apiResult: ApiContentResult<EmailWhitelistGet> = await this._emailWhitelistController.GetByID(emailWhitelistID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSEmailWhitelistGet = apiResult.content;
      }
    }
  }

  private async doRemove(emailWhitelistID: number) {
    const apiResult: ApiResult = await this._emailWhitelistController.Delete(emailWhitelistID);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._maintainerRouter.ToEmailWhitelist();
    }
  }
}
