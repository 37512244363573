import { DecimalPipe, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
// Third Party imports
import { BadgeModule }              from 'primeng/badge';
import { ButtonModule }             from 'primeng/button';
import { InputTextareaModule }      from 'primeng/inputtextarea';
import { RippleModule }             from 'primeng/ripple';
import { InputTextModule }          from 'primeng/inputtext';
// Moon imports
import { ComponentUtilityService }  from '@moon-core/services/component-utility.service';
import { SupportMessage }           from '@moon-public/api/request/support-message.request';
import { SupportController }        from '@moon-public/api/support.controller';
import { MoonFormControlComponent } from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonLoadingComponent }     from '@moon-shared/components/moon-loading/moon-loading.component';
import { MoonFormPageComponent }    from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { MoonMessageService }       from '@moon-shared/services/moon-message.service';
import { FormGroupOf }              from '@moon-shared/types/form-group-of.type';

@Component({
  selector: 'moon-contact-support',
  standalone: true,
  imports: [
    NgIf, NgFor, NgClass, DecimalPipe, SlicePipe,
    BadgeModule, ButtonModule, RippleModule, ReactiveFormsModule,
    InputTextareaModule,
    InputTextModule,
    MoonLoadingComponent, MoonFormControlComponent, MoonFormPageComponent
  ],
  templateUrl: './contact-support.component.html',
  styleUrl: './contact-support.component.scss',
  providers: [SupportController]
})
export class ContactSupportComponent {
  @Input() public MSVisible: boolean = false;
  @Output() public MSCloseDialog: EventEmitter<boolean> = new EventEmitter();
  public MSLoading: boolean = false;
  public MSIsFormSubmitting: boolean = false;

  public MSContactSupportForm: FormGroup<FormGroupOf<SupportMessage>> = new FormGroup<FormGroupOf<SupportMessage>>({
    // FormBuilder PlaceHolder - DO NOT REMOVE
    message: new FormControl(String.empty, { nonNullable: true, validators: Validators.required  }),
    contactInfo: new FormControl(String.empty, { nonNullable: true }),
    contactTime: new FormControl(String.empty),
  });

  constructor(
    private _supportController: SupportController,
    private _moonMessageService: MoonMessageService,
    private _componentUtilityService: ComponentUtilityService,
  ) { }

  public async MSOnClickSubmit() {
    this.MSContactSupportForm.controls.message.setValue(this.MSContactSupportForm.controls.message.value.trimStart().trimEnd());
    this.MSContactSupportForm.controls.message.updateValueAndValidity();

    try {
      this.MSLoading = true;
      if (this._componentUtilityService.IsFormValid(this.MSContactSupportForm)) {
        const apiResult = await this._supportController.Contact(this.MSContactSupportForm.getRawValue());
        if (this._componentUtilityService.WasSuccessful(apiResult)) {
          this._moonMessageService.toastSuccess('Message submitted to Moonstone support.');
          this.MSCloseDialog.emit(true);
        }
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  public MSOnClickCancel() {
    this.MSCloseDialog.emit(true);
  }
}
