import { NgIf, SlicePipe }            from '@angular/common';
import { Component, OnInit }          from '@angular/core';
import { Title }                      from '@angular/platform-browser';
// Third party imports
import { ButtonModule }               from 'primeng/button';
import { ProgressBarModule }          from 'primeng/progressbar';
import { RippleModule }               from 'primeng/ripple';
import { filter }                     from 'rxjs';
import { MoonstoneApp }               from 'src/moon-config/moonstone-app';
// MS Imports
import { AppRouter }                  from '@app/app.router';
import { DatabaseDto }                from '@moon-core/api/response/database.response';
import { ApiContentResult }           from '@moon-core/models/api-result';
import { Diagnostic }                 from '@moon-core/models/diagnostic.model';
import { ComponentUtilityService }    from '@moon-core/services/component-utility.service';
import { MsalWrapperService }         from '@moon-core/services/msal-wrapper-service';
import { DefaultValues }              from '@moon-shared/constants/default-values';
import { MsalBroadcastService }       from '@azure/msal-angular';
import { EventMessage, EventType }    from '@azure/msal-browser';
import { DiagnosticApiService }       from '@moon-core/api/diagnostic-api.service';

@Component({
  selector: 'moon-azure-login',
  standalone: true,
  imports: [
    NgIf, SlicePipe,
    ProgressBarModule, ButtonModule, RippleModule
  ],
  providers: [DiagnosticApiService],
  templateUrl: './azure-login.component.html',
  styleUrl: './azure-login.component.scss'
})
export class AzureLoginComponent implements OnInit {
  public MSDiagnostic: DatabaseDto = new Diagnostic();
  public MSDisplayMessage: string = String.empty;
  public MSLoading: boolean = false;
  public MSWebApplicationVersion: string = MoonstoneApp.ApplicationVersion;
  public MSShowReloadDiagnosticButton: boolean = false;
  constructor(
    private _titleService: Title,
    private _componentUtilityService: ComponentUtilityService,
    private _diagnosticApiService: DiagnosticApiService,
    private _msalWrapperService: MsalWrapperService,
    private _msalBroadcastService: MsalBroadcastService,
    private _appRouter: AppRouter
  ) {

  }

  async ngOnInit(): Promise<void> {
    this._titleService.setTitle(MoonstoneApp.ApplicationName);
    this.checkAndIntialiseAzureConnection();
  }

  public MSOnClickReloadDiagnosticButton() {
    this.loadApiDiagnosticAndConnectToAzure();
  }
  private checkAndIntialiseAzureConnection() {
    if (this._msalWrapperService.IsConnectedToAzure()) {
      this._appRouter.RouteToConnectDatabase();
    }
    else {
      this.loadApiDiagnosticAndConnectToAzure();
    }
  }

  private async loadApiDiagnosticAndConnectToAzure() {
    this.MSLoading = true;
    this.MSDisplayMessage = "Loading, Please Wait.";
    try {
      const apiResult: ApiContentResult<DatabaseDto> = await this._diagnosticApiService.GetDatabaseDto(MoonstoneApp.ApplicationName);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this.MSDiagnostic = apiResult.content;
        this._msalWrapperService.MSDiagnostic = apiResult.content;
        await this.doConnectToAzure();
      }
      else {
        this.MSDisplayMessage = "Loading failed.";
      }
    }
    finally {
      this.MSLoading = false;
    }
  }

  private async doConnectToAzure() {
    this.MSDisplayMessage = 'Connecting to Azure!';
    try {
      this._msalWrapperService.ConnectToAzure();

      this._msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe(_ => {
          this._appRouter.RouteToConnectDatabase();
        });
    }
    catch (ex: unknown) {
      this.MSDisplayMessage = this.getErrorMessage(ex);
    }
  }

  private getErrorMessage(ex: unknown) {
    const separator = DefaultValues.Separator;
    let errorMessage = "Azure connection failed!";

    if (typeof ex === "string") {
      errorMessage += separator + ex;
    }
    else if (typeof ex === "object" && ex != null && 'message' in ex) {
      errorMessage += separator + ex.message;
    }
    else {
      console.error(ex);
      errorMessage += separator + "Unknown reason.";
    }
    return errorMessage;
  }
}
