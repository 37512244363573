<!-- Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSAirplaneList"
    [MSDisplayCreateButton]="MSDisplayCreate"
    [MSDisplaySecondaryButton]="true"
    [MSSecondaryButtonIcon]="'pi pi-refresh'"
    (MSEventOnClickCreate)="MSOnClickCreate()"
    (MSEventOnClickSecondary)="MSOnClickRefresh()"
>
    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [rowHover]="true"
        [value]="MSAirplaneList"
        selectionMode="single"
        [loading]="MSLoading"
        styleClass="p-datatable-gridlines p-datatable-striped"
    >
        <ng-template pTemplate="caption">
            <moon-table-header
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="MSDisplayCreate"
                [MSDisplaySecondaryButton]="true"
                [MSSecondaryButtonIcon]="'pi pi-refresh'"
                (MSEventOnClickCreate)="MSOnClickCreate()"
                (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                (MSEventOnClickSecondary)="MSOnClickRefresh()"
            >
            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [ngClass]="{ 'actions-column': col.field === 'actions' }"
                >
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <td
                    *ngFor="let col of columns"
                    class="cursor-auto"
                    [class]="col.class"
                >
                    <div [ngSwitch]="col.field">
                        <div
                            *ngSwitchCase="'actions'"
                            class="text-right"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                (click)="MSOnDownloadWordSummaryClick(rowData)"
                                icon="pi pi-upload"
                                tooltipPosition="left"
                                [disabled]="MSLoading"
                                icon="pi pi-download"
                                label="Summary"
                            > </button>
                        </div>
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>