// Moon Auto Generated (1.0.0) at 11/26/2023 12:54:44 PM
import { NgIf }                         from '@angular/common';
import { Component, OnDestroy, OnInit }            from '@angular/core';
// Moon Imports
import { ApiContentResult }             from '@moon-core/models/api-result';
import { ComponentUtilityService }      from '@moon-core/services/component-utility.service';

import { MoonLoadingComponent }         from '@moon-shared/components/moon-loading/moon-loading.component';
import { ConstantString }               from '@moon-shared/constants/constant-string';

import { SkillController }              from '@moon-maintainer/api/skill.controller';
import { SkillGet }                     from "@moon-maintainer/api/response/skill-get.response";
import { SkillTableComponent }          from '@moon-maintainer/skill/skill-table/skill-table.component';
import { AppBreadcrumbService }         from '@app/app-layout/services/app.breadcrumb.service';
import { HistoryService }               from '@moon-shared/services/history.service';

@Component({
  selector: 'moon-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.css'],
  standalone: true,
  imports: [NgIf,
            MoonLoadingComponent, SkillTableComponent],
  providers: [
    SkillController,
  ]
})
export class SkillComponent implements OnInit, OnDestroy {

  public MSSkillList: SkillGet[];
  public MSLoading: boolean = true;

  public MSPageTitle: string = ConstantString.Skills;
  public MSEnvironmentName = String.empty;

  constructor(
    private _appBreadcrumbService: AppBreadcrumbService,
    private _componentUtilityService: ComponentUtilityService,
    private _skillController: SkillController,
    private _historyService: HistoryService
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.loadSkillList();
    this.setHistoryEntityValues(true, ConstantString.Skill, null);
  }
  
  public MSRefreshPage() {
    this.loadSkillList();
  }

  private setBreadcrumb() {
    this._appBreadcrumbService.SetLandingPageBreadcrumb(this.MSPageTitle);
  }
  
  private async loadSkillList() {
    this.MSLoading = true;
    
    const apiResult: ApiContentResult<SkillGet[]> = await this._skillController.GetList();
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this.MSSkillList = apiResult.content;
      this.MSSkillList.map(skill => {
        skill.lastUpdatedAt = skill.lastUpdatedAtUtc.toLocalDate();
      })
    }
    this.MSLoading = false;  
  }

  private setHistoryEntityValues(showButton: boolean, entityType: string | null, entityID: number | null): void {
    this._historyService.setHistoryEntityValues(showButton, entityType, entityID, null);
  }

  ngOnDestroy(): void {
    this.setHistoryEntityValues(false, null, null);
  }
}
