interface Number {
    getReadableSizeFromBytes(): string;
}

Number.prototype.getReadableSizeFromBytes = function (): string {
    if (this == null)
        return '0 byte';

    const sizeInBytes = this as number;
    if (sizeInBytes > 1073741824) {
        return Math.round(sizeInBytes / (1024 * 1024 * 1024)).toString() + ' Gb';
    }
    if (sizeInBytes > 1048576) {
        return Math.round(sizeInBytes / (1024 * 1024)).toString() + ' Mb';
    }
    if (sizeInBytes > 1024) {
        return Math.round(sizeInBytes / (1024)).toString() + ' Kb';
    }

    return sizeInBytes.toString() + ' byte';
};