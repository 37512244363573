import { Injectable }                           from "@angular/core";
// Moon Imports
import { DocumentController }                   from "@moon-collaborator/api/document.controller";
import { DocumentFind }                         from "@moon-collaborator/api/response/document-find.response";
import { CollaboratorRouter }                   from "@moon-collaborator/collaborator.router";
import { ApiBlobResult, IApiResult }            from "@moon-core/models/api-result";
import { ComponentUtilityService }              from "@moon-core/services/component-utility.service";
import { MessageSeverity }                      from "@moon-shared/constants/message-severity";
import { MoonProcessBaseService }               from "@moon-shared/services/moon-base/common-process.service";
import { MoonDialogService }                    from "@moon-shared/services/moon-dialog.service";
import { MoonMessageService }                   from "@moon-shared/services/moon-message.service";
@Injectable()
export class DocumentBatchProcessService extends MoonProcessBaseService {
    constructor(
        public override _moonDialogService: MoonDialogService,
        private _documentController: DocumentController,
        private _moonMessageService: MoonMessageService,
        private _collaboratorRouter: CollaboratorRouter,
        private _componentUtilityService: ComponentUtilityService,

    ) {
        super(_moonDialogService);
    }

    public async DownloadWord(environmentName: string, documentIDList: Array<number>) {
        this.SetProcessName("Downloading Document");
        this.MarkProcessInProgress(String.empty);

        const apiResult: ApiBlobResult = await this._documentController.DownloadWordFilesZip(environmentName, documentIDList)
        if (this._componentUtilityService.WasDownloadSuccessful(apiResult)) {
            this.MarkProcessComplete();
            this._moonMessageService.toastSuccess("Download completed.");
        }

    }

    // Remove Document
    public Remove(environmentName: string, documentList: DocumentFind[]) {
        this.SetProcessName("Removing Document");
        this.MarkProcessInProgress(String.empty);
        this.removeDocuments(environmentName, documentList);
    }

    private async removeDocuments(environmentName: string, documentList: DocumentFind[]) {
        let index: number = 0;
        const apiResults: IApiResult[] = [];
        for (const document of documentList) {
            this.MarkProcessInProgress(`${++index} of ${documentList.length} - ${document.documentName}`);
            const deleteResult = await this._documentController.Delete(environmentName, document.documentID);
            apiResults.push(deleteResult);

        }
        if (this._componentUtilityService.WasBatchSuccessfulWithErrorDialog(apiResults)) {
            this.MarkProcessComplete();
            this._moonMessageService.showToastMessage(
                MessageSeverity.Success,
                'Document Removed.'
            );
            this._collaboratorRouter.ToDocument(environmentName);
        }

    }
}
