import { inject } from '@angular/core';
// MS Imports
import { AppRouter } from "../../app.router";
import { UserSessionService } from '@moon-core/services/user-session.service';

export const PublicRouteGuard = (): boolean => {
    
    const _appRouter = inject(AppRouter);
    const service = inject(UserSessionService);
    // Only b2c users are not allowed in public route
    if (!service.IsConnectedToDatabase() || service.IsB2CUser())
    {
        _appRouter.RouteToDefault();
        return false;
    }
    return true;
}
