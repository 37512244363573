<!-- eslint-disable @angular-eslint/template/no-autofocus -->
<form
    [formGroup]="MSTitleCommitmentForm"
    class="px-2 md:px-8 moon-title-commitment-wizard-form"
>
    <moon-form-control
        class="p-fluid p-5 fadeinright animation-duration-100 "
        [MSFormControl]="MSTitleCommitmentForm.controls.propertyState"
        [MSLabel]="'What is the State of the Property?'"
        [MSErrorLabel]="'Property State'"
        [MSLabelSize]="12"
        [MSInputSize]="12"
        [MSErrorMessageSize]="12"
    >
        <div class="flex gap-2 justify-content-center">
            <p-autoComplete
                formControlName="propertyState"
                [showClear]="true"
                [suggestions]="MSFilteredSelectedState"
                [dropdown]="true"
                [forceSelection]="true"
                (onSelect)="MSOnSelectState($event.value)"
                (completeMethod)="NSFilterState($event)"
                optionLabel="label"
                optionValue="value"
                placeholder="Enter Property State"
                appendTo="body"
            />
        </div>
    </moon-form-control>
</form>