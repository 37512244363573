<!-- Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM -->
<moon-form-page [MSParentForm]="MSParentForm"
                [MSIsFormSaving]="MSIsFormSubmitting"
                (MSOnClickEventSubmit)="OnClickSubmit()"
                (MSOnClickEventCancel)="OnClickCancel()">
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'Deployment Name'"
            [MSFormControl]="MSParentForm.controls.deploymentName"
        >
            <input
                type="text"
                formControlName="deploymentName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Deployment Status'"
            [MSFormControl]="MSParentForm.controls.deploymentStatus"
        >
            <input
                type="text"
                formControlName="deploymentStatus"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Deployment Type'"
            [MSFormControl]="MSParentForm.controls.deploymentType"
        >
            <input
                type="text"
                formControlName="deploymentType"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Service Name'"
            [MSFormControl]="MSParentForm.controls.serviceName"
        >
            <input
                type="text"
                formControlName="serviceName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Region'"
            [MSFormControl]="MSParentForm.controls.region"
        >
            <input
                type="text"
                formControlName="region"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Model Name'"
            [MSFormControl]="MSParentForm.controls.modelName"
        >
            <input
                type="text"
                formControlName="modelName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Model Version'"
            [MSFormControl]="MSParentForm.controls.modelVersion"
        >
            <input
                type="text"
                formControlName="modelVersion"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Notes'"
            [MSFormControl]="MSParentForm.controls.notes"
        >
            <div
                class="grow-wrap"
                [attr.textarea-value]="notes.value"
            >
                <textarea
                    #notes
                    id="notes"
                    rows="6"
                    pInputTextarea
                    [formControl]="MSParentForm.controls.notes"
                ></textarea>
            </div>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Capacity K_TPM'"
            [MSFormControl]="MSParentForm.controls.capacityK_TPM">
            <input
                type="number"
                formControlName="capacityK_TPM"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Deployment Version'"
            [MSFormControl]="MSParentForm.controls.deploymentVersion">
            <input
                type="number"
                formControlName="deploymentVersion"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Model Input Tokens'"
            [MSFormControl]="MSParentForm.controls.modelInputTokens">
            <input
                type="number"
                formControlName="modelInputTokens"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Model Output Tokens'"
            [MSFormControl]="MSParentForm.controls.modelOutputTokens">
            <input
                type="number"
                formControlName="modelOutputTokens"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Model Total Tokens'"
            [MSFormControl]="MSParentForm.controls.modelTotalTokens">
            <input
                type="number"
                formControlName="modelTotalTokens"
                pInputText
            />
        </moon-form-control>
    </div>
</moon-form-page>
