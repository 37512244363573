<form
    [formGroup]="MSAirplaneForm"
    class="px-2 md:px-8 moon-title-commitment-wizard-form"
>
    <!-- File Input -->
    <moon-form-control
        class="col-12 moon-title-commitment-file-input"
        [MSFormControl]="MSAirplaneForm.controls.inputFile"
        [MSErrorLabel]="'File'"
        [MSLabelSize]="12"
        [MSInputSize]="12"
        [MSErrorMessageSize]="12"
        [MSDisplayLabel]="false"
    >

        <p-fileUpload
            #fileUploader
            class="w-full"
            [accept]="'application/pdf'"
            [auto]="true"
            [customUpload]="true"
            [mode]="'advanced'"
            [showUploadButton]="false"
            [showCancelButton]="false"
            (onUpload)="MSHandleFileInput($event.files)"
            (onSelect)="MSHandleFileInput($event.files)"
            (onRemove)="MSClearSelectedFile()"
        >
            <ng-template pTemplate="content">
                <div
                    *ngIf="fileUploader.files.length > 0"
                    moonEnterKeyup
                    (MSOnEnterKeyUp)="MSOnClickNext()"
                >
                </div>
                <div
                    class="w-full md:h-20rem xl:h-25rem py-3 moon-cursor-copy"
                    onkeypress=""
                    (click)="fileUploader.advancedFileInput.nativeElement.click()"
                >
                    <div class="h-full flex flex-column justify-content-center align-items-center">
                        <i class="pi pi-upload text-900 text-2xl mb-3"></i>
                        <span class="text-xl mb-3">Upload or Drag File Here</span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="file">
                <p-table [value]="[MSAirplaneForm.value.inputFile]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th
                                pSortableColumn="name"
                                class="white-space-nowrap"
                            >
                                Name <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th
                                pSortableColumn="size"
                                class="white-space-nowrap"
                            >
                                File Size <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="body"
                        let-file
                    >
                        <tr>
                            <td>
                                <div class="flex align-items-center">
                                    <i [class]="'text-xl text-primary mr-2 ' + file.icon"></i>
                                    <span>{{file.name}}</span>
                                </div>
                            </td>
                            <td>
                                <span>{{file.size/1024 | number:'1.0-2'}} kb</span>
                            </td>
                            <td class="text-center">
                                <button
                                    pButton
                                    pRipple
                                    class="p-button-danger p-button-text p-button-rounded mr-2"
                                    icon="pi pi-times"
                                    (click)="MSClearSelectedFile()"
                                > </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
        </p-fileUpload>
    </moon-form-control>
</form>
<div class="moon-wizard-buttons">
    <button
        class="moon-go-back-button"
        pButton
        pRipple
        (click)="MSOnClickGoBack()"
        tabindex="-1"
    >Go Back</button>
    <button
        class="moon-next-button"
        pButton
        pRipple
        (click)="MSOnClickNext()"
        tabindex="1"
    >Next</button>
</div>