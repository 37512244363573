<!-- Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>
<div *ngIf="!MSLoading">
    <moon-table-page
        [MSPageTitle]="MSPageTitle"
        [MSItemList]="MSDeliveryList"
        [MSDisplayCreateButton]="MSDisplayCreate"
        [MSDisplayFindButton]="MSDisplayFind"
        [MSNumberOfFilteredTableRows]="MSNumberOfFilteredValues"
    >
        <p-table
            #MSTable
            [value]="MSDeliveryList"
            [columns]="MSTableColumns"
            (onFilter)="MSOnFilter($event.filteredValue)"
            [tableStyle]="{ 'min-width': '50rem' }"
            [globalFilterFields]="['firstName', 'lastName', 'preferredUserName']"
        >
            <ng-template pTemplate="caption">
                <moon-table-header
                    [MSPageTitle]="MSPageTitle"
                    [MSEnableSearch]="MSEnableSearch"
                    [MSDisplayCreateButton]="MSDisplayCreate"
                    [MSDisplayFindButton]="MSDisplayFind"
                    (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                >
                </moon-table-header>
            </ng-template>
            <ng-template
                pTemplate="header"
                let-columns
            >
                <tr>
                    <th
                        *ngFor="let col of columns"
                        [class]="col.class"
                    >
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>

            <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
            >
            <tr>
                <td
                    *ngFor="let col of columns"
                    [class]="col.class"
                >
                    <div [ngSwitch]="col.field">
                        <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                    </div>
                </td>
            </tr>
            </ng-template>
        </p-table>
    </moon-table-page>
</div>