import { CommonModule }                                 from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild }     from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule }  from '@angular/forms';
// Third party imports
import { FieldsetModule }                         from 'primeng/fieldset';
import { SliderModule }                           from 'primeng/slider';
import { InputNumberModule }                      from 'primeng/inputnumber';
import { DynamicDialogConfig, DynamicDialogRef }  from 'primeng/dynamicdialog';
import { ProgressBarModule }                      from 'primeng/progressbar';
import { ButtonModule }                           from 'primeng/button';
import { RippleModule }                           from 'primeng/ripple';
import { InputTextModule }                        from 'primeng/inputtext';
import { DropdownModule }                         from 'primeng/dropdown';
import { SelectItem }                             from 'primeng/api';
import { InputTextareaModule }                    from 'primeng/inputtextarea';
import { TooltipModule }                          from 'primeng/tooltip';
import { AutoFocusModule }                        from 'primeng/autofocus';
// Moon imports
import { ComponentUtilityService }                from '@moon-core/services/component-utility.service';

import { MoonFormPageComponent }                  from '@moon-shared/components/moon-maintenance-page/moon-form-page/moon-form-page.component';
import { FormGroupOf }                            from '@moon-shared/types/form-group-of.type';
import { MoonFormControlComponent }               from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { MoonMessageService }                     from '@moon-shared/services/moon-message.service';
import { MessageSeverity }                        from '@moon-shared/constants/message-severity';

import { DeploymentController }                   from '@moon-maintainer/api/deployment.controller';
import { SkillController }                        from '@moon-maintainer/api/skill.controller';

import { TitleDocumentController }                from '@moon-public/api/title-document.controller';
import { TitleSummaryInternalNotes }              from '@moon-maintainer/api/request/title-summary-internal-notes.request';

@Component({
  selector: 'moon-skill-advanced-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    ProgressBarModule,
    ButtonModule,
    RippleModule,
    MoonFormControlComponent,
    MoonFormPageComponent,
    FieldsetModule,
    SliderModule,
    InputNumberModule,
    InputTextareaModule,
    TooltipModule,
    AutoFocusModule,
  ],
  templateUrl: './title-summary-internal-notes-modal.component.html',
  styleUrls: ['./title-summary-internal-notes-modal.component.scss'],
  providers: [SkillController, DeploymentController, TitleDocumentController],
})
export class TitleSummaryInternalNotesModalComponent implements OnInit {
  public MSLoading: boolean = true;
  public MSDeploymentSelectItems: SelectItem[] = [
    { label: 'Select Deployment', value: null },
  ];
  public MSTitleSummaryInternalNotes: TitleSummaryInternalNotes;
  public MSParentForm: FormGroup<FormGroupOf<TitleSummaryInternalNotes>> =
    new FormGroup<FormGroupOf<TitleSummaryInternalNotes>>({
      titleSummaryID: new FormControl(),
      internalNotes: new FormControl(null, { nonNullable: true }),
      // FormBuilder PlaceHolder - DO NOT REMOVE
    });

  public MSIsFormSubmitting: boolean = false;
  public MSEditNotes: boolean = false;
  @ViewChild('internalNotes', { static: false }) internalNotes: ElementRef<HTMLTextAreaElement>;
  constructor(
    private _dynamicDialogConfig: DynamicDialogConfig<TitleSummaryInternalNotes>,
    private _dynamicDialogRef: DynamicDialogRef,
    private _moonMessageService: MoonMessageService,
    private _componentUtilityService: ComponentUtilityService
  ) {}

  async ngOnInit() {
    try {
      this.MSTitleSummaryInternalNotes =
        this._dynamicDialogConfig.data ?? new TitleSummaryInternalNotes();
      if (this.MSTitleSummaryInternalNotes == null) {
        this._moonMessageService.showToastMessage(
          MessageSeverity.Info,
          'No Item found.'
        );
        this._dynamicDialogRef.close();
        return;
      }

      if (
        this.MSTitleSummaryInternalNotes.internalNotes == '' ||
        this.MSTitleSummaryInternalNotes.internalNotes == null
      )
        this.MSEditNotes = true;

      this.MSParentForm.patchValue(this.MSTitleSummaryInternalNotes);
    } finally {
      this.MSLoading = false;
    }
  }

  public async MSOnClickEditNotes() {
    this.MSEditNotes = true;

    setTimeout(() => {
      this.internalNotes.nativeElement.focus();
    }, 0);
  }

  public async MSOnClickSubmit() {
    if (this._componentUtilityService.IsFormValid(this.MSParentForm)) {
      this._dynamicDialogRef.close(this.MSParentForm.value);
    }
  }

  public MSOnClickCancel() {
    this._dynamicDialogRef.close();
  }
}
