import { Component, Input } from '@angular/core';
// Third party imports
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TieredMenuModule } from 'primeng/tieredmenu';

@Component({
  selector: 'moon-dropdown-button',
  standalone: true,
  imports: [ButtonModule, TieredMenuModule],
  templateUrl: './dropdown-button.component.html',
  styleUrl: './dropdown-button.component.scss'
})
export class DropdownButtonComponent {
  @Input() public MSDisabled: boolean = false;
  @Input() public MSLabel: string = String.empty;
  @Input() public MSButtons!: MenuItem[];
}
