import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
//Third party imports
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
//Moon Imports
import { TitleCommitmentWizardService }   from '@moon-consumer/title-commitment/add-title-commitment-wizard/service/title-commitment-wizard.service';
import { TitleDocumentsUploadComponent }  from '@moon-consumer/title-commitment/title-documents-upload/title-documents-upload.component';

@Component({
  selector: 'moon-title-document-manual-upload',
  standalone: true,
  imports: [NgIf, AsyncPipe, ButtonModule, RippleModule, TitleDocumentsUploadComponent],
  templateUrl: './title-document-manual-upload.component.html',
  styleUrl: './title-document-manual-upload.component.scss',
})
export class TitleDocumentManualUploadComponent implements OnInit {
  public MSTitleSummaryID!: number;
  public MSManualUploadMessage: string = String.empty;
  constructor(
    private _titleCommitmentewizardService: TitleCommitmentWizardService
  ) { }

  ngOnInit() {
    this.MSTitleSummaryID = this._titleCommitmentewizardService.MSTitleSummaryID;
    this.MSManualUploadMessage = this._titleCommitmentewizardService.MSManualUploadMessage;
  }
}
