import { Injectable }                       from "@angular/core";
// MS Imports
import { ConnectUserDto }                   from "@moon-core/models/connect-user-dto.model";
import { DbConnectApiService }         from "@moon-core/api/db-connect-api.service";
import { MoonApplicationInsight }           from "@moon-core/services/moon-application-insight.service";
import { MoonMessageService }               from "@moon-shared/services/moon-message.service";
import { MoonstoneApp }                     from "src/moon-config/moonstone-app";
import { ApiContentResult } from "../models/api-result";
import { UserSession } from "../api/response/user-session.response";
import { MoonFetchAPIService } from "./moon-fetch-api.service";
import { MessageSeverity } from "@moon-shared/constants/message-severity";
import { DefaultValues } from "@app/moon-shared/constants/default-values";
@Injectable()
export class UserSessionService {
    private _isConnectedToDatabase: boolean = false;
    private _moonstoneUserSessionHeader: string;
    private _currentUserSession: UserSession;

    constructor(
        private _dbConnectApiService: DbConnectApiService,
        private _moonMessageService: MoonMessageService,
        private _moonApplicationInsights: MoonApplicationInsight,
        private _moonFetchAPIService: MoonFetchAPIService,
    ) { }

    public GetMoonUserSessionHeader(): string {
        return this._moonstoneUserSessionHeader;
    }

    public IsConnectedToDatabase(): boolean {
        return this._isConnectedToDatabase;
    }

    public get GetCurrentUserSession(): UserSession {
        return this._currentUserSession;
    }
    public IsMaintainer = () => DefaultValues.Y.equalsIgnoreCase(this._currentUserSession?.showMaintainer);
    public IsCollaborator = () => DefaultValues.Y.equalsIgnoreCase(this._currentUserSession?.showCollaborator);
    public IsPreviewAvailable = () => DefaultValues.Y.equalsIgnoreCase(this._currentUserSession?.showPreview);
    public IsB2CUser = () => DefaultValues.B2C.equalsIgnoreCase(this._currentUserSession?.userType);

    public OnDisclaimerReject() {
        this._isConnectedToDatabase = false;
        this._moonMessageService.showToastMessage(MessageSeverity.Warn, 'Disclaimer Rejected.');
    }

    public async ConnectUser(forceDisconnect: string): Promise<ApiContentResult<UserSession>> {

        const userConnectionDto = this.getUserConnectionDto(forceDisconnect);
        this._moonApplicationInsights.logEvent(`Moonstone Session: INITIATED`);
        
        const connectResult = await this._dbConnectApiService.ConnectUserAsync(userConnectionDto);
        if (connectResult.success){
            
            const userSession: UserSession = connectResult.content;

            this._moonApplicationInsights.MSSetMoonUserDetail(userSession.preferredUserName, userSession.userSessionID);
            this.logEvent(`Moonstone Session: START`);

            this._currentUserSession = userSession;
            this._moonstoneUserSessionHeader = btoa(JSON.stringify(userSession));
            localStorage.setItem(MoonstoneApp.ApplicationName, this._currentUserSession.userSessionID?.toString());
            this._isConnectedToDatabase = true;
        }
        else {
            this.logEvent(`Moonstone Session: FAILED`);
            this._isConnectedToDatabase = false;
        }
        return connectResult;
    }
    
    public async DisconnectUserAsync(): Promise<void> {
        this._moonApplicationInsights.logEvent(`Moonstone Session (Terminate): App Close`);

        const disconnectUserDto = { reason: "UserClosing" };

        const apiResult = await this._dbConnectApiService.DisconnectUserAsync(disconnectUserDto);
        if (apiResult.success){
            this.onCloseMoonSession();
        }
        else {
            this.logEvent(`Moonstone Session (Terminate): FAILED`);
            throw new Error(apiResult.message);
        }
    }

    private getUserConnectionDto(forceDisconnect: string): ConnectUserDto {
        const connectUserDto = new ConnectUserDto();
        connectUserDto.applicationName = MoonstoneApp.ApplicationName;
        connectUserDto.applicationVersion = MoonstoneApp.ApplicationVersion;
        connectUserDto.forceDisconnect = forceDisconnect;

        return connectUserDto;
    }
    
    public getDisplayString(): string {
        const currentDateTime = new Date().toUTCString();
        if (this._currentUserSession == null) {
            return `User: Unidentified user on ${currentDateTime}`
        }
        const userConnectionID = this._currentUserSession.userSessionID;
        const userName = this._currentUserSession.preferredUserName;
        return `User: ${userName} at $(${userConnectionID}) on ${currentDateTime}`;
    }
    
    private onCloseMoonSession() {
        this.logEvent(`Moonstone Session: END`);
        this._isConnectedToDatabase = false;
        localStorage.removeItem(MoonstoneApp.ApplicationName);
    }

    private logEvent(event: string) {
        this._moonApplicationInsights.logEvent(event);
    }

    
    public HandleWindowBeforeUnload(): void {
        if (this._isConnectedToDatabase) {

            const body = { DisconnectReason: "OnWindowBeforeUnload" };
            this._moonFetchAPIService.doFetchPOST('DatabaseConnect/disconnectuser', this._moonstoneUserSessionHeader, body);            
        }
    }
}
