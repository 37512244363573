<!-- Moon Auto Generated (1.2.0) at 1/1/2024 7:49:19 AM -->
<moon-form-page
    [MSParentForm]="MSParentForm"
    [MSIsFormSaving]="MSIsFormSubmitting"
    (MSOnClickEventSubmit)="OnClickSubmit()"
    (MSOnClickEventCancel)="OnClickCancel()"
>
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [MSLabel]="'First Name'"
            [MSFormControl]="MSParentForm.controls.firstName"
        >
            <input
                type="text"
                formControlName="firstName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Last Name'"
            [MSFormControl]="MSParentForm.controls.lastName"
        >
            <input
                type="text"
                formControlName="lastName"
                pInputText
            />
        </moon-form-control>
        <moon-form-control
            [MSLabel]="'Object Identifier'"
            [MSFormControl]="MSParentForm.controls.objectIdentifier"
        >
            <input
                type="text"
                formControlName="objectIdentifier"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Preferred User Name'"
            [MSFormControl]="MSParentForm.controls.preferredUserName"
        >
            <input
                type="text"
                formControlName="preferredUserName"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Email Address'"
            [MSFormControl]="MSParentForm.controls.emailAddress"
        >
            <input
                type="email"
                formControlName="emailAddress"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Role Code'"
            [MSFormControl]="MSParentForm.controls.roleCode"
        >
            <p-dropdown
                formControlName="roleCodeSelectItem"
                [options]="MSRoleCodeSelectItem"
                optionLabel="label"
                (onChange)="OnChangeUserRole($event.value)"
            ></p-dropdown>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'User Type'"
            [MSFormControl]="MSParentForm.controls.userType"
        >
            <p-dropdown
                formControlName="userTypeSelectItem"
                [options]="MSUserTypeSelectItem"
                optionLabel="label"
                (onChange)="OnChangeUserType($event.value)"
            ></p-dropdown>
        </moon-form-control>
        <moon-form-control
            [MSLabel]="'User Status'"
            [MSFormControl]="MSParentForm.controls.userStatusSelectItem"
        >
            <p-dropdown
                formControlName="userStatusSelectItem"
                [options]="MSUserStatusSelectItem"
                optionLabel="label"
                (onChange)="OnChangeUserStatus($event.value)"
            ></p-dropdown>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Allow Favorite Search Result'"
            [MSFormControl]="MSParentForm.controls.allowFavoriteSearchResult"
        >
            <p-dropdown
                formControlName="allowFavoriteSearchResult"
                [options]="['Y', 'N']"
            ></p-dropdown>
        </moon-form-control>
    </div>

    <user-option-map-form
        [(MSUserOptionMapFind)]="MSUserOptionMapFind"
        [MSUserInfoID]="MSUserInfoID"
        (MSUserOptionMapFormDirtyEvent)="MSUserOptionMapFormChanged()"
    ></user-option-map-form>
</moon-form-page>