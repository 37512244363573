import { CommonModule, KeyValue, NgIf }                             from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output }           from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators }  from '@angular/forms';
// Third party imports
import { ConfirmationService, MenuItem, PrimeIcons }                from 'primeng/api';
import { BadgeModule }                                              from 'primeng/badge';
import { ButtonModule }                                             from 'primeng/button';
import { ConfirmDialogModule }                                      from 'primeng/confirmdialog';
import { DialogModule }                                             from 'primeng/dialog';
import { DialogService, DynamicDialogConfig, DynamicDialogRef }     from 'primeng/dynamicdialog';
import { InputTextModule }                                          from 'primeng/inputtext';
import { InputTextareaModule }                                      from 'primeng/inputtextarea';
import { RippleModule }                                             from 'primeng/ripple';
// MS Imports
import { ApiContentResult, ApiResult }                              from '@moon-core/models/api-result';
import { ComponentUtilityService }                                  from '@moon-core/services/component-utility.service';
import { ChatSetting }                                              from '@moon-maintainer/api/request/chat-setting.request';
import { TitleDocumentGet }                                         from '@moon-public/api/response/title-document.response';
import { TitleDocumentController }                                  from '@moon-public/api/title-document.controller';
import { PublicRouter }                                             from '@moon-public/public.router';
import { ChatLogComponent }                                         from '@moon-maintainer/title-commitment-advanced/chat-log/chat-log.component';
import { SkillAdvancedEditModalComponent }                          from "@moon-maintainer/title-commitment-advanced/modals/skill-advanced-edit-modal/skill-advanced-edit-modal.component";
import { DropdownButtonComponent }                                  from '@moon-shared/components/dropdown-button/dropdown-button.component';
import { MoonFormControlComponent }                                 from '@moon-shared/components/moon-form-control/moon-form-control.component';
import { ConstantString }                                           from '@moon-shared/constants/constant-string';
import { MessageSeverity }                                          from '@moon-shared/constants/message-severity';
import { MoonMessageService }                                       from '@moon-shared/services/moon-message.service';

@Component({
  selector: 'moon-title-document',
  standalone: true,
  imports: [
    CommonModule, NgIf, ReactiveFormsModule,
    BadgeModule, ButtonModule, RippleModule, DialogModule, InputTextModule, InputTextareaModule, ConfirmDialogModule,
    DropdownButtonComponent, ChatLogComponent, MoonFormControlComponent
  ],
  templateUrl: './title-document.component.html',
  styleUrls: ['./title-document.component.scss'],
  providers: [
    TitleDocumentController,
    DialogService
  ]
})
export class TitleDocumentComponent implements OnInit {
  public MSIsDocumentManualUpload: boolean = false
  public MSSaving: boolean = false;
  @Input() public MSApplying: boolean = false;
  @Input() public MSDocumentIndexNumber: number;
  @Input() public MSTitleDocument: TitleDocumentGet;
  @Input() public MSTitleCommitmentType: string | null;
  @Output() public MSOnTitleDocumentChange = new EventEmitter();
  public MSShowChatLog: boolean = false;
  public MSChatLogID: number;

  public MSPromptMenuItems: MenuItem[] = [
    {
        label: PromptButton.DocumentClassifyLog,
        command: () => this.onClickExceptionNumberLog()
    },
    {
        label: PromptButton.DocumentSummarizeLog,
        command: () => this.onClickSummaryLog()
    },
    { separator: true },
    {
        label: PromptButton.DocumentClassify,
        command: () => this.onClickPromptExceptionNumber()
    },
    {
        label: PromptButton.DocumentSummarize,
        command: () => this.onClickPromptSummary()
    },
    { separator: true },
    {
        label: PromptButton.DocumentClassifyAdvanced,
        command: () => this.onClickAdvanced(PromptButton.DocumentClassifyAdvanced)
    },
    {
        label: PromptButton.DocumentSummarizeAdvanced,
        command: () => this.onClickAdvanced(PromptButton.DocumentSummarizeAdvanced)
    }
  ];

  public MSEditAction: boolean = false;
  public MSEditType: boolean = false;
  public MSEditSummary: boolean = false;
  public MSActionFormGroup: FormGroup<{ recommendedAction: FormControl<string> }>;
  public MSTypeFormGroup: FormGroup<{ exceptionType: FormControl<string> }>;
  public MSSummaryTextFormGroup: FormGroup<{ summaryText: FormControl<string> }>;
  public MSIconAngleDown: string = PrimeIcons.ANGLE_DOWN;
  public MSDynamicDialogRef?: DynamicDialogRef;
  constructor(
    private _dialogService: DialogService,
    private _titleDocumentController: TitleDocumentController,
    private _componentUtilityService: ComponentUtilityService,
    private _moonMessageService: MoonMessageService,
    private _publicRouter: PublicRouter,
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.checkForManualUpload();
  }

  private checkForManualUpload(): void {
    this.MSIsDocumentManualUpload = ConstantString.ManualUpload.equalsIgnoreCase(this.MSTitleDocument.referenceType);
  }

  public MSClearLogMessage() {
    this._confirmationService.confirm({
      message: "This log message will be deleted. Are you sure you want to continue?",
      acceptLabel: "Yes",
      accept: () => this.doClearLogMessages(),
      rejectLabel: "No",
      reject: () => { this._confirmationService.close(); }
    });
  }

  public MSToggleActionEdit() {
    this.MSEditAction = !this.MSEditAction;
    if (this.MSActionFormGroup == null) {
      this.initializeActionFormGroup();
    }
  }

  public MSToggleTypeEdit() {
    this.MSEditType = !this.MSEditType;
    if (this.MSTypeFormGroup == null) {
      this.initializeTypeFormGroup();
    }
  }

  public MSToggleSummaryEdit() {
    this.MSEditSummary = !this.MSEditSummary;
    if (this.MSSummaryTextFormGroup == null) {
      this.initializeSummaryTextFormGroup();
    }
  }

  public MSShowPdfButton(document: TitleDocumentGet): boolean {
    return document.hasBlob;
  }

  public async MSOnClickPdfReviewForDocument(titleDocumentID: number) {
    this._publicRouter.RouteToDocumentPdfReview(this.MSTitleDocument.titleSummaryID, titleDocumentID);
  }


  // START: Prompt
  
  public onClickExceptionNumberLog() {
    if (this.MSTitleDocument.exceptionChatLogID !== null) {
      this.MSChatLogID = this.MSTitleDocument.exceptionChatLogID;
      this.MSShowChatLog = true;
    }
    else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Exception Chat Log Not Found.")
    }
  }

  public onClickSummaryLog() {
    if (this.MSTitleDocument.summaryChatLogID !== null) {
      this.MSChatLogID = this.MSTitleDocument.summaryChatLogID;
      this.MSShowChatLog = true;
    }
    else {
      this._moonMessageService.showToastMessage(MessageSeverity.Info, "Summary Chat Log Not Found.")
    }
  }
  
  private async onClickAdvanced(promptType: string): Promise<void>  {

    const caption = PromptButton.DocumentClassifyAdvanced.equalsIgnoreCase(promptType) ? `Skill: ${SkillName.ExceptionNumberForDocument}` : `Skill: ${SkillName.SummarizeTitleDocument} | Sub-Skill Type: ${this.MSTitleCommitmentType}`;

    const apiResult: ApiContentResult<ChatSetting> = await this.doRequestChatSetting(this.MSTitleDocument?.titleSummaryID, promptType);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {

        const dynamicDialogConfig: DynamicDialogConfig<ChatSetting> = {
          styleClass: 'w-50per moon-modal-wrapper',
          header: caption,
          data: apiResult.content
        };

        this.MSDynamicDialogRef = this._dialogService.open(SkillAdvancedEditModalComponent, dynamicDialogConfig);
        this.MSDynamicDialogRef.onClose.subscribe(
          (chatSetting: ChatSetting) => {
            if (chatSetting) {
              if (promptType.equalsIgnoreCase(PromptButton.DocumentSummarizeAdvanced))
                this.doPromptSummarize(chatSetting);
              else
                this.doPromptDocumentClassify(chatSetting);
            }
          });
    }
  }

  private async onClickPromptSummary(): Promise<void>  {
    if (this.MSTitleDocument.hasError) {
      this.doPromptSummarize(null);
    }
    else {
      const confirmMessage: string = "This prompt is already completed. Are you sure you want override?";

      this._confirmationService.confirm({

        message: confirmMessage,
        acceptLabel: "Yes",
        accept: () => {this.doPromptSummarize(null); },
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }

  private async doPromptSummarize(chatSetting: ChatSetting | null): Promise<void>  {
    this.MSApplying = true;

    const apiResult: ApiResult = await this._titleDocumentController.PromptSummary(this.MSTitleDocument.titleSummaryID, this.MSTitleDocument.titleDocumentID, chatSetting);
    if (this._componentUtilityService.WasSuccessful(apiResult)) {
      this._moonMessageService.toastSuccess("Prompt summarize successful.");
      this.MSOnTitleDocumentChange.emit();
    }
    this.MSApplying = false;
  }

  private async onClickPromptExceptionNumber(): Promise<void>  {
    if (this.MSTitleDocument.hasError) {
      this.doPromptDocumentClassify(null);
    }
    else {
      const confirmMessage: string = "This prompt is already completed. Are you sure you want to continue?";

      this._confirmationService.confirm({
        message: confirmMessage,
        acceptLabel: "Yes",
        accept: () => { this.doPromptDocumentClassify(null); },
        rejectLabel: "No",
        reject: () => { this._confirmationService.close(); }
      });
    }
  }

  private async doPromptDocumentClassify(chatSetting: ChatSetting | null): Promise<void>  {
    this.MSApplying = true;
    try {
      
      const apiResult: ApiResult = await this._titleDocumentController.PromptExceptionNumber(this.MSTitleDocument.titleSummaryID, this.MSTitleDocument.titleDocumentID, chatSetting);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Prompt exception number successful.");
        this.MSOnTitleDocumentChange.emit();
      }
    } finally {
      this.MSApplying = false;
    }
  }

  // END: Prompt

  public async MSOnOverrideChatLogData(chatLogID: number)
    : Promise<void> {
    try {
      this.MSApplying = true;
      const apiResult: ApiResult = await this._titleDocumentController.ChatLogDataOverride(this.MSTitleDocument.titleSummaryID, this.MSTitleDocument.titleDocumentID, chatLogID);
      this._componentUtilityService.WasSuccessful(apiResult);
    } finally {
      this.MSApplying = false;
    }
  }

  public MSSaveRecommendedAction() {
    this.MSActionFormGroup.markAllAsTouched();
    this.MSActionFormGroup.updateValueAndValidity();
    if (this.MSActionFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid recommended action.")
      return;
    }
    this.doOverrideRecommendedAction();
  }

  public async MSSaveExceptionType(): Promise<void>  {
    this.MSTypeFormGroup.markAllAsTouched();
    this.MSTypeFormGroup.updateValueAndValidity();

    if (this.MSTypeFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid exception type.")
      return;
    }
    this.doOverrideExceptionType();
  }

  public async MSSaveSummaryText(): Promise<void> {
    this.MSSummaryTextFormGroup.markAllAsTouched();
    this.MSSummaryTextFormGroup.updateValueAndValidity();

    if (this.MSSummaryTextFormGroup.invalid) {
      this._moonMessageService.showToastMessage(MessageSeverity.Warn, "Invalid summary.")
      return;
    }
    this.doOverrideSummaryText();
  }

  private async doClearLogMessages(): Promise<void>  {
    this.MSApplying = true;
    try {
      const apiResult: ApiResult = await this._titleDocumentController.ClearLogMessage(this.MSTitleDocument.titleSummaryID, this.MSTitleDocument.titleDocumentID);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.toastSuccess("Log message cleared successfully.");
        this.MSOnTitleDocumentChange.emit();
      }
    }
    finally {
      this.MSApplying = false;
    }
  }

  private initializeActionFormGroup(): void {
    this.MSActionFormGroup = new FormGroup({
      recommendedAction: new FormControl<string>(this.MSTitleDocument.recommendedAction,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(500)]
        })
    });
  }

  private initializeTypeFormGroup(): void {
    this.MSTypeFormGroup = new FormGroup({
      exceptionType: new FormControl<string>(this.MSTitleDocument.exceptionType,
        {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(100)]
        })
    });
  }

  private initializeSummaryTextFormGroup(): void {
    this.MSSummaryTextFormGroup = new FormGroup({
      summaryText: new FormControl<string>(this.MSTitleDocument.summaryText,
        {
          nonNullable: true,
          validators: [Validators.required]
        })
    });
  }

  private async doOverrideExceptionType(): Promise<void>  {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.OverrideExceptionType(this.MSTitleDocument.titleSummaryID, this.MSTitleDocument.titleDocumentID, this.MSTypeFormGroup.getRawValue().exceptionType);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Document type updated successfully.");
        this.MSOnTitleDocumentChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }
  
  private async doRequestChatSetting(titleSummaryID: number, promptType: string) : Promise<ApiContentResult<ChatSetting>> {

    if (PromptButton.DocumentClassifyAdvanced.equalsIgnoreCase(promptType))
    {
      return this._titleDocumentController.GetChatSettingExceptionNumber(titleSummaryID);
    }
    else if (PromptButton.DocumentSummarizeAdvanced.equalsIgnoreCase(promptType))
    {
      return this._titleDocumentController.GetChatSettingSummary(titleSummaryID);
    }
    else
    {
      // TODO: skipping proper error handling due to time constraints.
      throw new Error("Unknown prompt type");
    }
  }
  
  private async doOverrideRecommendedAction() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.OverrideRecommendedAction(this.MSTitleDocument.titleSummaryID, this.MSTitleDocument.titleDocumentID, this.MSActionFormGroup.getRawValue().recommendedAction);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Document action updated successfully.");
        this.MSOnTitleDocumentChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }
  private async doOverrideSummaryText() {
    this.MSSaving = true;
    try {
      const apiResult = await this._titleDocumentController.OverrideSummaryText(this.MSTitleDocument.titleSummaryID, this.MSTitleDocument.titleDocumentID, this.MSSummaryTextFormGroup.getRawValue().summaryText);
      if (this._componentUtilityService.WasSuccessful(apiResult)) {
        this._moonMessageService.showToastMessage(MessageSeverity.Success, "Document summary updated successfully.");
        this.MSOnTitleDocumentChange.emit();
      }
    } finally {
      this.MSSaving = false;
    }
  }

  public MSPreserveOriginalOrderInKeyValuePipe = (_a: KeyValue<number, string>, _b: KeyValue<number, string>): number => {
    return 0;
  }

}

export class PromptButton {
  public static readonly DocumentClassifyLog = 'View Document Classify Log' as const;
  public static readonly DocumentSummarizeLog = 'View Document Summarize Log' as const;
  public static readonly DocumentClassify = 'Run Document Classify' as const;
  public static readonly DocumentSummarize = 'Run Document Summarize' as const;
  public static readonly DocumentClassifyAdvanced = 'Run Document Classify Override' as const;
  public static readonly DocumentSummarizeAdvanced = 'Run Document Summarize Override' as const;
}

export class SkillName
{
  public static readonly ExceptionNumberForDocument = "Extract - Exception Number for Document" as const;
  public static readonly SummarizeTitleDocument = "Summarize - Title Document (Each Summary Item Step by Step / Update Summary)" as const;
}
