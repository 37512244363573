import { Injectable }               from '@angular/core';
import { AbstractControl, FormControl,
        FormGroup, Validators }     from '@angular/forms';

// MS Imports
import { ConsumerRouter }           from '@moon-consumer/consumer.router';
import { TitleBackendOption }       from '@moon-public/api/request/title-backend-option.request';
import { TitleCommitmentUpload }    from '@moon-public/api/request/title-commitment.upload';
import { TitleIntakeController }    from '@moon-public/api/title-intake.controller';
import { TitleCommitmentPdf }       from '@moon-maintainer/title-commitment-advanced/title-commitment-pdf';
import { FieldValues }              from '@moon-shared/constants/field-values';
import { MessageSeverity }          from '@moon-shared/constants/message-severity';
import { RoutePath }                from '@moon-shared/constants/route-path';
import { MoonMessageService }       from '@moon-shared/services/moon-message.service';
import { FormGroupOf }              from '@moon-shared/types/form-group-of.type';
import { CallResult, CallContentResult }               from '@moon-core/models/call-result';
import { ApiResult }                from '@moon-core/models/api-result';
import { FieldNames }               from '@moon-shared/constants/field-names';

@Injectable()
export class TitleCommitmentWizardService {
    public MSCurrentStep: string = RoutePath.CommitmentType;
    public MSLoading: boolean = false;
    public MSLoadingMessage: string = "Loading...";
    public MSDisabledSubmitOnEnterKey: boolean = false;
    public MSTitleCommitmentPdfBlob: TitleCommitmentPdf = new TitleCommitmentPdf();
    public MSTitleSummaryID: number;
    public MSManualUploadMessage: string;
    private moonJobID!: number;
    public MSTitleCommitmentForm: FormGroup<FormGroupOf<TitleCommitmentUpload>> = new FormGroup<FormGroupOf<TitleCommitmentUpload>>({
        summaryType: new FormControl(String.empty, { nonNullable: true, validators: Validators.required }),
        matterName: new FormControl(String.empty, { nonNullable: true, validators: [Validators.required] }),
        propertyState: new FormControl(String.empty, { nonNullable: true, validators: [Validators.required] }),
        inputFile: new FormControl(null, Validators.required),
        exceptionPagesCsv: new FormControl(String.empty, { nonNullable: true, validators: Validators.required }),
    }, this.fileValidator);

    constructor(
        private _titleIntakeController: TitleIntakeController,
        private _consumerRouter: ConsumerRouter,
        private _moonMessageService: MoonMessageService
    ) { }

    public IsCurrentStep(stepName: string) {
        return this.MSCurrentStep.equalsIgnoreCase(stepName);
    }

    public async GoToNextStep(): Promise<CallResult> {
        
        const result: CallResult = new CallResult();
        
        switch (this.MSCurrentStep) {
            case RoutePath.CommitmentType: {
                const validateResult = this.validateFormControl("summaryType");
                if (result.parseApiResult(validateResult)) {
                    this.routeToStep(RoutePath.MatterName);
                }                
                break;
            }
            case RoutePath.MatterName: {
                const validateResult = this.validateFormControl("matterName");
                if (result.parseApiResult(validateResult)) {
                    this.routeToStep(RoutePath.UploadFile);
                }
                break;
            }
            case RoutePath.UploadFile: {
                const validateResult = this.validateFormControl("inputFile");
                if (result.parseApiResult(validateResult)) {
                    this.routeToStep(RoutePath.Endorsement);
                }
                break;
            }
            case RoutePath.Endorsement: {
                const validateResult = this.validateFormControl("propertyState");
                if (result.parseApiResult(validateResult)) {
                    this.routeToStep(RoutePath.MarkScheduleOfExceptions);
                }                
                break;
            }
            case RoutePath.MarkScheduleOfExceptions: {
                this.setExceptionPagesFromMarkedPages();
                const validateResult = this.validateFormControl("exceptionPagesCsv");
                if (result.parseApiResult(validateResult)) {
                    
                    if (this.MSTitleCommitmentForm.valid) {
                        const stepResult: CallContentResult<string> = await this.RunStepStartJob();
                        if (result.parseApiResult(stepResult)) {
                            this.routeToStep(stepResult.content);
                        }
                    }
                    else {
                        result.setFailure(`Tile Summary fields are required.`);
                    }
                }
                break;
            }
            case RoutePath.UploadTitleDocument: {
                
                const validateResult = await this._titleIntakeController.ValidateTitleSummary(this.moonJobID, this.MSCurrentStep);
                if (result.parseApiResult(validateResult)) {
                    const queueResult = await this.QueueJob(true);
                    if (result.parseApiResult(queueResult) ){
                        this.routeToStep(RoutePath.Final);
                    }
                }
                break;
            }
            default: {
                result.setFailure(`Unknown step: ${this.MSCurrentStep}`);
                break;
            }
        }
        return result;
    }

    public async validateStep(stepName: string): Promise<CallResult> {
        
        const result: CallResult = new CallResult();        
        switch (stepName) {
            case RoutePath.UploadTitleDocument: {
                const validateResult = await this._titleIntakeController.ValidateTitleSummary(this.moonJobID, stepName);
                result.parseApiResult(validateResult);
                break;
            }
            default: {
                result.setSuccess();
                break;
            }
        }
        return result;
    }

    private validateFormControl(formControl: keyof TitleCommitmentUpload): CallResult {

        this.MSTitleCommitmentForm.controls[formControl]?.markAsTouched();
        this.MSTitleCommitmentForm.updateValueAndValidity();

        const result = new CallResult();
        if (this.MSTitleCommitmentForm.controls[formControl]?.invalid) {
            const message = formControl.equalsIgnoreCase(FieldNames.ExceptionPagesCsv)
                            ? `Please mark at least one exception page.`
                            : `${formControl.getDisplayName()} is required.`;

            this._moonMessageService.showToastMessage(MessageSeverity.Warn, message);
            result.setFailure(message);
        }
        else {
            result.setSuccess();
        }
        return result;
    }

    private routeToStep(nextStepRoute: string): void {
        this.MSCurrentStep = nextStepRoute;
        this._consumerRouter.RouteToTitleCommitmentsAdd(nextStepRoute);
    }

    private setExceptionPagesFromMarkedPages() {
        const exceptionPages: number[] = this.MSTitleCommitmentPdfBlob.GetExceptionPageArray();
        if (exceptionPages) {
            this.MSTitleCommitmentForm.controls.exceptionPagesCsv.setValue(exceptionPages.join(','));
        }
    }

    /* public GoToLastStep(): void {
        switch (this.MSCurrentStep) {
            case RoutePath.Process: {
                this.goToPreviousRoute(RoutePath.MarkScheduleOfExceptions);
                break;
            }
            case RoutePath.MarkScheduleOfExceptions: {
                this.goToPreviousRoute(RoutePath.UploadFile);
                break;
            }
            case RoutePath.UploadFile: {
                this.goToPreviousRoute(RoutePath.AddMatterName);
                break;
            }
            case RoutePath.AddMatterName: {
                this.goToPreviousRoute(RoutePath.SelectCommitmentType);
                break;
            }
            case RoutePath.SelectCommitmentType: {
                this._consumerRouter.RouteToTitleCommitmentsSimple();
                break;
            }
        }
    }

    private goToPreviousRoute(lastStepRoute: string) {
        this.MSCurrentStep = lastStepRoute;
        this._consumerRouter.RouteToTitleCommitmentsAdd(lastStepRoute);
    }
 */

    public async RunStepStartJob(): Promise<CallContentResult<string>> {

        const stepResult = new CallContentResult<string>();
        try {
            this.MSLoading = true;
            this.MSLoadingMessage = "Starting...";

            const description = `Starting Job for Matter name: ${this.MSTitleCommitmentForm.value.matterName}`
            const startResult = await this._titleIntakeController.Start(description);
            if (!stepResult.parseApiResult(startResult)) {
                return stepResult;
            }
    
            this.moonJobID = startResult.content;
            this.MSLoadingMessage = "Uploading document...";
            const createTitleCommitment = await this._titleIntakeController.CreateTitleSummary(this.moonJobID, this.MSTitleCommitmentForm.getRawValue());
            if (!stepResult.parseApiResult(createTitleCommitment)) {
                return stepResult;
            }

            this.MSTitleSummaryID = createTitleCommitment.content;            
            this.MSLoadingMessage = "Analyzing document...";
            const createTitleDocuments = await this._titleIntakeController.CreateTitleDocuments(this.moonJobID)
            if (!stepResult.parseApiResult(createTitleDocuments)) {
                return stepResult;
            }
            
            const titleDocumentIDs: number[] = createTitleDocuments.content;
            if (titleDocumentIDs?.length > 0) {

                this.MSLoadingMessage = "Downloading hyperlinked documents...";
                const downloadFirstDocument = await this._titleIntakeController.DownloadFirstDocument(this.moonJobID)
                if (downloadFirstDocument.success) {
                    this.QueueJob(false);
                    stepResult.content = RoutePath.Final;
                }
                else {
                    this.MSLoadingMessage = "Download failed...";
                    this.MSManualUploadMessage = `We could not download the documents with the links provided. Please upload manually and finish.`;
                    stepResult.content = RoutePath.UploadTitleDocument;
                }
            } 
            else {

                this.MSLoadingMessage = "Processing exceptions...";
                const createExceptions = await this._titleIntakeController.CreateExceptions(this.moonJobID)
                if (!stepResult.parseApiResult(createExceptions)) {
                    return stepResult;
                }
                else {
                    stepResult.content = RoutePath.UploadTitleDocument;
                }
        
                this.MSManualUploadMessage =
                    `Below is a list of the title exceptions listed in Schedule B-II. Please upload PDFs of any documents that you would like summarized that are not uploaded.
When you are finished hit the "Submit for Processing" button.`;
            }
        }
        finally {
            this.MSLoading = false;
        }
        return stepResult;
    }

    public async QueueJob(isManualUpload: boolean): Promise<ApiResult> {
        
        const option = new TitleBackendOption();
        option.isManualUpload = isManualUpload ? FieldValues.Y : FieldValues.N;
        const apiResult = await this._titleIntakeController.Queue(this.moonJobID, option)        
        return apiResult;
    }

    private fileValidator() {
        return (form: AbstractControl<TitleCommitmentUpload>): { [key: string]: any; } | null => {
            const fileControl: AbstractControl<File> | null = form.get('inputFile') as AbstractControl<File>;

            // File empty check
            if (fileControl?.value == null || fileControl.value.size === 0) {
                form.setErrors({ hasEmptyContent: true });
            }
            else {
                // File type check
                if (![FieldValues.FileType_PDF].includes(fileControl.value.type)) {
                    fileControl.setErrors({
                        invalidFileType: true,
                    });
                }
            }
            return null;
        }
    }

}

