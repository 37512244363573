// Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM
import { Injectable }           from "@angular/core";
// MS Imports
import { ApiBlobResult, ApiContentResult, 
         ApiResult}            from '@moon-core/models/api-result';
import { MoonApiClient }        from '@moon-core/services/moon-api.client';
import { Airplane }                 from "@moon-consumer/api/request/airplane.request";
import { FindAirplaneParameter }    from "@moon-consumer/api/request/find-airplane.parameter";
import { AirplaneFind }             from "@moon-consumer/api/response/airplane-find.response";
import { AirplaneGet }              from "@moon-consumer/api/response/airplane-get.response";
import { ConstantString }       from "@moon-shared/constants/constant-string";
import { RoutePath } from "@moon-shared/constants/route-path";

@Injectable()
export class AirplaneController {
  
  private getBaseUrl(): string {
    return this._moonApi.getBaseUrl(ConstantString.Airplane);
  }

  constructor(private _moonApi: MoonApiClient) { }
  
/**
 *   POST: Public/v1/Airplane/find
 */
  public async Find(findParameter: FindAirplaneParameter
    ): Promise<ApiContentResult<AirplaneFind[]>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), 'find');
    return this._moonApi.postWithResultAsync(url, findParameter);
  }
  
/**
 *   GET: Public/v1/Airplane
 */
  public async GetList(
    ): Promise<ApiContentResult<AirplaneGet[]>> {
    
    const url = this.getBaseUrl();
    return this._moonApi.getAsync<AirplaneGet[]>(url);
  }
  
/**
 *   POST: Public/v1/Airplane
 */
  public async Create(airplane: Airplane
  ): Promise<ApiContentResult<number>> {

    const formData = new FormData();
    formData.set('termsPagesCsv', airplane.termsPagesCsv);
    if (airplane.inputFile) {
      formData.set('inputFile', airplane.inputFile);
    }
    const url = this.getBaseUrl();
    return this._moonApi.postWithResultAsync(url, formData);
  }
  
/**
 *   POST: Public/v1/Airplane
 */
  public async ProcessAirplaneTerms(airplaneID: number): Promise<ApiBlobResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), airplaneID, ConstantString.Process);
    return this._moonApi.getBlobWithPostAsync(url, {});
  }
  
/**
 *   GET: Public/v1/Airplane/{documentID}
 */
  public async GetByID(airplaneID: number
    ): Promise<ApiContentResult<AirplaneGet>> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), airplaneID);
    return this._moonApi.getAsync<AirplaneGet>(url);
  }
  
/**
 *   PUT: Public/v1/Airplane/{documentID}
 */
  public async Update(airplaneID: number, airplane: Airplane
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), airplaneID);
    return this._moonApi.putAsync(url, airplane);
  }
  
/**
 *   DELETE: Public/v1/Airplane/{documentID}
 */
  public async Delete(airplaneID: number
    ): Promise<ApiResult> {
    
    const url = this._moonApi.combineUrl(this.getBaseUrl(), airplaneID);
    return this._moonApi.deleteAsync(url);
  }
  
/**
 *   GET: Public/v1/Airplane/{airplaneID}/DocxBlob
 */
  public async GetDocxBlob(airplaneID: number
    ): Promise<ApiBlobResult> {
    const url = this._moonApi.combineUrl(this.getBaseUrl(), airplaneID, RoutePath.DocxBlob);
    return this._moonApi.getBlobAsync(url);
  }
}
