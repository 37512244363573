import { Injectable } from '@angular/core';
import { MoonApplicationInsight } from '@moon-core/services/moon-application-insight.service';
@Injectable()
export class FileSaverService {
    constructor(private _moonApplicationInsight: MoonApplicationInsight) { }

    public ValidateFileSize(file: File, maxSizeInBytes: number): boolean { 
        // Check if the file exists and has a size
        if (!file || file.size === 0) {
            return false;
        }
        // Check if the file size is less than or equal to the max size in bytes
        return file.size <= (maxSizeInBytes);
    }
    public ValidateFileExtension(file: File, fileExtensions: string[]): boolean { 
        // Check if the file exists and has a size
        if (!file || file.size === 0) {
            return false;
        }
        // Get the file extension
        const fileExtension = file.name.getFileExtension().toLowerCase();
        
        // Check if the file extension is in the list of allowed extensions
        if (fileExtension) {
            return fileExtensions.map(ext => ext.toLowerCase()).includes(fileExtension);
        }

        return false;
    }
    public SaveFile(data: Blob, contentDisposition: string | null) {
        if (data == null)
            return;

        const defaulFileName = 'file';
        const blob = new Blob([data], { type: 'application/octet-stream' });

        // Extract filename from content disposition header
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition || '');
        let fileName = matches && matches.length > 1 ? matches[1] : defaulFileName;

        // Create a download link and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (fileName) {
            fileName = fileName.replaceAll('"', '');
            link.download = fileName;
        }
        this._moonApplicationInsight.logEvent(`FileSaverService.SaveFile(): Downloading ${fileName}`)
        link.click();
    }
}