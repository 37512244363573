<!-- Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM -->
<moon-loading *ngIf="MSLoading"></moon-loading>
<div *ngIf="!MSLoading">
    <moon-table-page
        [MSPageTitle]="MSPageTitle"
        [MSItemList]="MSSubscriberList"
        [MSDisplayCreateButton]="MSDisplayCreate"
        [MSDisplayFindButton]="MSDisplayFind"
        [MSNumberOfFilteredTableRows]="MSNumberOfFilteredValues"
    >
        <p-table
            #MSTable
            [value]="MSSubscriberList"
            [columns]="MSTableColumns"
            (onFilter)="MSOnFilter($event.filteredValue)"
            [tableStyle]="{ 'min-width': '50rem' }"
            [globalFilterFields]="['firstName', 'lastName', 'preferredUserName']"
        >
            <ng-template pTemplate="caption">
                <moon-table-header
                    [MSPageTitle]="MSPageTitle"
                    [MSEnableSearch]="MSEnableSearch"
                    [MSDisplayCreateButton]="MSDisplayCreate"
                    [MSDisplayFindButton]="MSDisplayFind"
                    (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                >
                </moon-table-header>
            </ng-template>
            <ng-template
                pTemplate="header"
                let-columns
            >
                <tr>
                    <th
                        *ngFor="let col of columns"
                        [class]="col.class"
                    >
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>

            <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
            >
                <tr>
                    <td
                        *ngFor="let col of columns"
                        [class]="col.class"
                    >
                        <div [ngSwitch]="col.field">
                            <span
                                *ngSwitchCase="'userName'"
                                class="text-left"
                            >
                                {{ rowData.firstName }} {{ rowData.lastName}} <em>({{ rowData.preferredUserName}})</em>
                            </span>
                            <span
                                *ngSwitchCase="'subscription'"
                                class="text-center"
                            >
                                <p-button
                                    *ngIf="rowData.isSubscribed === 'N'"
                                    label="Subscribe"
                                    [rounded]="true"
                                    [raised]="true"
                                    severity="danger"
                                    (click)="MSSubscribeUser(rowData)"
                                />
                                <p-button
                                    *ngIf="rowData.isSubscribed === 'Y'"
                                    icon="pi pi-bell"
                                    label="Subscribed"
                                    [rounded]="true"
                                    [raised]="true"
                                    (click)="MSUnsubscribeUser(rowData)"
                                />
                            </span>
                            <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </moon-table-page>
</div>