// Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM
// Moon Auto Generated (1.6.0) at 7/12/2024 6:04:43 AM
// Moon Auto Generated (1.6.0) at 7/4/2024 5:50:19 AM
// Moon Auto Generated (1.6.0) at 7/4/2024 5:44:58 AM
// Moon Auto Generated (1.6.0) at 5/24/2024 7:04:39 AM
// Moon Auto Generated (1.6.0) at 5/6/2024 7:16:59 AM
// Moon Auto Generated (1.5.0) at 2/21/2024 10:18:48 AM
export class RouteParameter {
    
    
    public static readonly AirplaneID: string = "AirplaneID";
    public static readonly ChatLogID: string = 'ChatLogID';    
    public static readonly DeploymentID: string = "DeploymentID";
    public static readonly DocumentCategoryID: string = 'DocumentCategoryID';
    public static readonly DocumentID: string = 'DocumentID';
    public static readonly EmailWhitelistID = "EmailWhitelistID" as const;
    public static readonly EntityID: string = 'EntityID';
    public static readonly EntityType: string = 'EntityType';
    public static readonly EnvironmentName: string = 'EnvironmentName';
    public static readonly ExternalRoutePage: string = 'ExternalRoutePage';
    public static readonly MezzLoanChunkID: string = "MezzLoanChunkID";
    public static readonly MezzLoanID: string = "MezzLoanID";
    public static readonly MoonNotificationID = "MoonNotificationID" as const;
    public static readonly NodeEnvironmentID: string = 'NodeEnvironmentID';
    public static readonly NodeEnvironmentUserGroupMapID: string = 'NodeEnvironmentUserGroupMapID';
    public static readonly SkillExampleID: string = 'SkillExampleID';
    public static readonly SkillID: string = 'SkillID';
    public static readonly SkillNumber: string = 'SkillNumber' as const;
    public static readonly SkillTestDataID = "SkillTestDataID" as const;
    public static readonly TemplateID: string = "TemplateID";
    public static readonly TitleDocumentID: string = 'TitleDocumentID';
    public static readonly TitleSummaryID: string = 'TitleSummaryID';
    public static readonly UserGroupID: string = "UserGroupID";
    public static readonly UserInfoID = 'UserInfoID' as const;
    public static readonly UserOptionMapID: string = "UserOptionMapID";
    
}
