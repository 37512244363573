<!-- Moon Auto Generated (1.6.0) at 9/11/2024 10:24:04 PM -->
<moon-form-page [MSParentForm]="MSParentForm"
                [MSIsFormSaving]="MSIsFormSubmitting"
                (MSOnClickEventSubmit)="OnClickSubmit()"
                (MSOnClickEventCancel)="OnClickCancel()">
    <div [formGroup]="MSParentForm">
        <!-- Form PlaceHolder - DO NOT REMOVE -->
        <moon-form-control
            [hidden]="true"
            [MSLabel]="'Skill Number'"
            [MSFormControl]="MSParentForm.controls.skillNumber">
            <input
                type="text"
                formControlName="skillNumber"
                pInputText
            />
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Input Data'"
            [MSFormControl]="MSParentForm.controls.inputData"
        >
            <textarea
                #inputData
                [formControl]="MSParentForm.controls.inputData"
                pInputTextarea
            >
            </textarea>
        </moon-form-control>

        <moon-form-control
            [MSLabel]="'Output Data'"
            [MSFormControl]="MSParentForm.controls.outputData"
        >
            <textarea
                #outputData
                [formControl]="MSParentForm.controls.outputData"
                pInputTextarea
            >
            </textarea>
        </moon-form-control>

    </div>
</moon-form-page>
