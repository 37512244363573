<!-- Moon Auto Generated (1.0.0) at 12/2/2023 9:14:42 AM -->
<moon-table-page
    [MSPageTitle]="MSPageTitle"
    [MSItemList]="MSSurveyList"
    [MSNumberOfFilteredTableRows]="MSTable?.filteredValue?.length || 0"
    [MSDisplayCreateButton]="MSDisplayCreate"
    [MSDisplayFindButton]="MSDisplayFind"
    [MSDisplaySecondaryButton]="MSDisplaySecondary"
    [MSSecondaryButtonIcon]="'pi pi-refresh'"
    [MSSecondaryButtonToolTip]="'Reload'"
    (MSEventOnClickCreate)="OnClickCreate()"
    (MSEventOnClickFind)="OnClickFind()"
    (MSEventOnClickSecondary)="MSReloadList()"
>
    <p-table
        #MSTable
        [columns]="MSTableColumns"
        [scrollable]="true"
        [rowHover]="true"
        [value]="MSSurveyList"
        selectionMode="multiple"
        styleClass="p-datatable-gridlines p-datatable-striped"
    >
        <ng-template
            pTemplate="emptymessage"
            let-columns
        >
            <tr>
                <td
                    [style.flex-basis]="'auto'"
                    [attr.colspan]="columns?.length"
                    class="moon-text-center"
                >
                    No data found.
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="caption">
            <moon-table-header
                [MSPageTitle]="MSPageTitle"
                [MSEnableSearch]="MSEnableSearch"
                [MSDisplayCreateButton]="false"
                [MSDisplayFindButton]="MSDisplayFind"
                [MSDisplaySecondaryButton]="MSDisplaySecondary"
                MSSecondaryButtonIcon="pi pi-refresh"
                MSSecondaryButtonToolTip="Refresh"
                (MSEventOnClickFind)="OnClickFind()"
                (MSEventOnSearchInput)="OnSearchInput(MSTable, $event)"
                (MSEventOnClickSecondary)="MSReloadList()"
            >
                <ng-template moonTemplate="button-left">
                    <button
                        pButton
                        pRipple
                        class="p-button-raised ml-2"
                        icon="pi pi-plus"
                        label="Add New Survey"
                        (click)="OnClickCreate()"
                    > </button>
                </ng-template>

            </moon-table-header>
        </ng-template>

        <ng-template
            pTemplate="header"
            let-columns
        >
            <tr>
                <th
                    *ngFor="let col of columns"
                    [class]="col.class"
                    [pSortableColumn]="col.sortable ? col.field: ''"
                    [ngClass]="{ 'actions-column': col.field === 'actions' || col.field === 'resultButtons' }"
                >

                    <div *ngIf="col.showColumnFilter; else normalColumnHeader">
                        <div class="flex gap-1 align-items-center">
                            {{ col.header }}
                            <p-columnFilter
                                [field]="col.field"
                                matchMode="in"
                                display="menu"
                                [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false"
                                (click)="$event.stopPropagation()"
                                (keypress)="$event.stopPropagation()"
                            >
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">{{ col.header }}</span>
                                    </div>
                                </ng-template>
                                <ng-template
                                    pTemplate="filter"
                                    let-value
                                    let-filter="filterCallback"
                                >
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="col.filterOptions"
                                        (onChange)="filter($event.value)"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Any"
                                    >
                                        <ng-template
                                            let-option
                                            pTemplate="item"
                                        >
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">{{ option.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </div>

                    <ng-template #normalColumnHeader>
                        <div class="flex gap-1 align-items-center">
                            {{ col.header }}
                            <p-sortIcon
                                *ngIf="col.sortable"
                                [field]="col.field"
                            ></p-sortIcon>
                            <p-columnFilter
                                class="hidden"
                                (click)="$event.stopPropagation()"
                                (keypress)="$event.stopPropagation()"
                            ></p-columnFilter>
                        </div>
                    </ng-template>
                </th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
        >
            <tr>
                <ng-container *ngFor="let col of columns">
                    <td
                        [title]="(col.field === 'actions' || col.field === 'resultButtons') ? '' : 'Click to view details'"
                        [class]="col.class"
                        [ngClass]="{'actions-column': (col.field === 'actions' || col.field === 'resultButtons'), clickable: (col.field !== 'actions' && col.field !== 'resultButtons'),
                            'moon-disable-row': MSDisableRow(rowData)
                        }"
                        (click)="col.field === 'actions' || col.field === 'resultButtons'"
                        (keypress)="col.field === 'actions' || col.field === 'resultButtons'"
                    >
                        <ng-container [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'actions'">
                                <div class="text-center">
                                    <moon-custom-dropdown
                                        [model]="rowData.actions"
                                        [MSMenuStyleClass]="'w-15rem'"
                                    >
                                        <i class="pi pi-ellipsis-v text-2xl"></i>
                                    </moon-custom-dropdown>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'b2CStatus'">
                                <p-tag
                                    styleClass="bg-unset text-primary-600 text-base flex-row-reverse gap-2"
                                    [rounded]="true"
                                    [value]="rowData[col.field]"
                                    [icon]="MSGetStatusIcon(rowData)"
                                />
                            </ng-container>
                            <ng-container *ngSwitchCase="'lastUpdated'">
                                <span> {{ rowData.lastUpdatedBy }} <br />
                                    at {{ rowData.lastUpdatedAt | moondate}}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'createdAt'">
                                {{rowData[col.field] | moondate}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'resultButtons'">
                                <div *ngIf="!MSDisableRow(rowData)">     
                                    <button
                                        *ngFor="let resultBtn of rowData.resultButtons"
                                        #btn
                                        type="button"
                                        pButton
                                        pRipple
                                        [label]="resultBtn.label"
                                        [pTooltip]="resultBtn.tooltip"
                                        [icon]="resultBtn.icon"
                                        [disabled]="MSDownloading"
                                        (click)="resultBtn.command()"
                                    > </button>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>{{ rowData[col.field] }}</ng-container>
                        </ng-container>
                    </td>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</moon-table-page>

<moon-loading
    *ngIf="MSLoading"
    [MSLoadingMessage]="'Processing'"
></moon-loading>
